import { Stack } from '@fluentui/react';
import React from 'react';
import ProgressBar from '../../progressBar/progressBar';
import TitlePane from '../../titlePane/titlePane';

const ListPaneSection = (props: {
  activeClass: string;
  titlePaneHeader: string;
  showProgress: boolean;
  renderCommandBar: any;
  renderShotcutPane: any;
  children: any;
}) => {
  return (
    <Stack horizontal>
      {props.renderShotcutPane()}

      <div className={props.activeClass}>
        <Stack
          className="project-list-header right-panel"
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            paddingRight: 0,
          }}
          styles={{ root: { width: '100%', paddingRight: 0 } }}
        >
          <TitlePane
            headerTitle={props.titlePaneHeader}
          />
          {props.renderCommandBar()}
          <ProgressBar show={props.showProgress}/>
        </Stack>

        <Stack style={{ alignSelf: 'center' }}>
          {props.children}
          { /* LIST PANE*/}
        </Stack>
      </div>
    </Stack>
  );
};

export default ListPaneSection;
