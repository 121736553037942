import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    FontIcon,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    Link,
    mergeStyles,
    mergeStyleSets,
    Stack,
} from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import CommandBarRight from '../commandBar/commandBarRight';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {Field, Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {DatePickerAdapter, DropDownAdaptater, TextFieldFF} from '../../../shared/fluentFinalForm';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import client from '../../../api';
import _ from 'lodash';
import DataGridWithModal, {ModalGridContext} from '../../../shared/dataGridModal/component';
import CIAWModalContent from './ciawModal/ciawModal';
import i18n from '../../../../i18n';
import HistoryBar from '../../../shared/historyBar/historyBar';
import {createCabCertification, deleteCabCertification} from '../../../reducers/addressBookReducer';
import {v4 as uuidv4} from 'uuid';
import {format24hDateTime, formatDateTimeToISOString} from '../../../shared/util';
import {uploadInstructionDoc} from '../../../reducers/contractReducer';
import {CERTIFICATION_TAXONOMY_IDS, CiawFormData, defaultCertificateData} from '../../../types/ciaw';
import {readCabCertificationCIAW, saveCIAWStateAttr} from '../../../reducers/ciawReducer';
import moment from 'moment';
import textField from "../../../shared/fluentFinalForm/TextField";
import ProjectHeader from "../../../shared/projectHeader/projectHeader";

const contentStyle = mergeStyles({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    selectors: {
        '@media(max-width: 1100px)': {
            width: '97.4%',
            height: 'calc(100vh)',
            position: 'absolute',
        },
    },
});

const classNames = mergeStyleSets({
    fullWidth: {width: '100%'},
    halfWidth: {width: '49%'},
});

const stackStyles: IStackStyles = {root: {padding: 0, marginBottom: 10}};

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50,
        width: 0,
        flexGrow: 1,
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

const DocumentPane = () => {
    const {t} = useTranslation();
    // const store = useStore();
    const dispatch = useDispatch();
    const inputFile: any = useRef(null);

    const [selectedCertificate, setSelectedCertificate] = useState<any>({})
    const [openModal, setOpenModal] = useState<boolean>(false)

    const {
        selectedCu,
        selectedBu,
    } = useSelector(
      (state: any) => state.projectDPlan
    );

    const {
        selectedCiaw,
        ciawDropdownData,
        showProgressBar,
        formData,
        collapseBookmarkPane,
        timeClockSelectedDate,
        ciawCancelResult
    } = useSelector(
      (state: any) => state.ciaw
    );

    const {ciawCertificate} = useSelector((state: any) => state.ciaw);
    const {userInfo} = useSelector((state: any) => state.uprince);
    const {showContractProgressBar} = useSelector((state: any) => state?.contract?.showProgressBar);

    const newCertificateData = defaultCertificateData.map(defaultData => {
        if (ciawCertificate && ciawCertificate.length > 0) {
            const newData = ciawCertificate?.find((item: { certificationTaxonomyId: string; }) => item.certificationTaxonomyId === defaultData.certificationTaxonomyId);

            return {...defaultData, ...newData};
        }
        return defaultData;
    });

    const ciawColumns = [
        {
            key: 'column1',
            name: '',
            fieldName: 'validity',
            minWidth: 20,
            maxWidth: 50,
            isResizable: true,
            onRender: (item: any) => {
                return !item?.validity && <FontIcon
                    style={{margin: 5}}
                    aria-label="Warning"
                    iconName="Warning"
                />
            }
        },
        {
            key: 'column2',
            name: i18n.t('certificationType'),
            fieldName: 'title',
            minWidth: 120,
            maxWidth: 240,
            isResizable: true,
        },
        {
            key: 'column3',
            name: i18n.t('certificationTitle'),
            fieldName: 'certificationTitle',
            minWidth: 120,
            maxWidth: 240,
            isResizable: true,
        },
        {
            key: 'column5',
            name: i18n.t('expireDate'),
            fieldName: 'endDate',
            minWidth: 120,
            maxWidth: 240,
            isResizable: true,
            onRender: (item: any) => {
                return <span>{item?.endDate ? format24hDateTime(item?.endDate) : '-'}</span>
            }
        },
        {
            key: 'column6',
            name: i18n.t(''),
            fieldName: 'upload',
            minWidth: 120,
            maxWidth: 300,
            isResizable: true,
            onRender: (item: any) => {
                if (formData && formData.orgCountryCode) {
                    if (formData?.orgCountryCode === "420f5bbd-0891-44ae-9527-75341234ec49") {
                        return item?.certificationTaxonomyId != CERTIFICATION_TAXONOMY_IDS.LIMOSA_ID &&
                            <div><FontIcon
                                aria-label="Upload"
                                iconName="Upload"
                                style={{margin: 5, cursor: 'pointer'}}
                                onClick={() => {
                                    setOpenModal(true)
                                    // inputFile?.current?.click();
                                }}
                            />
                                {renderDownloadButton(item)}
                            </div>
                    } else {
                        return item?.certificationTaxonomyId != CERTIFICATION_TAXONOMY_IDS.SSNI_ID &&
                            <div><FontIcon
                                aria-label="Upload"
                                iconName="Upload"
                                style={{margin: 5, cursor: 'pointer'}}
                                onClick={() => {
                                    setOpenModal(true)
                                    // inputFile?.current?.click();
                                }}
                            />
                                {renderDownloadButton(item)}
                            </div>
                    }
                }
            }
        },
    ];

    const onSubmit = async (values: any) => {
        const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(300);

    };

    useEffect(() => {

        return () => {
            dispatch(saveCIAWStateAttr('ciawFormData', null));
        }
    }, []);

    useEffect(() => {
        if (formData && formData.id) {
            dispatch(saveCIAWStateAttr('ciawFormData', formData));
        }
    }, [formData, ciawCancelResult]);

    const getInitialFormValues = (formData: CiawFormData) => {
        // console.log("🚀 ~ file: component.tsx ~ line 153 ~ getInitialFormValues ~ formData", formData)
        if (formData && formData?.id) {
            return {
                employee: formData?.cabPersonId,
                employeeName: formData?.cabPersonName,
                date: formData?.date,
                projectSequenceCode: {value: formData?.project, label: formData?.projectTitle},
                ciawStatus: formData?.status?.key,
                projectManager: formData?.projectManager,
                projectManagerName: formData?.projectManager,
                ciawCode: formData?.ciawCode,
                ciawRegistrationStatus: formData?.ciawRegistrationStatus,
                nationality: formData?.nationality,
                certifiaction: formData?.cabCertification,
                projectCiawCode: formData?.projectCiawCode,
                organisation: formData?.organisation,
                orgCiawCode: formData?.orgCiawCode,
                orgCountryCode: formData?.orgCountryName,
                certificationId: formData?.certificationId,
                ciawError: formData?.errorWarning,
                ciawWarning: formData?.remarks?.remarkList?.remark[0],
                eligibilityError: formData?.errorWarning,    //TODO: set eligibilityError data
                orgCountryName: formData?.orgCountryName,
            };
        }

        return formData;
    };

    const projectOptions = (inputValue: any) =>
      new Promise((resolve) => {
          resolve(getProjectByName(inputValue));
      });

    const getProjectByName = async (name: string) => {
        let projects: any = [];
        if (name.length >= 2 || name === '') {
            let cu = selectedCu ? selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
            let bu = selectedBu ? selectedBu?.key : null;
            const response = await client.post(
              'ContractingUnitTenants/GetProjectsByUserProjectFilter', {
                  title: name,
                  projectTypeId: null,
                  managementLevelId: null,
                  toleranceStateId: null,
                  toleranceState: null,
                  contractingUnit: cu ? cu : null,
                  buId: bu,
                  sorter: {
                      attribute: null,
                      order: null
                  }
              });
            return formatProjectResponse(response);
        }
        return projects;
    };

    const formatProjectResponse = (response: any) => {
        let options: { value: string; label: string }[] = [];
        if (response && response.data && response.data.result) {
            const data = response.data.result;

            if (data && _.isArray(data)) {
                options = data.map((item: any) => {
                    return {
                        value: item.sequenceCode,
                        label: item.title,
                        sequenceCode: item.sequenceCode
                    };
                });
            }
            return options;
        }
    };

    const renderDownloadButton = (item: any) => {
        if (item?.certificationUrl) {
            return <FontIcon
              aria-label="Download"
              iconName="Download"
              style={{margin: 5, cursor: 'pointer'}}
              onClick={() => {
                  let link = document.createElement('a');
                  link.href = item?.certificationUrl;
                  link.download = item?.certificationUrl;
                  link.click();
              }}
            />
        } else {
            return ''
        }
    };

    return <div>
        <Stack
          className="project-list-header right-panel"
          style={{backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0}}
          styles={{root: {width: '100%', paddingRight: 0}}}
        >
            <TitlePane
              close={true}
              headerTitle={""}
              collapse={true}
              isCollapse={collapseBookmarkPane}
              type={1}
            />
            <CommandBarRight/>
            <ProgressBar show={showProgressBar ? showProgressBar : showContractProgressBar}/>
        </Stack>

        <div style={{height: screen.height - 328, overflow: 'overlay', backgroundColor: '#fff'}}>
            <Stack
              className={`project-detail-list ${(collapseBookmarkPane && window.innerWidth > 1440) ? 'four-pane-full-inner-container' : 'four-pane-full-inner-container'} cpc  card-container`}
              styles={{
                  root: {
                      width: '100%',
                      height: '76vh',
                  },
              }}
            >
                {/*doc*/}
                <Stack
                  className={contentStyle}
                  horizontalAlign="start"
                  verticalAlign="start"
                  padding={0}
                  gap={0}
                  styles={{
                      root: {
                          width: '100%',
                          height: '100%',
                      },
                  }}
                >
                    <div className="proj-detail-block">
                        <Form
                          onSubmit={onSubmit}
                          initialValues={getInitialFormValues(formData ? formData : null)}
                          mutators={{
                              setEmployeeName: (args, state, utils) => {
                                  const field = state.fields['employeeName'];
                                  field.change(args[0]);
                              },
                              setProjectManagerName: (args, state, utils) => {
                                  const field = state.fields['projectManagerName'];
                                  field.change(args[0]);
                              },
                          }}
                          render={({
                                       handleSubmit,
                                       form,
                                       submitting,
                                       pristine,
                                       values,
                                   }) => {
                              return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <ProjectHeader projectDetails={{
                                        title: values?.projectSequenceCode?.label,
                                        company: values?.organisation,
                                        pmName: values?.projectManagerName
                                    }}/>
                                    <div className="document-pane-card">
                                        <div className={'card-header'}>
                                            <Link href="#" id="ciaw-header">
                                                <Label>1. {t('ciawHeader')}</Label>
                                            </Link>
                                        </div>
                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field name="employee">
                                                        {({input, meta}) => (
                                                          <PeoplePicker
                                                            label={t('employeeName')}
                                                            isDisabled={true}
                                                            selectedItem={values?.employee ? [{
                                                                key: values?.employee,
                                                                text: values?.employeeName,
                                                            }] : []}
                                                            onChange={(items: any) => {
                                                                input.onChange;
                                                                return items;
                                                            }}
                                                            onPersonSelected={(item: any) => {
                                                                input.onChange(item.key);
                                                                form.mutators.setEmployeeName(item.text);
                                                                return item;
                                                            }}
                                                          />
                                                        )}
                                                    </Field>
                                                    <Field
                                                      name="employeeName"
                                                      component={'input'}
                                                      type={'text'}
                                                      disabled={true}
                                                      hidden
                                                    />
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="date"
                                                      component={DatePickerAdapter}
                                                      lable={t('date')}
                                                      parse={value => value && formatDateTimeToISOString(value)}
                                                      format={value => (value ? new Date(value) : null)}
                                                      disabled={true}
                                                      // minDate={new Date()}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>

                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="ciawStatus"
                                                      component={DropDownAdaptater}
                                                      lable={t('ciawStatus')}
                                                      placeholder={t('ciawStatus')}
                                                      options={ciawDropdownData?.status}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="projectCiawCode"
                                                      component={textField}
                                                      lable={t('projectCiawCode')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>

                                        {/*<Stack*/}
                                        {/*  horizontal*/}
                                        {/*  wrap*/}
                                        {/*  styles={stackStyles}*/}
                                        {/*  tokens={stackTokens}*/}
                                        {/*>*/}
                                        {/*    <Stack.Item grow={6} styles={stackItemStyles}>*/}
                                        {/*        <div className={`${classNames.fullWidth}`}>*/}
                                        {/*            <Label>{t('projectSequenceCode')}</Label>*/}
                                        {/*            <Field*/}
                                        {/*              name="projectSequenceCode"*/}
                                        {/*              // validate={required}*/}
                                        {/*            >*/}
                                        {/*                {({input, meta}) => (*/}
                                        {/*                  <CustomDropdown*/}
                                        {/*                    id={values?.businessUnit + 'projectSequenceCode'}*/}
                                        {/*                    onChange={(*/}
                                        {/*                      key: string,*/}
                                        {/*                      text: string*/}
                                        {/*                    ) => {*/}
                                        {/*                        if (key) {*/}
                                        {/*                            input.onChange({value: key, label: text});*/}
                                        {/*                        } else {*/}
                                        {/*                            input.onChange(null);*/}
                                        {/*                        }*/}
                                        {/*                        //form.mutators.setContractingUnit([]);*/}
                                        {/*                        form.submit();*/}
                                        {/*                    }}*/}
                                        {/*                    selectedOption={*/}
                                        {/*                        values && values?.projectSequenceCode*/}
                                        {/*                          ? values?.projectSequenceCode : null}*/}
                                        {/*                    promiseOptions={projectOptions}*/}
                                        {/*                    validationMessage={''}*/}
                                        {/*                    required={false}*/}
                                        {/*                    reference={''}*/}
                                        {/*                    noHeight={true}*/}
                                        {/*                    disabled={false}*/}
                                        {/*                  />*/}
                                        {/*                )}*/}
                                        {/*            </Field>*/}
                                        {/*        </div>*/}
                                        {/*    </Stack.Item>*/}
                                        {/*</Stack>*/}

                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="organisation"
                                                      component={textField}
                                                      lable={t('organisation')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="orgCiawCode"
                                                      component={textField}
                                                      lable={t('organisationCiawCode')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>

                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="orgCountryName"
                                                      component={textField}
                                                      lable={t('orgCountryCode')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="certificationId"
                                                      component={textField}
                                                      lable={t('limosaSsniId')}  //Limosa/SSNI id
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                    </div>

                                    <div className="document-pane-card">
                                        <div className={'card-header'}>
                                            <Link href="#" id="check-in-at-work">
                                                <Label>2. {t('checkInAtWork')}</Label>
                                            </Link>
                                        </div>
                                        {/*<Stack*/}
                                        {/*  horizontal*/}
                                        {/*  wrap*/}
                                        {/*  styles={stackStyles}*/}
                                        {/*  tokens={stackTokens}*/}
                                        {/*>*/}
                                        {/*    <Stack.Item grow={6} styles={stackItemStyles}>*/}
                                        {/*        <div className={`${classNames.fullWidth}`}>*/}
                                        {/*            <Label>{t('projectSequenceCode')}</Label>*/}
                                        {/*            <Field*/}
                                        {/*              name="projectSequenceCode"*/}
                                        {/*              // validate={required}*/}
                                        {/*            >*/}
                                        {/*                {({input, meta}) => (*/}
                                        {/*                  <CustomDropdown*/}
                                        {/*                    id={values?.businessUnit + 'projectSequenceCode'}*/}
                                        {/*                    onChange={(*/}
                                        {/*                      key: string,*/}
                                        {/*                      text: string*/}
                                        {/*                    ) => {*/}
                                        {/*                        if (key) {*/}
                                        {/*                            input.onChange({value: key, label: text});*/}
                                        {/*                        } else {*/}
                                        {/*                            input.onChange(null);*/}
                                        {/*                        }*/}
                                        {/*                        //form.mutators.setContractingUnit([]);*/}
                                        {/*                        form.submit();*/}
                                        {/*                    }}*/}
                                        {/*                    selectedOption={*/}
                                        {/*                        values && values?.projectSequenceCode*/}
                                        {/*                          ? values?.projectSequenceCode : null}*/}
                                        {/*                    promiseOptions={projectOptions}*/}
                                        {/*                    validationMessage={''}*/}
                                        {/*                    required={false}*/}
                                        {/*                    reference={''}*/}
                                        {/*                    noHeight={true}*/}
                                        {/*                    disabled={true}*/}
                                        {/*                  />*/}
                                        {/*                )}*/}
                                        {/*            </Field>*/}
                                        {/*        </div>*/}
                                        {/*    </Stack.Item>*/}
                                        {/*    <Stack.Item grow={6} styles={stackItemStyles}>*/}
                                        {/*        <div className={`${classNames.fullWidth}`}>*/}
                                        {/*            <Field name="projectManager">*/}
                                        {/*                {({input, meta}) => (*/}
                                        {/*                  <PeoplePicker*/}
                                        {/*                    label={t('projectManager')}*/}
                                        {/*                    isDisabled={true}*/}
                                        {/*                    selectedItem={values?.employee ? [{*/}
                                        {/*                        key: values?.projectManager,*/}
                                        {/*                        text: values?.projectManagerName,*/}
                                        {/*                    }] : []}*/}
                                        {/*                    onChange={(items: any) => {*/}
                                        {/*                        input.onChange;*/}
                                        {/*                        return items;*/}
                                        {/*                    }}*/}
                                        {/*                    onPersonSelected={(item: any) => {*/}
                                        {/*                        input.onChange(item.key);*/}
                                        {/*                        form.mutators.setProjectManagerName(item.text);*/}
                                        {/*                        return item;*/}
                                        {/*                    }}*/}
                                        {/*                  />*/}
                                        {/*                )}*/}
                                        {/*            </Field>*/}
                                        {/*            <Field*/}
                                        {/*              name="projectManagerName"*/}
                                        {/*              component={'input'}*/}
                                        {/*              type={'text'}*/}
                                        {/*              disabled={true}*/}
                                        {/*              hidden*/}
                                        {/*            />*/}
                                        {/*        </div>*/}
                                        {/*    </Stack.Item>*/}
                                        {/*</Stack>*/}
                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="ciawCode"
                                                      component={TextFieldFF}
                                                      lable={t('ciawCode')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="ciawRegistrationStatus"
                                                      component={TextFieldFF}
                                                      lable={t('ciawRegistrationStatus')}
                                                      // placeholder={t('ciawRegistrationStatus')}
                                                      // options={[]}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="ciawError"
                                                      component={TextFieldFF}
                                                      lable={t('error')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.fullWidth}`}>
                                                    <Field
                                                      name="ciawWarning"
                                                      component={TextFieldFF}
                                                      lable={t('warning')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Stack.Item grow={6} styles={stackItemStyles}>
                                                <div className={`${classNames.halfWidth}`}>
                                                    <Field
                                                      name="eligibilityError"
                                                      component={TextFieldFF}
                                                      lable={t('eligibilityError')}
                                                      disabled={true}
                                                    />
                                                </div>
                                            </Stack.Item>
                                            {/*<Stack.Item grow={6} styles={stackItemStyles}>*/}
                                            {/*    <div className={`${classNames.fullWidth}`}>*/}
                                            {/*        <Field*/}
                                            {/*          name="ciawWarning"*/}
                                            {/*          component={TextFieldFF}*/}
                                            {/*          lable={t('warning')}*/}
                                            {/*          disabled={true}*/}
                                            {/*        />*/}
                                            {/*    </div>*/}
                                            {/*</Stack.Item>*/}
                                        </Stack>
                                        <Stack
                                          horizontal
                                          wrap
                                          styles={stackStyles}
                                          tokens={stackTokens}
                                        >
                                            <Field name="certifiaction">
                                                {({input, meta}) => (
                                                  <div style={{width: '100%'}}>
                                                      <DataGridWithModal
                                                        dataList={newCertificateData ? newCertificateData : defaultCertificateData}
                                                        // dataList={certificate ? filterCertificatesOnNationality(certificate) : defaultCertificateData}
                                                        modalTitle={t('certifiaction')}
                                                        deleteDataGrid={async (selection: any) => {
                                                            const selectedList = selection.map((item: any) => {
                                                                return item.id;
                                                            });

                                                            if (selectedList.length > 0) {
                                                                Promise.all([
                                                                    dispatch(deleteCabCertification(selectedList))
                                                                ]).then(() => {
                                                                    dispatch(readCabCertificationCIAW({
                                                                        personId: selectedCiaw[0]?.cabPersonId,
                                                                        date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
                                                                    }));
                                                                });
                                                            }
                                                        }}
                                                        title={`2.1 ${t('certifiactionsQualification')}`}
                                                        readOnly={true}  //TODO: show when data loded
                                                        columns={ciawColumns}
                                                        isLoaded={false}
                                                        keyProp={'caiwId'}
                                                        onDismiss={() => {
                                                            setOpenModal(false);
                                                        }}
                                                        isCompact={true}
                                                        getEditItem={(editItem: any) => {
                                                            setSelectedCertificate(editItem)
                                                        }}
                                                        selectionMode={1}
                                                        onItemInvoked={(item: any) => {
                                                        }}
                                                        openModal={openModal}
                                                      >
                                                          <ModalGridContext.Consumer>
                                                              {(modalProps: any) => (
                                                                <CIAWModalContent
                                                                  modalData={modalProps.editItem}
                                                                  dataList={modalProps.dataList}
                                                                  lotContractorId={values?.id}
                                                                  disabled={false}
                                                                  onChange={(item: any) => {
                                                                      // console.log("🚀 ~ file: component.tsx ~ line 563 ~ DocumentPane ~ item", item);
                                                                      const certificate = {
                                                                          id: item?.id ? item?.id : uuidv4(),
                                                                          certificationTaxonomyId: item?.certificationType,
                                                                          startDate: item?.startDate,
                                                                          endDate: item?.expireDate,
                                                                          projectSequenceCode: selectedCiaw[0]?.projectSequenceCode,
                                                                          personId: selectedCiaw[0]?.cabPersonId,
                                                                          certificationUrl: item?.certificationUrl,
                                                                          certificationTitle: item?.certificationTitle,
                                                                      };

                                                                      Promise.all([
                                                                          dispatch(createCabCertification(certificate))
                                                                      ]).then(() => {
                                                                          dispatch(readCabCertificationCIAW({
                                                                              personId: selectedCiaw[0]?.cabPersonId,
                                                                              date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
                                                                          }));
                                                                      });

                                                                      modalProps.closeModal();
                                                                      setOpenModal(false);
                                                                  }}
                                                                />
                                                              )}
                                                          </ModalGridContext.Consumer>
                                                      </DataGridWithModal>
                                                      <input
                                                        type="file"
                                                        id="file"
                                                        ref={inputFile}
                                                        hidden
                                                        onChange={(event: any) => {
                                                            const formData = new FormData();
                                                            if (event?.target?.files[0]) {
                                                                formData.append('file', event.target.files[0]);
                                                                formData.append('id', uuidv4());
                                                                Promise.all([
                                                                    dispatch(uploadInstructionDoc(formData))
                                                                ]).then((response: any) => {
                                                                    if (response[0]?.payload?.data?.result) {
                                                                        if (selectedCertificate) {
                                                                            Promise.all([
                                                                                dispatch(createCabCertification({
                                                                                    ...selectedCertificate,
                                                                                    certificationUrl: response[0]?.payload?.data?.result
                                                                                }))
                                                                            ]).then(() => {
                                                                                dispatch(readCabCertificationCIAW({
                                                                                    personId: selectedCiaw[0]?.cabPersonId,
                                                                                    date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
                                                                                }));
                                                                            });
                                                                        }
                                                                    } else {
                                                                        dispatch(readCabCertificationCIAW({
                                                                            personId: selectedCiaw[0]?.cabPersonId,
                                                                            date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
                                                                        }));
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                      />
                                                  </div>
                                                )}
                                            </Field>
                                        </Stack>
                                    </div>

                                    {
                                      formData?.historyLog && formData?.historyLog?.createdDate && (
                                        <div className="proj-detail-block" id="3">
                                            <div className="document-pane-card">
                                                <div className="marginTop marginBottom">
                                                    <Link href="#" id="history">
                                                        <Label>3. {t('history')} </Label>
                                                    </Link>
                                                    <HistoryBar
                                                      createdByUser={
                                                          formData.historyLog.createdBy
                                                            ? formData.historyLog.createdBy
                                                            : ''
                                                      }
                                                      updatedByUser={
                                                          formData.historyLog.modifiedBy
                                                            ? formData.historyLog.modifiedBy
                                                            : ''
                                                      }
                                                      createdDateTime={
                                                          formData.historyLog.createdDate
                                                            ? formData.historyLog.createdDate
                                                            : ''
                                                      }
                                                      updatedDateTime={
                                                          formData.historyLog.modifiedDate
                                                            ? formData.historyLog.modifiedDate
                                                            : ''
                                                      }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                      )
                                    }

                                    {/*<pre>{JSON.stringify(values)}</pre>*/}
                                </form>
                              )
                          }}
                        />
                    </div>
                </Stack>
            </Stack>
        </div>

        {/*<ConfirmationDialog*/}
        {/*  hidden={isCancelOpen}*/}
        {/*  title={t('areyousure')}*/}
        {/*  subText={t('Are you sure you want to cancel the CIAW?')}*/}
        {/*  onClickConfirmDialog={() => handleCancelCiaw()}*/}
        {/*  onClickCancelDialog={() => setIsCancelOpen(true)}*/}
        {/*  cancelButtonText={t('No, I won\'t!')}*/}
        {/*  confirmButtonText={t('Yes, I want!')}*/}
        {/*/>*/}
    </div>
};

export default DocumentPane