import React from 'react';
import {useTranslation} from 'react-i18next';
import {messageService} from '../../../services/messageService';
import {
  ActionButton,
  DatePicker,
  defaultCalendarStrings,
  Dropdown,
  IDropdownOption,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {saveMyDayPlanningStateAttr} from '../../../reducers/myDayPlanningReducer';
import {DpViewMode, GridView, toolButtonId, workerButtonId} from '../../../types/myDayPlanning';
import {formatDateTimeToISOString, stringDateToDateObject} from '../../../shared/util';
import {generateCIAW, generateRFQ} from '../../../reducers/projectDayPlanningReducer';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import PersonFilters from '../bottomLIstPane/filters';

const classNames = mergeStyleSets({
  wrapper: {
    display: 'block',
    maxWidth: '200px',
    minWidth: '180px',
  },
  aligment: {
    margin: '5px 0 0 5px'
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' }
});

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackTokens: IStackTokens = { childrenGap: 10 };

const CommandBarLeft = (props: { disable: boolean, isTopPane?: boolean, onSelectDate?: any, refresh?: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const viewModeDp: IDropdownOption[] = [
    {
      key: DpViewMode.OneHour,
      text: t('oneHour'),
    },
    {
      key: DpViewMode.TwoHour,
      text: t('twoHours'),
    },
    {
      key: DpViewMode.FourHour,
      text: t('fourHours'),
    },
    {
      key: DpViewMode.SixHour,
      text: t('sixHours'),
    },
    {
      key: DpViewMode.EightHour,
      text: t('eightHours'),
    },
    {
      key: DpViewMode.TwelveHour,
      text: t('twelveHours'),
    }
  ];

  const { pmolDropDowns } = useSelector(
    (state: any) => state.pmol
  );

  const { userInfo } = useSelector(
    (state: any) => state.uprince
  );

  const {
    view,
    mydPlanResourceType,
    myDpViewMode,
    selectedDate,
    // selectedBu,
    teamList,
    // selectedCu,
    planboardPersonId,
    planboardPersonName
  } = useSelector(
    (state: any) => state.mydPlan
  );

  const { selectedCu, selectedBu } = useSelector(
    (state: any) => state.projectDPlan
  );

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          messageService.sendMessage({ isNewMYDP: true });
        },
        disabled: props.disable
      },
    ];
  };

  const getPmolDropdown = (options: any) => {
    if (options) {
      const defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true
        }
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    }
    return [];
  };

  const handleCIAW = () => {
    const ciaw = {
      date: formatDateTimeToISOString(selectedDate),
      buId: selectedBu,
    }
    dispatch(generateCIAW(ciaw));
    // dispatch(saveProjectDayPlanningStateAttr('generateCIAW', true));
  };

  const handleGenerateRFQ = () => {
    let cu = selectedCu ? selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
    const filter = {
      executionDate: formatDateTimeToISOString(selectedDate),
      contractingUnit: [cu],
      buId: selectedBu,
    }
    dispatch(generateRFQ(filter))
  }

  const handleGenerateRFQDisable = () => {
    let pmol = teamList?.filter((t: any) => t?.pmol != null);
    return !(teamList && teamList.length > 0 && pmol && pmol.length > 0);
  }

  return (
    props.isTopPane ?
      <div className={`${classNames.aligment}`}>
        <Stack
          horizontal
          wrap
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item>
            <div className={`${classNames.wrapper}`}>
              <Dropdown
                placeholder={t('pmolStatus')}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(ev, item) => {
                  messageService.sendMessage({ pmolDpStatus: item?.key });
                }}
                disabled={false}
                options={getPmolDropdown(pmolDropDowns?.status)}
              /></div>
          </Stack.Item>
          <Stack.Item>
            <div className={`${classNames.wrapper}`}>
              <PeoplePicker
                label=''
                isDisabled={false}
                selectedItem={
                  planboardPersonId ? [{
                    key: planboardPersonId,
                    text: planboardPersonName,
                  }] : []}
                onChange={(item: any) => {
                  dispatch(saveMyDayPlanningStateAttr('planboardPersonId', ""));
                  dispatch(saveMyDayPlanningStateAttr('planboardPersonName', ""));
                  return item;
                }}
                onPersonSelected={(item: any) => {
                  dispatch(saveMyDayPlanningStateAttr('planboardPersonId', item?.cabPersonId));
                  dispatch(saveMyDayPlanningStateAttr('planboardPersonName', item?.text));
                  return item;
                }}
              /></div>
          </Stack.Item>
          {view == GridView.DAY && <Stack.Item>
            <div className={`${classNames.wrapper}`}>
              <Dropdown
                placeholder={t('viewMode')}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(ev, item) => {
                  dispatch(saveMyDayPlanningStateAttr('myDpViewMode', item?.key));
                  // messageService.sendMessage({ myDpViewMode: item?.key });
                }}
                disabled={false}
                options={viewModeDp}
                selectedKey={myDpViewMode}
              /></div>
          </Stack.Item>}
          {view == GridView.DAY && <Stack.Item>
            <div>
              <PrimaryButton disabled={handleGenerateRFQDisable()} text={t('generateRFQ')} onClick={() => handleGenerateRFQ()} />
            </div>
          </Stack.Item>}
          <Stack.Item>
            <div>
              <PrimaryButton text={t('generateCIAW')} disabled={handleGenerateRFQDisable()} onClick={() => handleCIAW()} />
            </div>
          </Stack.Item>
          {props.onSelectDate && view === GridView.DAY && <Stack.Item>
            <div className={`${classNames.wrapper}`}>
              <div style={{ width: 200, paddingLeft: 5 }}>
                <DatePicker
                  onSelectDate={props?.onSelectDate}
                  placeholder={t('selectadate')}
                  ariaLabel={t('projectCompletionDate')}
                  value={stringDateToDateObject(selectedDate)}
                  strings={defaultCalendarStrings}
                />
              </div>
            </div>
          </Stack.Item>}
          {props.refresh && <Stack.Item>
            <div className={`${classNames.wrapper}`}>
              <div style={{ width: 200, paddingLeft: 5 }}>
                <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={() => { props.refresh() }} allowDisabledFocus >
                  {t('refresh')}
                </ActionButton>
              </div>
            </div>
          </Stack.Item>}
        </Stack>
      </div> :
      <div>
        <Pivot
          selectedKey={mydPlanResourceType}
          onLinkClick={(item?: PivotItem) => {
            if (item) {
              if (item.props.itemKey! === workerButtonId) {
                dispatch(saveMyDayPlanningStateAttr('mydPlanResourceType', workerButtonId));
              } else if (item.props.itemKey! === toolButtonId) {
                dispatch(saveMyDayPlanningStateAttr('mydPlanResourceType', toolButtonId));
              }
            }
          }}
        >
          <PivotItem headerText={t('worker/vehicle')} itemKey={`${workerButtonId}`} />
          <PivotItem headerText={t('tool')} itemKey={`${toolButtonId}`} />
        </Pivot>
        {mydPlanResourceType === workerButtonId && <PersonFilters type={"teamPlanBoard"}/>}
      </div>
  );
};

export default CommandBarLeft;
