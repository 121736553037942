import React, {useEffect} from 'react';
import {Gantt} from '../../../shared/ganttv2/components/gantt/gantt';

import {walk} from '@nosferatu500/react-sortable-tree';
import {Task, ViewMode} from '../../../shared/ganttv2';
import {initTasks} from './helper';
import {ViewSwitcher} from './view-switcher';
import _ from 'lodash';
import {Label, Text} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {saveVPStateAttr, updateVpPersonDate, updateVpPO} from '../../../reducers/visualPlanReducer';
import {getCPCTeams, getPersonIDArray, getVPPOFromBarTask} from '../utils';
import {useTranslation} from 'react-i18next';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {ChartType} from '../../../shared/ganttv2/types/public-types';

// Init
const ListPaneComponent = (props: {
  vpList: any[];
  isDataLoaded: boolean;
  vpBarList: any[];
  handleBarTaskItemClick: (id: any) => void;
}) => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reduxBarTasks: any[] = useSelector(
    (state: any) => state.ms.mileStoneBarList,
  );

  const reloadMSList: any[] = useSelector(
    (state: any) => state.ms.reloadListPane,
  );

  const listTasks: any[] = useSelector(
    (state: any) => state.ms.mileStoneTaxonomyList,
  );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.ms.milestoneInteractBarList,
  );

  const isLabourTeamAssigned: any[] = useSelector(
    (state: any) => state.vp.isLabourTeamAssigned,
  );

  useEffect(() => {
    if (isLabourTeamAssigned) {

    }
  }, [isLabourTeamAssigned]);



  let columnWidth = 60;
  if (view === ViewMode.Day) {
    columnWidth = 60;
  } else if (view === ViewMode.Month) {
      columnWidth = 300;
  } else if (view === ViewMode.Week) {
      columnWidth = 250;
  } else if (view === ViewMode.QuaterYear) {
      columnWidth = 350;
  }

  const handleTaskChange = (task: any) => {
    const newTasks = interactBarTasks.map((t) => (t.id === task.id ? task : t));
    dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    dispatch(updateVpPO(getVPPOFromBarTask(task)));
    const cpcLabourTeams = getCPCTeams(task.teams, task.cpcId);
    if (cpcLabourTeams.length > 0 && task.cpcId) {
      getPersonIDArray(cpcLabourTeams);
      const payload = {
        personId: cpcLabourTeams,
        startDate: task.start,
        endDate: task.end,
      };
      dispatch(updateVpPersonDate(payload));
    }
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
    dispatch(saveVPStateAttr('selectedVpProjectBarType', task.type));
    if (task.type.toString() === 'milestone') {
      dispatch(saveVPStateAttr('selectedMileStone', task.milestoneSequanceId));
    }
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const stringToArr = (string1: any) => {
    if (string1) {
      const x = string1.trim().split(',');
      x.shift();
      return x;
    }
    return [];
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
      indexss += ',' + node.id;
    };
    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: true,
    });

    return indexss;
  };

  const onVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interactBarTasks.length) {
      const newTasks = interactBarTasks.filter((item) =>
        stringToArr(treeTotal1).includes(item.id),
      );
      dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    } else {
      const newTasks = reduxBarTasks.filter((item) =>
        stringToArr(treeTotal1).includes(item.id),
      );
      dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    }
  };

  const onvisibilityToggled = (data:any) => {

  }

  return (
    <div>
      <ViewSwitcher
        onViewModeChange={(viewMode: React.SetStateAction<ViewMode>) =>
          setView(viewMode)
        }
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        renderFilterComponent={() => <Label></Label>}
      />
      {!props.isDataLoaded && <ProgressBar show={true}/>}
      {props.isDataLoaded && _.isEmpty(interactBarTasks) && (
        <Text style={{ marginLeft: 20 }}>{t('noData')}</Text>
      )}

      {props.isDataLoaded && !_.isEmpty(interactBarTasks) && (
        <Gantt
          tasks={
            listTasks &&
            _.isArray(listTasks) &&
            !_.isEmpty(listTasks) &&
            props.isDataLoaded &&
            !_.isEmpty(interactBarTasks) &&
            typeof interactBarTasks[0].start !== 'string'
              ? interactBarTasks
              : tasks
          }
          listTasks={
            listTasks &&
            _.isArray(listTasks) &&
            !_.isEmpty(listTasks) &&
            props.isDataLoaded
              ? listTasks
              : tasks
          }
          searchQuery={''}
          searchMethod={() => {
          }}
          searchFocusOffset={0}
          searchFinishCallback={(matches) => {

          }}
          onlyExpandSearchedNodes={true}
          viewMode={view}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? '155px' : ''}
          columnWidth={columnWidth}
          onVisibilityChanged={onVisibilityChanged}
          onVisibilityToggle={ onvisibilityToggled }
          ganttHeight={560}
          isInitialCollaps={false}
          canDrag={({ node, path, treeIndex }: any) => {
            return false;
          }}
          canDrop={({ node, nextParent }: any) => {
            return false;
          }}
          onMoveNode={(path, node) => {
          }}
          chartType={ChartType.MS}
          onTreeDataChange={(treeData) => {

          }}
        />
      )}
    </div>
  );

};

export default ListPaneComponent;
