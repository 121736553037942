import React, {useEffect, useState} from 'react';
import SplitPane, {Pane} from 'react-split-pane';
import {DocumentPaneType} from '../../types/dayPlanning';
import {Panel, PanelType, Stack} from '@fluentui/react';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {useTranslation} from 'react-i18next';
import TitlePane from '../../shared/titlePane/titlePane';
import ProgressBar from '../../shared/progressBar/progressBar';
import {useDispatch, useSelector} from 'react-redux';
import {saveProjectDayPlanningStateAttr} from '../../reducers/projectDayPlanningReducer';
import {messageService} from '../../services/messageService';
import {DpViewMode} from '../../types/myDayPlanning';
import {readPMOLDropDownData} from "../../reducers/pmolReducer";
import {getContractingUnits} from '../../reducers/cuProjectReducer';
import {PMOLShortcutPane} from "../dayPlanning/shortCutPane/pmolShortcutPane/component";
import CommandBarLeft from "../dayPlanning/commandBar/commandBarLeft";
import ResourceListPane from "../dayPlanning/resourceListPane/container";
import _ from "lodash";
import {getContractingUnit} from "../../shared/util";
import {getMyCalOrgList, saveDayPlanningStateAttr} from '../../reducers/dayPlanningReducer';
import MyCalTopListPane from "./listPane/topListPane";
import './shortCutPane/my-cal-shortcut-pane-style.css';
import './listPane/my-cal-listpane-style.css';
import MyCalBottomShortcutPane from "./shortCutPane/bottomShortcutPane";
import {OrganisationSettingListPaneFilter} from "../../types/organisationSetting";

const CuMyCalendarMainLayout = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [showPanel, setShowPanel]: any = useState(false);
    const [showDetails, setShowDetails]: any = useState(false);
    const [topReSizer, setTopReSizer]: any = useState(screen.width);
    const [bottomReSizer, setBottomReSizer]: any = useState(screen.width);
    const [documentPaneType, setDocumentPaneType]: any = useState("");
    const [isExpand, setIsExpand] = useState(false);
    const [isMinimize, setIsMinimize] = useState(true);
    const [divClass, setDivClass] = useState<string>('filter-panel-left');

    const {
        showProgressBar,
        horizontalReSizer,
        selectedPmol,
        isShortCutPaneCollapse
    } = useSelector(
        (state: any) => state.projectDPlan
    );

    useEffect(() => {
        dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', true));
        dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', screen.height - 330));
        dispatch(readPMOLDropDownData());
        dispatch(getContractingUnits());
        dispatch(getMyCalOrgList(new OrganisationSettingListPaneFilter()));

        if (_.isNull(getContractingUnit())) {
            dispatch(saveDayPlanningStateAttr('isMyEnv', true));
        } else {
            dispatch(saveDayPlanningStateAttr('isMyEnv', false));
        }

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.hideDocumentPane) {

                    if (data.data.documentPaneType === 1) {
                        _hideDocPane(true)
                    } else {
                        _hideDocPane(false)
                    }
                }

                if (data.data.collapseDocumentPane) {
                    setIsExpand((prevCheck) => !prevCheck);
                }

                if (data.data.minimizeBottomPane) {
                    dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', data.data.isMinimize ? screen.height - 330 : screen.height / 2 - 140));
                    setIsMinimize(data.data.isMinimize);
                }
            }
        });

        return () => {
            subscription.unsubscribe();
            dispatch(saveProjectDayPlanningStateAttr('selectedPmol', null));
            dispatch(saveProjectDayPlanningStateAttr('projectDpViewMode', DpViewMode.TwoHour));
            dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', true));
            dispatch(saveDayPlanningStateAttr('dayPlanningDate', new Date()));
            dispatch(saveDayPlanningStateAttr('dayPlanningWeek', {}));
            setTopReSizer(screen.width);
        }
    }, []);


    useEffect(() => {
        if (selectedPmol) {
            setShowDetails(true);
            setDocumentPaneType(DocumentPaneType.PMOL);
            setTopReSizer(screen.width / 2)
        }
    }, [selectedPmol]);

    useEffect(() => {
        if (isExpand) {
            if (showDetails) {
                dispatch(saveProjectDayPlanningStateAttr('collapseBookmarkPane', true));
                setTopReSizer(screen.width / 2);
            }
        } else {
            if (showDetails) {
                dispatch(saveProjectDayPlanningStateAttr('collapseBookmarkPane', false));
                setTopReSizer(300);
            }
        }
    }, [isExpand]);


    const _hidePanel = () => {
        setShowPanel(false)
    };
    const _hideDocPane = (isTop?: boolean) => {
        if (isTop) {
            setTopReSizer(screen.width)
        } else {
            setBottomReSizer(screen.width)
        }
        setShowDetails(false);
        setDocumentPaneType(null);
        setTopReSizer(screen.width);
        dispatch(saveProjectDayPlanningStateAttr('selectedPmol', null))
    };

    const getDocumentPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return <div>
                &nbsp;
                {/*<DocumentPane/>*/}
            </div>
        } else {
            return <div> &nbsp; </div>
        }

    };

    const renderShortCutPane = (isFirst: boolean, isTopPane?: boolean) => {
        if (isTopPane) {
            return <div>
                <PMOLShortcutPane
                    divClass={divClass}
                    horizontalReSizer={horizontalReSizer}
                />
            </div>
        } else {
            return <div>
                <MyCalBottomShortcutPane/>
            </div>
        }
    };

    const getListPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return (
                <div>
                    <TitlePane
                        headerTitle={"All"}
                        type={1}
                        isFullScreen={true}
                    />
                    <div style={{
                        height: '100vh',
                        overflow: 'overlay',
                    }}>
                        <Stack horizontal>
                            {renderShortCutPane(true, true)}

                            <div className={'grid-righter'}>
                                <Stack
                                    className="project-list-header right-panel"
                                    style={{
                                        backgroundColor: 'rgb(255, 255, 255)',
                                        paddingRight: 0,
                                    }}
                                    styles={{root: {width: '100%', paddingRight: 0}}}
                                >

                                    <CommandBarLeft isMyCalendarTop={true}/>
                                    <ProgressBar show={showProgressBar}/>
                                </Stack>

                                <Stack style={{alignSelf: 'center'}}>
                                    <MyCalTopListPane/>
                                </Stack>
                            </div>
                        </Stack>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <TitlePane
                        headerTitle={'All'}
                        minimize={true}
                        isMinimize={isMinimize}
                        type={2}
                    />
                    <div style={{height: (getListPaneHeight() - 30), overflow: 'overlay'}}>
                        <Stack horizontal>
                            {renderShortCutPane(false, false)}

                            <div className={'grid-righter'}>
                                <Stack
                                    className="project-list-header right-panel"
                                    style={{
                                        backgroundColor: 'rgb(255, 255, 255)',
                                        paddingRight: 0,
                                    }}
                                    styles={{root: {width: '100%', paddingRight: 0}}}
                                >

                                    <CommandBarLeft isMyCalendarTop={false}/>
                                    <ProgressBar show={showProgressBar}/>
                                </Stack>

                                <Stack style={{alignSelf: 'center'}}>
                                    <ResourceListPane
                                        reSizer={bottomReSizer}
                                    />
                                </Stack>
                            </div>
                        </Stack>
                    </div>
                </div>
            );
        }
    };

    const getListPaneHeight = () => {
        if (screen.width <= 1920) {
            return screen.height - (horizontalReSizer + 285)
        } else if (screen.width > 1920 && screen.width <= 2048) {
            return screen.height - (horizontalReSizer + 150)
        } else if (screen.width > 2048) {
            return screen.height - (horizontalReSizer + 200)
        } else {
            return screen.height - (horizontalReSizer + 285)
        }
    };

    return <div>
        <SplitPane
            defaultSize={screen.height - 310}
            onChange={(size: any) => {
                dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', size));
            }}
            maxSize={screen.height - 310}
            minSize={40}
            style={{position: 'inherit'}}
            split="horizontal"
            size={horizontalReSizer}
        >
            <div style={{width: '100%', height: '100%'}} className={'ms-Grid'}>
                <SplitPane
                    className={'SplitPane'}
                    minSize={(screen.width / 3)}
                    maxSize={(screen.width / 3) * 2}
                    defaultSize={
                        showDetails && documentPaneType === DocumentPaneType.PMOL ? screen.width / 2 : screen.width
                    }
                    split="vertical"
                    onChange={(size: any) => {
                        setTopReSizer(size)
                    }}
                    size={topReSizer}
                >
                    <Pane className={''} style={{width: '100%'}}>{getListPaneView(true)} </Pane>
                    <Pane className={''} style={{
                        width: '100%',
                        height: '100%',
                        zIndex: 1
                    }}> {documentPaneType === DocumentPaneType.PMOL && getDocumentPaneView(true)}</Pane>
                </SplitPane>
                <div></div>
            </div>
            <div style={{width: '100%'}} className={'ms-Grid'}>
                <div id={"bottomPane"} style={{height: getListPaneHeight()}}>
                    <SplitPane
                        className={'SplitPane'}
                        minSize={(screen.width / 3)}
                        maxSize={(screen.width / 3) * 2}
                        defaultSize={
                            showDetails && (documentPaneType === DocumentPaneType.CU_HR || documentPaneType === DocumentPaneType.CPC) ? screen.width / 2 : screen.width
                        }
                        split="vertical"
                        onChange={(size: any) => {
                            setBottomReSizer(size)
                        }}
                        size={bottomReSizer}
                    >
                        <Pane className={''} style={{width: '100%', height: '100%'}}>{getListPaneView()}</Pane>
                        <Pane className={''} style={{width: '100%', height: '100%'}}>{getDocumentPaneView()}</Pane>
                    </SplitPane>
                </div>
            </div>

        </SplitPane>

        <Panel
            isOpen={showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={_hidePanel}
            isLightDismiss={true}
            headerText={t('corporateProductCatalogue')}
            className="custom-detail-panel"
            closeButtonAriaLabel={t('close')}
        >
            {getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
            hidden={true}
            title={t('areyousure')}
            subText={t('youwonTbeabletorevertthis')}
            onClickConfirmDialog={() => {
            }}
            onClickCancelDialog={() => {
            }}
            cancelButtonText={t('noIwonT')}
            confirmButtonText={t('yesDeleteit')}
        />
    </div>
};


export default CuMyCalendarMainLayout
