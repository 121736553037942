import { ActionButton, Dropdown, IDropdownOption, IIconProps, Label, TextField } from '@fluentui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import {
  formatVProductTaxonomyBarData,
  GetVPFilterDropdownData, readVisualPlanProjectList,
  readVPPBSTaxonomyData,
  readVPPBSTaxonomyDataWithoutRefreshing,
  saveVPStateAttr,
} from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
// import 'style.css';
import { ViewMode } from '../../../shared/ganttv2';
import {
  calculateEndDate, calculateStartDate,
  formatDateTimeToISOString,
  getSelectedFilterDates,
  updateVPProductStatusFilter,
  updateVPStandardFilter,
} from '../../../shared/util';
import { ViewModeContext } from './component';
import ModeSwitcherWithRadioButtons from '../../../shared/ganttViewSwitcher/viewSwitcherWithRadioButtons';
import moment from 'moment/moment';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import _ from 'lodash';

export const ViewSwitcher: React.FC = () => {
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  // const [isProjectSelected, setIsProjectSeleted] = React.useState<boolean>(true);
  const [searchTriggered, setSearchTriggered] = React.useState<boolean>(false);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const [prodStatusFilterKey, setProdStatusFilterKey] = React.useState<any>('1');
  const [selectedProject, setSelectedProject] = React.useState<any>(null);
  const [standardFilterKey, setStandardFilterKey] = React.useState<any>('5'); // Initially set to Project
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onViewModeChange, onViewListChange, isCheck } =
    useContext(ViewModeContext);
  const formRef: any = useRef();
  const standerdFilterOptions = useSelector(
  (state: any) => updateVPStandardFilter(state.vp.vpFilterDropdown)
  );

  const addFriendIcon: IIconProps = { iconName: 'Refresh' };

  const productTaxonomyReloadRequired: boolean = useSelector(
    (state: any) => state.vp.productTaxonomyReloadRequired,
  );

  const pbsSave: boolean = useSelector(
    (state: any) => state.projectBreakdown.pbsCreated,
  );

  const {updatedVPPBS, isPBSOpenFromVP} = useSelector(
    (state: any) => state.projectBreakdown,
  );

  const listTasks: any[] = useSelector(
    (state: any) => state.vp.vpProducTaxonomyList
  );

  const productStatusFilterOptions = useSelector(
    (state: any) => updateVPProductStatusFilter(state.projectBreakdown?.productStates)
  );

  const vpProjectListForFilter: any[] = useSelector(
    (state: any) => state.vp.vpProjectListForFilter,
  );


  useEffect(() => {
    dispatch(GetVPFilterDropdownData());
  }, []);

  useEffect(() => {
    dispatch(readVisualPlanProjectList({
      buId: null,
      title: null,
    }));
  }, []);

  useEffect(() => {
    if (productTaxonomyReloadRequired) {
      // stop refreshing pbs taxonomy data, emil requested this
      // PBSTAXRELOAD
      // dispatch(readVPPBSTaxonomyDataWithoutRefreshing({
      //   fromDate: formatDateTimeToISOString(startDate!!),
      //   toDate: formatDateTimeToISOString(endDate!!)
      // }));
    }
  }, [productTaxonomyReloadRequired]);


  const debounced = useDebouncedCallback(
    (value: any) => {
      if (value) {
        dispatch(
          readVPPBSTaxonomyData({
            fromDate: formatDateTimeToISOString(startDate!!),
            toDate: formatDateTimeToISOString(endDate!!),
            title: value,
            projectTitle: selectedProject,
          }),
        );
      } else {
        dispatch(
          readVPPBSTaxonomyData({
            fromDate: formatDateTimeToISOString(startDate!!),
            toDate: formatDateTimeToISOString(endDate!!),
            title: null,
            projectTitle: selectedProject,
          }),
        );
      }
    },
    // delay in ms
    1000,
  );

  const resetViewSwitcher = () => {
    setStartDate(null);
    setEndDate(null);
    setStandardFilterKey('5');
    setProdStatusFilterKey('1');
    formRef.current.reset();
    formRef.current.mutators.setSearchString(null);
  }

  const projectOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(getProjectByName(inputValue));
    });

  const getProjectByName = async (name: string) => {
    const response = vpProjectListForFilter.filter(
      (element: { text: string; value: string }) => element.text.toLowerCase().includes(name.toLowerCase()),
    );
    return formatProjectResponse(response).sort();
  };

  const formatProjectResponse = (response: any) => {
    if (response) {
      if (_.isArray(response)) {
        const titleNotEmptyList = response.filter(
          (item: { name: string | null }) => item.name !== null,
        );
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item.key,
            label: item.text,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: '0.5px solid gray',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
          width: '100%',
        }}
      >
        <Form
          onSubmit={() => {
          }}
          initialValues={{
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
          }}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
            setSearchString: (args, state, utils) => {
              const field = state.fields['searchString'];
              field.change(args[0]);
            },
            setDateRange: (args, state, utils) => {
              const from = state.fields['startDate'];
              const to = state.fields['endDate'];
              from.change(getSelectedFilterDates(args)[0]);
              to.change(getSelectedFilterDates(args)[1]);
              dispatch(
                saveVPStateAttr(
                  'scrollPosDate',
                  getSelectedFilterDates(args)[0],
                ),
              );

              dispatch(
                readVPPBSTaxonomyData({
                  fromDate: formatDateTimeToISOString(
                    getSelectedFilterDates(args)[0],
                  ),
                  toDate: formatDateTimeToISOString(
                    getSelectedFilterDates(args)[1],
                  ),
                  filter: args[0],
                  productStatus: prodStatusFilterKey == 1 ? null : prodStatusFilterKey,
                }),
              );
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
          formRef.current = form;
            // props.submit(handleSubmit);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                      <div style={{ width: 180, marginRight: 10 }}>
                        <div style={{ width: 180, marginLeft: 15 }} className={'projectSelect'}>
                          <Label style={{ width: 180 }}>{t('projectSearch')}</Label>
                          <Field name="project">
                            {({ input, meta }) => (
                              <CustomDropdown
                                reference={''}
                                onChange={(
                                  key: string,
                                  label: string,
                                ) => {
                                  let project = null;
                                  if (key) {
                                    input.onChange(key);
                                    project = label;
                                    setSelectedProject(label);
                                  } else {
                                    input.onChange(null);
                                    setSelectedProject(null);
                                  }
                                  dispatch(
                                    readVPPBSTaxonomyData({
                                      title: values.searchString,
                                      fromDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null,
                                      toDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null,
                                      projectTitle: project,
                                    }))
                                }}
                                selectedOption={
                                  values && values?.project ?
                                    {
                                      value: values?.project,
                                      // label: getProjectTitle(values?.project),
                                      label: values?.project,
                                    }
                                    : null
                                }
                                promiseOptions={projectOptions}
                                validationMessage={''}
                                required={true}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div style={{ width: 180 }}>
                        <div style={{ width: 180, marginLeft: 15 }}>
                          <Label style={{ width: 180 }}>{t('pbsSearch')}</Label>
                          <Field name="searchString">
                            {({ input, meta }) => (
                              <TextField
                                value={values.searchString}
                                placeholder={t('search')}
                                onChange={(event, value) => {
                                  if (value?.length!! >= 1) {
                                    input.onChange(value);
                                  } else {
                                    input.onChange(null);
                                  }
                                  if (value?.length!! >= 3) {
                                    setSearchTriggered(true)
                                    debounced(value);
                                    //handleTreeOnSearch(value);
                                  } else if (value?.length!! === 0 && searchTriggered) {
                                    setSearchTriggered(false);
                                    debounced(null);
                                    //handleTreeOnSearch('');
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div style={{ width: 180, marginLeft: 20 }}>
                        <Field name="productStatus">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('productStatus')}
                              label={t('productStatus')}
                              selectedKey={prodStatusFilterKey}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: IDropdownOption | undefined,
                              ) => {
                                if (item) {
                                  setProdStatusFilterKey(item.key)
                                  input.onChange(
                                    dispatch(
                                      readVPPBSTaxonomyData({
                                        title: values.searchString,
                                        fromDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null,
                                        toDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null,
                                        productStatus: item.key == 1 ? null : item.key
                                      }))
                                  );
                                }
                              }}
                              options={ productStatusFilterOptions }
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="date">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('standardFilter')}
                              label={t('standardFilter')}
                              selectedKey={standardFilterKey}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(event, value) => {
                                let date = null;
                                if (value) {
                                  date = value.key;
                                  input.onChange(value.key);
                                  setStandardFilterKey(value.key)
                                } else {
                                  date = null;
                                  input.onChange(null);
                                }
                                form.mutators.setDateRange(value?.key);
                                if (value?.key === '5'){
                                  onViewModeChange(ViewMode.QuaterYear)
                                  // setIsProjectSeleted(true)
                                  setSelectedViewMode(ViewMode.QuaterYear)
                                } else {
                                  onViewModeChange(ViewMode.Week)
                                  // setIsProjectSeleted(false)
                                  setSelectedViewMode(ViewMode.Week)
                                }
                              }}
                              options={
                                standerdFilterOptions
                                  ? standerdFilterOptions
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15 }}
                      >
                        <Label style={{ width: 180 }}>{t('fromDate')}:</Label>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setValue={values.startDate}
                              getValue={(date: Date) => {
                                setStandardFilterKey('8')
                                input.onChange(date);
                                setStartDate(date);
                                input.onChange(date);
                                const newDate = calculateEndDate(selectedMode, date);
                                setEndDate(newDate);

                                dispatch(
                                  readVPPBSTaxonomyData({
                                    fromDate: formatDateTimeToISOString(date),
                                    toDate: formatDateTimeToISOString(newDate),
                                    productStatus: prodStatusFilterKey == 1 ? null : prodStatusFilterKey
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15 }}
                      >
                        <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                        <Field name="endDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setMinDate={
                                startDate
                                  ? startDate
                                  : new Date(
                                  form.getFieldState('startDate')?.value,
                                  )
                              }
                              setValue={values.endDate}
                              getValue={(date: Date) => {
                                setStandardFilterKey('8');
                                input.onChange(date);
                                const newDate = calculateStartDate(selectedMode, date);
                                setStartDate(newDate);
                                setEndDate(date);
                                dispatch(
                                  readVPPBSTaxonomyData({
                                    fromDate: formatDateTimeToISOString(newDate),
                                    toDate: formatDateTimeToISOString(date),
                                    productStatus: prodStatusFilterKey == 1 ? null : prodStatusFilterKey
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <ModeSwitcherWithRadioButtons
                        onViewModeChange={(t:any)=>{
                          onViewModeChange(t)
                          setSelectedViewMode(t)
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isCheck}
                        selectedViewMode={selectedMode}
                      />


                    <ActionButton onClick={()=> {
                         resetViewSwitcher()
                         dispatch(
                          readVPPBSTaxonomyData({
                            fromDate: formatDateTimeToISOString(startDate!!,),
                            toDate: formatDateTimeToISOString(endDate!!),
                            productStatus: prodStatusFilterKey == 1 ? null : prodStatusFilterKey
                          }),
                        );
                      }}
                        iconProps={addFriendIcon}
                        allowDisabledFocus
                        disabled={false}
                        checked={false}
                        style={{marginTop:15}}>
                            {t('refresh')}
                      </ActionButton>

                    </div>
                  </div>
                </div>

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
