import {
  Checkbox,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  mergeStyleSets,
  Modal,
  PrimaryButton, Stack,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../theme';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {createPMOLwithResources, getPBSLabourForVP, getPBSResourcesForVP} from '../../../reducers/projectBreakdownReducer';
import useMoreMenu from '../../../hooks/useMoreMenu';
import { useTranslation } from 'react-i18next';
import DataGridWithModal, { ModalGridContext } from '../../../shared/dataGridModal/component';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import moment from 'moment/moment';
import { formatDateTimeToISOString } from '../../../shared/util';

const theme = getTheme();

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackStyles: IStackStyles = { root: { padding: 0 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const PmolModal = () => {
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '4px 4px 0px 4px',
      overflowY: 'hidden',
      minWidth: '40vw',
      minHeight: '20vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 10 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'block',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });

  const stackItemCheckStyles: IStackItemStyles = {
    root: {
      marginLeft: 20,
      marginTop: 20,
      marginBottom: -25,
    },
  };

  const classNames = mergeStyleSets({
    warningLabel: {
      marginTop: 30,
    },
  });

  const {isPmolCreate, setPmolCreate, product, setProduct} = useMoreMenu()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ selectedResource, setResource ] = React.useState<any>([]);
  const [isRepeat, setRepeat] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date | null >(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const diffInDays = moment(endDate)?.diff(moment(startDate), 'days');
  const {pbsLabourResourcesLoading, pbsLabourResources, pmolCreatedWithResources}:any = useSelector(
    (state: any) =>  state.projectBreakdown,
  );
  
  useEffect(()=>{
    if (product?.pbsSequenceId) {
        dispatch(getPBSResourcesForVP(product?.id))
    }
  },[product])

  const getResourceTypeData = () => {
    return pbsLabourResources.map((resource:any) => {
      return {
        ...resource,
        type :getResourceCategory(resource.resourceTypeId)
      }
    })
  }

  const getResourceCategory = (resourceId: string) => {
    switch (resourceId) {
      case 'c46c3a26-39a5-42cc-n7k1-89655304eh6':
        return 'Materials';
      case 'c46c3a26-39a5-42cc-n9wn-89655304eh6':
        return 'Tools';
      case 'c46c3a26-39a5-42cc-m06g-89655304eh6':
        return 'Consumables';
      case 'c46c3a26-39a5-42cc-b07s-89655304eh6':
        return 'Labours';
      default:
        return 'Service';
        break;
    }
  };

  const resetRepeatData = () => {
    setRepeat(false);
    setStartDate(null);
    setEndDate(null);
  }

  return (
    <Modal
      titleAriaId={''}
      isOpen={isPmolCreate}
      onDismiss={() => {}}
      isBlocking={true}
    >
      <div className={contentStyles.header}>
        <span>{t('createPmol')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={() => {
            setPmolCreate(false)
            setProduct(null)
          }}
        />
        <ProgressBar show={pbsLabourResourcesLoading} />
      </div>
      <ProgressBar show={pbsLabourResourcesLoading} />

      <Stack.Item styles={stackItemCheckStyles}>

        <div style={{ display: 'inline-block' }}>
          <Checkbox
            checked={isRepeat}
            label={t('repeat')}
            onChange={(event, value) => {
              setRepeat(prevState => !prevState);
            }}
          />
        </div>

        {<div style={{display: 'inline-block', visibility: isRepeat ? 'visible' : 'hidden'}}>
          <label style={{ marginLeft: 20, fontWeight: 600 }}>{t('startDate')}</label>
          <div style={{ display: 'inline-block', marginLeft: '16px', width: 150 }}>
            <CustomDatePicker
              placeholder={t('startDate')}
              ariaLabel={t('startDate')}
              value={startDate}
              setMaxDate={endDate}
              setValue={startDate}
              getValue={(date: Date) => {
                setStartDate(date)
              }}
            />
          </div>

          <label style={{ marginLeft: 20, fontWeight: 600 }}>{t('endDate')}</label>
          <div style={{ display: 'inline-block', marginLeft: '16px', width: 150 }}>
            <CustomDatePicker
              placeholder={t('endDate')}
              ariaLabel={t('endDate')}
              value={endDate}
              setMinDate={startDate}
              setValue={endDate}
              getValue={(date: Date) => {
                setEndDate(date)
              }}
            />
          </div>
        </div>}

      </Stack.Item>

      {(diffInDays > 30 && isRepeat) && <Stack.Item styles={stackItemCheckStyles} className={classNames.warningLabel}>
        <label style={{ color: '#E55D0B', marginTop: 30 }}>{t('theDateRangeSelectedExceedsOneMonth')}</label>
      </Stack.Item>}

      <div className={contentStyles.body}>
        
        <DataGridWithModal
                  dataList={pbsLabourResources && Array.isArray(pbsLabourResources) ? getResourceTypeData() : []}
                  modalTitle={t('finalDelivery')}
                  deleteDataGrid={async (selection: any) => {
                
                  }}
                  title={t('resources')}
                  readOnly={true}
                  keyProp={'id'}
                  columns={[
                    {
                      key: 'column1',
                      name: t('cPCTitle'),
                      fieldName: 'cpcTitle',
                      minWidth: 120,
                      maxWidth: 300,
                      isResizable: true,
                    },
                    {
                      key: 'column2',
                      name: t('resourceType'),
                      fieldName: 'type',
                      minWidth: 120,
                      maxWidth: 300,
                      isResizable: true,
                    },
                    {
                      key: 'column3',
                      name: t('quantity'),
                      fieldName: 'quantity',
                      minWidth: 120,
                      maxWidth: 300,
                      isResizable: true,
                    },
                  ]}
                  isLoaded={false}
                  onDismiss={() => {}}
                  onSelectionChange={(data:any) => {
                    console.log(data)
                    const selectedIdList = data.map((item: any) => {
                        return {
                            productId: product?.id,
                            cpcId: item.cpcKey,
                            resourceTypeId: item.resourceTypeId
                        }
                      });
                      setResource(selectedIdList)

                  }}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => undefined}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    text={t('createPMOL')}
                    style={{ marginTop: 40, marginBottom: 10 }}
                    onClick={() => {
                      Promise.all([dispatch(createPMOLwithResources(
                        {
                          productId: product?.pbsSequenceId,
                          cpc: selectedResource,
                          isRepeat: isRepeat,
                          startDate: isRepeat ? formatDateTimeToISOString(startDate ? startDate : '') : undefined,
                          endDate: isRepeat ? formatDateTimeToISOString(endDate ? endDate : '') : undefined,
                        }))]).then(() => {
                        setPmolCreate(false);
                      });
                      setProduct(null);
                      resetRepeatData();
                    }}
                  />
                </div>
    
      </div>
    </Modal>
  );
};

export default PmolModal;
