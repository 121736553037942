import React from 'react';
import * as TdDropBox from "../../../shared/dnd/dropBox";
import {useSelector} from "react-redux";
import {AcceptType} from "../../../types/myDayPlanning";
import DragBox from "../../../shared/dnd/dragBox";
import { DirectionalHint, FontIcon, Icon, TooltipDelay, TooltipHost } from '@fluentui/react';
import { iconColors } from '../../../../theme';
import _ from 'lodash';

function ResourceHeader({label, index, resource}: any) {
    const selectedDate = useSelector((state: any) => state?.mydPlan?.selectedDate);

    const acceptType = (label: any, resource: any) => {

        return [AcceptType.TEAM, AcceptType.PMOL, AcceptType.PERSON, AcceptType.PBS, AcceptType.PROJECT];
    };

    const getTeamMembers = (htmlString: any, resource: any) => {
        const array = htmlString?.split(",");
        return array?.map((text: any) => {
            const cabPerson = resource?.team?.find((team: any) => team?.name == text);
            const item = {
                cabPerson: cabPerson,  //needed for extract selected cabPersonId
                resource: resource
            };

            return (
              <div style={{ display: 'flex' }}>
                  <DragBox
                    name={text}
                    item={item}
                    type={AcceptType.PERSON}
                    style={{ fontSize: 12 }}
                  />
                  {!_.isEmpty(cabPerson?.absenceData) && (
                    <TooltipHost
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.rightCenter}
                      // content={JSON.stringify(cabPerson?.absenceData)}
                      content={
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>{cabPerson.absenceData[0].reason}</span>
                              <span>{new Date(cabPerson.absenceData[0].startDate).toLocaleDateString('en-US')} - {new Date(cabPerson.absenceData[0].endDate).toLocaleDateString('en-US')}</span>
                          </div>
                      }
                    >
                        <FontIcon
                          aria-label='WarningSolid'
                          iconName='WarningSolid'
                          style={{ marginTop: 7, cursor: 'pointer', color: iconColors.warningIconColor }}
                        />
                    </TooltipHost>
                  )}
              </div>
            );
        });
    };

    return <div id={'resource' + resource?.resourceId} key={JSON.stringify(label)}
                style={{width: '100%', height: '100%'}}>
        <TdDropBox.DropBox
            item={resource}
            day={selectedDate}
            type={acceptType(index, resource)}
        >
            <div style={{minWidth: '100px', minHeight: '120px', display: 'flex', alignItems: 'flex-start'}}>
                <div style={{padding: 6, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    {resource?.teamTitle} <br/>
                    <span style={{fontSize: 12, fontWeight: 400}}>{resource?.contractingUinit}</span> <br/>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 12
                }}>{getTeamMembers(label, resource)}</div>
            </div>
        </TdDropBox.DropBox>
    </div>
}

export default ResourceHeader;