import React from 'react';
import { BarTask } from '../../../types/bar-task';
import style from './bar.module.css';

type ProductDisplayProps = {
  task: BarTask;
  x: number;
  y: number;
  width: number;
  height: number;
  isSelected: boolean;
  /* progress start point */
  progressX: number;
  progressWidth: number;
  barCornerRadius: number;
  styles: {
    backgroundColor: string;
    backgroundSelectedColor: string;
    progressColor: string;
    progressSelectedColor: string;
  };
  onMouseDown: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void;
};
export const BarDisplay: React.FC<ProductDisplayProps> = ({
                                                            task,
                                                            x,
                                                            y,
                                                            width,
                                                            height,
                                                            isSelected,
                                                            progressX,
                                                            progressWidth,
                                                            barCornerRadius,
                                                            styles,
                                                            onMouseDown,
                                                          }) => {

  const barColor = isSelected
    ? task.styles?.backgroundSelectedColor
    : task.styles?.backgroundColor;
  const processColor = isSelected
    ? task?.styles.progressSelectedColor
    : task?.styles.progressColor;
  const projectWith = task.x2 - task.x1;

  const projectLeftTriangle = [
    task.x1,
    task.y + 6 + task.height / 2 - 1,
    task.x1,
    task.y + 6 + task.height,
    task.x1 + 15,
    task.y + 6 + task.height / 2 - 1,
  ].join(',');
  const projectRightTriangle = [
    task.x2,
    task.y + 6 + task.height / 2 - 1,
    task.x2,
    task.y + 6 + task.height,
    task.x2 - 15,
    task.y + 6 + task.height / 2 - 1,
  ].join(',');

  const getProcessColor = () => {
    return isSelected ? styles.progressSelectedColor : styles.progressColor;
  };

  const getBarColor = () => {
    return isSelected ? styles.backgroundSelectedColor : styles.backgroundColor;
  };

  return (
    <g onMouseDown={onMouseDown}>
      <rect
        fill={barColor}
        x={task.x1}
        width={projectWith}
        y={task.y + 12}
        height={(task.height / 2) - 6}
        rx={task.barCornerRadius}
        ry={task.barCornerRadius}
        className={style.projectTop}
      />
      <polygon
        className={style.projectTop}
        points={projectLeftTriangle}
        fill={barColor}
      />
      <polygon
        className={style.projectTop}
        points={projectRightTriangle}
        fill={barColor}
      />
    </g>
  );
};
