import React, { useEffect, useRef, useState } from 'react';
import './planboard-shortcut-pane-style.css';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontIcon, Stack } from '@fluentui/react';
import {
  filterProjectDayPlanningProjectList,
  filterProjectDayPlanningShortCutPaneData,
  getBuDropdownData,
  getProjectDayPlanningResourceList,
  saveProjectDayPlanningStateAttr,
} from '../../../reducers/projectDayPlanningReducer';
import { formatDateTimeToISOString, getProject, isMyEnvironment } from '../../../shared/util';
import ProjectPbCalendar from './component/projectPbCalendar';
import TeamPbCalendar from './component/teamPbCalendar';
import { Field, Form } from 'react-final-form';
import MultiSelectDropDown from '../../../shared/fluentFinalForm/MultiSelectDropDown';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import client from '../../../api';
import _ from 'lodash';
import DragBox from '../../../shared/dnd/dragBox';
import { AcceptType, getBuGroups } from '../../../types/myDayPlanning';
import SelectedKeyDropDownAdaptater from '../../../shared/fluentFinalForm/SelectedKeyDropDwon';
import { saveMyDayPlanningStateAttr } from '../../../reducers/myDayPlanningReducer';
import { savePMOLStateAttr } from '../../../reducers/pmolReducer';

type Props = {
  type: string;
};

const PlanBoardShortcutPane: React.FC<Props> = ({ type }) => {  //{name}
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const store = useStore();
  const formRef: any = useRef();
  const [shortCutPaneSelectedProject, setShortCutPaneSelectedProject] = useState<any>(null);

  const {
    shortCutPaneData,
    horizontalReSizer,
    buDropDown,
    ppbPmolTypeId,
    ppbSiteManagerId,
    ppbProjectSequenceCode,
    selectedCu,
    selectedBu,
    isAddMultipleMembersSuccess,
    isDeleteLabourSuccess,
    isAssignTeamToPmolSuccess,
  } = useSelector(
    (state: any) => state.projectDPlan,
  );

  const { selectedProject } = useSelector(
    (state: any) => state.project,
  );

  const initialCu = {
    key: buDropDown[0]?.contractingUnitId,
    sequenceCode: buDropDown[0]?.contractingUnit,
  }

  const { projectClassificationBuisnessUnit } = useSelector((state: any) => state.project); // This returns All the BU's

  // Validates if you have the access to the BU's in the dropdown
  const isBuSelectedInDropdown = () : Boolean => {
    return buDropDown.some((item: { key: any; }) => item.key === selectedProject?.projectClassification?.projectClassificationBuisnessUnit);
  }

  const {
    planboardPersonId,
    selectedDate,
    selectedWeek,
    view,
    isPBSAssignSuccess,
    isUpdateTeamSuccess,
    isRemovePersonSuccess,
    isUpdatePmolPersonSuccess,
    isUpdatePmolDayPlanningSuccess,
  } = useSelector(
    (state: any) => state.mydPlan,
  );

  const { contractingUnits } = useSelector(
    (state: any) => state.cuProject,
  );

  const { newPMOLCreated } = useSelector(
    (state: any) => state.pmol,
  );

  const getSelectedProject = () => {
    return {
      value: selectedProject?.sequenceCode,
      label: selectedProject?.title,
    };
  };

  const dateSelector = (dateType: any): any => {
    if (view === 'week') {
      if (dateType === 'startDate') {
        return formatDateTimeToISOString(selectedWeek.startDate);
      }
      if (dateType === 'endDate') {
        return formatDateTimeToISOString(selectedWeek.endDate);
      }
    } else {
      return selectedDate ? formatDateTimeToISOString(selectedDate) : formatDateTimeToISOString(new Date());
    }
  };

  const cleanupStates = () => {
    dispatch(saveMyDayPlanningStateAttr('isPBSAssignSuccess', false));
    dispatch(saveMyDayPlanningStateAttr('isUpdatePmolDayPlanningSuccess', false));
    dispatch(savePMOLStateAttr('newPMOLCreated', false));
    dispatch(saveMyDayPlanningStateAttr('isUpdateTeamSuccess', false));
    dispatch(saveMyDayPlanningStateAttr('isRemovePersonSuccess', false));
    dispatch(saveMyDayPlanningStateAttr('isUpdatePmolPersonSuccess', false));
    dispatch(saveProjectDayPlanningStateAttr('isAddMultipleMembersSuccess', false));
    dispatch(saveProjectDayPlanningStateAttr('isDeleteLabourSuccess', false));
    dispatch(saveProjectDayPlanningStateAttr('isAssignTeamToPmolSuccess', false));
  }

  const [toggleOverlay, setToggleOverlay] = useState<boolean>(false);

  useEffect(() => {

      if (!selectedBu && type === 'team') {
        dispatch(saveProjectDayPlanningStateAttr('selectedCu', {
          key: buDropDown[0]?.contractingUnitId,
          sequenceCode: buDropDown[0]?.contractingUnit,
        }));
        dispatch(saveProjectDayPlanningStateAttr('selectedBu', buDropDown[0]?.key));
      }

    formRef.current.submit();

  }, [type, selectedDate, selectedWeek]);

  // Handling the shortcut pane refresh after the PBS DND, PMOL update, Labour DND, Labour remove
  useEffect(() => {
    if (isPBSAssignSuccess || newPMOLCreated || isUpdateTeamSuccess || isRemovePersonSuccess || isUpdatePmolPersonSuccess || isAddMultipleMembersSuccess || isDeleteLabourSuccess || isUpdatePmolDayPlanningSuccess || isAssignTeamToPmolSuccess) {
      formRef.current.submit();
      return () => {
        cleanupStates();
      }
    }
  }, [isPBSAssignSuccess, newPMOLCreated, isUpdateTeamSuccess, isRemovePersonSuccess, isUpdatePmolPersonSuccess, isAddMultipleMembersSuccess, isDeleteLabourSuccess, isUpdatePmolDayPlanningSuccess, isAssignTeamToPmolSuccess]);

  const onSubmit = async (values: any) => {
    let filterData = {
      project: values?.projectSequenceCode?.value,
      buId: values?.businessUnit,
      contractingUnit: [values?.contractingUnit],
      startDate : dateSelector('startDate'),
      endDate: dateSelector('endDate'),
      executionDate: selectedDate ? formatDateTimeToISOString(selectedDate) : formatDateTimeToISOString(new Date()),
      cabPersonId: planboardPersonId ?? null,
      projectSequenceCode: null,
      pmolTypeId: ppbPmolTypeId ?? null,
      siteManagerId: ppbSiteManagerId ?? null,
    }

    dispatch(filterProjectDayPlanningShortCutPaneData({
      ...filterData,
      contractingUnit: [values?.contractingUnit?.key],
    }));

    if (type === 'project') {
      // ProjectsWithPmol
      dispatch(filterProjectDayPlanningProjectList({
        ...filterData,
        contractingUnit: [values?.contractingUnit?.sequenceCode] ?? null,
      }));
      // Get available teams
      dispatch(getProjectDayPlanningResourceList({
        ...filterData,
        contractingUnit: values?.contractingUnit?.sequenceCode,
        type: "day"
      }));
    }
  }

  const setStateValues = (contractingUnit: {
    key: string | null,
    sequenceCode: string | null
  }, businessUnitId: string | null) => {
    dispatch(saveProjectDayPlanningStateAttr('selectedCu', {
      key: contractingUnit.key ?? null,
      sequenceCode: contractingUnit.sequenceCode ?? null,
    }));
    dispatch(saveProjectDayPlanningStateAttr('selectedBu', businessUnitId));
  };

  const projectOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(getProjectByName(inputValue));
    });

  const getProjectByName = async (name: string) => {
    let projects: any = [];
    const cu = store.getState()?.uprince?.userCu ? store.getState()?.uprince?.userCu?.sequenceCode : null;
    const bu = store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu : null;

    if (name.length >= 2 || name === '') {
      const response = await client.post(
        'ContractingUnitTenants/GetProjectsByUserProjectFilter', {
          title: name,
          projectTypeId: null,
          managementLevelId: null,
          toleranceStateId: null,
          toleranceState: null,
          contractingUnit: cu ? cu : null,
          buId: bu ? bu : null,
          sorter: {
            attribute: null,
            order: null,
          },
        });
      return formatProjectResponse(response);
    }
    return projects;
  };

  const formatProjectResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item.sequenceCode,
            label: item.title,
            sequenceCode: item.sequenceCode,
          };
        });
      }
      return options;
    }
  };

  const getBuList = (sequenceCode: string) => {
    const filterData = {
      title: null,
      contractingUnit: sequenceCode,  //sequenceCode as a string
      businessUnit: getBuGroups(),
    };

    dispatch(getBuDropdownData(filterData));
  };

  const onClickToggle = (): void => {
    setToggleOverlay(toggleOverlay => !toggleOverlay);
    dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', toggleOverlay));
  };

  const onSelectCu = (event: React.FormEvent<HTMLDivElement>, option?: any, index?: number) => {
    if (option?.selected) {
      dispatch(saveProjectDayPlanningStateAttr('selectedCu', {
        key: option.key,
        text: null,
        sequenceCode: option.sequenceCode,
        selected: true,
      }));
      getBuList(option?.sequenceCode);
      formRef.current.mutators.setContractingUnit('contractingUnit', option);
    } else {
      dispatch(saveProjectDayPlanningStateAttr('selectedCu', {
        key: null,
        text: null,
        sequenceCode: null,
        selected: false,
      }));
      dispatch(saveProjectDayPlanningStateAttr('selectedBu', null));
      // dispatch(saveProjectDayPlanningStateAttr('ppbProjectSequenceCode', null));
      getBuList('');
      formRef.current.mutators.setContractingUnit('contractingUnit', { ...option, selected: false, key: null, sequenceCode: null });
      formRef.current.mutators.setBusinessUnit('businessUnit', null);
    }
    formRef.current.submit();
  };

  const onSelectBu = (event: React.FormEvent<HTMLDivElement>, option?: any, index?: number) => {
    if (option?.key) {
      dispatch(saveProjectDayPlanningStateAttr('selectedBu', option?.key));
      dispatch(saveProjectDayPlanningStateAttr('selectedCu', {
        key: option?.contractingUnitId ?? null,
        text: null,
        sequenceCode: option?.contractingUnit ?? null,
        selected: true,
      }));
      setStateValues({ key: option?.contractingUnitId, sequenceCode: option?.contractingUnit }, option?.key);
    }
    formRef.current.mutators.setBusinessUnit('businessUnit', option?.key);
    setShortCutPaneSelectedProject(null)
    formRef.current.submit();
  };

  const onSelectProject = (key: string, text: string) => {
    if (key) {
      setShortCutPaneSelectedProject({
        value: key,
        label: text,
      });
    } else {
      setShortCutPaneSelectedProject(null)
    }
    formRef.current.mutators.setProjectSequenceCode('projectSequenceCode',  !_.isEmpty(key) ? { value: key, label: text } : null);
    formRef.current.submit();
  };

  const renderFilterItems = () => {
    if (shortCutPaneData) {
      return shortCutPaneData.map((item: any, index: any) => {
        // console.log('shortCutPaneData item >', item)
        return (
          <div key={item?.project + '1div'}>
            {/*<Text key={item?.project} style={{ fontWeight: '700' }}>{item?.project} </Text>*/}
            <DragBox
              name={`${item?.project ?? ''}`}
              item={item}
              type={AcceptType.PROJECT}
              style={
                {
                  fontSize: 14,
                  fontWeight: '700',
                  // height: 12,
                }
              }
            />
            <div key={item?.project + '2div'} style={{ marginLeft: '20px' }}>
              {renderPMOLList(item.pbs)}
            </div>
          </div>
        );
      });
    }
    return <div></div>;
  };

  const initialValues = () => {
    return {
      contractingUnit: !selectedBu ? initialCu : selectedCu,
      businessUnit: isMyEnvironment() ? (!selectedBu ? buDropDown[0]?.key : selectedBu) : isBuSelectedInDropdown() ? selectedProject?.projectClassification?.projectClassificationBuisnessUnit : null,
      projectSequenceCode: isMyEnvironment() ? null : getSelectedProject(),
    };
  };

  const renderPMOLList = (pbsList: any) => {
    return pbsList.map((item: any, index: any) => {
      let divider;
      if (index > 0) {
        const previousItem = pbsList[index - 1];
        divider = previousItem.productId !== item.productId;
      } else {
        divider = true;
      }


      return (
        <div key={item?.id + '3div'}>
          {divider && (
            <div style={{ height: 5 }} />
          )}
          <DragBox
            name={`${item?.title ?? ''} ${item?.quantity ?? ''}`}
            item={item}
            type={AcceptType.PBS}
            style={
              {
                fontSize: 13,
                color: item?.isPlanOverload ? '#ff0000' : undefined,
              }
            }
          />
          {item.pmol.map((pmol: any, index: any) => {
            return <div key={pmol?.id + '4div'} style={{ marginLeft: '20px' }}>
              <DragBox
                name={`${pmol?.projectMoleculeId} - ${pmol?.name ? pmol?.name : ''}`}
                item={pmol}
                type={AcceptType.PMOL}
                style={{ fontSize: 13 }}
              />
            </div>;
          })}
        </div>
      );
    });
  };

  return (
    <div
      className={`projectDayPlanning filter-panel-left wrapper ${type === 'project' ? !toggleOverlay ? 'collapsed' : 'expand' : 'expand'}`}>
      {/* Collapse button */}
      {type === 'project' && <FontIcon
        iconName='GlobalNavButton'
        className={'icon'}
        onClick={onClickToggle}
      />}
      {/* Body */}
      <div style={{ padding: 8 }}
           className={`planboard-calender ${type === 'project' ? !toggleOverlay ? 'display-none' : '' : ''}`}>
        {type === 'project'
          ? <ProjectPbCalendar />
          : <TeamPbCalendar />
        }

        <div style={{ height: 1, backgroundColor: '#f3ede6' }}></div>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues()}
          keepDirtyOnReinitialize={true}
          mutators={{
            setContractingUnit: (args, state) => {
              const field = state.fields.contractingUnit;
              field.change(args[1]);
            },
            setBusinessUnit: (args, state) => {
              const field = state.fields.businessUnit;
              field.change(args[1]);
            },
            setProjectSequenceCode: (args, state) => {
              const field = state.fields.projectSequenceCode;
              field.change(args[1]);
            },
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            formRef.current = form;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div>
                  <Stack
                    gap={15}
                    verticalFill
                    styles={{
                      root: {
                        width: '100%',
                        verticalAlign: 'center',
                        marginTop: '15px',
                      },
                    }}
                  >
                    <Stack.Item>
                      <div className={'full-width'}>
                        <Field
                          name='contractingUnit'
                          // hidden={toggleOverlay}
                          component={MultiSelectDropDown}
                          options={contractingUnits?.map((item: any) => {
                            return {
                              key: item.key,
                              text: item.name,
                              sequenceCode: item.sequenceCode,
                            };
                          })}
                          label=''
                          placeholder={t('selectCu')}
                          disabled={!!getProject()}
                          required={false}
                          selectedKeys={values?.contractingUnit?.key}
                          onSelect={onSelectCu}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item>
                      <div className={'full-width'}>
                        <Field
                          name='businessUnit'
                          // key={selectedCu}
                          // hidden={toggleOverlay}
                          selectedKey={isMyEnvironment() ? values?.businessUnit : selectedProject?.projectClassification?.projectClassificationBuisnessUnit ?? null}
                          component={SelectedKeyDropDownAdaptater}
                          options={values?.contractingUnit?.key ? buDropDown : []}
                          placeholder={t('selectBu')}
                          lable=''
                          disabled={!!getProject()}
                          required={false}
                          onSelected={onSelectBu}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item>
                      <div className={'full-width'}>
                        <Field name='projectSequenceCode'>
                          {({ input, meta }) => (
                            <CustomDropdown
                              // key={selectedBu}
                              id={values?.businessUnit + 'projectSequenceCode'}
                              onChange={onSelectProject}
                              selectedOption={values?.projectSequenceCode}
                              promiseOptions={projectOptions}
                              validationMessage={''}
                              required={false}
                              reference={''}
                              noHeight={true}
                              disabled={!!getProject()}
                            />
                          )}
                        </Field>
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>
                {/*<pre>{JSON.stringify({selectedCu, selectedBu, ppbProjectSequenceCode}, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </form>
            );
          }}
        />

        <div style={{ height: 1, backgroundColor: '#f3ede6' }}></div>

        <div className='filter-option-list' style={{ marginTop: '1px', position:'relative' }}>
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                marginTop: 2,
                width: '100%',
                verticalAlign: 'center',
                // Height:250,
                height: horizontalReSizer - 470 > 1 ? horizontalReSizer - 435 : 1,
                overflow: 'auto',
              },
            }}
          >
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default PlanBoardShortcutPane;
