import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CommandBar} from "@fluentui/react/lib/CommandBar";
import {useDispatch, useSelector} from "react-redux";
import {readCIAWById, sendCIAW} from "../../../reducers/ciawReducer";
import ConfirmationDialog from "../../../shared/confirmationDialog/confirmationDialog";
import _ from 'lodash';

const CommandBarLeft = (props: { disable: boolean }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(true);
    // const [isCancelOpen, setIsCancelOpen] = useState<boolean>(true);

    const {
        selectedCIAWListItems,
        timeClockSelectedDate,
        formData
    } = useSelector(
      (state: any) => state.ciaw
    );

    // const getDateDif = () => {
    //     const startDate = moment();
    //     const endDate = moment(timeClockSelectedDate);
    //     const diff = endDate.diff(startDate);
    //     const diffDuration = moment.duration(diff);
    //
    //     return diffDuration.days();
    // };

    const handleSendCiaw = () => {
        const ciawId = selectedCIAWListItems.filter((item: any) => item.ciawStatus != "Cancelled").map((item: any) => item.id);

        Promise.all([
            dispatch(sendCIAW({ciawId: ciawId}))    //, dateDif: `${getDateDif() + 1}`}))
        ]).then(() => {
            dispatch(readCIAWById(formData?.id));
            setIsOpen(true);
        });
    };

    const handleCiawDisabled = () => {
        return _.isArray(selectedCIAWListItems) && (selectedCIAWListItems[0]?.ciawStatus === "Cancelled" || !(selectedCIAWListItems && selectedCIAWListItems.length > 0));
        //TODO: timeClockSelectedDate <= new Date();
    };

    const getItems = () => {
        return [
            {
                key: 'sendCiaw',
                text: t('sendCiaw'),
                iconProps: {iconName: 'Send'},
                onClick: () => {
                    setIsOpen(false);
                },
                disabled: handleCiawDisabled(),
            },
            // {
            //     key: 'cancelCiaw',
            //     text: t('cancelCiaw'),
            //     iconProps: {iconName: 'UserRemove'},
            //     onClick: () => {
            //         setIsCancelOpen(false);
            //     },
            //     disabled: handleCiawDisabled(),
            // },
        ];
    };

    return (
      <div>
          <CommandBar
            items={getItems()}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          <ConfirmationDialog
            hidden={isOpen}
            title={t('areyousure')}
            subText={t('Are you sure you want to send the CIAW?')}
            onClickConfirmDialog={() => handleSendCiaw()}
            onClickCancelDialog={() => setIsOpen(true)}
            cancelButtonText={t('No, I won\'t!')}
            confirmButtonText={t('Yes, I want!')}
          />
          {/*<ConfirmationDialog*/}
          {/*  hidden={isCancelOpen}*/}
          {/*  title={t('areyousure')}*/}
          {/*  subText={t('Are you sure you want to cancel the CIAW?')}*/}
          {/*  onClickConfirmDialog={() => handleCancelCiaw()}*/}
          {/*  onClickCancelDialog={() => setIsCancelOpen(true)}*/}
          {/*  cancelButtonText={t('No, I won\'t!')}*/}
          {/*  confirmButtonText={t('Yes, I want!')}*/}
          {/*/>*/}
      </div>
    );
};

export default CommandBarLeft;
