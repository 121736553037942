import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  SelectableOptionMenuItemType,
  Toggle,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useExpand from '../../hooks/useExpand';
import { ViewMode } from '../ganttv2/types/public-types';
import { getProject } from '../util';

const basicStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

const ModeSwitcher = ({ onViewModeChange, onViewListChange, isCheck, selectedViewMode, expandToggleEnabled = true, viewSwitcherOptions }: any) => {
  const { t } = useTranslation();
  const {isExpand, setExpand, setIsApplyExpandContext} = useExpand();
  const [mode, setMode] = React.useState<any>(getProject() ? ViewMode.Week: ViewMode.Month );

  const onRenderOption = (item: any) => {
    switch (item.itemType) {
      case SelectableOptionMenuItemType.Header:
      case SelectableOptionMenuItemType.Divider:
        return <span style={{ width: 180 }}>{item.text}</span>;

      default:
        return <span style={{ width: 180 }}>{item.text}</span>;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ height: 42, marginTop: 0, paddingLeft: 10 }}>
        <ComboBox
          defaultSelectedKey={ selectedViewMode }
          selectedKey={ selectedViewMode }
          label={t('viewMode')}
          autoComplete="off"
          options={viewSwitcherOptions}
          onRenderOption={onRenderOption as any}
          styles={basicStyles}
          onChange={(
            event: React.FormEvent<IComboBox>,
            option?: IComboBoxOption,
            index?: number,
            value?: string,
          ) => {
            onViewModeChange(option?.key);
            setMode(option?.key)

          }}
        />
      </div>
      {expandToggleEnabled &&
      <div style={{ marginTop: 26, marginLeft:20 }}>
        <Toggle
          onText={t('collapse')}
          offText={t('expand')}
          onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            setIsApplyExpandContext(true)
            setExpand(!checked);


          }}
        />
      </div>
}
    </div>
  );
};

export default ModeSwitcher;
