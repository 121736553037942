import React from 'react';
import { DatePicker, DayOfWeek } from '@fluentui/react';
import { withTranslation } from 'react-i18next';


class CustomRequiredDatePicker extends React.Component<any, any> {


  public render(): JSX.Element {
    const { t } = this.props;
    const language = window.navigator.language;
    // let today=new Date();
    return (
      <DatePicker
        firstDayOfWeek={this.firstDayOfWeek(language)}
        minDate={this.props.setMinDate}
        maxDate={this.props.setMaxDate}
        label={this.props.label}
        placeholder={t('selectadate')}
        ariaLabel={this.props.label}
        value={(this.props.setValue) ? this.props.setValue : null}
        onSelectDate={this.onSelectDate}
        disabled={this.props.disabled}
        isRequired
        textField={{ errorMessage: this.props.errorMessage }}
      />
    );
  }


  private onSelectDate = (date: Date | null | undefined): void => {
    if (date) {
      let month = (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1);
      let day = (date.getDate() < 10 ? '0' : '') + (date.getDate());
      let formatDate = (date.getFullYear()) + '-' + month + '-' + day + 'T00:00:00.000Z';

      this.props.getValue(date);
    }
  };

  private firstDayOfWeek(language: string): DayOfWeek | undefined {
    switch (language) {
      case 'en':
        return 0;
      case 'nl':
        return 1;
      default:
        return 0;
    }
  };
}

export default withTranslation()(CustomRequiredDatePicker);
