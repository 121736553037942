import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapHtmlMarker,
  AzureMapLayerProvider,
  AzureMapsProvider,
  IAzureDataSourceChildren,
  IAzureMapHtmlMarkerEvent,
  IAzureMapLayerType,
} from 'react-azure-maps';
import { AuthenticationType, data, HtmlMarkerOptions, SymbolLayerOptions } from 'azure-maps-control';
import { getTheme, Icon, PrimaryButton } from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { Position } from '../../../types/projectDefinition';
import ENV from '../../../../env.json';

const theme = getTheme();

const point1 = new data.Position(4.3452, 50.8373);
const point2 = new data.Position(-120.2, 45.1);
const point3 = new data.Position(-120.2, 50.1);
const point4 = new data.Position(-126.2, 55.1);

const colorValue = () =>
  '#000000'.replace(/0/g, function() {
    return (~~(Math.random() * 16)).toString(16);
  });

const markersStandardImages = [
  `marker-black`,
  `marker-blue`,
  `marker-darkblue`,
  `marker-red`,
  `marker-yellow`,
  `pin-blue`,
  `pin-darkblue`,
  `pin-red`,
  `pin-round-blue`,
  `pin-round-darkblue`,
  `pin-round-red`,
];

const rand = () => {
  const randomImage2 =
    markersStandardImages[
      Math.floor(Math.random() * markersStandardImages.length)
      ];
  return randomImage2;
};
const AzureMapComponent = (props: {
  setLocationAddress: (pos: Position) => void;
  position: Position;
}) => {
  function clusterClicked(e: any) {
  }

  function dragMarker(e: any) {
  }

  function onDragEnd(e: any) {
    if (
      e.type === 'dragend' &&
      e.target &&
      e.target.marker &&
      e.target.marker._lngLat.lat
    ) {
      setPosition({
        lat: e.target.marker._lngLat.lat.toFixed(8),
        lon: e.target.marker._lngLat.lng.toFixed(8),
      });
      setDisable(false);
    }
  }

  const onClick = (e: any) => {
  };

  function azureHtmlMapMarkerOptions(
    coordinates: data.Position,
  ): HtmlMarkerOptions {
    return {
      position: coordinates,
      text: 'My text',
      title: 'Title',
      draggable: true,
    };
  }

  const memoizedOptions: SymbolLayerOptions = {
    textOptions: {
      textField: ['get', 'title'], //Specify the property name that contains the text you want to appear with the symbol.
      offset: [0, 1.2],
    },
  };

  const eventToMarker: Array<IAzureMapHtmlMarkerEvent> = [
    { eventName: 'click', callback: onClick },
    { eventName: 'drag', callback: dragMarker },
    { eventName: 'dragend', callback: onDragEnd },
  ];

  const renderPoint = (coordinates: data.Position) => {
    const rendId = Math.random();

    return (
      <AzureMapFeature
        key={rendId}
        id={rendId.toString()}
        type="Point"
        coordinate={coordinates}
        properties={{
          title: 'Pin',
          icon: 'pin-round-blue',
        }}
      />
    );
  };

  function renderHTMLPoint(coordinates: data.Position): any {
    const rendId = Math.random();
    return (
      <AzureMapHtmlMarker
        key={rendId}
        markerContent={
          <Icon
            iconName="PinnedSolid"
            style={{
              marginBottom: -2,
              paddingRight: 4,
              fontSize: 30,
              color: uPrinceTheme.palette.themePrimary,
            }}
          />
        }
        options={{ ...azureHtmlMapMarkerOptions(coordinates) } as any}
        events={eventToMarker}
      />
    );
  }

  const [markers, setMarkers] = useState([point1]);
  const [htmlMarkers, setHtmlMarkers] = useState([point1]);
  const [markersLayer, setMarkersLayer] = useState<IAzureMapLayerType>(
    'SymbolLayer',
  );
  const [layerOptions, setLayerOptions] = useState<SymbolLayerOptions>(
    memoizedOptions,
  );
  const [position, setPosition] = useState({ lat: 0.0, lon: 0.0 });
  const [mapOption, setMapOption] = useState<any>(null);
  const [disable, setDisable] = useState(true);

  let pos = { lat: 0, lon: 0 };
  if (typeof position.lat === 'string' && typeof position.lon === 'string') {
    pos = { lat: parseFloat(position?.lat), lon: parseFloat(position?.lon) };
  } else {
    pos = position;
  }
  // const option: IAzureMapOptions =
  //    {
  //     authOptions: {
  //       authType: AuthenticationType.subscriptionKey,
  //       subscriptionKey: ENV.MAP_KEY,
  //     },
  //     center:
  //      pos?.lat === 0
  //         ? [-74.22669, 41.39681]
  //         : [83.83762556, 6.19468181],//pos?.lat, pos?.lon],
  //     zoom: 6,
  //     view: 'Auto',
  //   };

  useEffect(() => {
    if (props.position?.lat == 0 || props.position?.lon == 0) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }, []);

  const showPosition = (position: any) => {
    if (position.coords.latitude > 0 || position.coords.longitude > 0) {
      setHtmlMarkers([
        new data.Position(position.coords.longitude, position.coords.latitude),
      ]);
      setPosition({ lat: position.coords.latitude, lon: position.coords.longitude });
    }
  };

  useEffect(() => {
    //addRandomMarker();
  }, [markers]);

  useEffect(() => {
    if (props.position?.lat > 0 || props.position?.lon > 0) {
      setHtmlMarkers([
        new data.Position(props.position?.lon, props.position?.lat),
      ]);
      setPosition({ lat: props.position?.lat, lon: props.position?.lon });
    }
  }, [props.position]);

  useEffect(() => {
    //addRandomMarker();
  }, [htmlMarkers]);

  useEffect(() => {
    let pos = { lat: 0, lon: 0 };
    if (typeof position.lat === 'string' && typeof position.lon === 'string') {
      pos = { lat: parseFloat(position?.lat), lon: parseFloat(position?.lon) };
    } else {
      pos = position;
    }
    if (pos.lat !== 0) {
      setMapOption({
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: ENV.MAP_KEY,
        },
        center:
          [pos.lon, pos.lat],
        zoom: 6,
        view: 'Auto',
      });
    }
  }, [position]);

  // const addRandomMarker = () => {
  //   const randomLongitude = Math.floor(Math.random() * (-80 - -120) + -120);
  //   const randomLatitude = Math.floor(Math.random() * (30 - 65) + 65);
  //   const newPoint = new data.Position(50.8373, 4.3452);
  //   setMarkers([newPoint]);
  // };

  // const addRandomHTMLMarker = () => {
  //   const randomLongitude = Math.floor(Math.random() * (-80 - -120) + -120);
  //   const randomLatitude = Math.floor(Math.random() * (30 - 65) + 65);
  //   const newPoint = new data.Position(randomLongitude, randomLatitude);
  //   setHtmlMarkers([...htmlMarkers, newPoint]);
  // };

  // const removeAllMarkers = () => {
  //   setMarkers([]);
  //   setHtmlMarkers([]);
  // };

  const memoizedMarkerRender: IAzureDataSourceChildren = useMemo(
    (): any => markers.map((marker) => renderPoint(marker)),
    [markers],
  );

  const memoizedHtmlMarkerRender: IAzureDataSourceChildren = useMemo(
    (): any => htmlMarkers.map((marker) => renderHTMLPoint(marker)),
    [htmlMarkers],
  );

  const { t } = useTranslation();

  return (
    <>
      <div style={styles.buttonContainer}>
        {/* <Chip label={`Lat: ${pos.lat}`} style={{marginLeft:20}} /> */}
        {/* <Chip label={`Lng: ${pos.lon}`} /> */}
        <PrimaryButton
          text={`Lat: ${pos.lat}`}
          disabled={true}
          style={{
            marginLeft: 20,
            borderRadius: 100,
            color: 'rgba(0, 0, 0, 0.87)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 12,
            paddingRight: 12,
            whiteSpace: 'nowrap',
            fontSize: '0.8125rem',
          }}
        />
        <PrimaryButton
          text={`Lng: ${pos.lon}`}
          disabled={true}
          style={{
            borderRadius: 100,
            color: 'rgba(0, 0, 0, 0.87)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 12,
            paddingRight: 12,
            whiteSpace: 'nowrap',
            fontSize: '0.8125rem',
          }}
        />
        <PrimaryButton
          text={t('Pick This Location')}
          style={{ marginTop: 0, marginBottom: 0, marginLeft: 10 }}
          onClick={() => {
            if (
              typeof position?.lat === 'string' &&
              typeof position?.lon === 'string'
            ) {
              let pos = {
                lat: parseFloat(position?.lat),
                lon: parseFloat(position?.lon),
              };
              props.setLocationAddress(pos);
            }
          }}
          disabled={disable}
        />
      </div>
      {mapOption &&
      <AzureMapsProvider>
        <div style={styles.map}>
          <AzureMap options={mapOption}>
            <AzureMapDataSourceProvider
              events={{
                dataadded: (e: any) => {
                },
              }}
              id={'markersExample AzureMapDataSourceProvider'}
              options={{ cluster: true, clusterRadius: 2 }}
            >
              <AzureMapLayerProvider
                id={'markersExample AzureMapLayerProvider'}
                options={layerOptions}
                events={{
                  click: clusterClicked,
                  dbclick: clusterClicked,
                  drag: () => {
                    dragMarker;
                  },
                }}
                lifecycleEvents={{
                  layeradded: () => {
                  },
                }}
                type={markersLayer}
              ></AzureMapLayerProvider>
              {/* {memoizedMarkerRender} */}
              {memoizedHtmlMarkerRender}
            </AzureMapDataSourceProvider>
          </AzureMap>
        </div>
      </AzureMapsProvider>
      }
    </>
  );
};

const styles = {
  map: {
    height: '80vh',
    width: '100%',
  },
  buttonContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '10px',
    gridAutoColumns: 'max-content',
    padding: '10px 0',
    alignItems: 'center',
    width: '80vw',
  },
  button: {
    height: 35,
    width: 80,
    backgroundColor: '#68aba3',
    'text-align': 'center',
  },
};

export default memo(AzureMapComponent);
