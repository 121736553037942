import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../services/messageService';

const CommandBarLeft = ({ handelShowDocumentPane }: any) => {
  const { t } = useTranslation();

  const projectRedirect = () => {
    messageService.sendMessage({ isNewProject: true });
    handelShowDocumentPane(true);
  };

  const handlePrint = useReactToPrint({ content: () => document.getElementById('projectList') });

  const getItems = () => {
    return [
      {
        key: 'newItem',
        text: t('new'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Add' },
        onClick: projectRedirect,
      },

      {
        key: 'Clone',
        disabled: true,
        name: t('clone'),
        iconProps: { iconName: 'Copy' },
      },
      {
        key: 'Edit',
        disabled: true,
        name: t('edit'),
        iconProps: { iconName: 'Edit' },
      },
      {
        key: 'Print',
        name: t('print'),
        disabled: true,
        iconProps: { iconName: 'Print' },
        onClick: handlePrint,
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;


