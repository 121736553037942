import _ from 'lodash';
import { Selection } from '@fluentui/react/lib/DetailsList';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../../i18n';
import { GET_BY_BOR, GET_BY_PBS } from '../../../actionTypes/billOfResourcesType';
import client from '../../../api';
import history from '../../../history';
import { v4 as uuidv4 } from 'uuid';
import {
  clearCpcData,
  createCompetence,
  createConsumable,
  createLabour,
  createMaterials,
  createTechInstruction,
  createTools,
  deleteCompetence,
  deleteConsumable,
  deleteLabours,
  deleteMaterials,
  deleteTechInstruction,
  deleteTools,
  filterCompetenceList,
  filterQualityList,
  getById,
  getCpcById,
  getProductById,
  getRiskDropDownsList,
  readCompetenceDropDown,
  readConsumableData,
  readInstructionDropDown,
  readLabourData,
  readMaterialsData,
  readPbsConsumableData,
  readPbsLabourData,
  readPbsMaterialsData,
  readPbsToolsData,
  readTaxonomyData,
  readTechInstruction,
  readToolsData,
  resetRiskGridList,
  saveBORStateAttr,
  saveFormData,
  updateBORConsumable,
  updateBORLabour,
  updateBORMaterial,
  updateBORTool,
  uploadInstructionDoc,
} from '../../../reducers/billOfResourcesReducer';
import { messageService } from '../../../services/messageService';
import UprinceLogger from '../../../shared/Logger/logger';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import { formatCpcResponse, formatPersonResponse } from '../../../shared/util';
import {
  BillOfResource,
  BillOfResourceValidationMessage,
  BOR_TYPE,
  BorFormData,
  BorResources,
  BorResourcesItem,
  BORResourceUpdateRequest,
  FilterItem,
  Product,
  Resource,
  ResourceTypes,
  ShortCutPaneItem,
} from '../../../types/billOfResources';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import RequiredModal from '../resources/modal/requiredModal';
import { DocumentPaneComponent } from './component';
import CustomAlert from '../../../shared/alert/customAlert';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  formData: BillOfResource;
  borResources: BorResources;
  filter: FilterItem;
  isEdit: boolean;
  validationMessages: BillOfResourceValidationMessage;
  materialList: BorResourcesItem[];
  isMaterialModalVisible: boolean;
  materialSelectionDetails: {} | string;
  materialActionButtonState: ActionButtonState;
  material: BorResourcesItem;
  isMaterialEdit: boolean;
  materialDeleteItems: [] | string[];
  tools: BorResourcesItem[];
  isToolsModalVisible: boolean;
  toolsSelectionDetails: {} | string;
  toolsActionButtonState: ActionButtonState;
  tool: BorResourcesItem;
  readToolsStatus: boolean;
  toolsDeleteItems: [] | string[];
  isToolEdit: boolean;
  labours: BorResourcesItem[];
  isLabourModalVisible: boolean;
  labourSelectionDetails: {} | string;
  labourActionButtonState: ActionButtonState;
  labour: BorResourcesItem;
  readLabourStatus: boolean;
  labourDeleteItems: [] | string[];
  isLabourEdit: boolean;
  isConsumablesModalVisible: boolean;
  consumableSelectionDetails: {} | string;
  consumableActionButtonState: ActionButtonState;
  consumable: BorResourcesItem;
  readConsumableStatus: boolean;
  consumableDeleteItems: [] | string[];
  isConsumableEdit: boolean;
  consumables: BorResourcesItem[];
  selectedShortCutItem: ShortCutPaneItem;
  selectedResourceTypeId: string | null;
  selectedCpcId: string | null;
  selectedCpcEnvironment: string | null;
  borResourcesItem: BorResourcesItem;
  isRequiredModalOpen: boolean;
  requiredModalTitle: string;
  requiredModalType: string | number;
  requiredResourceType: string | number;
  isBorReturn: boolean;
  borTypeId: any;
  borResourcesDif: boolean,
  showError: boolean,
  errorMsg: string,
  isPreviousCleared: boolean
}

interface Props extends WithTranslation {
  isEdit: boolean;
  formData: BillOfResource;
  borResources: BorResources;
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: BillOfResource) => any;
  getById: (id: string) => any;
  getProductById: (id: string) => void;
  getCpcById: (id: string) => void;
  clearCpcData: () => void;
  readMaterialsData: (id: string) => void;
  readPbsMaterialsData: (id: string) => void;
  readMaterialsStatus: boolean;
  materialList: BorResourcesItem[];
  createMaterials: any;
  createMaterialsStatus: boolean;
  readToolsData: (id: string) => void;
  readPbsToolsData: (id: string) => void;
  readToolsStatus: boolean;
  tools: BorResourcesItem[];
  createTools: any;
  createToolsStatus: boolean;
  deleteMaterials: any;
  deleteMaterialsStatus: boolean;
  deleteTools: any;
  deleteToolsStatus: boolean;
  readLabourData: (id: string) => void;
  readPbsLabourData: (id: string) => void;
  readLabourStatus: boolean;
  labours: BorResourcesItem[];
  createLabourStatus: boolean;
  createLabour: any;
  deleteLabours: any;
  deleteLabourStatus: boolean;
  readConsumableStatus: boolean;
  deleteConsumableStatus: boolean;
  createConsumable: any;
  deleteConsumable: any;
  consumables: BorResourcesItem[];
  readConsumableData: (id: string) => void;
  readPbsConsumableData: (id: string) => void;
  createConsumableStatus: boolean;
  locationTaxonomy: [];
  utilityTaxonomy: [];
  readTaxonomyData: () => void;
  productData: [];
  borStatus: [];
  borType: [];
  cpcData: any;
  updateBORMaterial: (data: BORResourceUpdateRequest) => any;
  updateBORTool: (data: BORResourceUpdateRequest) => any;
  updateBORConsumable: (data: BORResourceUpdateRequest) => any;
  updateBORLabour: (data: BORResourceUpdateRequest) => any;
  isBorReturn: boolean;
  location: any;
  borCreated: boolean;
  saveBORStateAttr: any;
  isNewBor: boolean;
  slectedPBSForBor: string;
  borInheritedPBSTitle:string
}

class DocumentPane extends React.Component<Props, State> {
  private _materialsSelection: Selection;
  private _toolsSelection: Selection;
  private _labourSelection: Selection;
  private _consumableSelection: Selection;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      borResources: {
        consumable: [],
        labour: [],
        materials: [],
        tools: [],
      },
      formData: BorFormData,
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      borResourcesItem: {
        id: null,
        required: null,
        title: null,
        date: null,
        consumed: null,
        deliveryRequested: null,
        purchased: null,
        warf: null,
        invoiced: null,
        type: null,
        environment: null,
        isNew: false,
        resourceNumber: null,
      },
      validationMessages: {
        nameErrorMsg: '',
        productTitleErrorMsg: '',
        statusErrorMsg: '',
      },
      materialList: [],
      isMaterialModalVisible: false,
      materialSelectionDetails: {},
      materialActionButtonState: { add: false, edit: true, remove: true },
      material: new BorResourcesItem(),
      isMaterialEdit: false,
      materialDeleteItems: [],
      readToolsStatus: false,
      tools: [],
      tool: new BorResourcesItem(),
      isToolsModalVisible: false,
      toolsActionButtonState: { add: false, edit: true, remove: true },
      toolsSelectionDetails: {},
      toolsDeleteItems: [],
      isToolEdit: false,
      labours: [],
      labour: new BorResourcesItem(),
      readLabourStatus: false,
      isLabourModalVisible: false,
      labourActionButtonState: { add: false, edit: true, remove: true },
      labourSelectionDetails: {},
      labourDeleteItems: [],
      isLabourEdit: false,
      isConsumablesModalVisible: false,
      consumableSelectionDetails: {},
      consumableActionButtonState: { add: false, edit: true, remove: true },
      consumable: new BorResourcesItem(),
      readConsumableStatus: false,
      consumableDeleteItems: [],
      isConsumableEdit: false,
      consumables: [],
      selectedShortCutItem: { key: null, id: null, name: 'Bor', type: 'Bor' },
      selectedResourceTypeId: null,
      selectedCpcId: null,
      selectedCpcEnvironment: null,
      isRequiredModalOpen: false,
      requiredModalTitle: '',
      requiredModalType: '',
      requiredResourceType: '',
      isBorReturn: false,
      borTypeId: null,
      borResourcesDif: false,
      showError: false,
      errorMsg: '',
      isPreviousCleared: false,
    };

    // Populate with items for demos.
    this._materialsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_materialsSelection',
          this._getMaterialSelectionDetails(),
        );
        this.setState({
          materialSelectionDetails: this._getMaterialSelectionDetails(),
          materialActionButtonState: this._getMaterialActionButtonState(),
          material: this._materialEditDetails(),
          materialDeleteItems: this._resourceDeleteDetails(
            this._materialsSelection,
          ),
        });
      },
    });

    this._toolsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_toolsSelection', this._getToolsSelectionDetails());
        this.setState({
          toolsSelectionDetails: this._getToolsSelectionDetails(),
          toolsActionButtonState: this._getToolsActionButtonState(),
          tool: this._toolsEditDetails(),
          toolsDeleteItems: this._resourceDeleteDetails(this._toolsSelection),
        });
      },
    });

    this._labourSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_labourSelection',
          this._getLabourSelectionDetails(),
        );
        this.setState({
          labourSelectionDetails: this._getLabourSelectionDetails(),
          labourActionButtonState: this._getLabourActionButtonState(),
          labour: this._labourEditDetails(),
        });
      },
    });

    this._consumableSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_consumableSelection',
          this._getConsumableSelectionDetails(),
        );
        this.setState({
          consumableSelectionDetails: this._getConsumableSelectionDetails(),
          consumableActionButtonState: this._getConsumableActionButtonState(),
          consumable: this._consumableEditDetails(),
        });
      },
    });
  }

  formattedPbsResourcesToBorResources = (resource: BorResourcesItem[]) => {
    if (resource) {
      let data: BorResourcesItem[] = resource.map((item: any) => {
        return {
          id: item.cpcKey,
          title: item.cpcText,
          required: item.quantity,
          consumed: null,
          date: item.data ? item.data : new Date(),
          deliveryRequested: null,
          purchased: null,
          warf: null,
          invoiced: null,
          type: null,
          environment: null,
          isNew: false,
          resourceNumber: null,
        };
      });
      return data;
    } else {
      return [];
    }
  };

  formattedProductToBorProduct = (product: any) => {
    if (product) {
      let data: Product = {
        id: product.id,
        title: product.title,
        productId: product.productId,
        pbsProductItemTypeId: product.pbsProductItemTypeId,
        pbsProductItemType: product.pbsProductItemType,
        locationTaxonomyParentId: product.locationTaxonomyParentId,
        utilityTaxonomyParentId: product.utilityTaxonomyParentId,
        utilityParentId: null,
        locationParentId: null,
      };
      return data;
    } else {
      return {
        id: null,
        productId: null,
        title: null,
        utilityParentId: null,
        locationParentId: null,
        locationTaxonomyParentId: null,
        utilityTaxonomyParentId: null,
        pbsProductItemType: null,
        pbsProductItemTypeId: null,
      };
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.slectedPBSForBor !== this.props.slectedPBSForBor) {
      this.props.saveBORStateAttr("createdVPBOR",null)
      this.setState({
        formData: {
          ...BorFormData,
          id:uuidv4(),
          borTypeId: BOR_TYPE.REGULAR,
          name: this.props.borInheritedPBSTitle,
          product: {
            ...BorFormData.product,
            productId: this.props.slectedPBSForBor,
            title: this.props.slectedPBSForBor,
          },
        }, isPreviousCleared: true,
      }, this.getProductDetails);
    }

    if (prevProps.slectedPBSForBor === this.props.slectedPBSForBor && !this.state.isPreviousCleared) {
      this.props.saveBORStateAttr("createdVPBOR",null)
      this.setState({
        formData: {
          ...BorFormData,
          id:uuidv4(),
          borTypeId: BOR_TYPE.REGULAR,
          name: this.props.borInheritedPBSTitle,
          product: {
            ...BorFormData.product,
            productId: this.props.slectedPBSForBor,
            title: this.props.slectedPBSForBor,
          },
        }, isPreviousCleared: true,
      }, this.getProductDetails);
    }

    if (prevProps.readMaterialsStatus != this.props.readMaterialsStatus) {
      this.setState({ materialList: this.props.materialList });
    }
    if (prevState.formData?.id != this.state.formData?.id) {
      if (!this.props.formData.itemId) {
        let formData = this.state.formData;
        formData.borTypeId = this.state.isBorReturn ? BOR_TYPE.RETURN : BOR_TYPE.REGULAR;
        this.setState({ formData });
      }
    }

    if (prevProps.borCreated !== this.props.borCreated && this.props.borCreated) {
      const paths = window.location.pathname.split('/');
      if (paths && paths.length >= 5 && paths[5] === 'project-planning') {
        messageService.sendMessage({ borCreatedFromVP: true });
      }
    }

    if (prevProps.createMaterialsStatus != this.props.createMaterialsStatus) {
      this.setState({ isMaterialModalVisible: false });
      this.props.readMaterialsData(this.props.formData.id!!);
    }
    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      let formData = this.state.formData;
      formData.id = this.props.uid;
      formData.borTypeId = this.state.isBorReturn ? BOR_TYPE.RETURN : BOR_TYPE.REGULAR;
      this.setState({ formData });
    }

    if (prevProps.formData != this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData) && this.props.formData.id) {
          let borResources = this.props.formData.borResources;
          this.setState({
            formData: this.props.formData,
            borResources,
            isBorReturn: this.props.formData.borTypeId === BOR_TYPE.RETURN ? true : false,
          });
        }
      }
      this.resetValidation();
    }
    if (prevState.formData !== this.state.formData) {
      if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({
          formData,
          //isBorReturn: this.props.formData.borTypeId === BOR_TYPE.RETURN ? true : false
        });
      }
    }

    if (this.props?.location?.state?.type && (this.props?.location?.state?.type !== this.state.borTypeId)) {
      let borNewData = { ...this.props.formData, borTypeId: this.props?.location?.state?.type };
      this.setState({
        borTypeId: this.props?.location?.state?.type,
        isBorReturn: this.props?.location?.state?.isBorReturn,
        formData: borNewData,
      });
    }


    if (prevProps.readToolsStatus != this.props.readToolsStatus) {
      this.setState({ tools: this.props.tools });
    }

    if (prevProps.createToolsStatus != this.props.createToolsStatus) {
      this.setState({ isToolsModalVisible: false });
      this.getPbsToolsList(GET_BY_BOR);
    }

    if (prevProps.readLabourStatus != this.props.readLabourStatus) {
      this.setState({ labours: this.props.labours });
    }

    if (prevProps.createLabourStatus != this.props.createLabourStatus) {
      this.setState({ isLabourModalVisible: false });
      this.getPbsLabourList(GET_BY_BOR);
    }

    if (prevProps.readConsumableStatus != this.props.readConsumableStatus) {
      this.setState({ consumables: this.props.consumables });
    }

    if (prevProps.createConsumableStatus != this.props.createConsumableStatus) {
      this.setState({ isConsumablesModalVisible: false });
      this.getPbsConsumablesList(GET_BY_BOR);
    }

    if (prevProps.productData != this.props.productData) {
      if (this.props.productData) {
        let formData = this.state.formData;
        let product: Product = {
          id: null,
          productId: null,
          title: null,
          utilityParentId: null,
          locationParentId: null,
          locationTaxonomyParentId: null,
          utilityTaxonomyParentId: null,
          pbsProductItemType: null,
          pbsProductItemTypeId: null,
        };
        product = this.formattedProductToBorProduct(this.props.productData);
        formData.product = product;
        this.setState({ formData }, this.getResourcesListByProduct);
      }
    }

    if (prevProps.materialList != this.props.materialList) {
      if (this.props.productData) {
        let materialList: BorResourcesItem[] = this.formattedPbsResourcesToBorResources(
          this.props.materialList,
        );
        let borResources = this.state.borResources;
        borResources.materials = materialList;
        this.setState({
          borResources,
        });
      }
    }
    if (prevProps.tools != this.props.tools) {
      if (this.props.productData) {
        let tools: BorResourcesItem[] = this.formattedPbsResourcesToBorResources(
          this.props.tools,
        );
        let borResources = this.state.borResources;
        borResources.tools = tools;
        this.setState({
          borResources,
        });
      }
    }
    if (prevProps.consumables != this.props.consumables) {
      if (this.props.productData) {
        let consumables: BorResourcesItem[] = this.formattedPbsResourcesToBorResources(
          this.props.consumables,
        );
        let borResources = this.state.borResources;
        borResources.consumable = consumables;
        this.setState({
          borResources,
        });
      }
    }
    if (prevProps.labours != this.props.labours) {
      if (this.props.productData) {
        let labours: BorResourcesItem[] = this.formattedPbsResourcesToBorResources(
          this.props.labours,
        );
        let borResources = this.state.borResources;
        borResources.labour = labours;
        this.setState({
          borResources,
        });
      }
    }
    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      if (this.props.isNew) {
        this.resetFormData();
      }
    }
  }

  getResourcesListByProduct = () => {
    this.getMaterisList(GET_BY_PBS);
    this.getPbsToolsList(GET_BY_PBS);
    this.getPbsLabourList(GET_BY_PBS);
    this.getPbsConsumablesList(GET_BY_PBS);
  };

  resetSelection = () => {
  };

  getPbsToolsList = (type: string) => {
    if (type === GET_BY_PBS) {
      if (
        this.props.productData &&
        this.state.formData.product &&
        this.state.formData.product.id
      ) {
        this.props.readPbsToolsData(this.state.formData.product.id);
      }
    } else if (type === GET_BY_BOR) {
      if (
        !_.isEmpty(this.props.formData) &&
        this.props.formData.id !== null &&
        this.props.isEdit
      ) {
        this.props.readToolsData(this.props.formData.id!!);
      }
    }
  };

  getPbsLabourList = (type: string) => {
    if (type === GET_BY_PBS) {
      if (
        this.props.productData &&
        this.state.formData.product &&
        this.state.formData.product.id
      ) {
        this.props.readPbsLabourData(this.state.formData.product.id);
      }
    } else if (type === GET_BY_BOR) {
      if (
        !_.isEmpty(this.props.formData) &&
        this.props.formData.id !== null &&
        this.props.isEdit
      ) {
        this.props.readLabourData(this.props.formData.id!!);
      }
    }
  };

  getMaterisList = (type: string) => {
    if (type === GET_BY_PBS) {
      if (
        this.props.productData &&
        this.state.formData.product &&
        this.state.formData.product.id
      ) {
        this.props.readPbsMaterialsData(this.state.formData.product.id);
      }
    } else if (type === GET_BY_BOR) {
      if (
        !_.isEmpty(this.props.formData) &&
        this.props.formData.id !== null &&
        this.props.isEdit
      ) {
        this.props.readMaterialsData(this.props.formData.id!!);
      }
    }
  };

  getPbsConsumablesList = (type: string) => {
    if (type === GET_BY_PBS) {
      if (
        this.props.productData &&
        this.state.formData.product &&
        this.state.formData.product.id
      ) {
        this.props.readPbsConsumableData(this.state.formData.product.id);
      }
    } else if (type === GET_BY_BOR) {
      if (
        !_.isEmpty(this.props.formData) &&
        this.props.formData.id !== null &&
        this.props.isEdit
      ) {
        this.props.readConsumableData(this.props.formData.id!!);
      }
    }
  };

  componentDidMount() {
    //this.props.filterQualityList();
    this.getMaterisList(GET_BY_BOR);
    this.getPbsToolsList(GET_BY_BOR);
    this.getPbsLabourList(GET_BY_BOR);
    this.getPbsConsumablesList(GET_BY_BOR);
    this.props.readTaxonomyData();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewBor) {
          this.resetValidation();
          this.resetFormData();
          this.props.saveBORStateAttr(true);
          this.setState({
            isBorReturn: false,
          });
        }
        if (data.data.saveFormDataBor) {
          //alert("test");
          this.saveFormData();
        }
        if (data.data.borSelectedShortCutPaneItem) {
          this.setState({
            selectedShortCutItem: data.data.borSelectedShortCutPaneItem,
          });
        }
        if (data.data.selectedResourceTypeId) {
          this.setState({
            selectedResourceTypeId: data.data.selectedResourceTypeId,
          });
        }
        if (data.data.isRequiredModalOpen) {
          this.setState({
            isRequiredModalOpen: data.data.isRequiredModalOpen,
          });
        }
        if (data.data.requiredModalTitle) {
          this.setState({
            requiredModalTitle: data.data.requiredModalTitle,
          });
        }
        if (data.data.requiredModalType) {
          this.setState({
            requiredModalType: data.data.requiredModalType,
          });
        }
        if (data.data.requiredResourceType) {
          this.setState({
            requiredResourceType: data.data.requiredResourceType,
          });
        }
        if (data.data.addNewBorResources) {
          this.handleBorResourcesChange(
            data.data.borResourcesItem,
            data.data.selectedResourceTypeId,
          );
        }
        if (data.data.isBorReturn) {
          this.resetValidation();
          //this.resetFormData()
          let formData = this.props.formData;
          // formData.id = this.props.uid,
          formData.borTypeId = BOR_TYPE.RETURN;

          this.setState({
            isBorReturn: true,
            formData,
          });
        }
        if (data.data.returnResources) {
          let formData = this.state.formData;
          formData.borResources = data.data.returnResources;
          this.setState({ formData: formData, borResources: data.data.returnResources });
        }

        if (data.data?.rightClickTask?.type.toString() === 'pbs') {
          // this.resetValidation();
          this.props.saveBORStateAttr("createdVPBOR",null)
          this.resetFormData();
          this.setState({ isPreviousCleared: false });
        }
      }
    });
    // if(this.props.isBorReturn) {
    //   this.setState({
    //     isBorReturn: true
    //   })
    // }

    // if(!this.props.formData.id){
    //   if (this.props.isNew) {
    //     this.resetFormData();
    //   }
    // }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { borSelectedShortCutPaneItem: this.state.selectedShortCutItem },
    });
  };

  // private getProductByName = async (name: string) => {
  //   let filter = {
  //     title: name,
  //   };
  //   if (name.length >= 2) {
  //     let response = await client.post(`/Bor/FilterProduct`, filter);
  //     return this.formatProductResponse(response);
  //   }
  // };

  getProductByName = async (name: string) => {
    const filter = { title: name };
    if (name.length >= 2) {
      const response = await client.post('/Bor/FilterProduct', filter);
      return this.formatProductResponse(response);
    } else if (name === '') {
      const response = await client.post('/Bor/FilterProduct', { title: null });
      return this.formatProductResponse(response);
    }
  };


  formatProductResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const titleNotEmptyList = response.data.result.filter((item: { text: string | null; }) => item.text !== null);
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item.key,
            label: item.text,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  private getCpcByName = async (name: string) => {
    let filter = {
      resourceTypeId: this.state.selectedResourceTypeId,
      resourceFamilyId: null,
      resourceNumber: null,
      title: name,
      BORId: this.state.formData.id,
      status: 1,
      sorter: {
        attribute: 'title',
        order: 'asc',
      },
    };
    if (name.length >= 2) {
      let response = await client.post(
        `ContractingUnitTenants/BORCPCFilter`,
        filter,
      );
      return this.formatCpcResponse(response);
    }
  };

  private formatCpcResponse = (response: any) => {
    let localOptions: { value: string; label: string }[] = [];
    let contractingUnitOptions: { value: string; label: string }[] = [];
    let centralOptions: { value: string; label: string }[] = [];
    let groupedOptions = [
      {
        label: this.props.t('project'),
        options: localOptions,
      },
      {
        label: this.props.t('contractingUnit'),
        options: contractingUnitOptions,
      },
      {
        label: this.props.t('Organization'),
        options: centralOptions,
      },
    ];
    if (response && response.data) {
      let localData = response.data.local;
      let contractingUnit = response.data.contractingUnit;
      let central = response.data.central;

      if (localData && _.isArray(localData)) {
        localOptions = localData.map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            environment: 'local',
            item: item,
          };
        });
      }
      if (contractingUnit && _.isArray(contractingUnit)) {
        contractingUnitOptions = contractingUnit.map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            environment: 'cu',
            item: item,
          };
        });
      }
      if (central && _.isArray(central)) {
        centralOptions = central.map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            environment: 'central',
            item: item,
          };
        });
      }
      groupedOptions = [
        {
          label: this.props.t('project'),
          options: localOptions,
        },
        {
          label: this.props.t('contractingUnit'),
          options: contractingUnitOptions,
        },
        {
          label: this.props.t('organization'),
          options: centralOptions,
        },
      ];
    } else {
    }

    return groupedOptions;
  };

  private productOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getProductByName(inputValue));
      // }, 1000);
    });

  private cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getCpcByName(inputValue));
      // }, 1000);
    });

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        <CustomAlert
          showMsg={this.state.borResourcesDif}
          msg={this.props.t('resourcesCountAreNotSame')}
          msgType={'error'}
          handleCustomAlert={() => this.setState({ borResourcesDif: false })}
        />


        <div style={{ position: 'fixed', width: '75%', zIndex: 1000 }}>
          <CustomAlert
            showMsg={this.state.showError}
            msg={this.state.errorMsg}
            msgType={'error'}
            isMultiline={true}
            handleCustomAlert={() => this.setState({ showError: false })}
          />
        </div>
        <DocumentPaneComponent
          dropDownOptions={{
            locationTaxonomy: this.props.locationTaxonomy,
            utilityTaxonomy: this.props.utilityTaxonomy,
            borStatus: this.props.borStatus,
            borType: this.props.borType,
          }}
          billOfResource={this.state.formData}
          borResources={this.state.borResources}
          handleFormChange={(billOfResource: BillOfResource, save: boolean) => {
            this.handleFormChange(billOfResource);
          }}
          saveFormData={() => this.saveFormData()}
          getProductDetails={() => this.getProductDetails()}
          isEdit={this.props.isEdit}
          validationMessages={this.state.validationMessages}
          materials={this.state.materialList}
          material={this.state.material}
          openMaterialModal={() => {
            this.openMaterialModal();
          }}
          materialActionButtonState={this.state.materialActionButtonState}
          materialSelection={this._materialsSelection}
          tools={this.state.tools}
          toolsActionButtonState={this.state.toolsActionButtonState}
          openToolsModal={() => this.openToolsModal()}
          toolSelection={this._toolsSelection}
          handleMaterialDelete={() => {
            this.handleMaterialsDelete();
          }}
          handleToolsDelete={() => {
            this.handleToolsDelete();
          }}
          materialLoading={this.props.readMaterialsStatus}
          toolsLoading={this.props.readToolsStatus}
          labours={this.state.labours}
          labourActionButtonState={this.state.labourActionButtonState}
          openLabourModal={() => this.openLabourModal()}
          labourSelection={this._labourSelection}
          labourLoading={this.props.readLabourStatus}
          handleLabourDelete={() => {
            this.handleLaboursDelete();
          }}
          openConsumableModal={() => {
            this.openConsumaleModal();
          }}
          consumableSelection={this._consumableSelection}
          consumableActionButtonState={this.state.consumableActionButtonState}
          consumables={this.state.consumables}
          consumalbeLoading={this.props.readConsumableStatus}
          handleConsumableDelete={() => {
            this.handleConsumableDelete();
          }}
          productOptions={this.productOptions}
          cpcOptions={this.cpcOptions}
          cpcData={this.props.cpcData}
          isBorReturn={this.state.isBorReturn}
        />

        <RequiredModal
          isOpen={this.state.isMaterialModalVisible}
          openModal={() => {
            this.openMaterialModal();
          }}
          handleResourceSave={(quantity: string | number, cpc: any) => {
            this.handleResourceItemSave(quantity, this.state.isMaterialEdit, this.state.material, cpc);
          }}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          title={this.state.isMaterialEdit ? this.props.t('Edit') : this.props.t('Add')}
          type={this.state.requiredModalType}
          cpcOptions={this.cpcOptions}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          isEdit={this.state.isMaterialEdit}
          resource={this.state.material}
          isBorReturn={this.state.isBorReturn}
          // resourceType={'Materials'}
        />

        <RequiredModal
          isOpen={this.state.isToolsModalVisible}
          openModal={() => {
            this.openToolsModal();
          }}
          handleResourceSave={(quantity: string | number, cpc: any) => {
            this.handleResourceItemSave(quantity, this.state.isToolEdit, this.state.tool, cpc);
          }}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          title={this.state.isToolEdit ? this.props.t('Edit') : this.props.t('Add')}
          type={this.state.requiredModalType}
          cpcOptions={this.cpcOptions}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          isEdit={this.state.isToolEdit}
          resource={this.state.tool}
          isBorReturn={this.state.isBorReturn}
          // resourceType={'Tools'}
        />

        <RequiredModal
          isOpen={this.state.isConsumablesModalVisible}
          openModal={() => {
            this.openConsumaleModal();
          }}
          handleResourceSave={(quantity: string | number, cpc: any) => {
            this.handleResourceItemSave(quantity, this.state.isConsumableEdit, this.state.consumable, cpc);
          }}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          title={this.state.isConsumableEdit ? this.props.t('Edit') : this.props.t('Add')}
          type={this.state.requiredModalType}
          cpcOptions={this.cpcOptions}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          isEdit={this.state.isConsumableEdit}
          resource={this.state.consumable}
          isBorReturn={this.state.isBorReturn}
          // resourceType={'Consumables'}
        />

        <RequiredModal
          isOpen={this.state.isLabourModalVisible}
          openModal={() => {
            this.openLabourModal();
          }}
          handleResourceSave={(quantity: string | number, cpc: any) => {
            this.handleResourceItemSave(quantity, this.state.isLabourEdit, this.state.labour, cpc);
          }}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          title={this.state.isLabourEdit ? this.props.t('Edit') : this.props.t('Add')}
          type={this.state.requiredModalType}
          cpcOptions={this.cpcOptions}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          isEdit={this.state.isLabourEdit}
          resource={this.state.labour}
          isBorReturn={this.state.isBorReturn}
          // resourceType={'Labours'}
        />


      </div>
    );
  }

  private clearCpcData = () => {
    this.props.clearCpcData();
  };

  private handleResourceItemSave = (quantity: any, isEdit: boolean, resource: BorResourcesItem, cpc: any) => {
    if (isEdit) {
      resource.required = quantity;
      resource.type = '1';
      if (resource.isNew) {
        this.borResourcesItemEditSave(resource);
      } else {
        this.borResourcesItemEdit(resource);
      }
    } else {
      let borResourceNewItem: BorResourcesItem = {
        type: '1',
        invoiced: null,
        warf: null,
        purchased: null,
        deliveryRequested: null,
        consumed: null,
        date: new Date(),
        title: cpc.title,
        //required:(this.state.requiredModalType==='0')?-quantity:quantity,
        required: quantity,
        // id: this.props.cpcData.id,
        id: cpc.item.id,
        // resourceNumber: this.props.cpcData.resourceNumber,
        resourceNumber: cpc.item.id,
        environment: cpc.environment,
        // environment: this.state.selectedCpcEnvironment,
        isNew: true,
      };
      this.borResourcesItemSave(borResourceNewItem);
    }
  };

  private borResourcesItemEditSave = (borResourceNewItem: any) => {
    let updatedItem = [borResourceNewItem];
    let formData = this.state.formData;
    let borResources = formData.borResources;
    let resourceType = this.state.selectedResourceTypeId;
    let materials = borResources.materials ? borResources.materials : [];
    let tools = borResources.tools ? borResources.tools : [];
    let consumable = borResources.tools ? borResources.consumable : [];
    let labour = borResources.tools ? borResources.labour : [];
    if (resourceType === ResourceTypes.materials.id) {
      materials = materials.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    } else if (resourceType === ResourceTypes.tools.id) {
      tools = tools.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    } else if (resourceType === ResourceTypes.consumable.id) {
      consumable = consumable.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    } else if (resourceType === ResourceTypes.labour.id) {
      consumable = consumable.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    }
    borResources.materials = materials;
    borResources.tools = tools;
    borResources.consumable = consumable;
    borResources.labour = labour;
    formData.borResources = borResources;
    this.setState(
      {
        formData,
        isDirty: true,
      },
      this.handleResourceSave,
    );
  };

  private borResourcesItemSave = (borResourceNewItem: any) => {
    let formData = this.state.formData;
    let borResources = formData.borResources;
    let resourceType = this.state.selectedResourceTypeId;
    let materials = borResources.materials ? borResources.materials : [];
    let tools = borResources.tools ? borResources.tools : [];
    let consumable = borResources.tools ? borResources.consumable : [];
    let labour = borResources.tools ? borResources.labour : [];
    if (resourceType === ResourceTypes.materials.id) {
      materials.push(borResourceNewItem);
    } else if (resourceType === ResourceTypes.tools.id) {
      tools.push(borResourceNewItem);
    } else if (resourceType === ResourceTypes.consumable.id) {
      consumable.push(borResourceNewItem);
    } else if (resourceType === ResourceTypes.labour.id) {
      labour.push(borResourceNewItem);
    }
    borResources.materials = materials;
    borResources.tools = tools;
    borResources.consumable = consumable;
    borResources.labour = labour;
    formData.borResources = borResources;
    this.setState(
      {
        formData,
        isDirty: true,
      },
      this.handleResourceSave,
    );
  };

  private borResourcesItemEdit = (resource: BorResourcesItem) => {
    let editItem: BORResourceUpdateRequest = {
      borRequiredId: resource.id,
      //required:resource.required,
      required: (typeof resource.required === 'string') ? parseFloat(resource.required) : resource.required,
      borId: this.state.formData.id,
    };
    let updatedItem = [resource];
    let formData = this.state.formData;
    let borResources = formData.borResources;
    let resourceType = this.state.selectedResourceTypeId;
    let materials = borResources.materials ? borResources.materials : [];
    let tools = borResources.tools ? borResources.tools : [];
    let consumable = borResources.tools ? borResources.consumable : [];
    let labour = borResources.tools ? borResources.labour : [];
    if (resourceType === ResourceTypes.materials.id) {
      this.props.updateBORMaterial(editItem);
      materials = materials.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    } else if (resourceType === ResourceTypes.tools.id) {
      this.props.updateBORTool(editItem);
      tools = tools.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    } else if (resourceType === ResourceTypes.consumable.id) {
      this.props.updateBORConsumable(editItem);
      consumable = consumable.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    } else if (resourceType === ResourceTypes.labour.id) {
      this.props.updateBORLabour(editItem);
      consumable = consumable.map(
        (obj: any) => updatedItem.find((o) => o.id === obj.id) || obj,
      );
    }
    borResources.materials = materials;
    borResources.tools = tools;
    borResources.consumable = consumable;
    borResources.labour = labour;
    formData.borResources = borResources;
    this.setState(
      {
        formData,
        isDirty: true,
      });
  };

  private handleResourceSave = () => {
    this.props.clearCpcData();
    this.saveFormData();
  };

  private getProductDetails = () => {
    let productId = this.state.formData.product
      ? this.state.formData.product.productId
      : null;
    if (productId) {
      this.props.getProductById(productId);
    }
  };
  private getCpcDetails = (selectedCpc: any) => {
    if (selectedCpc) {
      this.setState({
        selectedCpcEnvironment: selectedCpc.environment,
      });
      this.props.getCpcById(selectedCpc);
    }
  };

  private getMaterialsByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadMaterial?name=${name}`);
    return formatCpcResponse(response);
  };

  private getToolsByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadTool?name=${name}`);
    return formatCpcResponse(response);
  };

  private getLaboursByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadLabour?name=${name}`);
    return formatCpcResponse(response);
  };

  private getConsumalbesByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadConsumable?name=${name}`);
    return formatCpcResponse(response);
  };

  private getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`,
    );
    return formatPersonResponse(response);
  };

  private getPersonByNameForQulity = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    let response = await client.post(`CentralAddressBook/PersonFilterByName`, {
      fullName: name,
    });
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result;
    }
    return data;
  };

  private handleFormChange = (formData: BillOfResource) => {
    this.setState({
      isDirty: true,
    });
    formData.borResources = this.state.borResources;

    this.setState(
      {
        formData: formData,
      },
      this.clearBorResources,
    ); //
  };

  private handleBorResourcesChange = (
    borResourcesItem: any,
    resourceType: any,
  ) => {
    let borResources = this.state.borResources;
    let materials = borResources.materials ? borResources.materials : [];
    let tools = borResources.tools ? borResources.tools : [];
    let consumable = borResources.tools ? borResources.consumable : [];
    let labour = borResources.tools ? borResources.labour : [];
    if (resourceType === ResourceTypes.materials.id) {
      materials.push(borResourcesItem);
    } else if (resourceType === ResourceTypes.tools.id) {
      tools.push(borResourcesItem);
    } else if (resourceType === ResourceTypes.consumable.id) {
      consumable.push(borResourcesItem);
    } else if (resourceType === ResourceTypes.labour.id) {
      labour.push(borResourcesItem);
    }
    borResources.materials = materials;
    borResources.tools = tools;
    borResources.consumable = consumable;
    borResources.labour = labour;
    this.setState(
      {
        borResources,
      },
      this.props.clearCpcData,
    );
  };

  private saveFormData = () => {
    if (this.validate()) {
      let formData = this.state.formData;
      let isNewBor = false
      if (!formData.id) {
        formData.id = uuidv4();
        isNewBor= true
      }

      formData.borResources = this.state.borResources;
      formData.borTypeId = this.state.isBorReturn ? BOR_TYPE.RETURN : BOR_TYPE.REGULAR;
      // if (!formData.product?.id){
      //   formData.product = null
      // }
      this.props.saveFormData(formData).then((res: any) => {
        this.props.getById(res.payload.data.result).then((data: any) => {
         
          this.props.saveBORStateAttr("createdVPBOR",data.payload.data.result)
          if (!formData.headerTitle) {
            this.props.saveBORStateAttr("borCreatedFromVP",true)
          } else {
            this.props.saveBORStateAttr('isBOROpenFromVP',false)
          }
          if (isNewBor){
            isNewBor = false;
          }
          if (!formData.itemId) {
            this.checkResourcesCount(data.payload.data.result);
          }
        });
      });
      this.setState({
        isDirty: false,
        isPreviousCleared: false,
      });
    }
  };

  checkResourcesCount = (formData: any) => {
    // console.log("getById",formData);
    if (formData?.borResources) {
      let isValid = true;
      let materialMissingCount = 0;
      let toolsMissingCount = 0;
      let consumableMissingCount = 0;
      let labourMissingCount = 0;
      let borResources: BorResources = formData?.borResources;
      // console.log("getById materials",borResources.materials.length+"="+formData.materialCount);
      // console.log("getById tools",borResources.tools.length+"="+formData.toolsCount);
      // console.log("getById consumable",borResources.consumable.length+"="+formData.consumableCount);
      // console.log("getById labour",borResources.labour.length+"="+formData.labourCount);

      if (borResources.materials && borResources.materials.length != formData.materialCount) {
        materialMissingCount = formData.materialCount - borResources.materials.length;
        isValid = false;
      }
      if (borResources.tools && borResources.tools.length != formData.toolsCount) {
        toolsMissingCount = formData.toolsCount - borResources.tools.length;
        isValid = false;
      }
      if (borResources.consumable && borResources.consumable.length != formData.consumableCount) {

        consumableMissingCount = formData.consumableCount - borResources.consumable.length;
        isValid = false;
      }
      if (borResources.labour && borResources.labour.length != formData.labourCount) {
        labourMissingCount = formData.labourCount - borResources.labour.length;
        isValid = false;
      }

      if (!isValid) {
        this.setState({
          showError: true,
          errorMsg: ` ${this.props.t('resourcesMissingPBSToBOR')}
        ${materialMissingCount != 0 ? this.props.t('materials') + ': ' + materialMissingCount : ''} 
        ${toolsMissingCount != 0 ? this.props.t('tools') + 'Tools: ' + toolsMissingCount : ''} 
        ${consumableMissingCount != 0 ? this.props.t('consumable') + 'Consumable: ' + consumableMissingCount : ''} 
        ${labourMissingCount != 0 ? this.props.t('labour') + ': ' + labourMissingCount : ''}`,
        });
        //setTimeout(()=>{this.setState({showError:false,errorMsg:''})},MSG_AUTO_CLOSE_TIME)
      } else {
      }

      // return {
      //   status:isValid,
      //   materialMissingCount:materialMissingCount,
      //   toolsMissingCount:toolsMissingCount,
      //   consumableMissingCount:consumableMissingCount,
      //   labourMissingCount:labourMissingCount
      // }
    } else {
      // alert('else')
    }

  };

  private clearBorResources = () => {
    let formData = this.state.formData;
    if (formData.product === null || formData.product.productId === null) {
      let borResources = {
        consumable: [],
        labour: [],
        materials: [],
        tools: [],
      };
      this.setState({
        borResources,
      });
    }
  };

  private validate = (): boolean => {
    let validationMessages = this.state.validationMessages;
    let name = this.state.formData.name;
    let productId = this.state.formData.product
      ? this.state.formData.product.productId
      : null;
    let borStatusId = this.state.formData.borStatusId;
    let isValid = true;
    if (_.isNil(name) || _.isEmpty(name)) {
      validationMessages.nameErrorMsg = i18n.t('nameRequired');
      isValid = false;
    } else {
      validationMessages.nameErrorMsg = '';
    }
    if (!this.state.isBorReturn) {
      if (_.isNil(productId) || _.isEmpty(productId)) {
        validationMessages.productTitleErrorMsg = i18n.t('productRequired');
        isValid = false;
      } else {
        validationMessages.productTitleErrorMsg = '';
      }
    }
    if (_.isNil(borStatusId) || _.isEmpty(borStatusId)) {
      validationMessages.statusErrorMsg = i18n.t('statusRequired');
      isValid = false;
    } else {
      validationMessages.statusErrorMsg = '';
    }

    this.setState({ validationMessages: validationMessages });
    return isValid;
  };

  private resetValidation = () => {
    let validationMessages = this.state.validationMessages;
    validationMessages.productTitleErrorMsg = '';
    validationMessages.nameErrorMsg = '';
    validationMessages.statusErrorMsg = '';

    this.setState({ validationMessages: validationMessages });
  };
  private resetFormData = () => {
    if (this.props.isNew) {
      let formData: BillOfResource = {
        id: this.props.uid,
        borStatusId: '94282458-0b40-40a3-b0f9-c2e40344c8f1',
        itemId: null,
        borTitle: null,
        headerTitle: null,
        name: null,
        startDate: null,
        endDate: null,
        pbsProductId: null,
        locationParentId: null,
        utilityParentId: null,
        productItemType: null,
        historyLog: null,
        borTypeId: this.state.isBorReturn ? BOR_TYPE.RETURN : BOR_TYPE.REGULAR,
        product: {
          id: null,
          productId: null,
          title: null,
          utilityParentId: null,
          locationParentId: null,
          locationTaxonomyParentId: null,
          utilityTaxonomyParentId: null,
          pbsProductItemType: null,
          pbsProductItemTypeId: null,
        },
        borResources: {
          labour: [],
          consumable: [],
          tools: [],
          materials: [],
        },
      };
      let borResources = formData.borResources;
      this.setState({ formData, borResources });
    }
  };

  private openMaterialModal = (): void => {

    this.setState({
      isMaterialModalVisible: !this.state.isMaterialModalVisible,
      material: this._materialEditDetails(),
      selectedResourceTypeId: ResourceTypes.materials.id,
    });
  };

  private openToolsModal = (): void => {
    this.setState({
      isToolsModalVisible: !this.state.isToolsModalVisible,
      tool: this._toolsEditDetails(),
      selectedResourceTypeId: ResourceTypes.tools.id,
    });
  };

  private openLabourModal = (): void => {
    this.setState({
      isLabourModalVisible: !this.state.isLabourModalVisible,
      labour: this._labourEditDetails(),
      selectedResourceTypeId: ResourceTypes.labour.id,
    });
  };

  private openConsumaleModal = (): void => {
    this.setState({
      isConsumablesModalVisible: !this.state.isConsumablesModalVisible,
      consumable: this._consumableEditDetails(),
      selectedResourceTypeId: ResourceTypes.consumable.id,
    });
  };

  private _resourceDeleteDetails(selection: Selection) {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = selection.getSelection() as Resource[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: any) => {
      return item.id;
    });
    return deleteList;
  }

  private handleMaterialsDelete = () => {
    const selectedList = this._materialsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteMaterials(selectedList).then(() => {
        if (this.props.formData.itemId) {
          this.props.getById(this.props.formData.itemId);
        }
      });
    }
    this._materialsSelection.setAllSelected(false);
  };

  private handleToolsDelete = () => {
    const selectedList = this._toolsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteTools(selectedList).then(() => {
        if (this.props.formData.itemId) {
          this.props.getById(this.props.formData.itemId);
        }
      });
    }
    this._toolsSelection.setAllSelected(false);
  };

  private handleLaboursDelete = () => {
    const selectedList = this._labourSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteLabours(selectedList).then(() => {
        if (this.props.formData.itemId) {
          this.props.getById(this.props.formData.itemId);
        }
      });
    }
    this._labourSelection.setAllSelected(false);
  };

  private handleConsumableDelete = () => {
    const selectedList = this._consumableSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteConsumable(selectedList).then(() => {
        if (this.props.formData.itemId) {
          this.props.getById(this.props.formData.itemId);
        }
      });
    }
    this._consumableSelection.setAllSelected(false);
  };

  private _getMaterialSelectionDetails(): string | number | {} | [] {
    return this._materialsSelection.getSelection();
  }

  private _getToolsSelectionDetails(): string | number | {} | [] {
    return this._toolsSelection.getSelection();
  }

  private _getLabourSelectionDetails(): string | number | {} | [] {
    return this._labourSelection.getSelection();
  }

  private _getConsumableSelectionDetails(): string | number | {} | [] {
    return this._consumableSelection.getSelection();
  }

  private _materialEditDetails() {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let material = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isMaterialEdit: true,
      });

      let selectedMaterial = this._materialsSelection.getSelection()[0] as BorResourcesItem;
      material = selectedMaterial;
      //assign
    } else {
      this.setState({
        isMaterialEdit: false,
      });
    }
    return material;
  }

  private _toolsEditDetails() {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let tool = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isToolEdit: true,
      });

      let selectedTool = this._toolsSelection.getSelection()[0] as BorResourcesItem;
      tool = selectedTool;
      //assign
    } else {
      this.setState({
        isToolEdit: false,
      });
    }
    return tool;
  }

  private _labourEditDetails() {
    const selectionCount = this._labourSelection.getSelectedCount();
    let labour = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isLabourEdit: true,
      });

      let selectedLabour = this._labourSelection.getSelection()[0] as BorResourcesItem;
      labour = selectedLabour;
      //assign
    } else {
      this.setState({
        isLabourEdit: false,
      });
    }
    return labour;
  }

  private _consumableEditDetails() {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let consumable = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isConsumableEdit: true,
      });

      let selectedConsumable = this._consumableSelection.getSelection()[0] as BorResourcesItem;
      consumable = selectedConsumable;
      //assign
    } else {
      this.setState({
        isConsumableEdit: false,
      });
    }
    return consumable;
  }

  private _getMaterialActionButtonState(): ActionButtonState {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _getToolsActionButtonState(): ActionButtonState {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getLabourActionButtonState(): ActionButtonState {
    const selectionCount = this._labourSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getConsumableActionButtonState(): ActionButtonState {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    riskList: state.billOfResource.riskList,
    readRiskStatus: state.billOfResource.readRiskStatus,
    createRiskStatus: state.billOfResource.createRiskStatus,
    deleteRiskStatus: state.billOfResource.deleteRiskStatus,
    qualityList: state.billOfResource.qualityList,
    readQualityStatus: state.billOfResource.readQualityStatus,
    createQualityStatus: state.billOfResource.createQualityStatus,
    deleteQualityStatus: state.billOfResource.deleteQualityStatus,
    toleranceStates: state.billOfResource.toleranceStates,
    productStates: state.billOfResource.productStates,
    itemTypes: state.billOfResource.itemTypes,
    isNew: state.billOfResource.isNew,
    uid: state.billOfResource.uid,
    materialList: state.billOfResource.materials,
    readMaterialsStatus: state.billOfResource.readMaterialsStatus,
    isEdit: state.billOfResource.isEdit,
    createMaterialsStatus: state.billOfResource.createMaterialsStatus,
    saveRiskStatus: state.billOfResource.saveRiskStatus,
    newRiskId: state.billOfResource.newRiskId,
    saveQualityStatus: state.billOfResource.saveQualityStatus,
    newQualityId: state.billOfResource.newQualityId,
    readToolsStatus: state.billOfResource.readToolsStatus,
    createToolsStatus: state.billOfResource.createToolsStatus,
    tools: state.billOfResource.tools,
    deleteMaterialsStatus: state.billOfResource.deleteMaterialsStatus,
    deleteToolsStatus: state.billOfResource.deleteToolsStatus,
    labours: state.billOfResource.labours,
    readLabourStatus: state.billOfResource.readLabourStatus,
    createLabourStatus: state.billOfResource.createLabourStatus,
    deleteLabourStatus: state.billOfResource.deleteLabourStatus,
    readConsumableStatus: state.billOfResource.readConsumableStatus,
    crateConsumableStatus: state.billOfResource.crateConsumableStatus,
    deleteConsumableStatus: state.billOfResource.deleteConsumableStatus,
    consumables: state.billOfResource.consumables,
    createConsumableStatus: state.billOfResource.createConsumableStatus,
    readTechInstructionStatus: state.billOfResource.readTechInstructionStatus,
    techInstructions: state.billOfResource.techInstructions,
    uploadedInstructionLink: state.billOfResource.uploadedInstructionLink,
    instructionUploadStatus: state.billOfResource.instructionUploadStatus,
    competenceList: state.billOfResource.competenceList,
    pbsSkills: state.billOfResource.pbsSkills,
    pbsExperience: state.billOfResource.pbsExperience,
    saveCompetenceStatus: state.billOfResource.saveCompetenceStatus,
    deleteCompetenceStatus: state.billOfResource.deleteCompetenceStatus,
    createTechInstructionStatus:
    state.billOfResource.createTechInstructionStatus,
    deleteTechInstructionStatus:
    state.billOfResource.deleteTechInstructionStatus,
    utilityTaxonomy: state.billOfResource.utilityTaxonomy,
    locationTaxonomy: state.billOfResource.locationTaxonomy,
    productData: state.billOfResource.productData,
    cpcData: state.billOfResource.cpcData,
    borStatus: state.billOfResource.borStatus,
    borType: state.billOfResource.borType,
    borCreated: state.billOfResource.borCreated,
    isNewBor: state.billOfResource.isNewBor,
    slectedPBSForBor: state.vp.slectedPBSForBor,
    borInheritedPBSTitle: state.vp.borInheritedPBSTitle,
  };
};

const mapDispatchToProps = {
  getRiskDropDownsList,
  filterQualityList,
  saveFormData,
  readMaterialsData,
  readPbsMaterialsData,
  createMaterials,
  resetRiskGridList,
  readToolsData,
  readPbsToolsData,
  createTools,
  deleteMaterials,
  deleteTools,
  readLabourData,
  readPbsLabourData,
  createLabour,
  deleteLabours,
  createConsumable,
  deleteConsumable,
  readConsumableData,
  readPbsConsumableData,
  readInstructionDropDown,
  readTechInstruction,
  uploadInstructionDoc,
  readTaxonomyData,
  createTechInstruction,
  filterCompetenceList,
  readCompetenceDropDown,
  createCompetence,
  deleteCompetence,
  deleteTechInstruction,
  getProductById,
  getCpcById,
  clearCpcData,
  getById,
  updateBORMaterial,
  updateBORTool,
  updateBORConsumable,
  updateBORLabour,
  saveBORStateAttr,
};

export enum INSTRUCTION_TYPE {
  TECH = '100',
  ENVIRONMENT = '200',
  SAFTY = '300',
  HELTH = '400',
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane)),
);


