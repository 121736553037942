import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  getTheme,
  IBasePickerSuggestionsProps,
  IIconProps,
  IPersona,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  NormalPeoplePicker,
  Separator,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import _ from 'lodash';
import { ProjectBreakdown, ProjectBreakdownValidationMessage } from '../../../types/projectBreakdownStructure';

const theme = getTheme();

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
    width: '50%',
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};
// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const itemAlignmentsStackTokens: IStackTokens = {
  padding: 0,
};

// Styles definition
const stackStylesFooter: IStackStyles = {
  root: {
    padding: 0,
    margin: 0,
  },
};
const stackItemStylesFooter: IStackItemStyles = {
  root: {
    padding: 0,
    margin: 0,
  },
};

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const QualityResponsible = (props: {
  onQualityFilterPersonChanged: any;
  isEdit: boolean,
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (
    projectBreakdown: ProjectBreakdown,
    save: boolean,
  ) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  readOnly: boolean
}) => {

  const { t } = useTranslation();
  let selectedPerson: IPersona[] = [];

  const [qualityProducer, setQualityProducer] = useState<IPersona[]>([]);
  const [qualityReviewer, setQualityReviewer] = useState<IPersona[]>([]);
  const [qualityApprover, setQualityApprover] = useState<IPersona[]>([]);

  useEffect(() => {
    if (props.projectBreakdown.qualityProducerId) {
      setQualityProducer([{
        key: props.projectBreakdown.qualityProducerId,
        text: props.projectBreakdown.qualityProducer,
      }]);
    } else {
      setQualityProducer([]);
    }
    if (props.projectBreakdown.qualityReviewerId) {
      setQualityReviewer([{
        key: props.projectBreakdown.qualityReviewerId,
        text: props.projectBreakdown.qualityReviewer,
      }]);
    } else {
      setQualityReviewer([]);
    }
    if (props.projectBreakdown.qualityApproverId) {
      setQualityApprover([{
        key: props.projectBreakdown.qualityApproverId,
        text: props.projectBreakdown.qualityApprover,
      }]);
    } else {
      setQualityApprover([]);
    }


  }, [props.projectBreakdown]);

  const onQualityProducerSelected = (item: any) => {
    if (item) {
      setQualityProducer([item]);
      props.projectBreakdown.qualityProducerId = item.key;
      props.projectBreakdown.qualityProducer = item.text;
      props.handleFormChange(
        props.projectBreakdown,
        true,
      );
    }
    return item;
  };

  const onQualityReviewerSelected = (item: any) => {
    if (item) {
      setQualityReviewer([item]);
      props.projectBreakdown.qualityReviewerId = item.key;
      props.projectBreakdown.qualityReviewer = item.text;
      props.handleFormChange(
        props.projectBreakdown,
        true,
      );
    }
    return item;
  };

  const onQualityApproverSelected = (item: any) => {
    if (item) {
      setQualityApprover([item]);
      props.projectBreakdown.qualityApproverId = item.key;
      props.projectBreakdown.qualityApprover = item.text;
      props.handleFormChange(
        props.projectBreakdown,
        true,
      );
    }
    return item;
  };

  const onSaveQualityResponsible = () => {
    props.saveFormData();
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  return (
    <div className={''}>
      <div className={''}>
        <div className='sub-header-title'>
          <Label>8.2 {t('qualityResponsible')} </Label>
        </div>
      </div>
      <div className={''}>
        <div className="proj-detail-content inner">
          <div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}
                     style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                  <Label>{t('qualityProducer')}</Label>
                  <NormalPeoplePicker
                    onChange={(items) => {
                      if (items && _.isArray(items) && items.length === 0) {
                        setQualityProducer([]);
                        props.projectBreakdown.qualityProducerId = null;
                        props.handleFormChange(
                          props.projectBreakdown,
                          true,
                        );
                      }
                    }}
                    selectedItems={
                      qualityProducer && qualityProducer[0] && qualityProducer[0] ? qualityProducer : []
                    }
                    onResolveSuggestions={props.onQualityFilterPersonChanged}
                    onItemSelected={onQualityProducerSelected}
                    pickerSuggestionsProps={limitedSearchAdditionalProps}
                    className={'ms-PeoplePicker'}
                    key={'qualityProducer'}
                    itemLimit={1}
                    removeButtonAriaLabel={'Remove'}
                    resolveDelay={300}
                    disabled={props.readOnly}

                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
          <div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}
                     style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                  <Label>{t('qualityReviewer')}</Label>
                  <NormalPeoplePicker
                    onChange={(items) => {
                      if (items && _.isArray(items) && items.length === 0) {
                        setQualityReviewer([]);
                        props.projectBreakdown.qualityReviewerId = null;
                        props.handleFormChange(
                          props.projectBreakdown,
                          true,
                        );
                      }
                    }}
                    selectedItems={
                      qualityReviewer && qualityReviewer[0] && qualityReviewer[0] ? qualityReviewer : []
                    }
                    onResolveSuggestions={props.onQualityFilterPersonChanged}
                    onItemSelected={onQualityReviewerSelected}
                    pickerSuggestionsProps={limitedSearchAdditionalProps}
                    className={'ms-PeoplePicker'}
                    key={'qualityReviewer'}
                    itemLimit={1}
                    removeButtonAriaLabel={'Remove'}
                    resolveDelay={300}
                    disabled={props.readOnly}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
          <div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}
                     style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                  <Label>{t('qualityApprover')}</Label>
                  <NormalPeoplePicker
                    onChange={(items) => {
                      if (items && _.isArray(items) && items.length === 0) {
                        setQualityApprover([]);
                        props.projectBreakdown.qualityApproverId = null;
                        props.handleFormChange(
                          props.projectBreakdown,
                          true,
                        );
                      }
                    }}
                    selectedItems={
                      qualityApprover && qualityApprover[0] && qualityApprover[0] ? qualityApprover : []
                    }
                    onResolveSuggestions={props.onQualityFilterPersonChanged}
                    onItemSelected={onQualityApproverSelected}
                    pickerSuggestionsProps={limitedSearchAdditionalProps}
                    className={'ms-PeoplePicker'}
                    key={'qualityApprover'}
                    itemLimit={1}
                    removeButtonAriaLabel={'Remove'}
                    resolveDelay={300}
                    disabled={props.readOnly}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
      </div>
      <div className={'card-footer'}>
        <Separator/>
        <Stack reversed styles={stackStylesFooter} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackItemStylesFooter}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              disabled={props.readOnly}
              allowDisabledFocus
              onClick={() => {
                onSaveQualityResponsible();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default QualityResponsible;
