import React, {useState} from 'react';
import {CommandBar} from 'office-ui-fabric-react/lib/CommandBar';
import {useTranslation} from 'react-i18next';
import {messageService} from '../../../services/messageService';
import {cancelCIAW, readCIAWById, sendCIAWWarningEmail} from '../../../reducers/ciawReducer';
import {useDispatch, useStore} from "react-redux";
import {DefaultButton, IDropdownOption, PrimaryButton} from "@fluentui/react";
import CancelCiawModal from "./modal/cancelCiawModal";
import DisplayJSONModal from "./modal/displayJsonModal";

const CommandBarRight = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const store = useStore();

    const [isCancelOpen, setIsCancelOpen] = useState<boolean>(true);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [leaveReason, setLeaveReason] = useState<any>(null);
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
    const [isJSONOpen, setIsJSONOpen] = useState<boolean>(true);

    const saveFormData = () => {
        messageService.sendMessage({saveCIAWFormData: true});
    };

    const handleCancelCiawConfirm = (): void => {
        if (leaveReason) {
            setShowErrorMsg(false);
            Promise.all([
                dispatch(cancelCIAW({
                    ciawId: store.getState().ciaw.ciawFormData.id,
                    leaveReasonId: leaveReason ? leaveReason.toString() : null
                }))    //, dateDif: `${getDateDif() + 1}`}))
            ]).then(() => {
                dispatch(readCIAWById(store.getState().ciaw.ciawFormData.id));
                // dispatch(readCabCertificationCIAW({
                //     personId: store.getState().ciaw.ciawFormData.cabPersonId,
                //     date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
                // }));
                setIsCancelOpen(true);
                setLeaveReason(null);
            });
        } else {
            setShowErrorMsg(true);
        }
    };

    const handleClose = (): void => {
        setIsCancelOpen(true);
        setLeaveReason(null);
        setShowErrorMsg(false);
    };

    const handleJSONClose = (): void => {
        setIsJSONOpen(true);
    };

    const handleLeaveReasonDropDown = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        if (option) {
            setShowErrorMsg(false);
            setLeaveReason(option.key);
        } else {
            setLeaveReason(null);
        }
    };

    const handleOnChangeConfirm = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        if (newValue === "YES") {
            setIsConfirmButtonDisabled(false);
        } else {
            setIsConfirmButtonDisabled(true);
        }
    }

    const getItems = () => {
        return [
            {
                key: 'save',
                text: t('save'),
                iconProps: {iconName: 'Save'},
                onClick: () => {
                    saveFormData();
                },
                disabled: true
            },
            {
                key: 'cancelCiaw',
                text: t('cancelCiaw'),
                iconProps: {iconName: 'UserRemove'},
                onClick: () => {
                    setIsCancelOpen(false);
                    setIsConfirmButtonDisabled(true);
                },
                disabled: store?.getState()?.ciaw?.ciawFormData?.status?.key === "7bcb4e8d-cancl-487d-ciaws-6b91c89fAcce",   //canceled
            },
            {
                key: 'notifyWarning',
                text: t('notifyWarning'),
                iconProps: {iconName: 'MailAlert'},
                onClick: () => {
                    dispatch(sendCIAWWarningEmail(store.getState().ciaw.ciawFormData.id));
                },
                disabled: false
            },
            {
                key: 'json',
                text: t('JSON'),
                iconProps: {iconName: 'Code'},
                onClick: () => {
                    setIsJSONOpen(false);
                },
                disabled: !store?.getState()?.ciaw?.formData
            },
        ];
    };

    const onRenderFooter = () => {
        return <>
            <DefaultButton
              text={t('close')}
              disabled={false}
              onClick={() => handleClose()}
              style={{marginRight: 4}}
            />
            <PrimaryButton
              text={t('cancelCiaw')}
              disabled={isConfirmButtonDisabled}
              onClick={() => handleCancelCiawConfirm()}
            />
        </>
    }

    return (
      <div>
          <CommandBar
            items={getItems()}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          <CancelCiawModal
            isCancelOpen={isCancelOpen}
            onRenderFooter={onRenderFooter}
            handleClose={handleClose}
            showErrorMsg={showErrorMsg}
            handleLeaveReasonDropDown={handleLeaveReasonDropDown}
            handleOnChangeConfirm={handleOnChangeConfirm}
          />
          <DisplayJSONModal
            isJSONOpen={isJSONOpen}
            handleClose={handleJSONClose}
          />
      </div>
    );
};

export default CommandBarRight;
