import * as React from 'react';
import {CommandBarButton, IButtonStyles, IStackStyles, Stack, TooltipHost} from 'office-ui-fabric-react';
import {initializeIcons} from '@uifabric/icons';
import AuthService from '../../services/auth.service';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {uPrinceTheme} from '../../../theme';
import ENV from '../../../env.json';
import history from '../../history';
import {hasPermissions} from '../util';
import BurnDownChartComponent from "../../components/burnDownChart/component";

initializeIcons();

export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

const authService = new AuthService();
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: '15px !important',
    minWidth: 0,
    padding: '0 4px',
    alignSelf: 'stretch',
    height: 40,
    width: 40,
    fontSize: 40,
    background: uPrinceTheme.palette.themePrimary,
  },
  menuIcon: {
    fontSize: 25,
    color: '#ffffff',
  },
};

// const getMenuProps = (
//   name: string,
//   email: string,
//   oid: string,
//   t: TFunction,
//   currentUser: any
// ) => {
//   const menuItems: any = {
//     items: [
//       {
//         key: 'name',
//         // text: (authService.getUser())?authService.getUser().name+" "+authService.getUser().idToken.family_name:'',
//         text: name,
//         onRender: (
//           item: any,
//           dismissMenu: (ev?: any, dismissAll?: boolean) => void
//         ) => {
//           return (
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//               <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 <FontIcon iconName="Contact" className={iconClass} />
//                 <Text style={{ marginTop: 5 }}>{name}</Text>
//               </div>

//               <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 <FontIcon iconName="Mail" className={iconEmail} />
//                 <Text style={{ marginTop: 5 }}>{email}</Text>
//               </div>
//               <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 <FontIcon iconName="World" className={iconEmail} />
//                 <Text style={{ marginTop: 2 }}>{oid}</Text>
//               </div>
//             </div>
//           );
//         },
//         onClick: () => {},
//       },
//       {
//         key: 'divider_1',
//         itemType: ContextualMenuItemType.Divider,
//       },
//       {
//         key: 'logout',
//         text: t('Logout'),
//         iconProps: { iconName: 'Signout' },
//         onClick: () => {
//           authService.logout();
//         },
//       },
//     ],
//   };

//   if (hasPermissions(currentUser?.groups).isProjectManager) {
//     menuItems.items.splice(2, 0, {
//       key: 'me',
//       text: t('My Environment'),
//       iconProps: { iconName: 'OpenInNewWindow' },
//       onClick: () => {
//         history.push('/me/visualPlan');
//       },
//     });
//     menuItems.items.splice(3, 0, {
//       key: 'divider_2',
//       itemType: ContextualMenuItemType.Divider,
//     });
//   }

//   return menuItems;
// };

export const BrandNavBar: React.FunctionComponent<IButtonExampleProps> = (
  props,
) => {
  const { disabled, checked } = props;
  const currentUser = useSelector<any, any>(
    (state) => state.uprince.currentUser,
  );
  const { t } = useTranslation();
  // Here we use a Stack to simulate a command bar.
  // The real CommandBar control also uses CommandBarButtons internally.
  if (hasPermissions(currentUser?.groups).isProjectManager && ENV.IS_CONTRACTOR) {
    return (
      <Stack horizontal styles={stackStyles}>
        {/* <TooltipHost content={t('visualPlanning')} id={'visualPlanningToolTip'}>
          <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={buttonStyles}
            menuIconProps={{ iconName: 'BarChartHorizontal' }}
            onClick={() => history.push(`/me/visualPlan`)}
          />
        </TooltipHost> */}
      <TooltipHost content={t('burnDownChart')} id={'burnDownChartToolTip'}>
          <BurnDownChartComponent styles={buttonStyles} menuIconProps={"MarketDown"}/>
      </TooltipHost>
      <TooltipHost content={t('projectPlaning')} id={'projectPlanningToolTip'}>
          <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={buttonStyles}
            menuIconProps={{ iconName: 'StackedBarChart' }}
            onClick={() => history.push(`/me/project-planning`)}
          />
        </TooltipHost>
        <TooltipHost content={t('myCalendar')} id={'myCalendarToolTip'}>
          <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={buttonStyles}
            menuIconProps={{ iconName: 'CalendarSettings' }}
            onClick={() => history.push(`/me/day-planning`)}
          />
        </TooltipHost>
        <TooltipHost content={t('teamPlanboard')} id={'teamPlanboardToolTip'}>
          <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={buttonStyles}
            menuIconProps={{ iconName: 'ColumnRightTwoThirdsEdit' }}
            onClick={() => history.push(`/me/day-planning-new`)}
          />
        </TooltipHost>
        <TooltipHost content={t('projectPlanboard')} id={'projectPlanboardToolTip'}>
          <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={buttonStyles}
            menuIconProps={{ iconName: 'QuadColumn' }}
            onClick={() => history.push(`/me/project-day-planning`)}
          />
        </TooltipHost>
      </Stack>
    );
  }
  ;

  return <div></div>;
};
