import {useBoolean, useId} from '@uifabric/react-hooks';
import _ from 'lodash';
import {
    Checkbox,
    ContextualMenu,
    Dropdown,
    FontWeights,
    getTheme,
    IconButton,
    IDragOptions,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SortableTree, {
    addNodeUnderParent,
    changeNodeAtPath,
    getFlatDataFromTree,
    getNodeAtPath,
    getTreeFromFlatData,
    removeNodeAtPath,
    toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import {v4 as uuidv4} from 'uuid';
import i18n from '../../../../i18n';
// import '../../../../shared/treeStructureDropdown/react-sortable-tree.css';
import '../../../shared/treeStructureDropdown/react-sortable-tree.css';
import {uPrinceTheme} from '../../../../theme';
import {getPerentFromTreeData} from '../../../shared/util';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import {messageService} from '../../../services/messageService';
import {OrganisationLevel, RoleId} from '../../../types/organisationSetting';
import {setDefaultBu} from '../../../reducers/organisationSettingReducer';
import {useDispatch, useSelector} from 'react-redux';

const theme = getTheme();
// Styles definition
const stackStyles: IStackStyles = { root: { padding: 0 } };
const ORG_PERSON_SEARCH_TYPE_ID = 'we10e768-3e06-po02-b337-ee367a82adwe';
const ORG_BU_TYPE_ID = 'oo10e768-3e06-po02-b337-ee367a82admn';
const ORG_TEAM_TAXONOMY_ID = 'fg10e768-3e06-po02-b337-ee367a82adfg';

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50
    }
};

// Tokens definition
const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10
};

const addWhiteIconButtonStyles = {
    root: {
        color: uPrinceTheme.palette.white,
        width: 17,
        minWidth: 17,
        height: 15,
        paddingRight: 1,
        paddingLeft: 1,
        paddingTop: 1,
        paddingBottom: 1
    },
    rootHovered: { color: theme.palette.neutralDark }
};
const addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: addWhiteIconButtonStyles
};

const editWhiteIconButtonStyles = {
    root: {
        color: uPrinceTheme.palette.white,
        width: 17,
        minWidth: 17,
        height: 15,
        paddingRight: 1,
        paddingLeft: 1,
        paddingTop: 1,
        paddingBottom: 1
    },
    rootHovered: { color: theme.palette.neutralDark }
};
const editIconWhite: IIconProps = {
    iconName: 'Edit',
    styles: editWhiteIconButtonStyles
};

const reminderPerson: IIconProps = {
    iconName: 'ReminderPerson',
    styles: editWhiteIconButtonStyles
};

const deleteWhiteIconButtonStyles = {
    root: {
        color: uPrinceTheme.palette.white,
        width: 17,
        minWidth: 17,
        height: 15,
        paddingRight: 1,
        paddingLeft: 1,
        paddingTop: 1,
        paddingBottom: 1
    },
    rootHovered: { color: theme.palette.neutralDark }
};
const deleteIconWhite: IIconProps = {
    iconName: 'Delete',
    styles: deleteWhiteIconButtonStyles
};


const CustomSortableTree = (props: {
    // treeData: any;
    onSelectItem: (id: string) => void;
    onAddNode: (selectedLevel: any) => void;
    onCreateNode: (newNode: any) => void;
    selectItemId: string | null;
    label?: string | null;
    treeLevel: any;
    personRoleDropdowns: any;
    onFilterTaxonomyNodeChanged: any
    searchString?: any
}) => {
  const dispatch = useDispatch();

  const { taxonomyList } = useSelector(
    (state: any) => state.os
  );

    const [treeData, setTreeData]: any = useState([]);
    const [, setFlatTreeData]: any = useState([]);
    const [searchString, setSearchString]: any = useState('');
    const [searchFocusIndex, setSearchFocusIndex]: any = useState(0);
    const [searchFoundCount, setSearchFoundCount]: any = useState(null);
    const [selectItemId, setSelectItemId]: any = useState(null);
    const [selectedParentIds, setSelectedParentIds]: any = useState([]);
    const [selectedPath, setSelectedPath]: any = useState(null);
    const [selectedNode, setSelectedNode]: any = useState(null);
    const [newNodeName, setNewNodeName]: any = useState(null);
    const [newNodeKey, setNewNodeKey]: any = useState('');
    const [nodeLevel, setNodeLevel]: any = useState(0);
    const [isEdit, setIsEdit]: any = useState(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [selectedLevel, setSelectedLevel]: any = useState(null);
    const [editNodeName, setEditNodeName]: any = useState(null);
    const [requester, setRequester] = useState<any>(null,);
    const [selectedPersonRole, setSelectedPersonRole] = useState<any>(null);
    const [serviceManager, setServiceManager] = useState<any>(null);
    const [defaultBu, setSelectedDefaultBu] = useState<any>(null);
    const [buName, setBuName]: any = useState(null);

    // If the foremanArray is empty, then there is no foreman in the team.
    // TODO - Make the team such only one foreman is allowed.
    const foremanArray =  selectedNode?.children?.filter((item: any) => item.roleId === '2210e768-msms-Item-team1-ee367a82ad22');
    // console.log('foremanArray', foremanArray);

    const slicedPathtoComNode = selectedPath ? selectedPath.slice(0, 2) : null;
    const comNode: any = getNodeAtPath({
      treeData: treeData,
      path: slicedPathtoComNode,
      getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
        // console.log("number", number);
        return number;
      },
    });

    // console.log('comNode', comNode?.node?.id);

    useEffect(() => {
        const tData = getTreeFromFlatData({
            flatData: taxonomyList,
            getKey: (node: any) => node.id,
            getParentKey: (node: any) => node.parentId,
            // @ts-ignore
            rootKey: null
        });
        // console.log("treeData row data",taxonomyList);
        // console.log("treeData tree data",tData);
        setTreeData(tData);
        // setSelectedItem(selectItemId);
        setSelectedParentIds(getPerentFromTreeData(taxonomyList, props.selectItemId));
    }, [taxonomyList]);

    useEffect(() => {
        setSelectItemId(props.selectItemId);
        setSelectedParentIds(getPerentFromTreeData(taxonomyList, props.selectItemId));
        // if(props.selectItemId){
        // setSelectedItem(props.selectItemId);
        // }
    }, [props.selectItemId]);

    useEffect(() => {
        setSearchString(props.searchString);

    }, [props.searchString]);

    useEffect(() => {
        const fData = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey: (node: any) => node.id

        });
        setFlatTreeData(fData);
    }, [treeData]);


    const expand = (expanded: any) => {
        setTreeData(
            toggleExpandedForAll({
                treeData: treeData,
                expanded
            })
        );
    };

    const expandAll = () => {
        expand(true);
    };

    const collapseAll = () => {
        setSearchString(null);
        expand(false);
    };


    // Case insensitive search of `node.title`
    const customSearchMethod = ({ node, searchQuery }: any) => {
        return searchQuery &&
            node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
    };

    const handleAddClick = (rowInfo: any) => {
        const { node, path } = rowInfo;
        const depth = path.length;
        const level = depth + 1;
        setIsOpen(true);
        setSelectedPath(path);
        setSelectedNode(node);
        setNodeLevel(node.displayOrder);
        setIsEdit(false);
        setRequester([]);
        setServiceManager([]);
    };

    const handleSave = () => {
        if (validate()) {
            addNewNode();
            resetValues();
            setIsOpen(false);
        }
    };

    const resetValues = () => {
        resetValidation();
        setIsEdit(false);
        setNewNodeName(null);
        setNewNodeKey('');
        setSelectedNode('');
        setSelectedPath('');
        setSelectedPersonRole('');
        setSelectedLevel('');
        setBuName('');
    };

    const addNewNode = () => {
        if (selectedPath) {
            const newNode = {
                title: (selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && newNodeName)
                    ? newNodeName : buName ? buName
                    : (requester && requester[0] && requester[0]?.key ? requester[0].text : editNodeName),
                key: newNodeKey,
                id: uuidv4(),
                organizationTaxonomyLevelId: selectedLevel ? selectedLevel.levelId : null,
                parentId: selectedNode.id,
                displayOrder: selectedLevel ? selectedLevel.displayOrder : null,
                children: [],
                personId: selectedLevel?.key === OrganisationLevel.BU ? (serviceManager && serviceManager[0] && serviceManager[0]?.key ? serviceManager[0].key : null) : (requester && requester[0] && requester[0]?.key ? requester[0].key : null),
                personName: selectedLevel?.key === OrganisationLevel.BU ? (serviceManager && serviceManager[0] && serviceManager[0]?.text ? serviceManager[0].text : null) : (requester && requester[0] && requester[0]?.text ? requester[0].text : null),
                // personName: serviceManager && serviceManager[0] && serviceManager[0]?.text ? serviceManager[0].text : null,
                roleId: selectedPersonRole ? selectedPersonRole.key : null,
                buName: buName ? buName : null,
            };
            setTreeData(
                addNodeUnderParent({
                    treeData: treeData,
                    parentKey: selectedPath[selectedPath.length - 1],
                    expandParent: true,
                    newNode: newNode,
                    getNodeKey: ({ treeIndex }) => treeIndex
                }).treeData
            );
            props.onCreateNode(newNode);
        }
    };

    const handleEditClick = (rowInfo: any) => {
        const { node, path } = rowInfo;
        setIsOpen(true);
        setSelectedPath(path);
        setSelectedNode(node);
        setEditNodeName(node.title);
        setIsEdit(true);
        setRequester([{ key: node.personId, text: node.title }]);
        setServiceManager([{ key: node.personId, text: node?.personName }]);
        setSelectedPersonRole({ key: node.roleId });
        setBuName(node.buName);
    };
    const handleEdit = () => {
        if (validate()) {
            updateNode();
            resetValues();
            setIsOpen(false);
        }
    };

    const handleDeleteClick = (rowInfo: any) => {
        const { node,treeIndex, path } = rowInfo;
        //setIsOpen(true);
        setIsDelete(true);
        setSelectedPath(path);
        setSelectedNode(node);

        // setEditNodeName(node.title);
        // setIsEdit(true);
        // setRequester({ key: node.personId, text: node.title });
    };

    const updateNode = () => {
        const node = selectedNode;
        const path = selectedPath;
        const { children } = node;
        const editNode = {
            ...selectedNode,
            organizationTaxonomyLevelId: selectedNode?.organizationTaxonomyLevelId,
            displayOrder: selectedNode?.displayOrder,
            title: (selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit)
                ? editNodeName
                : (requester && requester[0] && requester[0]?.key ? requester[0].text : editNodeName),
            key: newNodeKey,
            id: selectedNode.id,
            isEdit: true,
            personId: (selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit)
                ? (serviceManager && serviceManager[0] && serviceManager[0]?.key ? serviceManager[0].key : null)
                : requester && requester[0] && requester[0]?.key ? requester[0].key : null,
            personName: (selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit)
                ? (serviceManager && serviceManager[0] && serviceManager[0]?.text ? serviceManager[0].text : null)
                : (requester && requester[0] && requester[0]?.text ? requester[0].text : null),
            roleId: selectedPersonRole ? selectedPersonRole.key : null,
            buName: buName ? buName : null,
        };
        const value = (selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit)
            ? editNodeName
            : (requester && requester[0] && requester[0]?.key ? requester[0].text : editNodeName);

        const newTree = changeNodeAtPath({
            treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: {
                children,
                title: value,
                id: selectedNode.id,
                organizationTaxonomyLevelId: selectedNode?.organizationTaxonomyLevelId,
                displayOrder: selectedNode?.displayOrder,
                personId: (selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit) ? (serviceManager && serviceManager[0] && serviceManager[0]?.key ? serviceManager[0].key : null) : requester && requester[0] && requester[0]?.key ? requester[0].key : null,
                personName: serviceManager && serviceManager[0] && serviceManager[0]?.text ? serviceManager[0].text : null,
                roleId: selectedPersonRole ? selectedPersonRole.key : null,
                buName: buName ? buName : null,
            }
        });

        setTreeData(newTree);
        props.onCreateNode(editNode);
    };

    const handleNodeCheckboxClick = (rowInfo: any) => {
        const { node, path } = rowInfo;
        setSelectedPath(path);
        setSelectedNode(node);
        props.onSelectItem(node.id);
    };

    const handleTreeOnSearch = (searchString: string) => {
        setSearchString(searchString);
    };

    const selectPrevMatch = () => {
        let searchFoundCountVal = 0;
        let searchFocusIndexValue = 0;
        if (typeof searchFoundCount === 'number') {
            searchFoundCountVal = searchFoundCount;
        }
        if (typeof searchFocusIndex === 'number') {
            searchFocusIndexValue = searchFocusIndex;
        }

        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCountVal + searchFocusIndexValue - 1) %
                searchFoundCountVal
                : searchFoundCountVal - 1
        );
    };


    const selectNextMatch = () => {
        let searchFoundCountVal = 0;
        if (typeof searchFoundCount === 'number') {
            searchFoundCountVal = searchFoundCount;
        }

        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFocusIndex + 1) % searchFoundCountVal
                : 0
        );
    };

    // model////////
    const { t } = useTranslation();
    const [isDraggable] = useBoolean(false);
    const [isOpen, setIsOpen] = useState(false);
    const titleId = useId('title');
    const theme = getTheme();
    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch'
        },
        actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
        header: [
            // eslint-disable-next-line deprecation/deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '2px 2px 2px 10px',
                backgroundColor: uPrinceTheme.palette.themePrimary,
                fontSize: 18,
                color: 'white'
            }
        ],
        footer: [
            // eslint-disable-next-line deprecation/deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '0px 24px 14px 24px',
                textAlign: 'end'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 0px 24px',
            overflowY: 'hidden',
            paddingTop: 20,
            // minWidth: screen.width > 1280?"40vw":"50vw",
            // minHeight: screen.width > 1280?"60vh":"75vh",
            // height: screen.width > 1280?"80vh":"75vh",
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 }
            }
        },
        subHeader: {
            flex: '1 1 auto',
            display: 'none',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '2px 2px 2px 10px',
            fontSize: 14,
            color: uPrinceTheme.palette.themePrimary,
            backgroundColor: '#FFF7F4',
            height: 40,
            marginLeft: 'auto',
            marginRight: '2px'
        }
    });
    const cancelIconButtonStyles = {
        root: {
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
            color: 'white'
        },
        rootHovered: { color: theme.palette.neutralDark }
    };

    const [nameErrorMsg, setNameErrorMsg]: any = useState('');
    const [levelErrorMsg, setLevelErrorMsg]: any = useState('');
    const [roleErrorMsg, setRoleErrorMsg]: any = useState('');
    const [serviceManagerErrorMsg, setServiceManagerErrorMsg]: any = useState('');
    const [requesterManagerErrorMsg, setRequesterManagerErrorMsg]: any = useState('');
    const [buNameErrorMsg, setBuNameErrorMsg]: any = useState('');
    const validate = (): boolean => {
        let isValid = true;
        if (isEdit) {
            if (_.isNil(editNodeName) || _.isEmpty(editNodeName)) {
                setNameErrorMsg(t('nameRequired'));
                isValid = false;
            } else {
                setNameErrorMsg('');
            }
            if ((selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID) &&
                (_.isNil(serviceManager) || _.isEmpty(serviceManager))) {
                setServiceManagerErrorMsg(t('serviceManagerRequired'));
                isValid = false;
            } else {
                setServiceManagerErrorMsg('');
            }
            if (((selectedLevel && selectedLevel.levelId === ORG_PERSON_SEARCH_TYPE_ID)
                || (selectedNode && selectedNode.organizationTaxonomyLevelId === ORG_PERSON_SEARCH_TYPE_ID)) &&
                (_.isNil(requester) || _.isEmpty(requester))) {
                setRequesterManagerErrorMsg(t('personNameRequired'));
                isValid = false;
            } else {
                setRequesterManagerErrorMsg('');
            }
            if ((selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID) &&
            (_.isNil(buName) || _.isEmpty(buName))) {
                setBuNameErrorMsg(t('buNameRequired'));
                isValid = false;
            } else {
                setBuNameErrorMsg('');
            }
        } else {
            if (!(selectedLevel?.key === OrganisationLevel.BU)) {
                if (_.isNil(newNodeName) || _.isEmpty(newNodeName)) {
                    setNameErrorMsg(t('nameRequired'));
                    isValid = false;
                } else {
                    setNameErrorMsg('');
                }
            }
            if (_.isNil(selectedLevel) || _.isEmpty(selectedLevel)) {
                setLevelErrorMsg(t('levelRequired'));
                isValid = false;
            } else {
                setLevelErrorMsg('');
            }
            if ((selectedNode?.organizationTaxonomyLevelId === ORG_TEAM_TAXONOMY_ID) &&
                (_.isNil(selectedPersonRole) || _.isEmpty(selectedPersonRole)) &&
                (selectedLevel?.text === "Person")) {
                setRoleErrorMsg(t('roleRequired'));
                isValid = false;
            }
            if ((selectedLevel?.key === OrganisationLevel.BU) &&
                (_.isNil(serviceManager) || _.isEmpty(serviceManager))) {
                setServiceManagerErrorMsg(t('serviceManagerRequired'));
                isValid = false;
            } else {
                setServiceManagerErrorMsg('');
            }
            if (((selectedLevel && selectedLevel.levelId === ORG_PERSON_SEARCH_TYPE_ID)
                || (selectedNode && selectedNode.organizationTaxonomyLevelId === ORG_PERSON_SEARCH_TYPE_ID)) &&
                (_.isNil(requester) || _.isEmpty(requester))) {
                setRequesterManagerErrorMsg(t('personNameRequired'));
                isValid = false;
            } else {
                setRequesterManagerErrorMsg('');
            }
            if ((selectedLevel?.key === OrganisationLevel.BU) &&
                (_.isNil(serviceManager) || _.isEmpty(serviceManager))) {
                setBuNameErrorMsg(t('buNameRequired'));
                isValid = false;
            } else {
                setBuNameErrorMsg('');
            }
        }
        return isValid;
    };

    const resetValidation = () => {
        setNameErrorMsg('');
        setLevelErrorMsg('');
        setRoleErrorMsg('');
        setServiceManagerErrorMsg('');
        setRequesterManagerErrorMsg('');
        setBuNameErrorMsg('');
    };

    const treeLevelFormat = () => {
        const data = props.treeLevel.map((item: any) => {
            return {
                ...item,
                key: item.id,
                text: item.name,
                level: item.displayOrder
            };
        });
        const filterData = data.filter((item: any) => {
            return item.level > nodeLevel;
        });
        return filterData;
    };

    const renderCheckBox = (rowInfo: any) => {
        const isParent = selectedParentIds.includes(rowInfo.node.id);
        const isLastLevel = rowInfo.path.length === (Object.keys(props.treeLevel).length - 1);
        if (true) {
            if (selectedParentIds.length > 0 && isParent) {
                return (
                    <div key={rowInfo.node.id} className={'Indeterminate'} style={{ paddingTop: 6 }}>
                        <Checkbox
                            className="btn btn-outline-success"
                            styles={{root: { verticalAlign: 'middle' }}}
                            checked={rowInfo.node.id === selectItemId}
                            onChange={() => handleNodeCheckboxClick(rowInfo)}
                        >
                        </Checkbox>
                    </div>
                );
            }
            return (
                <div className={'default'} style={{ paddingTop: 0 }}>
                    <Checkbox
                        className="btn btn-outline-success"
                        styles={{root: {
                            verticalAlign: 'middle',
                            paddingTop: 6
                        }}}
                        checked={rowInfo.node.id === selectItemId}
                        onChange={() => handleNodeCheckboxClick(rowInfo)}
                    >
                    </Checkbox></div>
            );
        }
        if (selectedParentIds.length > 0 && isParent) {
            return (
                <div key={rowInfo.node.id} className={'Indeterminate'} style={{ paddingTop: 6 }}>
                    <Checkbox
                        className="btn btn-outline-success"
                        styles={{root: { verticalAlign: 'middle' }}}
                        defaultIndeterminate
                        indeterminate={true}

                    >
                    </Checkbox>
                </div>
            );
        }
    };

    const renderNameField = () => {
        if (nodeLevel && nodeLevel.levelId === ORG_PERSON_SEARCH_TYPE_ID) {
            return (<>
                <Label> {t('name')}</Label>
                <PeoplePicker
                    label={null}
                    isDisabled={false}
                    selectedItem={requester}
                    onChange={(items: any) => {
                        if (items && _.isArray(items) && items.length === 0) {
                            setRequester([]);
                            // props.filter.requester = null;
                            // props.handleFilterChange(props.filter);
                        } else {
                            setRequester([]);
                            // props.filter.requester = null;
                            // props.handleFilterChange(props.filter);
                        }
                    }}
                    onPersonSelected={(items: any) => {
                        if (items) {
                            // props.filter.requester = items.key;
                            setNewNodeName(items.text);
                            setRequester([{ key: items.key, text: items.text }]);
                        } else {
                            // props.filter.requester = null;
                            setRequester([]);
                        }
                        // props.handleFilterChange(props.filter);
                        return items;
                    }}

                />
            </>);
        }
        return (<TextField
            autoComplete="off"
            label={t('name')}
            value={newNodeName}
            required={true}
            onChange={(event, value) => {
                setNewNodeName(value);
                setNewNodeKey(null);
                resetValidation();
            }}
            errorMessage={nameErrorMsg
                ? nameErrorMsg
                : ''
            }
        />);
    };

    const renderEditNameField = () => {
        if (selectedNode) {
            return (
                selectedLevel?.key != OrganisationLevel.BU && <div>
                    <TextField
                        autoComplete="off"
                        label={
                            ((selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit) || (selectedLevel?.key === OrganisationLevel.BU))
                                ? t('title')
                                : t('name')
                        }
                        value={editNodeName}
                        required={true}
                        onChange={(event, value) => {
                            setEditNodeName(value);
                            setNewNodeName(value);
                            // resetValidation();
                        }}
                        errorMessage={nameErrorMsg ? nameErrorMsg : ''}
                        disabled={((selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit) || (selectedLevel?.key === OrganisationLevel.BU)) ? true : false}
                    />
                </div>);
        }
    };

    const renderBuNameField = () => {
        if (selectedNode) {
            return (
                <div><TextField
                    autoComplete="off"
                    label={t('buName')}
                    value={buName}
                    required={true}
                    onChange={(event, value) => {
                        setBuName(value);
                        // setNewNodeName(value);
                        // resetValidation();
                    }}
                    errorMessage={buNameErrorMsg ? buNameErrorMsg : ''}
                /></div>);
        }
    };

    const renderPersonSearchPicker = () => {
        return (
            <>
                <Label> {t('name')}</Label>
                <PeoplePicker
                    label={null}
                    isDisabled={false}
                    selectedItem={requester}
                    onChange={(items: any) => {
                        if (items && _.isArray(items) && items.length === 0) {
                            setRequester([]);
                            // props.filter.requester = null;
                            // props.handleFilterChange(props.filter);
                        } else {
                            setRequester([]);
                            // props.filter.requester = null;
                            // props.handleFilterChange(props.filter);
                        }
                    }}
                    onPersonSelected={(items: any) => {
                        if (items) {
                            // props.filter.requester = items.key;
                            setNewNodeName(items.text);
                            setRequester([{ key: items.key, text: items.text }]);
                            setRequesterManagerErrorMsg('');
                        } else {
                            // props.filter.requester = null;
                            setRequester([]);
                        }
                        // props.handleFilterChange(props.filter);
                        return items;
                    }}
                    endpoint={!!selectedNode?.buId
                        ? (selectedNode?.organizationTaxonomyLevelId == ORG_TEAM_TAXONOMY_ID ? 'OrganizationSettings/PersonFilterForBuTeam' : 'OrganizationSettings/PersonFilterForBu')
                        : (requester?.id ? 'OrganizationSettings/PersonFilter' : 'OrganizationSettings/PersonFilter')
                    }
                    // parentId={comNode?.node?.id}
                    comId={comNode?.node?.id}
                    buId={selectedNode?.buId ? selectedNode?.buId : null}
                    teamId={selectedNode?.organizationTaxonomyLevelId == ORG_TEAM_TAXONOMY_ID ? selectedNode?.id : undefined}
                />
                {requesterManagerErrorMsg && <Text
                    styles={{
                        root: { color: '#a4262c', fontSize: 12 },
                    }}
                >
                    {requesterManagerErrorMsg}
                </Text>
                }
            </>
        )
    }

    const renderServiceManagerPicker = () => {
        return <>
            <Label> {t('serviceManager')}</Label>
            <PeoplePicker
                label={null}
                isDisabled={false}
                selectedItem={serviceManager}
                onChange={(items: any) => {
                    if (items && _.isArray(items) && items.length === 0) {
                        setServiceManager([]);
                        // props.filter.requester = null;
                        // props.handleFilterChange(props.filter);
                    } else {
                        setServiceManager([]);
                        // props.filter.requester = null;
                        // props.handleFilterChange(props.filter);
                    }
                }}
                onPersonSelected={(items: any) => {
                    if (items) {
                        // props.filter.requester = items.key;
                        // setNewNodeName(items.text);
                        setServiceManager([{ key: items.key, text: items.text }]);
                        setServiceManagerErrorMsg('');
                    } else {
                        // props.filter.requester = null;
                        setServiceManager([]);
                    }
                    // props.handleFilterChange(props.filter);
                    return items;
                }}
                endpoint={'OrganizationSettings/PersonFilter'}
                // parentId={comNode?.node?.id}
                comId={comNode?.node?.id}
            />
            {serviceManagerErrorMsg && <Text
                styles={{
                    root: { color: '#a4262c', fontSize: 12 },
                }}
            >
                {serviceManagerErrorMsg}
            </Text>
            }
        </>
    };

    const getDeleteVisible = (node:any) => {
       if (node.displayOrder === 1 || node.displayOrder === 2){
           return false;
       } else return true
    }

    const renderPersonRoleDropDown = (options: any) => {
        const children = selectedNode?.children?.map((child: any) => {
            return child
        });
        let foreman = children?.find((item: any) => item.roleId === RoleId.FOREMAN);

        if (!!foreman) {
            const worker = [];
            worker.push(options[1])
            return worker
        } else {
            return options;
        }
    };

    const handleBuCheckboxClick=(rowInfo:any)=>{
      const { node } = rowInfo;
      let data={id:node.id?node.id:null}
      dispatch(setDefaultBu(data));
      setSelectedDefaultBu(node.id);
    };

    return (
        <div
            style={{ height: screen.width > 1280 ? '73vh' : '64vh', marginBottom: 10 }}
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
        >

            <Label>{props.label}</Label>
            <div className="row">
                <Stack horizontal styles={stackStyles} tokens={stackTokens}>
                    <Stack.Item grow={1} styles={stackItemStyles}>
                        <PrimaryButton onClick={expandAll}>
                            {i18n.t('expandAll')}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item grow={1} styles={stackItemStyles}>
                        <PrimaryButton onClick={collapseAll}>
                            {i18n.t('collapseAll')}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item grow={3} styles={stackItemStyles}>
                        <TextField
                            value={searchString}
                            placeholder={i18n.t('search')}
                            onChange={(event, value) => {
                                if (value) {
                                    handleTreeOnSearch(value);
                                } else {
                                    handleTreeOnSearch('');
                                }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1} styles={stackItemStyles}>
                        <PrimaryButton
                            style={{ minWidth: 25 }}
                            disabled={!searchFoundCount}
                            onClick={selectPrevMatch}
                        >
                            {' '}
                            &lt;
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item grow={1} styles={stackItemStyles}>
                        <PrimaryButton
                            style={{ minWidth: 25 }}
                            disabled={!searchFoundCount}
                            onClick={selectNextMatch}
                        >
                            &gt;
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item grow={1} styles={stackItemStyles}>
                        <span style={{ marginTop: 6 }}>
                            &nbsp;
                            {searchFoundCount
                                ? searchFoundCount > 0
                                    ? searchFocusIndex + 1
                                    : 0
                                : 0}
                            &nbsp;/&nbsp;
                            {searchFoundCount || 0}
                        </span>
                    </Stack.Item>
                </Stack>
            </div>
            <label htmlFor="find-box"></label>
            <SortableTree
                canDrag={({ }) => false}
                canDrop={() => false}
                searchQuery={searchString}
                // searchMethod={customSearchMethod}
                searchFocusOffset={searchFocusIndex}
                searchFinishCallback={(matches) => {
                    setSearchFoundCount(matches.length);
                    setSearchFocusIndex(
                        matches.length > 0 ? searchFocusIndex % matches.length : 0
                    );
                }}
                // isVirtualized={true}
                treeData={treeData}
                onChange={(treeData2) => setTreeData(treeData2)}
                onlyExpandSearchedNodes={true}
                generateNodeProps={(rowInfo:any) => {
                    const { path } = rowInfo;
                    let x = true;
                    //x= ((rowInfo?.node?.displayOrder !== 7) || (rowInfo?.node?.organizationTaxonomyLevelId !== ORG_PERSON_SEARCH_TYPE_ID) )
                    if (rowInfo?.node?.displayOrder !== 7) {
                        x = false;
                    }
                    if (rowInfo?.node?.organizationTaxonomyLevelId !== ORG_PERSON_SEARCH_TYPE_ID) {
                        x = false;
                    }
                    return {
                        buttons: [
                          <div> {(rowInfo?.node?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID)&& <div>
                            <Checkbox
                              className="btn btn-outline-success"
                              styles={{
                                root: { verticalAlign: 'middle',marginTop: 5,marginRight: 3  },
                              }}
                              checked={rowInfo?.node?.isDefaultBu?rowInfo?.node?.isDefaultBu:defaultBu === rowInfo?.node?.id}
                              onChange={() => handleBuCheckboxClick(rowInfo)}
                            />
                          </div> }

                          </div>,
                            // eslint-disable-next-line react/jsx-key
                            <div>
                                <span style={{ display: 'none' }}>{path}</span>

                                {rowInfo?.node?.roleId === RoleId.FOREMAN &&
                                    <PrimaryButton
                                        style={{
                                            width: 22,
                                            minWidth: 22,
                                            height: 22,
                                            padding: 5,
                                            marginTop: 5,
                                            marginRight: 3
                                        }}
                                        iconProps={reminderPerson}
                                        ariaLabel="Foreman"
                                        disabled={true}
                                    >
                                    </PrimaryButton>}

                                {/* <span style={{ display: 'block' }}>{path}</span> */}
                                {/*{  ((rowInfo?.node?.displayOrder !== 7) && (rowInfo?.node?.organizationTaxonomyLevelId !== ORG_PERSON_SEARCH_TYPE_ID) ) && renderCheckBox(rowInfo) }*/}
                            </div>,
                            // eslint-disable-next-line react/jsx-key
                            <div>
                                {((rowInfo?.node?.parentId !== null) &&
                                    (rowInfo?.node?.displayOrder !== 2)) ? <PrimaryButton
                                    style={{
                                        width: 22,
                                        minWidth: 22,
                                        height: 22,
                                        padding: 5,
                                        marginTop: 5,
                                        marginRight: 3
                                    }}
                                    iconProps={editIconWhite}
                                    ariaLabel="Close popup modal"
                                    onClick={() => {
                                        setNewNodeName(null)
                                        setEditNodeName(null)
                                        handleEditClick(rowInfo);

                                    }}
                                >
                                </PrimaryButton> : false}
                            </div>,
                            /** check is lastLevel**/
                            <div>
                                {(
                                    (rowInfo?.node?.displayOrder !== 1) &&
                                    (rowInfo?.node?.displayOrder !== 7) &&
                                    (rowInfo?.node?.organizationTaxonomyLevelId !== ORG_PERSON_SEARCH_TYPE_ID)
                                ) ? <PrimaryButton
                                    style={{
                                        width: 22,
                                        minWidth: 22,
                                        height: 22,
                                        padding: 5,
                                        marginTop: 5,
                                        marginRight: 3
                                    }}
                                    iconProps={addIconWhite}
                                    ariaLabel="Close popup modal"
                                    onClick={() => {
                                        setSelectedLevel(null);
                                        setSelectedPersonRole(null);
                                        setNewNodeName(null)
                                        setEditNodeName(null)
                                        handleAddClick(rowInfo);
                                    }}
                                >
                                </PrimaryButton> : false}
                            </div>,
                            <div>
                                { getDeleteVisible(rowInfo?.node) ?
                                <PrimaryButton
                                    style={{
                                        width: 22,
                                        minWidth: 22,
                                        height: 22,
                                        padding: 5,
                                        marginTop: 5
                                    }}
                                    iconProps={deleteIconWhite}
                                    ariaLabel="Close popup modal"
                                    onClick={() => {
                                        handleDeleteClick(rowInfo);
                                    }}
                                >
                                </PrimaryButton> : false
                    }
                            </div>,
                        ],
                        onClick: () => {
                            // handleNodeClick(rowInfo);
                        }
                    };
                }}
            />
{/*Modal*/}
            <Modal
                titleAriaId={titleId}
                isOpen={isOpen}
                onDismiss={() => {
                    setIsOpen(false);
                    resetValues();
                }}
                isBlocking={true}
                dragOptions={isDraggable ? dragOptions : undefined}
            >
                <div className={contentStyles.header}>
                    <span id={titleId}>{(isEdit) ? i18n.t('editNode') : i18n.t('addNode')} </span>
                    <IconButton
                        styles={cancelIconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={() => {
                            setIsOpen(false);
                            resetValues();
                        }}
                    />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                {!isEdit && <Dropdown
                                    errorMessage={levelErrorMsg}
                                    required={true}
                                    label={i18n.t('level')}
                                    placeholder={i18n.t('level')}
                                    options={treeLevelFormat()}
                                    onChange={(event, value) => {
                                        setLevelErrorMsg('');
                                        setNameErrorMsg('');
                                        setServiceManagerErrorMsg('');
                                        setRequesterManagerErrorMsg('');
                                        if (value) {
                                            setSelectedLevel(value);
                                            console.log(JSON.stringify(value));
                                        } else {
                                            setSelectedLevel(null);
                                        }
                                    }}
                                />}

                                {(selectedNode?.organizationTaxonomyLevelId === ORG_TEAM_TAXONOMY_ID) &&
                                    ((selectedNode?.organizationTaxonomyLevelId === ORG_PERSON_SEARCH_TYPE_ID) || (selectedLevel?.levelId === "we10e768-3e06-po02-b337-ee367a82adwe")) &&
                                    <Dropdown
                                        errorMessage={roleErrorMsg}
                                        selectedKey={selectedPersonRole?.key}
                                        required={true}
                                        label={i18n.t('personRole')}
                                        placeholder={i18n.t('personRole')}
                                        options={renderPersonRoleDropDown(props.personRoleDropdowns)}
                                        onChange={(event, value) => {
                                            setRoleErrorMsg('');
                                            if (value) {
                                                setSelectedPersonRole(value);
                                            } else {
                                                setSelectedPersonRole(null);
                                            }
                                        }}
                                    />
                                }

                                {/* { (selectedNode && selectedNode.organizationTaxonomyLevelId !== ORG_PERSON_SEARCH_TYPE_ID && !selectedLevel && isEdit) ? renderEditNameField() : null
                                }
                                { (selectedLevel && selectedLevel.levelId !== ORG_PERSON_SEARCH_TYPE_ID && !isEdit) ? renderEditNameField() : null
                                } */}

                                { ((selectedLevel && selectedLevel.levelId === ORG_PERSON_SEARCH_TYPE_ID )
                                || (selectedNode && selectedNode.organizationTaxonomyLevelId === ORG_PERSON_SEARCH_TYPE_ID))
                                ? renderPersonSearchPicker() : renderEditNameField()
                                }

                                {((selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit) || (selectedLevel?.key === OrganisationLevel.BU)) &&
                                    renderBuNameField()
                                }

                                {((selectedNode?.organizationTaxonomyLevelId === ORG_BU_TYPE_ID && isEdit) || (selectedLevel?.key === OrganisationLevel.BU)) &&
                                    renderServiceManagerPicker()
                                }
                            </div>
                        </div>
                        {/* <pre>{JSON.stringify(requester, 0, 2)}</pre>  */}
                    </div>
                </div>
                <div className={contentStyles.footer}>
                    {isEdit ? <PrimaryButton
                        iconProps={addIconWhite}
                        text={t('save')}
                        style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
                        onClick={() => {
                            handleEdit();
                        }}
                    /> : <PrimaryButton
                        iconProps={addIconWhite}
                        text={t('add')}
                        style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
                        onClick={() => {
                            handleSave();
                        }}
                    />}
                </div>
            </Modal>
{/*Modal end*/}

            {/* {isDelete && */}
                <ConfirmationDialog
                    hidden={!isDelete}
                    title={t('areyousure')}
                    subText={t('youwonTbeabletorevertthis')}
                    onClickConfirmDialog={() => {
                        messageService.sendMessage({ deleteOrgTaxonmy: selectedNode });

                        setTreeData(removeNodeAtPath({
                            treeData: treeData,
                            path: selectedPath,   // You can use path from here
                            // TODO - need to handle the case where a node cannot be deleted
                            getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
                                // console.log(number);
                                return number;
                            },
                            // ignoreCollapsed: false,
                        }));
                        setIsDelete(false)
                    }}
                    onClickCancelDialog={() => {
                        setIsDelete(false)
                    }}
                    cancelButtonText={t('noIwonT')}
                    confirmButtonText={t('yesDeleteit')}
                />
            {/* } */}
        </div>
    );
};

export default CustomSortableTree;
