// import { _renderDetailsFooterItemColumn } from './filterHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IconButton,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ITooltipHostStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ProjectBreakdown } from '../../../types/projectBreakdownStructure';
import { formatDate } from '../../../shared/util';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import {
  GOODDEERIS_PRODUCT_TYPE_ID,
  POFilter,
  POListItem,
  PRODUCT_TYPE_ID,
  RESOURCE_TYPE_ID,
  RFQ_REQUEST,
} from '../../../types/purchaseOrder';
import { IN_REVIEW_ACCEPT_STATUS_ID, IN_REVIEW_FEEDBACK_STATUS_ID } from '../../../types/projectMolecule';
import { iconColors, uPrinceTheme } from '../../../../theme';
import { ResourceTypes } from '../../../types/billOfResources';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const ListPaneComponent = (props: {
  filter: POFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: POListItem[];
  resourceTypes: [];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: POFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  formData: ProjectBreakdown;
  dropDownOptions: any;
  filterPOByResourceType: (resourceType: string) => void
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<POFilter>(props.filter);
  const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: 0,
    padding: 0,
  });

  const calloutProps = { gapSpace: 0 };
  // The TooltipHost root uses display: inline by default.
  // If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      const defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    }
    return [];
  };
  const getStatusDropdownWithDefualt = (options: any) => {
    const inReviewIds = [IN_REVIEW_FEEDBACK_STATUS_ID, IN_REVIEW_ACCEPT_STATUS_ID];
    if (options) {
      const defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      const filterOptions = options.filter((item: any) =>
        !inReviewIds.includes(item.key),
      );
      Array.prototype.push.apply(defualtOption, filterOptions);
      return defualtOption;
    }
    return [];
  };

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            props.handleListPaneItemClick(row.item.sequenceId);
          }}
        >
                    <DetailsRow {...row} styles={customStyles}/>
                </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };


  const _renderFilterItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column0': {
        return (
          <div>
            <div style={{ marginTop: 5 }}>
              <TooltipHost
                content={t('materials')}
                id={'materialsTp'}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton aria-label="Materials"
                            style={getHeaderIconActiveStyle(filter.resource === ResourceTypes.materials.id)}
                            iconProps={{ iconName: 'ExpandMenu' }} className={iconClass}
                            onClick={() => props.filterPOByResourceType(ResourceTypes.materials.id)}/>
              </TooltipHost>
              <TooltipHost
                content={t('tools')}
                id={'toolsTp'}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton aria-label="Tools"
                            style={getHeaderIconActiveStyle(filter.resource === ResourceTypes.tools.id)}
                            iconProps={{ iconName: 'DeveloperTools' }} className={iconClass}
                            onClick={() => props.filterPOByResourceType(ResourceTypes.tools.id)}/>
              </TooltipHost>
              <TooltipHost
                content={t('consumables')}
                id={'consumablesTp'}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton aria-label="Consumables"
                            style={getHeaderIconActiveStyle(filter.resource === ResourceTypes.consumable.id)}
                            iconProps={{ iconName: 'HintText' }} className={iconClass}
                            onClick={() => props.filterPOByResourceType(ResourceTypes.consumable.id)}/>
              </TooltipHost>
              <TooltipHost
                content={t('labours')}
                id={'laboursTp'}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton aria-label="Labours"
                            style={getHeaderIconActiveStyle(filter.resource === ResourceTypes.labour.id)}
                            iconProps={{ iconName: 'People' }} className={iconClass}
                            onClick={() => props.filterPOByResourceType(ResourceTypes.labour.id)}/>
              </TooltipHost>
            </div>
          </div>
        );
        break;
      }
      case 'column1': {
        return (
          <div>
            <TextField
              value={props.filter?.projectSequenceCode ? props.filter?.projectSequenceCode : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.projectSequenceCode = value;
                } else {
                  props.filter.projectSequenceCode = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <TextField
              value={props.filter?.title ? props.filter?.title : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              placeholder={t('type')}
              options={getStatusDropdownWithDefualt(props.dropDownOptions?.requestTypes)}
              selectedKey={props.filter?.requestTypeId ? props.filter?.requestTypeId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.requestTypeId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.requestTypeId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      // case 'column3': {
      //     return (
      //         <div>
      //             <TextField
      //                 value={ props.filter?.thirdParty ? props.filter?.thirdParty : '' }
      //                 onChange={ (event, value) => {
      //                     if (value) {
      //                         props.filter.thirdParty = value;
      //                     } else {
      //                         props.filter.thirdParty = null;
      //                     }
      //                     props.handleFilterChange(props.filter);
      //                 } }
      //             />
      //         </div>
      //     );
      //     break;
      // }
      case 'column4': {
        return (
          <div>
            <TextField
              value={props.filter?.customer ? props.filter?.customer : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.customer = value;
                } else {
                  props.filter.customer = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column5': {
        return (
          <div>
            <TextField
              value={props.filter?.supplier ? props.filter?.supplier : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.supplier = value;
                } else {
                  props.filter.supplier = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column6': {
        return (
          <div>
            <Dropdown
              placeholder={t('status')}
              options={getStatusDropdownWithDefualt(props.dropDownOptions?.states)}
              selectedKey={props.filter?.statusId ? props.filter?.statusId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.statusId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.statusId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column7': {
        return (
          <div>
            <TextField
              value={props.filter?.totalAmount ? props.filter?.totalAmount.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.totalAmount = parseInt(value, 10);
                } else {
                  props.filter.totalAmount = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column8': {
        return (
          <div>
            <CustomDatePicker
              setMinDate={null}
              setMaxDate={null}
              setValue={formatDate(props.filter.lastModifiedDate, 1)}
              getValue={(date: Date) => {
                // onChangeEndDateTime(formatDate(date, 2));
                if (date) {
                  props.filter.lastModifiedDate = formatDate(date, 2);
                } else {
                  props.filter.lastModifiedDate = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column9': {
        return (
          <div>
            <TextField
              value={props.filter?.modifiedBy ? props.filter?.modifiedBy : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.modifiedBy = value;
                } else {
                  props.filter.modifiedBy = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };


  const getHeaderIconActiveStyle = (isActive: any) => {
    return isActive ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.black };
  };

  const getIconActiveStyle = (numberofResource: any) => {
    return (numberofResource && parseInt(numberofResource) >= 1) ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.black };
  };


  const renderResourcesIcon = (item: POListItem) => {
    // const {t} = useTranslation()
    if (item && item.id && (item.typeId === RESOURCE_TYPE_ID || item.typeId === GOODDEERIS_PRODUCT_TYPE_ID)) {
      return <div>
        <TooltipHost
          content={t('materials')}
          id={'materialsTp'}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          <IconButton aria-label="Materials" style={getIconActiveStyle(item.noOfMaterials)}
                      iconProps={{ iconName: 'ExpandMenu' }} className={iconClass}/>
        </TooltipHost>
        <TooltipHost
          content={t('tools')}
          id={'toolsTp'}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          <IconButton aria-label="Tools" style={getIconActiveStyle(item.noOfTools)}
                      iconProps={{ iconName: 'DeveloperTools' }} className={iconClass}/>
        </TooltipHost>
        <TooltipHost
          content={t('consumables')}
          id={'consumablesTp'}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          <IconButton aria-label="Consumables" style={getIconActiveStyle(item.noOfConsumables)}
                      iconProps={{ iconName: 'HintText' }} className={iconClass}/>
        </TooltipHost>
        <TooltipHost
          content={t('labours')}
          id={'laboursTp'}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          <IconButton aria-label="Labours" style={getIconActiveStyle(item.noOfLabours)}
                      iconProps={{ iconName: 'People' }} className={iconClass}/>
        </TooltipHost>
      </div>;
    } else if (item && item.id && item.typeId === PRODUCT_TYPE_ID) {

      if(item && item.id && item?.requestTypeId === RFQ_REQUEST){
        return <TooltipHost
          content={t('RFQ')}
          id={'productTp'}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          <IconButton aria-label="Product" style={getIconActiveStyle(1)} iconProps={{ iconName: 'PeopleAdd' }}
                      className={iconClass}/>
        </TooltipHost>;
      }else{
        return <TooltipHost
          content={t('product')}
          id={'productTp'}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          <IconButton aria-label="Product" style={getIconActiveStyle(1)} iconProps={{ iconName: 'Org' }}
                      className={iconClass}/>
        </TooltipHost>;
      }
    }
    return <div></div>;
  };

  const POListColumn = (item: any, index?: number, column?: IColumn) => {
    if (column && column.fieldName && (column.fieldName === 'resourcesIcon')) {
      return renderResourcesIcon(item);
    } else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    }
    return <span style={{ paddingLeft: 9 }}>-</span>;
  };

  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={props.filter?.requestTypeId===RFQ_REQUEST?SelectionMode.multiple:SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              }
              return <span></span>;
            }}
            onRenderItemColumn={(
              item: any,
              index?: number,
              column?: IColumn,
            ) =>
              POListColumn(
                item,
                index,
                column,
              )
            }
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);
