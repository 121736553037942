export const LOAD_PS_SHORTCUTPANE = 'uprince/LOAD_PS_SHORTCUTPANE';
export const LOAD_PS_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_PS_SHORTCUTPANE_SUCCESS';
export const LOAD_PS_SHORTCUTPANE_FAIL = 'uprince/LOAD_PS_SHORTCUTPANE_FAIL';

export const LOAD_PS_LSITPANE = 'uprince/LOAD_PS_LSITPANE';
export const LOAD_PS_LSITPANE_SUCCESS = 'uprince/LOAD_PS_LSITPANE_SUCCESS';
export const LOAD_PS_LSITPANE_FAIL = 'uprince/LOAD_PS_LSITPANE_FAIL';

export const LOAD_PS_DROPDOWN = 'uprince/LOAD_PS_DROPDOWN';
export const LOAD_PS_DROPDOWN_SUCCESS = 'uprince/LOAD_PS_DROPDOWN_SUCCESS';
export const LOAD_PS_DROPDOWN_FAIL = 'uprince/LOAD_PS_DROPDOWN_FAIL';

export const SAVE_PS = 'uprince/SAVE_PS';
export const SAVE_PS_SUCCESS = 'uprince/SAVE_PS_SUCCESS';
export const SAVE_PS_FAIL = 'uprince/SAVE_PS_FAIL';

export const LOAD_PS_BY_ID = 'uprince/LOAD_PS_BY_ID';
export const LOAD_PS_BY_ID_SUCCESS = 'uprince/LOAD_PS_BY_ID_SUCCESS';
export const LOAD_PS_BY_ID_FAIL = 'uprince/LOAD_PS_BY_ID_FAIL';

export const SET_PS_UID = 'uprince/SET_PS_UID';
export const CLEAR_PS_UID = 'uprince/CLEAR_PS_UID';
export const CLEAR_PS_FORM_DATA = 'uprince/CLEAR_PS_FORM_DATA';
export const RESET_PS_IS_CHANGE = 'uprice/RESET_PS_IS_CHANGE';

export const LOAD_COST_LIST = 'uprince/LOAD_COST_LIST';
export const LOAD_COST_LIST_SUCCESS = 'uprince/LOAD_COST_LIST_SUCCESS';
export const LOAD_COST_LIST_FAIL = 'uprince/LOAD_COST_LIST_FAIL';

export const SAVE_PRICE_LIST = 'uprince/SAVE_PRICE_LIST';
export const SAVE_PRICE_LIST_SUCCESS = 'uprince/SAVE_PRICE_LIST_SUCCESS';
export const SAVE_PRICE_LIST_FAIL = 'uprince/SAVE_PRICE_LIST_FAIL';

export const READ_PRICE_LIST = 'uprince/READ_PRICE_LIST';
export const READ_PRICE_LIST_SUCCESS = 'uprince/READ_PRICE_LIST_SUCCESS';
export const READ_PRICE_LIST_FAIL = 'uprince/READ_PRICE_LIST_FAIL';

export const SAVE_RESOURCE = 'uprince/SAVE_RESOURCE';
export const SAVE_RESOURCE_SUCCESS = 'uprince/SAVE_RESOURCE_SUCCESS';
export const SAVE_RESOURCE_FAIL = 'uprince/SAVE_RESOURCE_FAIL';

export const LOAD_RESOURCE_MATERIALS = 'uprince/LOAD_RESOURCE_MATERIALS';
export const LOAD_RESOURCE_MATERIALS_SUCCESS = 'uprince/LOAD_RESOURCE_MATERIALS_SUCCESS';
export const LOAD_RESOURCE_MATERIALS_FAIL = 'uprince/LOAD_RESOURCE_MATERIALS_FAIL';

export const LOAD_RESOURCE_TOOLS = 'uprince/LOAD_RESOURCE_TOOLS';
export const LOAD_RESOURCE_TOOLS_SUCCESS = 'uprince/LOAD_RESOURCE_TOOLS_SUCCESS';
export const LOAD_RESOURCE_TOOLS_FAIL = 'uprince/LOAD_RESOURCE_TOOLS_FAIL';

export const LOAD_RESOURCE_CONSUMABLES = 'uprince/LOAD_RESOURCE_CONSUMABLES';
export const LOAD_RESOURCE_CONSUMABLES_SUCCESS = 'uprince/LOAD_RESOURCE_CONSUMABLES_SUCCESS';
export const LOAD_RESOURCE_CONSUMABLES_FAIL = 'uprince/LOAD_RESOURCE_CONSUMABLES_FAIL';

export const LOAD_RESOURCE_LABOUR = 'uprince/LOAD_RESOURCE_LABOUR';
export const LOAD_RESOURCE_LABOUR_SUCCESS = 'uprince/LOAD_RESOURCE_LABOUR_SUCCESS';
export const LOAD_RESOURCE_LABOUR_FAIL = 'uprince/LOAD_RESOURCE_LABOUR_FAIL';

export const DELETE_RESOURCE = 'uprince/DELETE_RESOURCE';
export const DELETE_RESOURCE_SUCCESS = 'uprince/DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAIL = 'uprince/DELETE_RESOURCE_FAIL';

export const CREATE_PS_RESOURCE = 'uprince/CREATE_PS_RESOURCE';
export const CREATE_PS_RESOURCE_SUCCESS = 'uprince/CREATE_PS_RESOURCE_SUCCESS';
export const CREATE_PS_RESOURCE_FAIL = 'uprince/CREATE_PS_RESOURCE_FAIL';

export const APPROVED_PS = 'uprince/APPROVED_PS';
export const APPROVED_PS_SUCCESS = 'uprince/APPROVED_PS_SUCCESS';
export const APPROVED_PS_FAIL = 'uprince/APPROVED_PS_FAIL';


export const APPROVED_PS_CU = 'uprince/APPROVED_PS_CU';
export const APPROVED_PS_CU_SUCCESS = 'uprince/APPROVED_PS_CU_SUCCESS';
export const APPROVED_PS_CU_FAIL = 'uprince/APPROVED_PS_CU_FAIL';

export const LOAD_VAT_LIST = 'uprince/LOAD_VAT_LIST';
export const LOAD_VAT_LIST_SUCCESS = 'uprince/LOAD_VAT_LIST_SUCCESS';
export const LOAD_VAT_LIST_FAIL = 'uprince/LOAD_VAT_LIST_FAIL';

export const SAVE_VAT = 'uprince/SAVE_VAT';
export const SAVE_VAT_SUCCESS = 'uprince/SAVE_VAT_SUCCESS';
export const SAVE_VAT_FAIL = 'uprince/SAVE_VAT_FAIL';
