import * as React from 'react';
import { Label, Link } from '@fluentui/react';
import i18n from '../../../../i18n';
import PmolCard from './PmolCard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { readDeliverablesToPbs } from '../../../reducers/projectBreakdownReducer';

const Deliverables = (props:{formData:any}) => {
  const dispatch = useDispatch();

  const { deliverables } = useSelector(
    (state: any) => state.projectBreakdown,
  );

   useEffect(()=>{
     console.log("props?.formData",props?.formData);
     if(props?.formData?.id){
       dispatch(readDeliverablesToPbs(props?.formData?.id))
     }
   },[props?.formData?.id])

 const renderPmolCards=()=>{
    if(deliverables){
      return deliverables.map((pmol:any)=>{
        return  <PmolCard pmol={pmol}/>
      })
    }
 }

 return (
  <> <div className="proj-detail-block" id="11">
   <div className="document-pane-card">
    <div className={'card-header'}>
     <Link href="#" id="deliverables">
      <Label>10. {i18n.t('deliverables')} </Label>
     </Link>
    </div>
    <div className={'card-body'} style={{paddingTop:20}}>
     {renderPmolCards()}
    </div>
   </div>
  </div>
  </>
 );

};

export default Deliverables
