import _ from 'lodash';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';
import DefaultNumberFormat from '../../../shared/fluentFinalForm/defaultNumberFormat';
import TextEditor from '../../../shared/textEditor/textEditor';
import { getImages } from '../../../shared/util';
import {
  PENDING_DEVELOPMENT_STATUS_ID,
  PMOLService,
  ProjectMolecule,
  ProjectMoleculeValidationMessage,
} from '../../../types/projectMolecule';

const ServiceUI = (props: {
  service: PMOLService,
  onChangeService: (prop: string, val: any) => void,
  pmol: ProjectMolecule,
  saveFormData: any,
  productTitle: string,
  projectTitle: string | null,
  cpcBasicUnitOfMeasure: any,
  validationMessages: ProjectMoleculeValidationMessage,
}) => {
  const { t } = useTranslation();
  const theme = getTheme();

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 3,
    padding: 10,
  };

  const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
  const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });
  return (
    <div>

      <div className="proj-detail-block" id="8">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <Link href="#" id="service">
              <Label>5. {t('service')} </Label>
            </Link>
          </div>


          <div className={'card-body'}>
            {props.projectTitle &&
            <div>
              <Label>5.1 {t('referenceDetails')} </Label>
              <Stack
                horizontal
                wrap
                styles={stackStyles}
                tokens={stackTokens}
              >
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('projectTitle')}
                      value={props.projectTitle ? props.projectTitle : ''}
                      disabled={true}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('productTitle')}
                      value={props.productTitle ? props.productTitle : ''}
                      disabled={true}/>
                  </div>
                </Stack.Item>
              </Stack>
            </div>
            }

            <Label>5.2 {t('financeDetails')} </Label>

            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <DefaultNumberFormat
                    label={t('quantity')}
                    value={props.service?.quantity ? props.service?.quantity : ''}
                    disabled={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
                    onChange={(value) => {
                      if (value) {
                        props.onChangeService('quantity', value);
                      } else {
                        props.onChangeService('quantity', 0);
                      }
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    placeholder={t('cpcBasicunitofMeasure')}
                    label={t('cpcBasicunitofMeasure')}
                    options={props.cpcBasicUnitOfMeasure}
                    errorMessage={props.validationMessages.mouErrorMsg ? props.validationMessages.mouErrorMsg : ''}
                    selectedKey={
                      props.service?.mou
                        ? props.service?.mou
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.onChangeService('mou', value.key);
                        props.validationMessages.mouErrorMsg = '';
                      } else {
                        props.onChangeService('mou', null);
                      }
                    }}
                    disabled={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
                    // required={ true }
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <DefaultNumberFormat
                    label={t('unitPrice')}
                    value={props.service?.unitPrice ? props.service?.unitPrice : ''}
                    disabled={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
                    prefix={'€'}
                    onChange={(value) => {
                      if (value) {
                        props.onChangeService('unitPrice', value);
                      } else {
                        props.onChangeService('unitPrice', null);
                      }
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <DefaultNumberFormat
                    label={t('totalPrice')}
                    prefix={'€'}
                    value={props.service?.totalPrice ? props.service?.totalPrice : ''}
                    disabled={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
                    onChange={(value) => {
                      if (value) {
                        props.onChangeService('totalPrice', value);
                      } else {
                        props.onChangeService('totalPrice', null);
                      }
                    }}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <div style={{ marginTop: 10 }}>
              <CustomFileUploader
                uploadedImages={props.service?.documents ? getImages(props.service?.documents) : []}
                label={5.3 + ' ' + t('documents')}
                uploadUrl={'PO/UploadPoDocuments'}
                onSaveSuccess={(response: any) => {
                  if (props.service?.documents && _.isArray(props.service?.documents)) {
                    props.service?.documents.push(response);
                  } else {
                    props.service.documents = [];
                    props.service.documents.push(response);
                  }
                  props.onChangeService('documents', props.service.documents);
                }}
                disable={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
              />
            </div>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Label>{5.4 + ' ' + t('comments')} </Label>

              <TextEditor
                value={(props.service && props.service?.comments) ? props.service.comments : null}
                onChange={(value) => {
                  props.onChangeService('comments', value);
                  // if (value) {
                  //     // props.formData.comments = value;
                  //     props.onChangeService('comments', props.service?.comments ? props.service?.comments : '');
                  // } else {
                  //     props.onChangeService('comments', null);
                  // }
                }}
                readOnly={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
              />
              { /* <TextEditor
                                value={ (props.service && props.service?.comments) ? props.service.comments : null }
                                onChange={ (value:any) => {
                                    if (value) {
                                        props.service.comments = value;
                                    } else if ( props.service) {
                                        props.service.comments = null;
                                    }
                                    props.onChangeService('comments', props.service?.comments ? props.service?.comments : '');
                                }
                                }
                                readOnly={ false }
                            /> */}
            </div>

            <div className={'card-footer'}>
              <Separator/>
              <Stack
                styles={stackStyles}
                tokens={itemAlignmentsStackTokens}>
                <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                  <ActionButton
                    className={classNames.actionButton}
                    iconProps={saveIcon}
                    allowDisabledFocus
                    onClick={() => {
                      props.saveFormData();
                    }}
                    disabled={props.pmol.statusId != PENDING_DEVELOPMENT_STATUS_ID ? true : false}
                  >
                    <Label className={classNames.actionButton}>{t('save')} </Label>
                  </ActionButton>
                </Stack.Item>
              </Stack>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default ServiceUI;
