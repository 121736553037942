import {store} from '../../index';

export class VisualPlanListPaneFilter {
  requestType: string[] | [] = [];
  resourceType: string[] | [] = [];
  // fromDate: string|null = moment().startOf('month').format('YYYY-MM-DD hh:mm');
  // toDate: string|null = moment().endOf('month').format('YYYY-MM-DD hh:mm');
  fromDate: string | null = null;
  toDate: string | null = null;
  projectSequenceCode: string | null = null;
  // title:string|null = null
  // sorter: Sorter = new Sorter();
}

// class Sorter {
//   attribute: string|null = null;
//   order: string|null = null;
// }

export class VisualPlanListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface VPDropDowns {
  status: DropDown[];
  type: DropDown[];
}

export interface VPDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface VPListPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  title: string | null;
}

export interface VPShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: any
}


export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const PURCHASE_REQUEST_TYPE_ID = '94282458-0b40-poa3-b0f9-c2e40344c8f1';
export const RETURN_REQUEST_TYPE_ID = '4010e768-3e06-po02-b337-ee367a82addb';

export enum VPPaneType {
  TOP = 1,
  BOTTOM = 2,
}

export const getSelectedBuFromCuProjectPlanning = () => {
  const selectedBu = store.getState().vp?.selectedBuInCuProjectPlanning;
  const buList = store.getState().projectDPlan?.buList;

  return selectedBu ? [selectedBu] : buList.map((item: { key: any; }) => item.key);
}

export class MidTermLabourAdd {
  // id: string = "";
  pbsLabourId?: string = "";
  cabPersonId: string[] = [];
  cpcId: string = ""; //node id of dropped cpc
  cu: string = "";
  project: string = "";
  pbsProduct: string = "";  //parent id of dropped cpc
}

