import _ from 'lodash';
import {
    ADD_MULTIPLE_MEMBERS_TO_PMOL,
    ADD_MULTIPLE_MEMBERS_TO_PMOL_FAIL,
    ADD_MULTIPLE_MEMBERS_TO_PMOL_SUCCESS,
    ASSIGN_PMOL_TO_PROJECT,
    ASSIGN_PMOL_TO_PROJECT_FAIL,
    ASSIGN_PMOL_TO_PROJECT_SUCCESS,
    ASSIGN_TEAM_TO_PMOL,
    ASSIGN_TEAM_TO_PMOL_FAIL,
    ASSIGN_TEAM_TO_PMOL_SUCCESS,
    ASSIGN_TEAM_TO_PROJECT,
    ASSIGN_TEAM_TO_PROJECT_FAIL,
    ASSIGN_TEAM_TO_PROJECT_SUCCESS,
    ASSIGN_VEHICLE_TO_PMOL,
    ASSIGN_VEHICLE_TO_PMOL_FAIL,
    ASSIGN_VEHICLE_TO_PMOL_SUCCESS,
    ASSIGN_VEHICLE_TO_PROJECT,
    ASSIGN_VEHICLE_TO_PROJECT_FAIL,
    ASSIGN_VEHICLE_TO_PROJECT_SUCCESS,
    CLONE_PMOL_PROJECT_DAY_PLANNING,
    CLONE_PMOL_PROJECT_DAY_PLANNING_FAIL,
    CLONE_PMOL_PROJECT_DAY_PLANNING_SUCCESS,
    DELETE_LABOUR_PROJECT_DAY_PLANNING,
    DELETE_LABOUR_PROJECT_DAY_PLANNING_FAIL,
    DELETE_LABOUR_PROJECT_DAY_PLANNING_SUCCESS,
    GENERATE_CIAW,
    GENERATE_CIAW_FAIL,
    GENERATE_CIAW_SUCCESS,
    GENERATE_RFQ,
    GENERATE_RFQ_FAIL,
    GENERATE_RFQ_SUCCESS,
    GET_BU_PERSON_WITH_COMPETENCE,
    GET_BU_PERSON_WITH_COMPETENCE_FAIL,
    GET_BU_PERSON_WITH_COMPETENCE_SUCCESS,
    GET_HTML_PRINT_DATA,
    GET_HTML_PRINT_DATA_FAIL,
    GET_HTML_PRINT_DATA_SUCCESS,
    GET_ORG_TEAMS_TAXONOMY,
    GET_ORG_TEAMS_TAXONOMY_FAIL,
    GET_ORG_TEAMS_TAXONOMY_SUCCESS,
    GET_TOOL_WITH_RESOURCE,
    GET_TOOL_WITH_RESOURCE_FAIL,
    GET_TOOL_WITH_RESOURCE_SUCCESS,
    GET_VEHICLE_WITH_RESOURCE,
    GET_VEHICLE_WITH_RESOURCE_FAIL,
    GET_VEHICLE_WITH_RESOURCE_SUCCESS,
    LOAD_BU_DROPDOWN_DATA,
    LOAD_BU_DROPDOWN_DATA_FAIL,
    LOAD_BU_DROPDOWN_DATA_SUCCESS,
    LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE,
    LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_FAIL,
    LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_SUCCESS,
    LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST,
    LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_FAIL,
    LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_SUCCESS,
    LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST,
    LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_FAIL,
    LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_SUCCESS,
    LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST,
    LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_FAIL,
    LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_SUCCESS,
    RESIZE_PMOL_ON_PROJECT,
    RESIZE_PMOL_ON_PROJECT_FAIL,
    RESIZE_PMOL_ON_PROJECT_SUCCESS,
    SAVE_PROJECT_DAY_PLANNING_STATE_ATTR,
    UPDATE_PLAN_BOARDS_PMOL_STATUS,
    UPDATE_PLAN_BOARDS_PMOL_STATUS_FAIL,
    UPDATE_PLAN_BOARDS_PMOL_STATUS_SUCCESS,
    UPDATE_PLAN_BOARDS_PMOL_TITLE,
    UPDATE_PLAN_BOARDS_PMOL_TITLE_FAIL,
    UPDATE_PLAN_BOARDS_PMOL_TITLE_SUCCESS,
} from '../actionTypes/projectDayPlanningActionTypes';
import {dataAmmo} from '../components/projectDayPlanning/listPane/topListPane/data';
import {
    ADD_MULTIPLE_MEMBERS_TO_PMOL_EP,
    ASSIGN_PMOL_TO_PROJECT_DAY_PLANNING_EP,
    ASSIGN_TEAM_TO_PMOL_DAY_PLANNING_EP,
    ASSIGN_TEAM_TO_PROJECT_DAY_PLANNING_EP,
    ASSIGN_VEHICLE_TO_PMOL_DAY_PLANNING_EP,
    ASSIGN_VEHICLE_TO_PROJECT_DAY_PLANNING_EP,
    CLONE_PMOL_DAY_PLANNING_EP,
    DELETE_LABOUR_PROJECT_DAY_PLANNING_EP,
    GENERATE_CIAW_EP,
    GENERATE_RFQ_EP,
    GET_BU_PERSON_WITH_COMPETENCE_EP,
    GET_HTML_PRINT_DATA_EP,
    GET_ORG_TEAMS_TAXONOMY_EP,
    LOAD_BU_DROPDOWN_DATA_EP,
    PLAN_BOARDS_PMOL_STATUS_TITLE_EP,
    PLAN_BOARDS_PMOL_STATUS_UPDATE_EP,
    READ_PROJECT_DAY_PLANNING_PMOL_LIST_EP,
    READ_PROJECT_DAY_PLANNING_PROJECT_LIST_EP,
    READ_PROJECT_DAY_PLANNING_RESOURCE_LIST_EP,
    READ_PROJECT_DAY_PLANNING_SHORTCUT_PANE_EP,
    RESIZE_PMOL_ON_PROJECT_DAY_PLANNING_EP,
} from '../shared/endpoints';
import {DayPlanningFilter, DpViewMode, PPBFilterTypes, ProjectPlanResourceType} from '../types/myDayPlanning';


const defaultState: any = {
    isNew: false,
    isChange: false,
    isEdit: false,
    showProgressBar: false,
    uid: null,
    loadMsg: '',
    reloadListPane: false,
    selectedDate: new Date(),
    shortCutPaneData: [],
    horizontalReSizer: screen.height - 330,
    draggedEvent: null,
    projectList: [],
    pmolList: [],
    isProjectDayPlanListLoaded: false,
    selectedPmol: null,
    selectedCu: {key: null, sequenceCode: null},
    selectedBu: null,    //NOTE: assign BU key
    collapseBookmarkPane: false,
    selectedResourceType: ProjectPlanResourceType.TEAM,
    resourceList: {},
    isResourceDataLoaded: false,
    projectDpViewMode: DpViewMode.TwoHour,
    buDropDown: [],
    isCIAWgenerated: false,
    generatedCIAW: [],
    loadMsgRfq: '',
    isConfirmDeleteModalOpen: true,
    selectedWorker: null,
    orgTeamsTaxonomy: [],
    personCompetenceList: [],
    isNewProjectModalOpen: false,
    isPmolTimeChangeModelOpen: false,
    selectedPmolData: null,
    isOpenTitlePopup: false,
    selectedPmolToRenamed: null,
    ppbProjectSequenceCode: null,
    ppbPmolTypeId: null,
    isShortCutPaneCollapse: true,
    isPrintModelOpen: false,
    ppbSiteManagerId: null,
    htmlPrintData: null,
    buList: [],
    projectBu: null,
    myEnvBu: null,
    reloadBottomListPane: false,
    isPmolTimeUpdated: false,
    isAddMultipleMembersSuccess: false,
    isDeleteLabourSuccess: false,
    isAssignTeamToPmolSuccess: false,
};

export default function projectDayPlanningReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {

            case SAVE_PROJECT_DAY_PLANNING_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };

            case LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE:
                return {...state, shortCutPaneData: []};
            case LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_SUCCESS:
                return {
                    ...state,
                    shortCutPaneData: action.payload.data.result
                };
            case LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_FAIL:
                return {...state, shortCutPaneData: []};

            case LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST:
                return {...state, showProgressBar: true};
            case LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_SUCCESS:
                return {
                    ...state,
                    isProjectDayPlanListLoaded: true,
                    projectList: formatProjectListResponse(action.payload.data.result?.projects),
                    pmolList: action.payload.data.result?.pmol,
                    //projectList: formatProjectListResponse(projectList),
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                    showProgressBar: false
                };
            case LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_FAIL:
                return {...state, projectList: []};

            case LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST:
                return {...state, pmolList: []};
            case LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_SUCCESS:
                return {
                    ...state,
                    isProjectDayPlanListLoaded: true,
                    //pmolList: formatProjectListResponse(action.payload.data.result),
                    pmolList: [],
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                    showProgressBar: false
                };
            case LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_FAIL:
                return {...state, pmolList: []};

            case LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST:
                return {...state, resourceList: {}, isResourceDataLoaded: false};
            case LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_SUCCESS:
                return {
                    ...state,
                    isResourceDataLoaded: true,
                    resourceList: formatResourceListResponse(action.payload.data.result),
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                    showProgressBar: false
                };
            case LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_FAIL:
                return {...state, resourceList: {}, isResourceDataLoaded: true};

            case ASSIGN_PMOL_TO_PROJECT:
                return {...state, reloadListPane: false, showProgressBar: true};
            case ASSIGN_PMOL_TO_PROJECT_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                    showProgressBar: false
                };
            case ASSIGN_PMOL_TO_PROJECT_FAIL:
                return {...state, showProgressBar: false};

            case RESIZE_PMOL_ON_PROJECT:
                return {
                    ...state,
                    isPmolTimeUpdated: false,
                };
            case RESIZE_PMOL_ON_PROJECT_SUCCESS:
                return {
                    ...state,
                    isPmolTimeUpdated: true,
                };
            case RESIZE_PMOL_ON_PROJECT_FAIL:
                return {
                    ...state,
                    isPmolTimeUpdated: false,
                };

            case ASSIGN_TEAM_TO_PROJECT:
                return {...state, reloadListPane: false, showProgressBar: true};
            case ASSIGN_TEAM_TO_PROJECT_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                    showProgressBar: false
                };
            case ASSIGN_TEAM_TO_PROJECT_FAIL:
                return {...state, showProgressBar: false, reloadListPane: true,};

            case ASSIGN_TEAM_TO_PMOL:
                return {...state, reloadListPane: false, showProgressBar: true, isAssignTeamToPmolSuccess: false};
            case ASSIGN_TEAM_TO_PMOL_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                    showProgressBar: false,
                    isAssignTeamToPmolSuccess: true
                };
            case ASSIGN_TEAM_TO_PMOL_FAIL:
                return {...state, showProgressBar: false, reloadListPane: true, isAssignTeamToPmolSuccess: false};


            case ASSIGN_VEHICLE_TO_PROJECT:
                return {...state, reloadListPane: false, showProgressBar: true};
            case ASSIGN_VEHICLE_TO_PROJECT_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                    showProgressBar: false
                };
            case ASSIGN_VEHICLE_TO_PROJECT_FAIL:
                return {...state, showProgressBar: false, reloadListPane: true,};

            case ASSIGN_VEHICLE_TO_PMOL:
                return {...state, reloadListPane: false, showProgressBar: true};
            case ASSIGN_VEHICLE_TO_PMOL_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                    showProgressBar: false
                };
            case ASSIGN_VEHICLE_TO_PMOL_FAIL:
                return {...state, showProgressBar: false, reloadListPane: true,};

            case CLONE_PMOL_PROJECT_DAY_PLANNING:
                return {...state, reloadListPane: false, showProgressBar: true};
            case CLONE_PMOL_PROJECT_DAY_PLANNING_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                    showProgressBar: false
                };
            case CLONE_PMOL_PROJECT_DAY_PLANNING_FAIL:
                return {...state, showProgressBar: false, reloadListPane: true,};

            case LOAD_BU_DROPDOWN_DATA:
                return {
                    ...state,
                    buDropDown: [],
                    buList: [],
                };
            case LOAD_BU_DROPDOWN_DATA_SUCCESS:
                return {
                    ...state,
                    buDropDown: formattedPayloadToBuDropDown(action?.payload?.data?.result),
                    buList: formattedPayloadToBuList(action?.payload?.data?.result),
                    // selectedBu: action?.payload?.data?.result[0]?.key,
                    // selectedCu: formattedPayloadForCu(action?.payload?.data?.result[0]),
                };
            case LOAD_BU_DROPDOWN_DATA_FAIL:
                return {...state, buDropDown: [], selectedBu: null, buList: []};

            case GENERATE_RFQ:
                return {...state, showProgressBar: true, loadMsgRfq: ''};
            case GENERATE_RFQ_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isRfqGenerated: action.payload.data.status,
                    generatedRfqData: action.payload.data.result,
                    //loadMsgRfq: !action.payload.data.status ? action.payload.data.message : '',
                };
            case GENERATE_RFQ_FAIL:
                return {...state, showProgressBar: false, loadMsgRfq: action.error.response.data.message,};

            case GENERATE_CIAW:
                return {
                    ...state,
                    showProgressBar: true,
                    generatedCIAW: [],
                };
            case GENERATE_CIAW_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isCIAWgenerated: action.payload.data.status,
                    generatedCIAW: action.payload.data.result,
                };
            case GENERATE_CIAW_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isCIAWgenerated: action.payload?.data?.status,
                    generatedCIAW: [],
                };

            case DELETE_LABOUR_PROJECT_DAY_PLANNING:
                return {
                    ...state,
                    showProgressBar: true,
                    isDeleteLabourSuccess: false,
                };
            case DELETE_LABOUR_PROJECT_DAY_PLANNING_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isDeleteLabourSuccess: true,
                };
            case DELETE_LABOUR_PROJECT_DAY_PLANNING_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isDeleteLabourSuccess: false,
                };

            case UPDATE_PLAN_BOARDS_PMOL_STATUS:
                return {
                    ...state,
                    showProgressBar: true,
                };
            case UPDATE_PLAN_BOARDS_PMOL_STATUS_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                };
            case UPDATE_PLAN_BOARDS_PMOL_STATUS_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                };

            case GET_ORG_TEAMS_TAXONOMY:
                return {
                    ...state,
                    orgTeamsTaxonomy: []
                };
            case GET_ORG_TEAMS_TAXONOMY_SUCCESS:
                return {
                    ...state,
                    orgTeamsTaxonomy: action.payload.data.result
                };
            case GET_ORG_TEAMS_TAXONOMY_FAIL:
                return {
                    ...state,
                    orgTeamsTaxonomy: []
                };

            case GET_BU_PERSON_WITH_COMPETENCE:
                return {
                    ...state,
                    personCompetenceList: [],
                    showProgressBar: true
                };
            case GET_BU_PERSON_WITH_COMPETENCE_SUCCESS:
                return {
                    ...state,
                    personCompetenceList: formatWorkersCompetenceModalResponse(action.payload.data.result), //dataAmmo,
                    showProgressBar: false
                };
            case GET_BU_PERSON_WITH_COMPETENCE_FAIL:
                return {
                    ...state,
                    personCompetenceList: [],
                    showProgressBar: false
                };

            case ADD_MULTIPLE_MEMBERS_TO_PMOL:
                return {
                    ...state,
                    showProgressBar: true,
                    isAddMultipleMembersSuccess: false
                };
            case ADD_MULTIPLE_MEMBERS_TO_PMOL_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isAddMultipleMembersSuccess: true
                };
            case ADD_MULTIPLE_MEMBERS_TO_PMOL_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isAddMultipleMembersSuccess: false
                };

            case GET_VEHICLE_WITH_RESOURCE:
                return {
                    ...state,
                    showProgressBar: true,
                    personCompetenceList: []
                };
            case GET_VEHICLE_WITH_RESOURCE_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    personCompetenceList: formatWorkersCompetenceModalResponse(dataAmmo),//action.payload.data.result, //dataAmmo,
                };
            case GET_VEHICLE_WITH_RESOURCE_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    personCompetenceList: []
                };

            case GET_HTML_PRINT_DATA:
                return {
                    ...state,
                    showProgressBar: true,
                    htmlPrintData: null
                };
            case GET_HTML_PRINT_DATA_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    htmlPrintData: action.payload.data.result
                };
            case GET_HTML_PRINT_DATA_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    htmlPrintData: null
                };

            case GET_TOOL_WITH_RESOURCE:
                return {
                    ...state,
                    showProgressBar: true,
                    personCompetenceList: []
                };
            case GET_TOOL_WITH_RESOURCE_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    personCompetenceList: formatWorkersCompetenceModalResponse(dataAmmo),//action.payload.data.result, //dataAmmo,
                };
            case GET_TOOL_WITH_RESOURCE_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    personCompetenceList: []
                };

            default:
                return state;
        }
    }
}


export function saveProjectDayPlanningStateAttr(attr: string, value: any) {
    return {
        type: SAVE_PROJECT_DAY_PLANNING_STATE_ATTR,
        [attr]: value
    };
}


export const filterProjectDayPlanningShortCutPaneData = (filter: DayPlanningFilter) => {
    return {
        types: [
            LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE,
            LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_SUCCESS,
            LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_PROJECT_DAY_PLANNING_SHORTCUT_PANE_EP,
                data: filter
            }
        }
    };
};

export const filterProjectDayPlanningProjectList = (filter: PPBFilterTypes) => {
    return {
        types: [
            LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST,
            LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_SUCCESS,
            LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_PROJECT_DAY_PLANNING_PROJECT_LIST_EP,
                data: filter
            }
        }
    };
};


export const filterProjectDayPlanningPmolList = (filter: any) => {
    return {
        types: [
            LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST,
            LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_SUCCESS,
            LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_PROJECT_DAY_PLANNING_PMOL_LIST_EP,
                data: filter
            }
        }
    };
};

export const getProjectDayPlanningResourceList = (filter: any) => {
    return {
        types: [
            LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST,
            LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_SUCCESS,
            LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_PROJECT_DAY_PLANNING_RESOURCE_LIST_EP,
                data: filter
            }
        }
    };
};


export const assignPmolToProject = (data: any) => {
    return {
        types: [
            ASSIGN_PMOL_TO_PROJECT,
            ASSIGN_PMOL_TO_PROJECT_SUCCESS,
            ASSIGN_PMOL_TO_PROJECT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_PMOL_TO_PROJECT_DAY_PLANNING_EP,
                data: data
            }
        }
    };
};

export const assignTeamToProject = (data: any) => {
    return {
        types: [
            ASSIGN_TEAM_TO_PROJECT,
            ASSIGN_TEAM_TO_PROJECT_SUCCESS,
            ASSIGN_TEAM_TO_PROJECT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_TEAM_TO_PROJECT_DAY_PLANNING_EP,
                data: data
            }
        }
    };
};
export const assignTeamToPmol = (data: any) => {
    return {
        types: [
            ASSIGN_TEAM_TO_PMOL,
            ASSIGN_TEAM_TO_PMOL_SUCCESS,
            ASSIGN_TEAM_TO_PMOL_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_TEAM_TO_PMOL_DAY_PLANNING_EP,
                data: data
            }
        }
    };
};

export const assignVehicleToProject = (data: any) => {
    return {
        types: [
            ASSIGN_VEHICLE_TO_PROJECT,
            ASSIGN_VEHICLE_TO_PROJECT_SUCCESS,
            ASSIGN_VEHICLE_TO_PROJECT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_VEHICLE_TO_PROJECT_DAY_PLANNING_EP,
                data: data
            }
        }
    };
};
export const assignVehicleToPmol = (data: any) => {
    return {
        types: [
            ASSIGN_VEHICLE_TO_PMOL,
            ASSIGN_VEHICLE_TO_PMOL_SUCCESS,
            ASSIGN_VEHICLE_TO_PMOL_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_VEHICLE_TO_PMOL_DAY_PLANNING_EP,
                data: data
            }
        }
    };
};

export const resizePmolOnProject = (data: any) => {
    return {
        types: [
            RESIZE_PMOL_ON_PROJECT,
            RESIZE_PMOL_ON_PROJECT_SUCCESS,
            RESIZE_PMOL_ON_PROJECT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: RESIZE_PMOL_ON_PROJECT_DAY_PLANNING_EP,
                data: data
            }
        }
    };
};

export const clonePmolDayPlanning = (data: any) => {
    return {
        types: [
            CLONE_PMOL_PROJECT_DAY_PLANNING,
            CLONE_PMOL_PROJECT_DAY_PLANNING_SUCCESS,
            CLONE_PMOL_PROJECT_DAY_PLANNING_FAIL
        ],
        payload: {
            request: {
                url: CLONE_PMOL_DAY_PLANNING_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const getBuDropdownData = (data: any) => {
    return {
        types: [
            LOAD_BU_DROPDOWN_DATA,
            LOAD_BU_DROPDOWN_DATA_SUCCESS,
            LOAD_BU_DROPDOWN_DATA_FAIL
        ],
        payload: {
            request: {
                url: LOAD_BU_DROPDOWN_DATA_EP,
                method: 'POST',
                data: data
            }
        }
    };
};


export const generateRFQ = (data: any) => {
    return {
        types: [
            GENERATE_RFQ,
            GENERATE_RFQ_SUCCESS,
            GENERATE_RFQ_FAIL
        ],
        payload: {
            request: {
                url: GENERATE_RFQ_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const generateCIAW = (data: any) => {
    return {
        types: [
            GENERATE_CIAW,
            GENERATE_CIAW_SUCCESS,
            GENERATE_CIAW_FAIL
        ],
        payload: {
            request: {
                url: GENERATE_CIAW_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const removeLabourPdp = (data: any) => {
    return {
        types: [
            DELETE_LABOUR_PROJECT_DAY_PLANNING,
            DELETE_LABOUR_PROJECT_DAY_PLANNING_SUCCESS,
            DELETE_LABOUR_PROJECT_DAY_PLANNING_FAIL
        ],
        payload: {
            request: {
                url: DELETE_LABOUR_PROJECT_DAY_PLANNING_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const updatePlanBoardsPmolStatus = (data: any) => {
    return {
        types: [
            UPDATE_PLAN_BOARDS_PMOL_STATUS,
            UPDATE_PLAN_BOARDS_PMOL_STATUS_SUCCESS,
            UPDATE_PLAN_BOARDS_PMOL_STATUS_FAIL
        ],
        payload: {
            request: {
                url: PLAN_BOARDS_PMOL_STATUS_UPDATE_EP,
                method: 'POST',
                data: data
            }
        }
    };
};
export const getOrgTeamTaxonomyList = (data: any) => {
    return {
        types: [
            GET_ORG_TEAMS_TAXONOMY,
            GET_ORG_TEAMS_TAXONOMY_SUCCESS,
            GET_ORG_TEAMS_TAXONOMY_FAIL
        ],
        payload: {
            request: {
                url: GET_ORG_TEAMS_TAXONOMY_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const getBuPersonWithCompetence = (data: any) => {
    return {
        types: [
            GET_BU_PERSON_WITH_COMPETENCE,
            GET_BU_PERSON_WITH_COMPETENCE_SUCCESS,
            GET_BU_PERSON_WITH_COMPETENCE_FAIL
        ],
        payload: {
            request: {
                url: GET_BU_PERSON_WITH_COMPETENCE_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const addMultipleMembersToPmol = (data: any) => {
    return {
        types: [
            ADD_MULTIPLE_MEMBERS_TO_PMOL,
            ADD_MULTIPLE_MEMBERS_TO_PMOL_SUCCESS,
            ADD_MULTIPLE_MEMBERS_TO_PMOL_FAIL
        ],
        payload: {
            request: {
                url: ADD_MULTIPLE_MEMBERS_TO_PMOL_EP,
                method: 'POST',
                data: data
            }
        }
    };
};


export const updatePlanBoardsPmolTitle = (data: any) => {
    return {
        types: [
            UPDATE_PLAN_BOARDS_PMOL_TITLE,
            UPDATE_PLAN_BOARDS_PMOL_TITLE_SUCCESS,
            UPDATE_PLAN_BOARDS_PMOL_TITLE_FAIL
        ],
        payload: {
            request: {
                url: PLAN_BOARDS_PMOL_STATUS_TITLE_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const getVehicleWithResource = (data: any) => {
    return {
        types: [
            GET_VEHICLE_WITH_RESOURCE,
            GET_VEHICLE_WITH_RESOURCE_SUCCESS,
            GET_VEHICLE_WITH_RESOURCE_FAIL
        ],
        payload: {
            request: {
                url: GET_BU_PERSON_WITH_COMPETENCE_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const getHtmlPrintData = (data: any) => {
    return {
        types: [
            GET_HTML_PRINT_DATA,
            GET_HTML_PRINT_DATA_SUCCESS,
            GET_HTML_PRINT_DATA_FAIL
        ],
        payload: {
            request: {
                url: GET_HTML_PRINT_DATA_EP,
                method: 'POST',
                data: data
            }
        }
    };
}

export const getToolsWithResource = (data: any) => {
    return {
        types: [
            GET_TOOL_WITH_RESOURCE,
            GET_TOOL_WITH_RESOURCE_SUCCESS,
            GET_TOOL_WITH_RESOURCE_FAIL
        ],
        payload: {
            request: {
                url: GET_BU_PERSON_WITH_COMPETENCE_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

const formatProjectListResponse = (projectList: any) => {
    // let name = ["Pradeep Madusanka","Shanuka Gayashan","Wasana Madushani","Supun costha"];
    if (projectList && projectList.length > 0) {
        return projectList.map((project: any) => {
            // let random = Math.floor(Math.random() * name.length);
            return {
                ...project,
                resourceId: project?.sequenceCode,
                resourceTitle: project?.title,
                color: "#fafafa",
                //color:projectUniqueColor(project?.sequenceCode),
                siteLeader: project?.siteManager?.name
            }
        })
    } else {
        return []
    }
};

const formatResourceListResponse = (resourceList: any) => {

    if (resourceList && resourceList.length > 0 && resourceList[0]) {
        return {
            workers: resourceList[0]?.workers,
            vehicle: resourceList[0]?.vehicle,
            tools: resourceList[0]?.tool,
        }
    } else {
        return {
            workers: [],
            vehicle: [],
            tools: [],
        }
    }
};

const formatWorkersCompetenceModalResponse = (list: any) => {
    const arr = list.team.map((item: any) => {
        if (_.isEmpty(item.competenceList)) {
            return null
        } else {
            return item;
        }
    });

    let team: any[] = [];
    arr.forEach((e: any) => {
        if (e !== null) {
            team.push(e);
        }
    });

    return {team};
};

const formattedPayloadToBuDropDown = (response: any) => {
    if (response) {
        return response.map((item: any) => {
            return {...item, key: item.key, text: item.text}
        });
    }

    return [];
};

const formattedPayloadToBuList = (response: any) => {
    if (response) {
        return response.map((item: any) => {
            return {key: item.buSequenceId, text: item.text}
        });
    }
    return [];
};

const formattedPayloadForCu = (contractingUnit: any) => {
    if(contractingUnit){
        return {key: contractingUnit?.contractingUnitId, sequenceCode: contractingUnit?.contractingUnit};
    }

    return {key: null, sequenceCode: null};
}

const ssData = [
    {
        "project": "P0042 - Dil pro",
        "pbs": [
            {
                "id": "ac028598-47b8-4c30-89c6-5bc9d7822f26",
                "name": "CAr-5",
                "title": "PBS-6761 - CAr-5",
                "pmol": [
                    {
                        "id": "ac028598-47b8-4c30-89c6-5bc9d7822f26",
                        "projectMoleculeId": "PMOL-6761",
                        "name": "Labor-5",
                        "typeId": "848e5e-622d-4783-95e6-4092004eb5eaff",
                        "statusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
                        "foremanMobileNumber": null,
                        "executionDate": null,
                        "foremanId": null,
                        "comment": null,
                        "isDeleted": false,
                        "borId": "27b09b73-4e77-4b16-b930-5a1e51c4077d",
                        "bor": null,
                        "title": "PMOL-6761 - Labor-5",
                        "locationId": "522e2ce8-9baf-4b7b-ac96-5fe2e91a0597",
                        "startDateTime": null,
                        "endDateTime": null,
                        "isFinished": false,
                        "isBreak": true,
                        "pmolType": "regular",
                        "productId": "3174feda-77eb-412b-aa35-d560edf60b33",
                        "projectSequenceCode": "P0042",
                        "parentId": null,
                        "executionStartTime": "05:00",
                        "executionEndTime": "14:00",
                        "productName": "Labor-5",
                        "contractingUinit": "COM-0001"
                    }
                ]
            }
        ]
    },
    {
        "project": "P0138 - My Env Test",
        "pbs": [
            {
                "id": "ac028598-47b8-4c30-89c6-5am837822f26",
                "name": "CAr-1",
                "title": "PBS-2469 - CAr-1",
                "pmol": [
                    {
                        "id": "b5c304a8-2d3c-4125-95ac-c1c138709c63",
                        "projectMoleculeId": "PMOL-6695",
                        "name": "hello",
                        "typeId": "3f8ce-f268-4ce3-9f12-fa6b3adad2cf9d1",
                        "statusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
                        "foremanMobileNumber": null,
                        "executionDate": null,
                        "foremanId": null,
                        "comment": null,
                        "isDeleted": false,
                        "borId": "681656a9-305b-45c3-9412-ca7b759a8e80",
                        "bor": null,
                        "title": "PMOL-6695 - hello",
                        "locationId": "9b7275d4-4e4e-4842-8021-3192d7d5fee1",
                        "startDateTime": null,
                        "endDateTime": null,
                        "isFinished": false,
                        "isBreak": true,
                        "pmolType": "regular",
                        "productId": "39211759-18b3-4bc1-9362-9712bac8540c",
                        "projectSequenceCode": "P0138",
                        "parentId": null,
                        "executionStartTime": "05.00",
                        "executionEndTime": "14.00",
                        "productName": "DENMARK",
                        "contractingUinit": "COM-0001"
                    },
                    {
                        "id": "4d9a3eab-1688-46f9-8e45-a4a78b42f688",
                        "projectMoleculeId": "PMOL-6696",
                        "name": "Hello PMOL",
                        "typeId": "3f8ce-f268-4ce3-9f12-fa6b3adad2cf9d1",
                        "statusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
                        "foremanMobileNumber": null,
                        "executionDate": null,
                        "foremanId": null,
                        "comment": null,
                        "isDeleted": false,
                        "borId": "681656a9-305b-45c3-9412-ca7b759a8e80",
                        "bor": null,
                        "title": "PMOL-6696 - Hello PMOL",
                        "locationId": "b1ab3b99-5ddd-4839-8729-f95c44e4277b",
                        "startDateTime": null,
                        "endDateTime": null,
                        "isFinished": false,
                        "isBreak": true,
                        "pmolType": "regular",
                        "productId": "39211759-18b3-4bc1-9362-9712bac8540c",
                        "projectSequenceCode": "P0138",
                        "parentId": null,
                        "executionStartTime": "05:00",
                        "executionEndTime": "14:00",
                        "productName": "DENMARK",
                        "contractingUinit": "COM-0001"
                    },
                    {
                        "id": "6f78f5a1-9688-49b5-a033-11312cac189d",
                        "projectMoleculeId": "PMOL-5554",
                        "name": "My Env Test Project",
                        "typeId": "5bb656-f708-4a0d-9973-3d834ffe757d01",
                        "statusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
                        "foremanMobileNumber": null,
                        "executionDate": null,
                        "foremanId": null,
                        "comment": null,
                        "isDeleted": false,
                        "borId": "30e6dcbe-ff9b-4478-abf6-263232260d9c",
                        "bor": null,
                        "title": "PMOL-5554 - My Env Test Project",
                        "locationId": "59d10ee3-6ad7-4927-b615-13150c4fd09e",
                        "startDateTime": null,
                        "endDateTime": null,
                        "isFinished": false,
                        "isBreak": true,
                        "pmolType": "regular",
                        "productId": "dbb86ed1-402a-4588-8e71-e6abf503a5c1",
                        "projectSequenceCode": "P0138",
                        "parentId": "68c180b8-2be7-44f1-b081-9e4a7037a6e2",
                        "executionStartTime": "05.00",
                        "executionEndTime": "14.00",
                        "productName": "My Env Test Project",
                        "contractingUinit": "COM-0001"
                    }
                ]
            }
        ]
    }
]

