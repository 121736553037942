import * as React from 'react';
import {
  ContextualMenu,
  FontWeights, getTheme,
  IconButton,
  IDragOptions,
  IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Label, Stack,
  mergeStyleSets,
  Modal, TextField, PrimaryButton,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../theme';
const theme = getTheme();
import i18n from '../../../../i18n';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import client from '../../../api';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectBreakdown } from '../../../types/projectBreakdownStructure';
import { saveCbcToPbs } from '../../../reducers/projectBreakdownReducer';
import { readCIAWById, sendCIAW } from '../../../reducers/ciawReducer';

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '32vw',
    minHeight: screen.width > 1280?'42vh':'60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const CbcModal = (props:{isOpen:boolean,openModal:any,contractor:any,projectBreakdown:ProjectBreakdown,onSaveSuccess:any,formData:any}) => {
  const dispatch = useDispatch();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [cbc, setCbc]: any = React.useState<any>(null);
  const [quantityValidationMsg, setQuantityValidationMsg]: any = React.useState<any>("");
  const [quantity, setQuantity]: any = React.useState<any>('0');
  const [cbcQuantity, setCbcQuantity]: any = React.useState<any>('0');
  const [disableSave, setDisableSave] = useState(true);
  const [focus, setFocus] = useState(1);


    useEffect(() => {
         if(props?.formData?.id){
           setCbc({value:props?.formData?.articleNo,label:props?.formData?.title})
           setQuantity(props?.formData?.quantity)
         }else{
           setCbc(null)
           setQuantity('0')
           setCbcQuantity('0')
         }
      }, [props?.formData]);

  const onModalClose = () => {
    props.openModal();
  }

    useEffect(() => {
       if(cbc && quantity){
         setDisableSave(false)
       }else{
         setDisableSave(true)
       }
    }, [cbc,quantity]);

  const saveForm= async ()=>{
     let data={
       id:props?.formData?.id?props?.formData?.id:null,
       articleNo:cbc?.value,
       quantity:quantity,
       pbsId:props?.projectBreakdown.id,
       lotId:props?.contractor.text
     }
     dispatch(saveCbcToPbs(data))
  }

  const onSaveCbc=()=>{
    Promise.all([
      saveForm()
    ]).then(() => {
      setCbc(null);
      setQuantity('0');
      props?.onSaveSuccess()
      onModalClose();
    });
  }
  const onSaveAndNewCbc=()=>{
    setFocus(0);
    Promise.all([
      saveForm()
    ]).then(() => {
      props?.onSaveSuccess()
      setCbc(null);
      setQuantity('0');
      setFocus(1);
    });
  }

  const cbcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      // test();
      resolve(getProductByName(inputValue));
      // }, 1000);
    });

  const getProductByName = async (name: string) => {
    const filter = { title: name,lotId:props?.contractor?.key };
    if (name && name.length >= 2) {
      const response = await client.post('/Contractor/GetAwardedContractorLotData', filter);
      return formatProductResponse(response);
    } else if (name === '' || name === null) {
      const response = await client.post('/Contractor/GetAwardedContractorLotData', { title: null,lotId:props?.contractor?.key });
      return formatProductResponse(response);
    }
  };

  const formatProductResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const titleNotEmptyList = response.data.result.filter((item: { text: string | null; }) => item.text !== null);
        const data = titleNotEmptyList.map((item: any) => {
          return {
            ...item,
            value: item.articleNo,
            label: item.title,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

 return (
  <>
    <Modal
      titleAriaId={titleId}
      isOpen={props.isOpen}
      onDismiss={() => {
        onModalClose();
      }}
      scrollableContentClassName="body"
      isBlocking={true}
      dragOptions={isDraggable ? dragOptions : undefined}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{i18n.t("cbc")}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={() => {
            onModalClose();
          }}
        />
      </div>
      <div className={contentStyles.body}>
          <div>
            <div>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div style={{width:'100%'}}>
                    <Label style={{ marginTop: 10 }}>{ i18n.t('cbc')}</Label>
                    {focus && <CustomDropdown
                      reference={focus}
                      onChange={(value: string, label: string,values:any) => {
                        if (value) {
                          setCbc({value,label,values})
                          setQuantity(values?.quantity)
                          setCbcQuantity(values?.quantity)
                        } else {
                          setCbc(null)
                          setQuantity("0")
                          setCbcQuantity("0")
                        }
                      }}
                      //to load risk data into modal
                      selectedOption={(cbc)}
                      promiseOptions={cbcOptions}
                      validationMessage={""}
                    />}
                  </div>

                </Stack.Item>
              </Stack>
            </div>
            <div>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div style={{width:'100%'}}>
                    <TextField
                      label={i18n.t('cbcQuantity')}
                      value={cbcQuantity
                        ? cbcQuantity.toString()
                        : ''
                      }
                      errorMessage={quantityValidationMsg}
                      type={'number'}
                      step={1}
                      min={0}
                      disabled={true}
                    />
                  </div>
                </Stack.Item> <Stack.Item grow={6} styles={stackItemStyles}>
                  <div style={{width:'100%'}}>
                    <TextField
                      label={i18n.t('quantity')}
                      value={quantity
                        ? quantity.toString()
                        : ''
                      }
                      errorMessage={quantityValidationMsg}
                      type={'number'}
                      step={1}
                      min={0}
                      onChange={(event, newValue)=>{
                        setQuantity(newValue)
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>
          </div>
      </div>
      <div className={contentStyles.footer}>
        <PrimaryButton
          iconProps={addIconWhite}
          text={ i18n.t('save')}
          disabled={disableSave}
          style={{ marginTop: 20, marginBottom: 30,marginRight:20 }}
          onClick={() => {
            onSaveCbc()
          }}
        />

        <PrimaryButton
          iconProps={addIconWhite}
          text={ i18n.t('saveAndNew')}
          disabled={disableSave}
          style={{ marginTop: 20, marginBottom: 30 }}
          onClick={() => {
            onSaveAndNewCbc();
          }}
        />
      </div>
    </Modal>
  </>
 );

};

export default CbcModal
