import * as React from 'react';
import { Label, Link } from '@fluentui/react';
import ImagePreview from '../../../shared/fileUploader/ImagePreview';
import { useState } from 'react';

const PmolCard = (props:{pmol:any}) => {


  const formattedDocs = (result: any) => {
    if (result) {
      let data = result.map((item: string) => {
        return {
          image: item,
          id: item,
          isLast: true,
        };
      });
      return data;
    } else {
      return [];
    }
  };

  const [selectedFiles, setFiles]: any = useState(formattedDocs(props?.pmol?.docLinks));
 return (
  <>
    <div className="document-pane-card">
      <div className={'card-header'}>
        <Link href="#" id="deliverables">
          <Label> {props?.pmol?.title} </Label>
        </Link>
      </div>
      <div className={'card-body'}>
       <div style={{paddingBottom:30}}>
         <ImagePreview
           transition={false}
           uploadedImages={selectedFiles}
           handleImageDelete={(id: string) => {

           }}
           disable={true}
           isDelete={false}
         />
       </div>
      </div>
    </div>
  </>
 );

};

export default PmolCard
