import React from 'react';
import {AcceptType} from "../../../types/myDayPlanning";
import EventBody from "../../projectDayPlanning/listPane/eventBody";
import * as TdDropBox from "../../../shared/dnd/dropBox";
import {ICommandBarItemProps} from "@fluentui/react";
import {PMOL_ACTIVITY_TYPES} from "../../../types/projectMolecule";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clonePmolDayPlanning, saveMyDayPlanningStateAttr} from "../../../reducers/myDayPlanningReducer";
import {saveStateAttr} from "../../../reducers/uprinceReducer";
import {updatePlanBoardsPmolStatus} from "../../../reducers/projectDayPlanningReducer";

function CustomEvent({event}: any) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const selectedDate = useSelector((state: any) => state?.mydPlan?.selectedDate);

    const openPmol = (pmol: any) => {
        dispatch(saveMyDayPlanningStateAttr('selectedMyDpPmol', pmol));
        dispatch(saveStateAttr('contractingUinit', pmol?.contractingUinit));
        dispatch(saveStateAttr('projectSequenceCode', pmol?.projectSequenceCode));
    };

    const createPmol = (pmol: any, type: string) => {
        if (pmol?.projectMoleculeId) {
            let data = {
                ...pmol,
                pmolId: pmol?.projectMoleculeId,
                typeId: type
            };

            Promise.all([
                dispatch(clonePmolDayPlanning(data))
            ]).then(() => {
                //TODO:  reloadListPaneItem();
            });
        }
    };

    const copyPmol = (pmol: any) => {
        dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {
            event: pmol,
            isCopyModalOpen: true
        }));
    };

    const handlePmolStatusChange = (data: any) => {
        Promise.all([
            dispatch(updatePlanBoardsPmolStatus(data))
        ]).then(() => {
            //TODO:  reloadListPaneItem();
        });
    };

    const _overflowItems: ICommandBarItemProps[] = [
        {key: 'open', text: t('open'), onClick: () => openPmol(event), iconProps: {iconName: 'MoveToFolder'}},
        {
            key: 'create',
            text: t('createTransportPMOL'),
            onClick: () => createPmol(event, PMOL_ACTIVITY_TYPES.TRAVEL),
            iconProps: {iconName: 'Add'}
        },
        {key: 'copy', text: t('copy'), onClick: () => copyPmol(event), iconProps: {iconName: 'Copy'}},
    ];

    return <TdDropBox.DropBox
        item={event}
        day={selectedDate}
        type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
        isPmol={true}
    >
        <EventBody pmol={event} overflowItems={_overflowItems} isDp={true}
                   handlePmolStatusChange={(data: any) => handlePmolStatusChange(data)}/>
    </TdDropBox.DropBox>
}

export default CustomEvent;