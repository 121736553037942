import * as React from 'react';
import DataGrid from '../../../shared/dataGrid/component';
import { RESOURCE_TYPE } from '../../../types/purchaseOrder';
import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Selection,
  Stack,
} from '@fluentui/react';
import { saveCIAWStateAttr } from '../../../reducers/ciawReducer';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import UprinceLogger from '../../../shared/Logger/logger';
import i18n from '../../../../i18n';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import {
  readCbcContractor,
  readCbcToPbs,
  readRiskById,
  removeCbcToPbs,
} from '../../../reducers/projectBreakdownReducer';
import client from '../../../api';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../theme';
import CbcModal from './CbcModal';
import { isPlanning } from '../../../shared/util';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import { store } from '../../../../index';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectBreakdown } from '../../../types/projectBreakdownStructure';

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const classNames = mergeStyleSets({
  halfWidth: { width: '50%' }
});
const CbcGrid = (props:{
  readOnly:boolean,
  projectBreakdown:ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (
    projectBreakdown: ProjectBreakdown,
    save: boolean
  ) => void;}) => {
  const [selectionDetails, setSelectionDetails]: any = React.useState<any>(null);
  const [contractor, setContractor]: any = React.useState<any>(null);
  const [isOpenModal, setIsOpenModal]: any = React.useState<any>(false);
  const [cbcLoading, setCbcLoading]: any = React.useState<any>(false);
  const dispatch = useDispatch();

  const {pbsCbcList,reFetchCbc,isLoadingCbc}: any = useSelector(
    (state: any) => state.projectBreakdown
  );
  const cbcContractors=store.getState().projectBreakdown.cbcContractor?store.getState().projectBreakdown.cbcContractor:[];

    useEffect(() => {
      dispatch(readCbcContractor())
    }, []);


    useEffect(() => {
      console.log({reFetchCbc});
      if(reFetchCbc){
        getPbsCbsList();
      }
    }, [reFetchCbc]);

    useEffect(() => {
      if(props?.projectBreakdown?.id){
        getPbsCbsList();
      }
      if(props?.projectBreakdown?.pbsLotId){
        setContractor({key:props?.projectBreakdown?.pbsLotId,text:props?.projectBreakdown?.pbsLotId});
      }else{
        setContractor(null);
      }
    }, [props?.projectBreakdown?.id]);

 const openCbcModal=()=>{
     setIsOpenModal(!isOpenModal);
 }
 const handleCbcDelete=()=>{
    let ids=selectionDetails?.map((item:any)=>{
      return item?.id
    })
   dispatch(removeCbcToPbs(ids))
   setCbcLoading(true);
   setTimeout(()=>{
     // getPbsCbsList();
   },4000)
 }

  const _gridSelection = new Selection({
    onSelectionChanged: () => {
      setSelectionDetails(_gridSelection.getSelection());

    }
  });

 const getPbsCbsList=()=>{
   dispatch(readCbcToPbs(props?.projectBreakdown?.id))
   setCbcLoading(false);
 }

 const cbcActionButtonState=()=> {
    const selectionCount = selectionDetails?.length;
    let disabled: ActionButtonState;
    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    console.log('_vendorSelection', disabled,selectionCount);
    return disabled;
  }

  const cbcColumns = [
    {
      key: 'column1',
      name: i18n.t('cbc'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: i18n.t('originalQuantity'),
      fieldName: 'cbcQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: i18n.t('plannedQuantity'),
      fieldName: 'quantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column4',
      name: i18n.t('consolidatedConsumedQuantity'),
      fieldName: 'consumedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column5',
      name: i18n.t('invoiceQuantity'),
      fieldName: 'invoicedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column6',
      name: i18n.t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

 const onSaveSuccess=()=>{
   setCbcLoading(true);
   console.log("onSaveSuccess");
setTimeout(()=>{
  getPbsCbsList();
},4000)
 }
  return (
  <>
    <div className={'document-pane-card'}>
      <div className={'card-header'}>
        <Link href="#" id="cbc">
          <Label>4. {i18n.t('cbc')}</Label>
        </Link>
      </div>
      <div className={'card-body'}>

          <Label style={{ marginTop: 10 }}>{'4.1 ' + i18n.t('contractor')}</Label>
          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
            <Stack.Item grow={6} styles={stackItemStyles}>
              <div className={`${classNames.halfWidth}`}>
                <ClearableDropdown
                  required={false}
                  placeholder={i18n.t('contractor')}
                  label={''}
                  options={cbcContractors}
                  onChange={(event, value) => {
                    if (value) {
                      console.log({value});
                        setContractor(value)
                      props.projectBreakdown.pbsLotId=value?.key
                    } else {
                        setContractor(null)
                        props.projectBreakdown.pbsLotId=null
                    }
                    props.handleFormChange(
                      props.projectBreakdown,
                      true
                    );
                  }}

                  selectedKey={contractor ? contractor.key : ''}
                  disabled={props.readOnly}
                />

              </div>
            </Stack.Item>
            <Stack.Item grow={6} styles={stackItemStyles}>

            </Stack.Item>
          </Stack>
        <DataGrid
          dataList={pbsCbcList?pbsCbcList:[]}
          editDataGrid={() => openCbcModal()}
          openModel={() => {
            openCbcModal();
          }}
          selection={_gridSelection}
          actionButtonState={cbcActionButtonState()}
          title={'4.2 ' + i18n.t('cbc')}
          deleteDataGrid={() => {
            handleCbcDelete();
          }}
          readOnly={props.readOnly}
          columns={cbcColumns}
          isLoaded={cbcLoading || isLoadingCbc}
          type={RESOURCE_TYPE.CBC}
          isClickable={true}
        />
      </div>
    </div>
    <CbcModal formData={selectionDetails?selectionDetails[0]:{}} isOpen={isOpenModal} contractor={contractor} openModal={openCbcModal} projectBreakdown={props?.projectBreakdown} onSaveSuccess={onSaveSuccess}/>
  </>
 );

};

export default CbcGrid


{/*<CustomDropdown*/}
{/*  reference={""}*/}
{/*  onChange={(value: string, label: string) => {*/}
{/*    if (value) {*/}
{/*      setContractor({value,label})*/}
{/*    } else {*/}
{/*      setContractor(null)*/}
{/*    }*/}
{/*  }}*/}
{/*  //to load risk data into modal*/}
{/*  selectedOption={(contractor)}*/}
{/*  promiseOptions={contractorOptions}*/}
{/*  validationMessage={""}*/}
{/*/>*/}

// const contractorOptions = (inputValue: any) =>
//   new Promise((resolve) => {
//     // setTimeout(() => {
//     // test();
//     resolve(getProductByName(inputValue));
//     // }, 1000);
//   });

// const getProductByName = async (name: string) => {
//   const filter = { title: name };
//     if (name && name.length >= 2) {
//       const response = await client.post('/Contractor/GetAwardedLotInProject', filter);
//       return formatProductResponse(response);
//     } else if (name === '' || name === null) {
//       const response = await client.post('/Contractor/GetAwardedLotInProject', { title: null });
//       return formatProductResponse(response);
//     }
// };
//
// const formatProductResponse = (response: any) => {
//   if (response && response.data) {
//     if (response.data.result && _.isArray(response.data.result)) {
//       const titleNotEmptyList = response.data.result.filter((item: { text: string | null; }) => item.text !== null);
//       const data = titleNotEmptyList.map((item: any) => {
//         return {
//           value: item.key,
//           label: item.text,
//         };
//       });
//       return data;
//     }
//     return [];
//   }
//   return [];
// };
