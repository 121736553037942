import {
  ActionButton,
  DatePicker,
  Dropdown,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import ClearableDropdown from '../../../../shared/clearableDropdown/clearableDropdown';
import CustomDatePicker from '../../../../shared/customDatePicker/customDatePicker';
import { formatDateTimeToISOString } from '../../../../shared/util';
import { ProgressStatement, ProgressStatementValidationMessage } from '../../../../types/progressStatement';
import { PENDING_DEVELOPMENT_STATUS_ID } from '../../../../types/projectMolecule';
import { messageService } from '../../../../services/messageService';


const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const dropdownStyles = { dropdown: { width: '100%' } };

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const ProjectInformation = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  formData: ProgressStatement;
  saveFormData: () => void;
  handleFormChange: (
    progressStatement: ProgressStatement,
    save: boolean,
  ) => void;
  validationMessages: ProgressStatementValidationMessage;
  ledgerDropDowns: { key: string, text: string }[];
  setGeneralLedger: (id: any) => void;
  generalLedger: string | null
}) => {
  const { t } = useTranslation();
  // const [contractIsRequired, setcontractIsRequired] = useState(false);

  useEffect(() => {
    if (!props.formData.projectStatusId) {
      props.formData.projectStatusId = PENDING_DEVELOPMENT_STATUS_ID;
    }
  }, [props.formData]);

  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="ps-header">
          <Label>1. {t('projectInformation')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className="marginTop">
            {props.isEdit && <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('title')}
                    value={(props.formData.title) ? props.formData.title : ''}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('progressStatementID')}
                    value={(props.formData.progressStatementId) ? props.formData.progressStatementId : ''}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>}
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('name')}
                    value={
                      props.formData.name
                        ? props.formData.name
                        : ''
                    }
                    errorMessage={
                      props.validationMessages.nameErrorMsg
                        ? props.validationMessages.nameErrorMsg
                        : ''
                    }
                    required={true}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.name = value;
                        props.validationMessages.nameErrorMsg = '';
                      } else {
                        props.formData.name = null;
                      }
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    disabled={props.dropDownOptions.isApproved}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    disabled={props.dropDownOptions.isApproved}
                    placeholder={t('type')}
                    label={t('type')}
                    options={props.dropDownOptions.types}
                    required={true}
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {
                      let selectedPsType = null;
                      if (value) {
                        props.formData.projectTypeId = value.key.toString();
                        selectedPsType = value.key.toString();
                      } else {
                        selectedPsType = 'clear';
                        props.formData.projectTypeId = null;
                      }
                      props.validationMessages.typeErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                      messageService.sendMessage({
                        selectedPsType: selectedPsType,
                      });
                    }}
                    selectedKey={
                      props.formData.projectTypeId
                        ? props.formData.projectTypeId
                        : ''
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>


          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <DatePicker
                    label={t('projectCompletionDate')}
                    placeholder={t('selectadate')}
                    ariaLabel={t('projectCompletionDate')}
                    value={(props.dropDownOptions.projectCompletionDate) ? new Date(props.dropDownOptions.projectCompletionDate) : undefined}
                    disabled={true}
                  />

                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    disabled={true}
                    placeholder={t('status')}
                    label={t('status')}
                    options={props.dropDownOptions.status}
                    required={true}
                    errorMessage={
                      props.validationMessages.statusErrorMsg
                        ? props.validationMessages.statusErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.projectStatusId = value.key.toString();
                      } else {
                        props.formData.projectStatusId = null;
                      }
                      props.validationMessages.statusErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    selectedKey={
                      props.formData.projectStatusId
                        ? props.formData.projectStatusId
                        : PENDING_DEVELOPMENT_STATUS_ID
                    }

                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    disabled={props.dropDownOptions.isApproved}
                    setMinDate={null}
                    setMaxDate={null}
                    label={t('workPeriodfrom')}
                    setValue={(props.formData.workPeriodFrom) ? new Date(props.formData.workPeriodFrom) : null}
                    getValue={(date: Date) => {
                      props.formData.workPeriodFrom = formatDateTimeToISOString(date);
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    disabled={props.dropDownOptions.isApproved}
                    setMinDate={props.formData.workPeriodFrom ? new Date(props.formData.workPeriodFrom) : null}
                    setMaxDate={null}
                    label={t('workPeriodto')}
                    setValue={(props.formData.workPeriodTo) ? new Date(props.formData.workPeriodTo) : null}
                    getValue={(date: Date) => {
                      props.formData.workPeriodTo = formatDateTimeToISOString(date);
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Dropdown
                placeholder={t('selectanoption')}
                label={t('generalLedger')}
                errorMessage={props.validationMessages.ledgerValidationMsg ? props.validationMessages.ledgerValidationMsg : ''}
                options={props.ledgerDropDowns}
                selectedKey={
                  props.generalLedger
                }
                disabled={props.dropDownOptions.isApproved}
                styles={dropdownStyles}
                onChange={(event, value) => {
                  props.setGeneralLedger(value!!);
                }
                }
              />
            </Stack>
          </div>
        </div>
      </div>

      <div className={'card-footer'}>
        <Separator/>
        <Stack
          styles={stackStyles}
          tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                props.saveFormData();
              }}
              disabled={(props.dropDownOptions.isApproved)}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

    </div>
  );
};


export default ProjectInformation;
