import {
  ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionProps,
  IComboBoxStyles, Icon, IRenderFunction,
  Toggle,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useExpand from '../../hooks/useExpand';
import { ViewMode } from '../ganttv2';
import { getProject } from '../util';
import { uPrinceTheme } from '../../../theme';

const basicStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

const ModeSwitcherWithRadioButtons = ({ onViewModeChange, selectedViewMode, expandToggleEnabled = true }: any) => {
  const { t } = useTranslation();
  const {isExpand, setExpand, setIsApplyExpandContext} = useExpand();
  const [mode, setMode] = React.useState<any>(getProject() ? ViewMode.Week: ViewMode.Month );

  const getItemIcon = (key: any) => {
    let icon = '';
    switch (key) {
      case ViewMode.QuarterDay:
        icon = 'QuadColumn';
        break;
      case ViewMode.HalfDay:
        icon = 'DoubleColumn';
        break;
      case ViewMode.Day:
        icon = 'EventDate';
        break;
      case ViewMode.Week:
        icon = 'CalendarWorkWeek';
        break;
      case ViewMode.Month:
        icon = 'Calendar';
        break;
      case ViewMode.QuaterYear:
        icon = 'Source';
        break;
    }
    return icon;
  };

  const onRenderField: IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps> = (props: any) => {
    return <div style={{
      width: 40,
      height: 40,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      alignItems: 'center',
    }}
    >
      <span style={{ fontSize: 10, textAlign: 'center', marginBottom: 2 }}>{props.text}</span>
      <Icon
        style={{
          fontSize: 23,
          color: props.checked ? uPrinceTheme.palette.themePrimary : 'black',
        }}
        iconName={getItemIcon(props?.key)}
      />
    </div>;
  };

  const iconOptions: IChoiceGroupOption[] = [
    { key: ViewMode.QuarterDay, text: t('quaterOfDay'), onRenderField },
    { key: ViewMode.HalfDay, text: t('halfOfDay'), onRenderField },
    { key: ViewMode.Day, text: t('day'), onRenderField },
    { key: ViewMode.Week, text: t('week'), onRenderField },
    { key: ViewMode.Month, text: t('month'), onRenderField },
    { key: ViewMode.QuaterYear, text: t('quater'), onRenderField },
  ];

// USE THIS IF YOU WANT TO USE RADIO BUTTONS
  // const options: IChoiceGroupOption[] = [
  //   { key: ViewMode.QuarterDay, text: t('quaterOfDay') },
  //   { key: ViewMode.HalfDay, text: t('halfOfDay'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.Day, text: t('day'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.Week, text: t('week'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.Month, text: t('month'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.QuaterYear, text: t('quater'), styles:{field: { marginLeft: "10px"}} },
  // ];

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ height: 42, paddingLeft: 10 }}>
      <ChoiceGroup
        defaultSelectedKey={selectedViewMode}
        options={iconOptions} // USE THIS IF YOU WANT TO USE RADIO BUTTONS: options={options}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          option?: IChoiceGroupOption,
        ) => {
          onViewModeChange(option?.key);
          setMode(option?.key)
        }}
        // label={t('viewMode')}
        styles={{ flexContainer: { display: 'flex', marginTop: 10 }} }
      />
      </div>

      {expandToggleEnabled &&
        <div style={{ marginTop: 26, marginLeft: 20 }}>
          <Toggle
            onText={t('collapse')}
            offText={t('expand')}
            onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
              setIsApplyExpandContext(true);
              setExpand(!checked);
            }}
          />

        </div>
      }
    </div>
  );
};

export default ModeSwitcherWithRadioButtons;
