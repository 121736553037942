import React, { useEffect, useState } from 'react';
import { getTheme, IconButton, IIconProps, IStackItemStyles, IStackStyles, Stack } from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import { messageService } from '../../services/messageService';
import { useWindowSize } from '../../hooks/useResize';

const theme = getTheme();
// Styles definition

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
    fontSize: 11,
  },
};

const iconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel', styles: { root: { fontSize: 11 } } };
const minimizeIcon: IIconProps = { iconName: 'ChevronDownSmall', styles: { root: { fontSize: 11 } } };
const maximizeIcon: IIconProps = { iconName: 'ChevronUpSmall', styles: { root: { fontSize: 11 } } };
const collapseIcon: IIconProps = { iconName: 'DockLeft', styles: { root: { fontSize: 11 } } };
const refreshIcon: IIconProps = { iconName: 'Refresh', styles: { root: { fontSize: 11 } } };
const doubleColumnIcon: IIconProps = { iconName: 'DoubleColumn', styles: { root: { fontSize: 11 } } };
const fullScreenIcon: IIconProps = { iconName: 'FullScreen', styles: { root: { fontSize: 11 } } };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const stackStyles: IStackStyles = {
  root: {
    borderBottom: '1px solid rgb(237, 235, 233)',
    height: 26,
    //height:"4.9vh",
    //marginRight:-9
  },
};

const TitlePane = (props: {
  headerTitle: any;
  close?: boolean;
  collapse?: boolean;
  isCollapse?: boolean;
  type?: number;
  minimize?: boolean
  isMinimize?: boolean;
  isRefresh?: boolean;
  isFullScreen?: boolean;
  isOldItem?: boolean;
}) => {

  const [width, height] = useWindowSize();

  const hideDocumentPane = () => {
    messageService.sendMessage({ hideDocumentPane: true, documentPaneType: props.type });
  };
  const collapseDocumentPane = () => {

    messageService.sendMessage({ collapseDocumentPane: true, documentPaneType: props.type });
  };
  const minimizeBottomPane = () => {
    messageService.sendMessage({ minimizeBottomPane: true, isMinimize: !props.isMinimize });
  };
  const refresh = () => {
    messageService.sendMessage({ refresh: true });
  };
  const fullScreen = () => {
    messageService.sendMessage({ fullScreen: true });
  };

  // const renderkList = () => {
  //   var titleList = props.list
  //     .map(function (el: any) {
  //
  //         return (
  //           <Stack.Item key={el} align="center" styles={stackItemStyles}>
  //             <span>{el}</span>
  //           </Stack.Item>
  //         );
  //
  //
  //     });
  //   return titleList;
  // };

  if (props.close) {
    return <div className={'title-pane'} style={{ backgroundColor: (uPrinceTheme.palette.themePrimary) }}>
      {/*<Stack horizontal horizontalAlign="space-between" styles={stackStyles}>*/}
      {/*<span style={itemStyles}>{props.headerTitle}</span>*/}
      {/*<span style={itemStyles}>3</span>*/}
      {/*</Stack>*/}
      <Stack horizontal horizontalAlign="space-between" disableShrink styles={stackStyles}>
        <Stack.Item align="center" styles={stackItemStyles}>
          <span>{props.headerTitle}</span>
        </Stack.Item>
        <Stack.Item align="center" styles={stackItemStyles}>
          {props.isRefresh && <IconButton
            styles={iconButtonStyles}
            iconProps={refreshIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              refresh();
            }}
          />}
          {props.collapse && <IconButton
            styles={iconButtonStyles}
            iconProps={props.isCollapse ? doubleColumnIcon : collapseIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              collapseDocumentPane();
            }}
          />}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              hideDocumentPane();
            }}
          />
        </Stack.Item>
      </Stack>
    </div>;
  } else {
    return <div className={'title-pane'} style={{ backgroundColor: (uPrinceTheme.palette.themePrimary) }}>
      <Stack horizontal horizontalAlign="space-between" disableShrink style={{width: props.isOldItem ? 'inherit' : (width-3)}} styles={stackStyles}>
        {/*{renderkList()}*/}
        <Stack.Item align="center" styles={stackItemStyles}>
          <span>{props.headerTitle} </span>
        </Stack.Item>
        {props.minimize && <Stack.Item align="center" styles={stackItemStyles}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={props.isMinimize ? maximizeIcon : minimizeIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              minimizeBottomPane();
            }}
          />
        </Stack.Item>}
        {props.isFullScreen && <Stack.Item align="center" styles={stackItemStyles}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={fullScreenIcon}
            onClick={() => {
              fullScreen();
            }}
          />
        </Stack.Item>}
      </Stack>
    </div>;
  }

};

export default TitlePane;
