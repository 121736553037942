import React, { useEffect } from 'react';
import {
  Checkbox,
  FontWeights, IButtonStyles,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal, PrimaryButton,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../theme';
import { getTheme, ITheme } from '@fluentui/react/lib/Styling';
import { addMidTermLabourTeam, saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import TaxonomyGrid from '../../../shared/taxonomyField/taxonomyGrid';
import { getTaxonomyList } from '../../../reducers/organisationSettingReducer';
import { OrganisationSettingListPaneFilter } from '../../../types/organisationSetting';
import { ORGANIZATION_TAXONOMY_LEVEL_TYPES } from '../../../types/cuMyCalendar';

const theme: ITheme = getTheme();

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

// const iconButtonStyles: Partial<IButtonStyles> = {
//   root: {
//     marginLeft: 'auto',
//     marginTop: '4px',
//     marginRight: '2px',
//   },
// };
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  fullWidth: { width: '100%' },
  footer: {
    flex: '1 1 auto',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 24px 14px 24px',
    textAlign: 'end',
  },
});

const OrgTaxonomyModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleId = useId('title');
  const isOrgTaxonomyModalOpen = useSelector((state: any) => state.vp.isOrgTaxonomyModalOpen);
  const cpcNodeInfo = useSelector((state: any) => state.vp.cpcNodeInfo);
  const taxonomyList = useSelector((state: any) => state.os.taxonomyList);
  const [selectedPersonIds, setSelectedPersonIds] = React.useState<any[]>([]);
  const [selectNodeIds, setSelectNodeIds] = React.useState<any[]>([]);

  // get selected nodes to an array
  useEffect(() => {
    if (selectNodeIds.length > 0) {
      const pbsAssignedLabourNodes = taxonomyList
        ?.filter((item: any) => selectNodeIds.includes(item.id) && item?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON)
        .map((item: any) => ({
          ...item,
          title: item?.title?.replace(" (Person)", ""),
          type: "PbsAssignedLabour",
          cpcId: cpcNodeInfo?.cpcId,
        }));
      dispatch(saveVPStateAttr('pbsAssignedLabourNodes', pbsAssignedLabourNodes));
    }
  }, [selectNodeIds]);

  useEffect(() => {
    dispatch(getTaxonomyList(new OrganisationSettingListPaneFilter()));
  }, []);

  const clearSelection = () => {
    dispatch(saveVPStateAttr('isOrgTaxonomyModalOpen', !isOrgTaxonomyModalOpen));
    setSelectedPersonIds([]);
    setSelectNodeIds([]);
  }

  const renderButtons = (rowInfo: any) => {
    const isParent = (rowInfo.node.id);
    const isPerson = rowInfo?.node?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON;
    const isTeam = rowInfo?.node?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.TEAM;

    if (isPerson || isTeam) {
      return ([
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
            <span
              style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
          <Checkbox
            className='btn btn-outline-success'
            styles={{ root: { verticalAlign: 'middle' } }}
            checked={selectNodeIds.includes(rowInfo.node.id)}
            onChange={(_ev, checked) => {
              if (isTeam) {
                const teamMembers = rowInfo?.node?.children || [];
                if (checked) {
                  const allTeamMemberIds = teamMembers.map((item: any) => item.personId);
                  const selectedNodeIds = teamMembers.map((item: any) => item.id);
                  setSelectedPersonIds(allTeamMemberIds);
                  setSelectNodeIds([...selectedNodeIds, rowInfo.node.id]); // Person nodes + Team node
                } else {
                  setSelectedPersonIds([]);
                  setSelectNodeIds([]);
                }
              }

              if (isPerson) {
                if (checked) {
                  const personId = rowInfo?.node?.personId;
                  const itemId = rowInfo?.node?.id;
                  setSelectedPersonIds([...selectedPersonIds, personId]);
                  setSelectNodeIds([...selectNodeIds, itemId]);
                } else {
                  const personId = rowInfo?.node?.personId;
                  const itemId = rowInfo?.node?.id;
                  setSelectedPersonIds(selectedPersonIds.filter((id: any) => id !== personId));
                  setSelectNodeIds(selectNodeIds.filter((id: any) => id !== itemId));
                }
              }
            }}
          />
        </div>,
      ]);
    }
  };


  return <div>
    <Modal
      titleAriaId={titleId}
      isOpen={isOrgTaxonomyModalOpen}
      onDismiss={() => dispatch(saveVPStateAttr('isOrgTaxonomyModalOpen', !isOrgTaxonomyModalOpen))}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{t('addTeamOrLabour')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Close popup modal'
          onClick={() => clearSelection()}
        />
      </div>
      <div style={{ padding: 10, overflow: 'scroll', height: 700 }}>
        <TaxonomyGrid
          treeData={taxonomyList ?? []}
          renderButtons={renderButtons}
        />
      </div>
      <div className={contentStyles.footer}>
        <PrimaryButton
          iconProps={saveIcon}
          text={t('save')}
          style={{ marginTop: 10, marginBottom: 5 }}
          onClick={() => {
            dispatch(addMidTermLabourTeam({
              cabPersonId: selectedPersonIds,
              cpcId: cpcNodeInfo?.cpcId,
              cu: cpcNodeInfo?.cu,
              pbsProduct: cpcNodeInfo?.parentId,
              project: cpcNodeInfo?.project,
            }));
            clearSelection();
          }}
          disabled={selectedPersonIds.length === 0}
        />
      </div>
    </Modal>
  </div>;
};

export default OrgTaxonomyModel;