import {
  APPROVED_STATUS_ID,
  HANDED_OVER_STATUS_ID,
  IN_DEVELOPMENT_STATUS_ID,
  IN_REVIEW_STATUS_ID,
  PENDING_DEVELOPMENT_STATUS_ID,
  PMOL_ACTIVITY_TYPES,
} from './projectMolecule';
import {store} from '../../index';

export class MyDayPlanningListPaneFilter {
  title: string | null = null
  sorter: Sorter = new Sorter();
}

export interface MyDayPlanningShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class MyDayPlanningListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface MYDPDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface MyDayPlanning {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface MYDPDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string'
}

export interface ShortCutPaneFilters {
  project: string | null,
  pmolStatus: string | null,
  contractingUnit: any[],
  buId: string | null,
}

export enum GridView {
  DAY = 'day',
  WEEK = 'week'
}

export const AcceptType = {
  PBS: 'PBS',
  PMOL: 'PMOL',
  TEAM: 'TEAM',
  VEHICLE: 'VEHICLE',
  TOOL: 'TOOL',
  PERSON: 'PERSON',
  PROJECT: 'PROJECT',
}


export const getPmolType = (item: any) => {
  switch (item?.typeId) {
    case PMOL_ACTIVITY_TYPES.TRAVEL:
      return 'travel';
    case PMOL_ACTIVITY_TYPES.WORK:
      return 'work';
    case PMOL_ACTIVITY_TYPES.LOAD:
      return 'load';
    default:
      return ""
  }
};

export enum ProjectPlanResourceType {
  TEAM = 'TEAM',
  TOOL = 'TOOL',
  VEHICLE = 'VEHICLE',
  PMOL = 'PMOL',
  ORG_TEAMS = 'ORG_TEAMS',
  PBS = 'PBS',
  PROJECT = 'PROJECT',
}

export enum DpViewMode {
  Default = 60,

  OneHour = 30,
  TwoHour = 60,
  FourHour = 120,
  SixHour = 180,
  EightHour = 240,
  TwelveHour = 360,
}

export const getStatesIcon = (statusId: string) => {
  switch (statusId) {
    case PENDING_DEVELOPMENT_STATUS_ID:
      return 'pie-chart-pending-development';
    case IN_DEVELOPMENT_STATUS_ID:
      return 'pie-chart-in-development';
    case IN_REVIEW_STATUS_ID:
      return 'pie-chart-in-review';
    case APPROVED_STATUS_ID:
      return 'pie-chart-approved';
    case HANDED_OVER_STATUS_ID:
      return 'pie-chart-handed-over';
    default:
      return '';
  }
};

export const getWeatherIcon = (iconCode: number) => {
  switch (iconCode) {
    case 1:
      return 'Sunny';
    case 2:
      return 'Sunny';
    case 3:
      return 'PartlyCloudyDay';
    case 4:
      return 'PartlyCloudyDay';
    case 5:
      return 'PartlyCloudyDay';
    case 6:
      return 'PartlyCloudyDay';
    case 7:
      return 'Cloud';
    case 8:
      return 'Cloudy';
    case 9:
      return '';
    case 10:
      return '';
    case 11:
      return 'Fog';
    case 12:
      return 'Rain';
    case 13:
      return 'RainShowersDay';
    case 14:
      return 'RainShowersDay';
    case 15:
      return 'Thunderstorms';
    case 16:
      return 'Thunderstorms';
    case 17:
      return 'Thunderstorms';
    case 18:
      return 'Rain';
    case 19:
      return 'Frigid';
    case 20:
      return 'SnowShowerDay';
    case 21:
      return 'SnowShowerDay';
    case 22:
      return 'Snowflake';
    case 23:
      return 'SnowShowerDay';
    case 24:
      return 'Snowflake';
    case 25:
      return 'Snowflake';
    case 26:
      return 'Snowflake';
    case 27:
      return '';
    case 28:
      return '';
    case 29:
      return 'Snowflake';
    case 30:
      return 'Sunny';
    case 31:
      return 'Frigid';
    case 32:
      return 'Squalls';
    case 33:
      return 'ClearNight';
    case 34:
      return 'ClearNight';
    case 35:
      return 'ClearNight';
    case 36:
      return 'ClearNight';
    case 37:
      return 'Fog';
    case 38:
      return 'PartlyCloudyDay';
    case 39:
      return 'PartlyCloudyDay';
    case 40:
      return 'PartlyCloudyDay';
    case 41:
      return 'PartlyCloudyDay';
    case 42:
      return 'PartlyCloudyDay';
    case 43:
      return 'PartlyCloudyDay';
    case 44:
      return 'PartlyCloudyDay';
    default:
      return '';
  }
};

export const getBuGroups=()=>{
  let currentUser=store.getState().uprince?.currentUser;
  let groups=currentUser?.groups;
  if(groups){
    let  BuGroups=groups.filter((item:any)=>item?.description==='BU')

    return BuGroups.map((item:any)=>{
      return item?.displayName
    })
  }else{
    return [];
  }

}

export class MDPBottomFilterData {
  cabPerson: string | null = null;
  ciawStatus: string | null = null;
  date: string | null = null;
  project: string | null = null;
  sorter: Sorter = new Sorter();
}

export const ORG_TEAM_ID = 'fg10e768-3e06-po02-b337-ee367a82adfg';

export interface PDPCustomModalProps {
  title: string
  list: any
  onRenderFooter?: any
  isModalOpen: boolean
  showProgressBar?: boolean | undefined
  handleClose: any
  handleSave: any
  selectedWorkers?: any
  handleOnChangeCheckbox?: any
  workerIdList?: any
}

export interface PPBFilterTypes {
  executionDate: string | null
  contractingUnit: string[]
  buId: string | null
  cabPersonId: string | null
  projectSequenceCode: string | null
  pmolTypeId: string | null
  siteManagerId: string | null
}

export interface DayPlanningFilter {
  startDate: string | null
  endDate: string | null
  contractingUnit: string[]
  buId: string | null
  project: string | null
}

export const dayButtonId = 'day';
export const weekButtonId = 'week';

export const checkDateIsValid = (date: any) => {
  if (typeof date === "string") {
    return new Date(date);
  } else {
    return date;
  }
};

export class GetAvailableTeamsPayload {
  startDate: string | null = "2023-08-23T07:27:16.110Z";
  endDate: string | null = "2023-08-23T07:27:16.110Z";
  localDate: string | null = "2023-08-23T07:27:16.110Z";
  type: string | null = null;
  pmolStatus: string | null = null;
  contractingUnit: string | null = null;
  weekEndDate: string | null = null;
  buId: string | null = null;
  cabPersonId: string | null = null;
  personName: string | null = null;
  isPlanned: string | null = null;
  preferedCpc: string | null = null;
}

export const workerButtonId = 'worker';
export const toolButtonId = 'tool';

export const foremanId = '2210e768-msms-Item-team1-ee367a82ad22';
