import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import PmolDocumentPane from './pmolContainer';
import {mergeStyles, Stack} from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import CommandBarRight from '../commandBar/commandBarRight';
import ProgressBar from '../../../shared/progressBar/progressBar';
import CollapseBookMarkPane from '../../../shared/collapseBookMarkPane/bookMarkPane';
import BookMarkPaneComponent from '../../projectMolecule/bookMarkPane/component';
import {useTranslation} from 'react-i18next';
import {PMOL_TYPE} from '../../../types/projectMolecule';

const DocumentPane = (props: { horizontalReSizer: any, collapseBookmarkPane: any }) => {
  const { t } = useTranslation();

  const contentStyle = mergeStyles({
    width: props.collapseBookmarkPane && window.innerWidth > 1440 ? '100%' : '83%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      '@media(max-width: 1100px)': {
        width: '97.4%',
        height: 'calc(100vh)',
        position: 'absolute',
      },
    },
  });

  const bookmarkList = [
    {
      id: 1,
      link: 'pbs-product-header',
      label: t('productHeader'),
    },
    {
      id: 2,
      link: 'pbs-product-detail',
      label: t('productDetails'),
    },
    { id: 3, link: 'pbs-competency', label: t('competencies') },
    { id: 4, link: 'pbs-resources', label: t('resources') },
    { id: 5, link: 'pbs-instructions', label: t('instructions') },
    { id: 6, link: 'pbs-risks', label: t('risks') },
    { id: 7, link: 'pbs-quality', label: t('quality') },
    {
      id: 8,
      link: 'history',
      label: t('history'),
    },
  ];

  const [bookmarkDivClass, setBookmarkDivClass] = useState('bookmark-panel-vp parent');
  const {
    selectedMyDpPmol,
    collapseBookmarkPane,
  } = useSelector(
    (state: any) => state.mydPlan
  );

  const { showProgressBar } = useSelector(
    (state: any) => state.pmol
  );

  const _toggleBookmark = (): void => {
    setBookmarkDivClass('bookmark-panel-vp parent panel-on');
  };

  const _toggleBookmarkOut = (): void => {
    setBookmarkDivClass('bookmark-panel-vp parent');
  };

  return <div>
    <Stack
      className="project-list-header right-panel"
      style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
      styles={{ root: { width: '100%', paddingRight: 0 } }}
    >
      <TitlePane
        close={true}
        headerTitle={selectedMyDpPmol ? selectedMyDpPmol.title : ""}
        collapse={true}
        isCollapse={props.collapseBookmarkPane}
        type={1}
      />
      <CommandBarRight />
      <ProgressBar show={showProgressBar} />
    </Stack>
    <div style={{ height: props.horizontalReSizer - 80, overflow: 'auto', backgroundColor: 'white' }}>
      <Stack
        className={`project-detail-list ${(props.collapseBookmarkPane && window.innerWidth > 1440) ? 'four-pane-full-inner-container' : 'four-pane-full-inner-container'} cpc  card-container`}
        styles={{
          root: {
            width: '100%',
            height: '76vh',
          },
        }}
      >
        {/*doc*/}
        <Stack
          className={contentStyle}
          horizontalAlign="start"
          verticalAlign="start"
          padding={0}
          gap={0}
          styles={{
            root: {
              width: '100%',
              height: 'auto',
            },
          }}
          verticalFill
        >
          <PmolDocumentPane projectId={selectedMyDpPmol?.projectSequenceCode} />
        </Stack>
        {props.collapseBookmarkPane && window.innerWidth > 1440 ? <CollapseBookMarkPane
          isEdit={true}
          divClass={bookmarkDivClass}
          currentActiveSection=""
          list={bookmarkList}
          toggleBookmark={_toggleBookmark}
          toggleBookmarkOut={_toggleBookmarkOut}
          pmolType={PMOL_TYPE.REGULAR}
        /> : <BookMarkPaneComponent
          isEdit={true}
          divClass={bookmarkDivClass}
          currentActiveSection=""
          list={bookmarkList}
          toggleBookmark={_toggleBookmark}
          toggleBookmarkOut={_toggleBookmarkOut}
          pmolType={PMOL_TYPE.REGULAR}
        />}
      </Stack>
    </div>
  </div>
};

export default DocumentPane;
