import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
} from '@fluentui/react';
import { createForm } from 'final-form';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { iconColors, uPrinceTheme } from '../../../../../theme';
import client from '../../../../api';
import CustomDropdown from '../../../../shared/customDropdown/customDropdown';
import CustomFileUploader from '../../../../shared/fileUploader/customFileUploader';
import { CKEditorWrapper, DatePickerAdapter, NumberField, TextFieldFF } from '../../../../shared/fluentFinalForm';
import CurrencyNumberFormatField from '../../../../shared/fluentFinalForm/CurrencyNumberFormatField ';
import { format24hDateTime, formatDateTimeToISOString, getImages, getProject } from '../../../../shared/util';
import { ResourceTypes } from '../../../../types/billOfResources';
import {
  HANDED_OVER_STATUS_ID,
  IN_DEVELOPMENT_STATUS_ID,
  IN_REVIEW_FEEDBACK_STATUS_ID,
  IN_REVIEW_STATUS_ID,
} from '../../../../types/projectMolecule';
import { POAccessLevel, PODetails, RESOURCE_TYPE } from '../../../../types/purchaseOrder';
import FixedDecimalNumberFormatField from '../../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';
import moment from 'moment';
import { useSelector } from 'react-redux';

const theme = getTheme();
1;
const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};


const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      minWidth: '75vw',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    // flex: '4 4 auto',
    // padding: '0 24px 0px 24px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    minWidth: '75vw',
    minHeight: '20vh',
    maxHeight: '90vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#C4C4C4',
    height: 40,
    marginLeft: 'auto',
  },
  subHeaderBtn: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    borderBottom: '1px solid #C4C4C4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const classNames = mergeStyleSets({
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.white,
    cursor: 'pointer',

  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  wrapper: {
    height: 'calc(100vh - 264px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};


const POResourceModal = (props: {
  isOpen: boolean,
  setOpenPOResourceModal: () => void,
  borResource: any,
  setResourceChangeProject: (resource: any, level: POAccessLevel) => void,
  resourceType: RESOURCE_TYPE,
  formData: PODetails,
  projectSequance: string
}) => {
  const [poResource, setPOResource] = useState<any>(null);
  const [status, setStatus] = useState<any>(IN_DEVELOPMENT_STATUS_ID);
  const [projectDisabled, setProjectDisabled] = useState<boolean>(true);
  const [cuDisabled, setCuDisabled] = useState<boolean>(true);

  const currentUser = useSelector<any, any>(
    (state) => state.uprince.currentUser,
  );

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    // window.alert(JSON.stringify(values, 0, 2));
  };

  const getResourceTypeId = (resourceType: RESOURCE_TYPE) => {
    if (resourceType === RESOURCE_TYPE.MATERIALS) {
      return ResourceTypes.materials.id;
    } else if (resourceType === RESOURCE_TYPE.TOOLS) {
      return ResourceTypes.tools.id;
    } else if (resourceType === RESOURCE_TYPE.CONSUMABLE) {
      return ResourceTypes.consumable.id;
    } else if (resourceType === RESOURCE_TYPE.LABOUR) {
      return ResourceTypes.labour.id;
    }
  };

  const getCpcByName = async (name: RESOURCE_TYPE) => {
    const filter = {
      resourceTypeId: getResourceTypeId(props.resourceType),
      resourceFamilyId: null,
      resourceNumber: null,
      title: name,
      BORId: props?.borResource?.borId,
      pbsId: null,
      status: 1,
      sorter: {
        attribute: 'resourceNumber',
        order: 'asc',
      },
    };
    if (name.length >= 2) {
      const response = await client.post(
        'ContractingUnitTenants/BORCPCFilter',
        filter, { headers: { project: props.borResource.projectTitle } },
      );
      return formatCpcResponse(response);
    }
  };

  const formatCpcResponse = (response: any) => {
    let localOptions: { value: string; label: string }[] = [];
    if (response && response.data) {
      const localData = response.data.contractingUnit;

      if (localData && _.isArray(localData)) {
        localOptions = localData.map((item: any) => {
          return {
            value: item,
            label: item.title,
          };
        });
      }


      return localOptions;
    }
  };

  const cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getCpcByName(inputValue));
      // }, 1000);
    });

  const calculatorProject = createDecorator(
    {
      field: 'pQuantity',
      updates: { pTotalPrice: (requiredValue, allValues: any) => (requiredValue * allValues?.pUnitPrice).toFixed(2) },
    },
    {
      field: 'pUnitPrice',
      updates: { pTotalPrice: (requiredValue, allValues: any) => (requiredValue * allValues?.pQuantity).toFixed(2) },
    },
  );

  const calculatorCU = createDecorator(
    {
      field: 'cQuantity', // when the value of foo changes...
      updates: {
        // ...set field "doubleFoo" to twice the value of foo
        cTotalPrice: (requiredValue, allValues: any) => (requiredValue * allValues?.cUnitPrice).toFixed(2),
      },
    },
    {
      field: 'cUnitPrice', // when the value of foo changes...
      updates: {
        // ...set field "doubleFoo" to twice the value of foo
        cTotalPrice: (requiredValue, allValues: any) => (requiredValue * allValues?.cQuantity).toFixed(2),
      },
    },
  );

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));
  const formRefcu = React.useRef(createForm({ onSubmit: onSubmit }));

  useEffect(() => {
    if (props?.borResource?.pstartdate) {
      props.borResource.pstartdate = new Date(props.borResource.pstartdate);
    }
    if (props?.borResource?.pstopdate) {
      props.borResource.pstopdate = new Date(props.borResource.pstopdate);
    }

    setPOResource(props.borResource);
  }, [props.borResource]);

  useEffect(() => {
    if (props?.formData?.status?.key) {
      setStatus(props?.formData?.status?.key);
    } else {
      setStatus(IN_DEVELOPMENT_STATUS_ID);
    }
  }, [props.formData]);

  useEffect(() => {
    if (getProject()) {
      setCuDisabled(true);
      if (status === IN_DEVELOPMENT_STATUS_ID) {
        setProjectDisabled(false);
      } else {
        setProjectDisabled(true);
      }
    } else {
      setProjectDisabled(true);
      if (status === IN_DEVELOPMENT_STATUS_ID || status === IN_REVIEW_STATUS_ID || status === IN_REVIEW_FEEDBACK_STATUS_ID) {
        setCuDisabled(false);
      } else {
        setCuDisabled(true);
      }
    }
  }, [status]);

  const { t } = useTranslation();

  const handleHideCu = () => {
    let handleHide = false;
    if (getProject() && (status === IN_DEVELOPMENT_STATUS_ID || status === IN_REVIEW_STATUS_ID)) {
      handleHide = true;
    } else {
      handleHide = false;
    }
    return handleHide;
  };

  const handleHideProject = () => {
    let handleHide = false;
    if (!getProject() && (status === IN_DEVELOPMENT_STATUS_ID)) {
      handleHide = true;
    } else if (status === HANDED_OVER_STATUS_ID && props.formData.isCu) {
      handleHide = true;
    } else {
      handleHide = false;
    }
    return handleHide;
  };

  const checkIsValueUpdate = (formValues: any): any => {
    // if(formValues.hasChanged){
    let formValuesComment = formValues.cComments;
    let poResourceComment = poResource.cComments;
    let formValuesTotalPrice = formValues.cTotalPrice;
    let poResourceTotalPrice = poResource.cTotalPrice;
    if (formValuesComment != poResourceComment) {
      formValues.cComments = null;
      poResource.cComments = null;
    }
    if (formValuesTotalPrice != poResourceTotalPrice) {
      formValues.cTotalPrice = null;
      poResource.cTotalPrice = null;
    }
    if (!_.isEqual(formValues, poResource)) {
      formValues.cTotalPrice = formValuesTotalPrice;
      poResource.cTotalPrice = poResourceTotalPrice;
      formValues.cComments = formValuesComment;
      poResource.cComments = poResourceComment;

      return autoGneratedComment(formValues);
    } else {
      formValues.cTotalPrice = formValuesTotalPrice;
      poResource.cTotalPrice = poResourceTotalPrice;
      formValues.cComments = formValuesComment;
      poResource.cComments = poResourceComment;
    }
    // }
    return false;
  };

  const autoGneratedComment = (formValues: any) => {
    let Username = '';
    let time = moment().format('DD/MM HH:mm (ddd)');
    if (currentUser && currentUser.user) {
      Username = currentUser.user.firstName + ' ' + currentUser.user.lastName;
    }
    let comment = poResource.cComments ? poResource.cComments : '';
    if (formValues.cCrossReference != poResource.cCrossReference) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('crossReference')}: ${poResource.cCrossReference} : ${formValues.cCrossReference}</p>`;
    }
    if (formValues.cTitle != poResource.cTitle) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('resourceTitle')}: ${poResource.cTitle} : ${formValues.cTitle}</p>`;
    }
    if (formValues.cQuantity != poResource.cQuantity) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('quantity')}: ${poResource.cQuantity} : ${formValues.cQuantity}</p>`;
    }
    if (formValues.cStartDate != poResource.cStartDate) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('startDate')}: ${poResource.cStopDate ? format24hDateTime(poResource.cStartDate) : 'null'} : ${format24hDateTime(formValues.cStartDate)}</p>`;
    }
    if (formValues.cStopDate != poResource.cStopDate) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('stopDate')}: ${poResource.cStopDate ? format24hDateTime(poResource.cStopDate) : 'null'} : ${format24hDateTime(formValues.cStopDate)}</p>`;
    }
    if (formValues.cNumberOfDate != poResource.cNumberOfDate) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('numberofDays')}: ${poResource.cNumberOfDate} : ${formValues.cNumberOfDate}</p>`;
    }
    if (formValues.cFullTimeEmployee != poResource.cFullTimeEmployee) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('FTE')}: ${poResource.cFullTimeEmployee} : ${formValues.cFullTimeEmployee}</p>`;
    }
    if (formValues.cdevices != poResource.cdevices) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('devices')}: ${poResource.cdevices} : ${formValues.cdevices}</p>`;
    }
    if (formValues.cUnitPrice != poResource.cUnitPrice) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('unitPrice')}: ${poResource.cUnitPrice} : ${formValues.cUnitPrice}</p>`;
    }
    return comment;
  };


  return (
    <div className={'splitPopup'}>
      <Modal
        isOpen={props.isOpen}
        onDismiss={() => {
          props.setOpenPOResourceModal();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        // className={ 'grid-righter column-filter-activated' }
      >
        <div className={contentStyles.header}>
          {!getProject() && (status === IN_DEVELOPMENT_STATUS_ID) &&
          <span>{(props?.formData && poResource?.projectTitle ? poResource?.projectTitle : props.projectSequance) + ' ◦ ' + poResource?.borTitle + ' ◦ ' + poResource?.pTitle}</span>
          }
          {((getProject()) || (!getProject() && (status !== IN_DEVELOPMENT_STATUS_ID))) &&
          <span>{t('cuApproval')} {' - ' + (props?.formData && poResource?.projectTitle ? poResource?.projectTitle : props.projectSequance) + ' ◦ ' + poResource?.borTitle + ' ◦ ' + poResource?.pTitle}</span>
          }
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.setOpenPOResourceModal();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="ms-Grid" dir="ltr" style={{ height: '100%' }}>
            <div className="ms-Grid-row">
              {!handleHideProject() &&

              <div
                className={handleHideCu() ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12' : 'ms-Grid-col ms-sm6 ms-md6 ms-lg6'}
                style={{ borderRight: '1px solid black', height: '100%', padding: 0, margin: 0 }}
              >
                <div className={contentStyles.subHeader}>
                  <Label className={contentStyles.actionButtonLabel}>
                    {t('project')} {' - ' + (props?.formData && poResource?.projectTitle ? poResource?.projectTitle : props.projectSequance) + ' ◦ ' + poResource?.borTitle + ' ◦ ' + poResource?.pTitle}
                  </Label>
                </div>
                <Form
                  onSubmit={onSubmit}
                  decorators={[calculatorProject]}
                  mutators={{
                    setResourceDocs: (args, state, utils) => {
                      const field = state.fields.pDocuments;
                      field.change(args[0]);
                    },
                  }}
                  initialValues={poResource}
                  // validate={ validate }
                  render={({
                             handleSubmit,
                             form,
                             submitting,
                             pristine,
                             values,
                           }) => {
                    formRef.current = form;
                    const valuesx: any = values;
                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="" style={{ padding: 16 }}>
                          <div className={'card-header'}>
                            <Label>1. {t('referenceDetails')}</Label>

                          </div>
                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="projectTitle"
                                  component={TextFieldFF}
                                  lable={t('projectTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pbsTitle"
                                  component={TextFieldFF}
                                  lable={t('productTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>

                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pCrossReference"
                                  component={TextFieldFF}
                                  lable={t('crossReference')}
                                  disabled={projectDisabled}
                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="borTitle"
                                  component={TextFieldFF}
                                  lable={t('borTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>


                          <Label>2. {t('articleDetails')}</Label>


                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pTitle"
                                  component={TextFieldFF}
                                  lable={t('resourceTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>

                          </Stack>

                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pQuantity"
                                  component={FixedDecimalNumberFormatField}
                                  lable={t('quantity')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pMou"
                                  component={TextFieldFF}
                                  lable={t('mou')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>
                          {(props.resourceType === RESOURCE_TYPE.LABOUR || props.resourceType === RESOURCE_TYPE.TOOLS) &&
                          <div>
                            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name="pStartDate"
                                    component={DatePickerAdapter}
                                    lable={t('startDate')}
                                    disabled={projectDisabled}
                                    parse={value => value && new Date(value)}
                                    format={value => (value ? new Date(value) : null)}
                                  />
                                </div>
                              </Stack.Item>
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <FormSpy
                                    subscription={{ /* ... */ }}
                                    render={({ form }) => (

                                      <Field
                                        name="pStopDate"
                                        component={DatePickerAdapter}
                                        lable={t('stopDate')}
                                        disabled={projectDisabled}
                                        parse={value => value && new Date(value)}
                                        format={value => (value ? new Date(value) : null)}
                                        minDate={new Date(form.getFieldState('pStartDate')?.value)}>


                                      </Field>
                                    )}
                                  />

                                </div>
                              </Stack.Item>
                            </Stack>
                            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name="pNumberOfDate"
                                    component={NumberField}
                                    lable={t('numberofDays')}
                                    disabled={projectDisabled}
                                  />
                                </div>
                              </Stack.Item>
                              {props.resourceType === RESOURCE_TYPE.LABOUR &&
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name={'pFullTimeEmployee'}
                                    component={NumberField}
                                    lable={t('FTE')}
                                    disabled={projectDisabled}
                                  />
                                </div>
                              </Stack.Item>
                              }
                              {props.resourceType === RESOURCE_TYPE.TOOLS &&
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name={'pdevices'}
                                    component={NumberField}
                                    lable={t('devices')}
                                    disabled={projectDisabled}
                                  />
                                </div>
                              </Stack.Item>
                              }
                            </Stack>
                          </div>
                          }
                          <Label>3. {t('financeDetails')}</Label>
                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pUnitPrice"
                                  component={CurrencyNumberFormatField}
                                  lable={t('unitPrice')}
                                  // prefix="€"
                                  disabled={true}
                                  // hasChanged={ true }
                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pTotalPrice"
                                  component={CurrencyNumberFormatField}
                                  lable={t('totalPrice')}
                                  // prefix="€"
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>

                          <Field
                            name="pDocuments"
                            component={'input'}
                            type={'hidden'}
                            disabled={false}
                            hidden
                          />
                          <CustomFileUploader
                            uploadedImages={valuesx?.pDocuments ? getImages(valuesx?.pDocuments) : []}
                            label={'4. ' + t('documents')}
                            uploadUrl={'PO/UploadPoDocuments'}
                            onSaveSuccess={(response: any) => {
                              if (valuesx?.pDocuments && _.isArray(valuesx?.pDocuments)) {
                                valuesx?.pDocuments.push(response);
                              } else {
                                valuesx.pDocuments = [];
                                valuesx?.pDocuments.push(response);
                              }

                              form.mutators.setResourceDocs(valuesx?.pDocuments);
                            }}
                            disable={projectDisabled}
                          />

                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <div className={`${classNames.fullWidth}`}>
                              <Label>{'5.' + t('comments')}</Label>
                              <Field
                                name="pComments"
                                component={CKEditorWrapper}
                                disabled={projectDisabled}
                              />
                            </div>
                          </Stack>

                          <div className={'card-footer'}>
                            <Separator/>
                            <Stack
                              styles={stackStyles}
                              tokens={itemAlignmentsStackTokens}
                            >
                              <Stack.Item
                                align="end"
                                styles={stackFooterBarItemStyles}
                              >
                                <PrimaryButton
                                  className={classNames.actionButton}
                                  // iconProps={ saveIcon }
                                  allowDisabledFocus
                                  onClick={() => {
                                    const formValues = formRef.current.getState().values;
                                    // formValues.pstartdate = formatDateTimeToISOString(formValues.pstartdate.toString())
                                    // formValues.pstopdate = formatDateTimeToISOString(formValues.pstopdate.toString())
                                    let passValue;
                                    if (formValues.pStartDate || formValues.pStopDate) {
                                      passValue = {
                                        ...formValues,
                                        pStartDate: formatDateTimeToISOString(formValues.pStartDate.toString()),
                                        pStopDate: formatDateTimeToISOString(formValues.pStopDate.toString()),
                                      };
                                      props.setResourceChangeProject(passValue, POAccessLevel.PROJECT);
                                    } else {
                                      props.setResourceChangeProject(formValues, POAccessLevel.PROJECT);
                                    }
                                    props.setOpenPOResourceModal();
                                  }}
                                  disabled={projectDisabled}
                                >
                                  <Label className={classNames.actionButton}>
                                    {t('save')}
                                  </Label>
                                </PrimaryButton>
                              </Stack.Item>
                            </Stack>
                          </div>

                          { /* <pre>{ JSON.stringify(values, 0, 2) } </pre>  */}
                        </div>

                      </form>
                    );
                  }}
                />
              </div>
              }

              {!handleHideCu() ? <div
                className={handleHideProject() ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12' : 'ms-Grid-col ms-sm6 ms-md6 ms-lg6'}
                style={{ padding: 0, margin: 0 }}>

                <div className={contentStyles.subHeader}>
                  <Label className={contentStyles.actionButtonLabel}>
                    {t('CU')} {' - ' + (props?.formData && poResource?.projectTitle ? poResource?.projectTitle : props.projectSequance) + ' ◦ ' + poResource?.borTitle + ' ◦ ' + poResource?.cTitle}
                  </Label>
                </div>

                <Form
                  onSubmit={onSubmit}
                  initialValues={poResource}
                  decorators={[calculatorCU]}
                  validate={(values: any) => {
                    const errors: any = {};
                    if (!values?.ccpcId) {
                      errors.resourceTitle = t('resourceRequired');
                    }
                    return errors;
                  }
                  }
                  mutators={{
                    setResourceDocs: (args, state, utils) => {
                      const field = state.fields.cDocuments;
                      field.change(args[0]);
                    },
                    setCuComment: (args, state, utils) => {
                      const field = state.fields.cComments;
                      field.change(args[0]);
                    },
                    setCUCPC: async (args, state, utils) => {
                      const fieldId = state.fields.ccpcId;
                      const fieldTitle = state.fields.cTitle;
                      const fieldmou = state.fields.cMou;
                      const fieldinitPrice = state.fields.cUnitPrice;
                      if (args && args[0] && args[0].id) {
                        fieldId.change(args[0].id);
                        fieldTitle.change(args[0].name);
                        await client.get(
                          `CorporateProductCatalog/Read/${args[0].id}`,
                          // { headers: { project: props.borResource.projectTitle } }
                        ).then((response: any) => {
                          if (response.data.result && response.data?.result?.inventoryPrice) {

                            fieldinitPrice.change(response.data.result.inventoryPrice);

                          } else {
                            fieldinitPrice.change(0);
                          }

                          if (response.data.result && response.data?.result?.cpcBasicUnitOfMeasure) {
                            fieldmou.change(response.data?.result?.cpcBasicUnitOfMeasure?.text);
                          }
                        });
                      } else {
                        fieldId.change(null);
                        fieldTitle.change(null);
                        fieldmou.change(null);
                        fieldinitPrice.change(0);
                      }
                    },
                  }}
                  // validate={ validate }
                  render={({
                             handleSubmit,
                             form,
                             submitting,
                             pristine,
                             values,
                           }) => {
                    // props.submit(handleSubmit);
                    formRefcu.current = form;
                    const valuesx: any = values;

                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="" style={{ padding: 16 }}>
                          <div className={'card-header'}>
                            <Label>1. {t('referenceDetails')}</Label>

                          </div>
                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="projectTitle"
                                  component={TextFieldFF}
                                  lable={t('projectTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="pbsTitle"
                                  component={TextFieldFF}
                                  lable={t('productTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>

                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="cCrossReference"
                                  component={TextFieldFF}
                                  lable={t('crossReference')}
                                  disabled={cuDisabled}
                                  hasChanged={(valuesx.pCrossReference !== valuesx.cCrossReference)}

                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="borTitle"
                                  component={TextFieldFF}
                                  lable={t('borTitle')}
                                  disabled={true}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>


                          <Label>2. {t('articleDetails')}</Label>


                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                {cuDisabled &&
                                <Field
                                  name="cTitle"
                                  component={TextFieldFF}
                                  lable={t('resourceTitle')}
                                  disabled={true}
                                  hasChanged={(valuesx.pTitle !== valuesx.cTitle)}
                                />}
                                {!cuDisabled &&
                                <div>
                                  <Label
                                    style={(valuesx.pTitle !== valuesx.cTitle) ? { color: iconColors.iconActiveColor } : {}}>{t('resourceTitle')}</Label>
                                  <CustomDropdown
                                    reference={''}
                                    // onChange={()=> props.handleVendorChange(props.vendor)}
                                    onChange={(value: any, label: string) => {
                                      if (value) {
                                        const cpc = {
                                          id: value.id,
                                          name: label,
                                          mou: value.basicUnitOfMeasure,
                                          sequenceCode: value.resourceNumber,
                                        };
                                        form.mutators.setCUCPC(cpc);
                                      } else {
                                        form.mutators.setCUCPC(null);
                                      }
                                    }}
                                    selectedOption={(valuesx && valuesx?.cTitle ? {
                                      value: valuesx?.ccpcId,
                                      label: valuesx?.cTitle,
                                    } : null)}
                                    promiseOptions={cpcOptions}
                                    validationMessage={form.getState()?.errors?.resourceTitle}
                                    required={true}
                                  />
                                </div>
                                }

                                <Field
                                  name="ccpcId"
                                  component={'input'}
                                  type={'hidden'}
                                  disabled={false}
                                  hidden
                                />

                                <Field
                                  name="cTitle"
                                  component={'input'}
                                  disabled={false}
                                  hidden
                                />
                              </div>
                            </Stack.Item>

                          </Stack>

                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="cQuantity"
                                  component={FixedDecimalNumberFormatField}
                                  lable={t('quantity')}
                                  disabled={cuDisabled}
                                  hasChanged={(valuesx.pQuantity !== valuesx.cQuantity)}
                                  // parse={ value => parseInt(value) }

                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="cMou"
                                  component={TextFieldFF}
                                  lable={t('mou')}
                                  disabled={true}
                                  hasChanged={(valuesx.pMou !== valuesx.cMou)}
                                />
                              </div>
                            </Stack.Item>
                          </Stack>
                          {(props.resourceType === RESOURCE_TYPE.LABOUR || props.resourceType === RESOURCE_TYPE.TOOLS) &&
                          <div>
                            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name="cStartDate"
                                    component={DatePickerAdapter}
                                    lable={t('startDate')}
                                    disabled={cuDisabled}
                                    parse={value => value && new Date(value)}
                                    format={value => (value ? new Date(value) : null)}
                                    hasChanged={(valuesx.pStartDate !== valuesx.cStartDate)}
                                  />
                                </div>
                              </Stack.Item>
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>

                                  <FormSpy
                                    subscription={{ /* ... */ }}
                                    render={({ form }) => (

                                      <Field
                                        name="cStopDate"
                                        component={DatePickerAdapter}
                                        lable={t('stopDate')}
                                        disabled={cuDisabled}
                                        parse={value => value && new Date(value)}
                                        format={value => (value ? new Date(value) : null)}
                                        minDate={new Date(form.getFieldState('cStartDate')?.value)}
                                        hasChanged={(valuesx.pStopDate !== valuesx.cStopDate)}
                                      >


                                      </Field>
                                    )}
                                  />
                                </div>
                              </Stack.Item>
                            </Stack>
                            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name="cNumberOfDate"
                                    component={NumberField}
                                    lable={t('numberofDays')}
                                    disabled={cuDisabled}
                                    type={'number'}
                                    step={1}
                                    hasChanged={(valuesx.pNumberOfDate !== valuesx.cNumberOfDate)}
                                    parse={value => value.replace(/[^0-9]/g, '')}

                                  />
                                </div>
                              </Stack.Item>
                              {props.resourceType === RESOURCE_TYPE.LABOUR &&
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name={'cFullTimeEmployee'}
                                    component={NumberField}
                                    lable={t('FTE')}
                                    disabled={cuDisabled}
                                    type={'number'}
                                    step={1}
                                    hasChanged={(valuesx.pFullTimeEmployee !== valuesx.cFullTimeEmployee)}
                                    parse={value => value.replace(/[^0-9]/g, '')}
                                  />
                                </div>
                              </Stack.Item>
                              }
                              {props.resourceType === RESOURCE_TYPE.TOOLS &&
                              <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                  <Field
                                    name={'cdevices'}
                                    component={NumberField}
                                    lable={t('devices')}
                                    disabled={cuDisabled}
                                    type={'number'}
                                    step={1}
                                    hasChanged={(valuesx.pdevices !== valuesx.cdevices)}
                                    parse={value => value.replace(/[^0-9]/g, '')}
                                  />
                                </div>
                              </Stack.Item>
                              }
                            </Stack>
                          </div>
                          }
                          <Label>3. {t('financeDetails')}</Label>
                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="cUnitPrice"
                                  component={CurrencyNumberFormatField}
                                  lable={t('unitPrice')}
                                  disabled={cuDisabled}
                                  step={1}
                                  hasChanged={(valuesx.pUnitPrice !== valuesx.cUnitPrice)}
                                  // parse={ value => !value ? null : parseFloat(value).toFixed(2) }
                                />
                              </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="cTotalPrice"
                                  component={CurrencyNumberFormatField}
                                  lable={t('totalPrice')}
                                  disabled={true}
                                  hasChanged={(parseFloat(valuesx.pTotalPrice) !== parseFloat(valuesx.cTotalPrice))}


                                />
                              </div>
                            </Stack.Item>
                          </Stack>


                          <CustomFileUploader
                            uploadedImages={valuesx?.cDocuments ? getImages(valuesx?.cDocuments) : []}
                            label={'4. ' + t('documents')}
                            uploadUrl={'PO/UploadPoDocuments'}
                            onSaveSuccess={(response: any) => {
                              if (valuesx?.cDocuments && _.isArray(valuesx?.cDocuments)) {
                                valuesx?.cDocuments.push(response);
                              } else {
                                valuesx.cDocuments = [];
                                valuesx?.cDocuments.push(response);
                              }

                              form.mutators.setResourceDocs(valuesx?.cDocuments);
                            }}
                            disable={cuDisabled}
                          />

                          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                            <div className={`${classNames.fullWidth}`}>
                              <Label>{'5.' + t('comments')}</Label>
                              <Field
                                name="cComments"
                                component={CKEditorWrapper}
                                disabled={cuDisabled}
                              />
                            </div>
                          </Stack>

                          <div className={'card-footer'}>
                            <Separator/>
                            <Stack
                              styles={stackStyles}
                              tokens={itemAlignmentsStackTokens}
                            >
                              <Stack.Item
                                align="end"
                                styles={stackFooterBarItemStyles}
                              >
                                <PrimaryButton
                                  className={classNames.actionButton}
                                  // iconProps={ saveIcon }
                                  allowDisabledFocus
                                  onClick={() => {
                                    let comment = checkIsValueUpdate(formRefcu.current.getState().values);
                                    if (comment) {
                                      form.mutators.setCuComment(comment);
                                    }
                                    const formValues = formRefcu.current.getState().values;
                                    const formError: any = formRefcu.current.getState().errors;
                                    if (formError?.resourceTitle) {
                                      return;
                                    }
                                    // formValues.pstartdate = formatDateTimeToISOString(formValues.pstartdate.toString())
                                    // formValues.pstopdate = formatDateTimeToISOString(formValues.pstopdate.toString())
                                    let passValue;
                                    if (formValues.cStartDate || formValues.cStopDate) {
                                      passValue = {
                                        ...formValues,
                                        cStartDate: formatDateTimeToISOString(formValues.cStartDate.toString()),
                                        cStopdate: formatDateTimeToISOString(formValues.cStopDate.toString()),
                                      };
                                      props.setResourceChangeProject(passValue, POAccessLevel.CU);
                                    } else {
                                      props.setResourceChangeProject(formValues, POAccessLevel.CU);
                                    }
                                    props.setOpenPOResourceModal();
                                  }}
                                  disabled={cuDisabled}
                                >
                                  <Label className={classNames.actionButton}>
                                    {t('save')}
                                  </Label>
                                </PrimaryButton>
                              </Stack.Item>
                            </Stack>
                          </div>
                          { /* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
                        </div>
                      </form>
                    );
                  }}
                />
              </div> : false}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default POResourceModal;
