import {
  CREATE_ABSENCE_EP,
  CREATE_CONTRACT_EP,
  DELETE_ABSENCE_EP,
  DELETE_CONTRACT_EP,
  DELETE_HUMAN_RESOURCE_EP,
  GET_ABSENCE_LEAVETYPE_DROPDOWN_EP,
  GET_CONTRACT_TYPE_DROPDOWN_EP,
  GET_HUMAN_RESOURCE_ROLES_DROPDOWN_EP,
  HUMAN_RESOURCE_SAVE_EP,
  LOAD_ASSIGNED_HUMAN_RESOURCES_EP,
  READ_ABSENCE_EP,
  READ_ABSENCE_LIST_BY_PERSON_EP,
  READ_CONTRACT_LIST_BY_PERSON_EP,
  READ_HUMAN_RESOURCE_BY_ID_EP,
  READ_HUMAN_RESOURCE_DROPDOWN_EP,
  READ_HUMAN_RESOURCE_LIST_EP,
  READ_HUMAN_RESOURCE_SHORTCUT_PANE_EP,
  READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_EP,
  READ_LABOUR_HISTORY_BY_PERSON_ID_EP,
  UPLOAD_CONTRACT_DOC_EP,
} from '../shared/endpoints';
import {
  CLEAR_HUMAN_RESOURCE_FORM_DATA,
  CLEAR_HUMAN_RESOURCEUID,
  CREATE_ABSENCE,
  CREATE_ABSENCE_FAIL,
  CREATE_ABSENCE_SUCCESS,
  CREATE_CONTRACT,
  CREATE_CONTRACT_FAIL,
  CREATE_CONTRACT_SUCCESS,
  DELETE_ABSENCE,
  DELETE_ABSENCE_FAIL,
  DELETE_ABSENCE_SUCCESS,
  DELETE_CONTRACT,
  DELETE_CONTRACT_FAIL,
  DELETE_CONTRACT_SUCCESS,
  DELETE_HUMAN_RESOURCE,
  DELETE_HUMAN_RESOURCE_FAIL,
  DELETE_HUMAN_RESOURCE_SUCCESS,
  GET_ABSENCE_LEAVETYPE_DROPDOWN,
  GET_ABSENCE_LEAVETYPE_DROPDOWN_FAIL,
  GET_ABSENCE_LEAVETYPE_DROPDOWN_SUCCESS,
  GET_CONTRACT_TYPE_DROPDOWN,
  GET_CONTRACT_TYPE_DROPDOWN_FAIL,
  GET_CONTRACT_TYPE_DROPDOWN_SUCCESS,
  GET_HUMAN_RESOURCE_ROLES_DROPDOWN,
  GET_HUMAN_RESOURCE_ROLES_DROPDOWN_FAIL,
  GET_HUMAN_RESOURCE_ROLES_DROPDOWN_SUCCESS,
  LOAD_ASSIGNED_HUMAN_RESOURCES,
  LOAD_ASSIGNED_HUMAN_RESOURCES_FAIL,
  LOAD_ASSIGNED_HUMAN_RESOURCES_SUCCESS,
  LOAD_HUMAN_RESOURCE_BY_ID,
  LOAD_HUMAN_RESOURCE_BY_ID_FAIL,
  LOAD_HUMAN_RESOURCE_BY_ID_SUCCESS,
  LOAD_HUMAN_RESOURCE_DROPDOWN,
  LOAD_HUMAN_RESOURCE_DROPDOWN_FAIL,
  LOAD_HUMAN_RESOURCE_DROPDOWN_SUCCESS,
  LOAD_HUMAN_RESOURCE_LSITPANE,
  LOAD_HUMAN_RESOURCE_LSITPANE_FAIL,
  LOAD_HUMAN_RESOURCE_LSITPANE_SUCCESS,
  LOAD_HUMAN_RESOURCE_SHORTCUTPANE,
  LOAD_HUMAN_RESOURCE_SHORTCUTPANE_FAIL,
  LOAD_HUMAN_RESOURCE_SHORTCUTPANE_SUCCESS,
  READ_ABSENCE,
  READ_ABSENCE_FAIL,
  READ_ABSENCE_LIST_BY_PERSON,
  READ_ABSENCE_LIST_BY_PERSON_FAIL,
  READ_ABSENCE_LIST_BY_PERSON_SUCCESS,
  READ_ABSENCE_SUCCESS,
  READ_CONTRACT_LIST_BY_PERSON,
  READ_CONTRACT_LIST_BY_PERSON_FAIL,
  READ_CONTRACT_LIST_BY_PERSON_SUCCESS,
  READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID,
  READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_FAIL,
  READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_SUCCESS,
  READ_LABOUR_HISTORY_BY_PERSON_ID,
  READ_LABOUR_HISTORY_BY_PERSON_ID_FAIL,
  READ_LABOUR_HISTORY_BY_PERSON_ID_SUCCESS,
  RESET_HUMAN_RESOURCE_IS_CHANGE,
  SAVE_ACTIVE_STATE_ATTR,
  SAVE_CU_HR_STATE_ATTRIBUTE,
  SAVE_HUMAN_RESOURCE,
  SAVE_HUMAN_RESOURCE_FAIL,
  SAVE_HUMAN_RESOURCE_SUCCESS,
  SET_HUMAN_RESOURCE_UID,
  UPLOAD_CONTRACT_DOC,
  UPLOAD_CONTRACT_DOC_FAIL,
  UPLOAD_CONTRACT_DOC_SUCCESS,
} from '../actionTypes/humanResourceActionTypes';
import {v4 as uuidv4} from 'uuid';
import {POFilter, PurchaseOrder} from '../types/purchaseOrder';


const defaultState: any = {
  hrList: [],
  isHRListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  hrDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  hrShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingHumanResource: false,
  hrId: null,
  organizationTaxonomyLevelId: null,
  hrRolesDropdown: [],
  labourHistory: {},
  absenceData: {},
  leaveTypes: [],
  currentActiveSection: null,
  isHumanResourceDeleted: false,
  isHumanDeleteModelOpen: false,
  assignedHumanResources: [],
  contractListData: {},
  contractTypes: [],
  uploadedContractDocLink: null,
  contractUploadStatus: false,
};

export default function humanResourceReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_HUMAN_RESOURCE_SHORTCUTPANE:
        return { ...state };
      case LOAD_HUMAN_RESOURCE_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          hrShortCutPaneData: action.payload.data.result,
        };
      case LOAD_HUMAN_RESOURCE_SHORTCUTPANE_FAIL:
        return { ...state, hrShortCutPaneData: [] };
      case LOAD_HUMAN_RESOURCE_LSITPANE:
        return { ...state, isHRListLoaded: false };
      case LOAD_HUMAN_RESOURCE_LSITPANE_SUCCESS:
        return {
          ...state,
          isHRListLoaded: true,
          hrList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_HUMAN_RESOURCE_LSITPANE_FAIL:
        return {
          ...state,
          isHRListLoaded: true,
          hrList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_HUMAN_RESOURCE_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_HUMAN_RESOURCE_DROPDOWN:
        return { ...state, hrDropDowns: {} };
      case LOAD_HUMAN_RESOURCE_DROPDOWN_SUCCESS:
        return { ...state, hrDropDowns: action.payload.data.result };
      case LOAD_HUMAN_RESOURCE_DROPDOWN_FAIL:
        return {
          ...state,
          hrDropDowns: {},
        };
      case LOAD_HUMAN_RESOURCE_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingHumanResource: true };
      case LOAD_HUMAN_RESOURCE_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingHumanResource: false,
        };
      case LOAD_HUMAN_RESOURCE_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingHumanResource: false,
        };

      case SAVE_HUMAN_RESOURCE:
        return { ...state, showProgressBar: true, reloadListPane: false, hrId: null };
      case SAVE_HUMAN_RESOURCE_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          hrId: action.payload.data.result,
        };
      case SAVE_HUMAN_RESOURCE_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          hrId: action.payload.data.result,
        };

      case SET_HUMAN_RESOURCE_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_HUMAN_RESOURCEUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_HUMAN_RESOURCE_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
          absenceData: {},
          labourHistory: {},
          contractListData: {},
        };

      case READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          hrId: null,
          organizationTaxonomyLevelId: null,
        };
      case READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          organizationTaxonomyLevelId: action.payload.data.result,
        };
      case READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          organizationTaxonomyLevelId: null,
        };

      case READ_LABOUR_HISTORY_BY_PERSON_ID:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          hrId: null,
          labourHistory: {},
        };
      case READ_LABOUR_HISTORY_BY_PERSON_ID_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          labourHistory: formatedPayloadToLabourHistory(action.payload.data.result),
        };
      case READ_LABOUR_HISTORY_BY_PERSON_ID_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          labourHistory: {},
        };

      case READ_ABSENCE:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          hrId: null,
          absenceData: {},
        };
      case READ_ABSENCE_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          absenceData: action.payload.data.result,
        };
      case READ_ABSENCE_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          absenceData: {},
        };

      case READ_ABSENCE_LIST_BY_PERSON:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          hrId: null,
          absenceData: {},
        };
      case READ_ABSENCE_LIST_BY_PERSON_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          absenceData: formattedPayloadToLeaveType(action.payload.data.result),
        };
      case READ_ABSENCE_LIST_BY_PERSON_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          absenceData: {},
        };

      case CREATE_ABSENCE:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          hrId: null,
        };
      case CREATE_ABSENCE_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
        };
      case CREATE_ABSENCE_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
        };

      case GET_HUMAN_RESOURCE_ROLES_DROPDOWN:
        return {
          ...state,
          hrRolesDropdown: [],
        };
      case GET_HUMAN_RESOURCE_ROLES_DROPDOWN_SUCCESS:
        return {
          ...state,
          hrRolesDropdown: action.payload.data.result,
        };
      case GET_HUMAN_RESOURCE_ROLES_DROPDOWN_FAIL:
        return {
          ...state,
          hrRolesDropdown: [],
        };

      case GET_ABSENCE_LEAVETYPE_DROPDOWN:
        return {
          ...state,
          leaveTypes: [],
        };
      case GET_ABSENCE_LEAVETYPE_DROPDOWN_SUCCESS:
        return {
          ...state,
          leaveTypes: action.payload.data.result,
        };
      case GET_ABSENCE_LEAVETYPE_DROPDOWN_FAIL:
        return {
          ...state,
          leaveTypes: [],
        };
      case DELETE_HUMAN_RESOURCE:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          isHumanResourceDeleted: false,
        };
      case DELETE_HUMAN_RESOURCE_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: true,
          isHumanResourceDeleted: true,
        };
      case DELETE_HUMAN_RESOURCE_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          isHumanResourceDeleted: false,
        };

      case LOAD_ASSIGNED_HUMAN_RESOURCES:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          assignedHumanResources: [],
        };
      case LOAD_ASSIGNED_HUMAN_RESOURCES_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: true,
          assignedHumanResources: action.payload.data.result,
        };
      case LOAD_ASSIGNED_HUMAN_RESOURCES_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          assignedHumanResources: [],
        };

      case READ_CONTRACT_LIST_BY_PERSON:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          contractListData: {},
        };
      case READ_CONTRACT_LIST_BY_PERSON_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          contractListData: action.payload.data.result,
        };
      case READ_CONTRACT_LIST_BY_PERSON_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          contractListData: {},
        };
      case GET_CONTRACT_TYPE_DROPDOWN:
        return {
          ...state,
          contractTypes: [],
        };
      case GET_CONTRACT_TYPE_DROPDOWN_SUCCESS:
        return {
          ...state,
          contractTypes: action.payload.data.result,
        };
      case GET_CONTRACT_TYPE_DROPDOWN_FAIL:
        return {
          ...state,
          contractTypes: [],
        };
      case UPLOAD_CONTRACT_DOC:
        return {
          ...state,
          showProgressBar: true,
          uploadedContractDocLink: null,
          contractUploadStatus: false,
        };
      case UPLOAD_CONTRACT_DOC_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          uploadedContractDocLink: action.payload.data.result,
          contractUploadStatus: true,
        };
      case UPLOAD_CONTRACT_DOC_FAIL:
        return {
          ...state,
          showProgressBar: false,
          uploadedContractDocLink: null,
          contractUploadStatus: true,
        };


      // eslint-disable-next-line no-case-declarations
      case SAVE_ACTIVE_STATE_ATTR:
      case SAVE_CU_HR_STATE_ATTRIBUTE:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      default:
        return state;
    }
  }
}

export const readHumanResourceShortCutPaneData = () => {
  return {
    types: [
      LOAD_HUMAN_RESOURCE_SHORTCUTPANE,
      LOAD_HUMAN_RESOURCE_SHORTCUTPANE_SUCCESS,
      LOAD_HUMAN_RESOURCE_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_HUMAN_RESOURCE_SHORTCUT_PANE_EP } },
  };
};

export const readHumanResourceDropDownData = () => {
  return {
    types: [
      LOAD_HUMAN_RESOURCE_DROPDOWN,
      LOAD_HUMAN_RESOURCE_DROPDOWN_SUCCESS,
      LOAD_HUMAN_RESOURCE_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_HUMAN_RESOURCE_DROPDOWN_EP } },
  };
};


export const filterHumanResourceListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_HUMAN_RESOURCE_LSITPANE,
      LOAD_HUMAN_RESOURCE_LSITPANE_SUCCESS,
      LOAD_HUMAN_RESOURCE_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_HUMAN_RESOURCE_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByHumanResourceId = (id: string) => {
  return {
    types: [
      LOAD_HUMAN_RESOURCE_BY_ID,
      LOAD_HUMAN_RESOURCE_BY_ID_SUCCESS,
      LOAD_HUMAN_RESOURCE_BY_ID_FAIL],
    payload: {
      request: {
        url: READ_HUMAN_RESOURCE_BY_ID_EP + id,
      },
    },
  };
};

export const resetIsChange = () => {
  return { type: RESET_HUMAN_RESOURCE_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_HUMAN_RESOURCE,
      SAVE_HUMAN_RESOURCE_SUCCESS,
      SAVE_HUMAN_RESOURCE_FAIL],
    payload: {
      request: {
        url: HUMAN_RESOURCE_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const readHumanResourceTaxonomyIdByPersonId = (personId: string) => {
  return {
    types: [
      READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID,
      READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_SUCCESS,
      READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_FAIL,
    ],
    payload: {
      request: {
        url: READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_EP + personId,
        method: 'GET',
      },
    },
  };
};

export const readLabourHistoryByPersonId = (personId: string) => {
  return {
    types: [
      READ_LABOUR_HISTORY_BY_PERSON_ID,
      READ_LABOUR_HISTORY_BY_PERSON_ID_SUCCESS,
      READ_LABOUR_HISTORY_BY_PERSON_ID_FAIL,
    ],
    payload: {
      request: {
        url: READ_LABOUR_HISTORY_BY_PERSON_ID_EP + personId,
        method: 'GET',
      },
    },
  };
};

export const readByAbsenceId = (id: string) => {
  return {
    types: [
      READ_ABSENCE,
      READ_ABSENCE_SUCCESS,
      READ_ABSENCE_FAIL,
    ],
    payload: {
      request: {
        url: READ_ABSENCE_EP + id,
      },
    },
  };
};

export const readAbsenceByPersonId = (personId: string) => {
  return {
    types: [
      READ_ABSENCE_LIST_BY_PERSON,
      READ_ABSENCE_LIST_BY_PERSON_SUCCESS,
      READ_ABSENCE_LIST_BY_PERSON_FAIL,
    ],
    payload: {
      request: {
        url: READ_ABSENCE_LIST_BY_PERSON_EP + personId,
      },
    },
  };
};

export const createAbsence = (absenceData: any) => {
  return {
    types: [
      CREATE_ABSENCE,
      CREATE_ABSENCE_SUCCESS,
      CREATE_ABSENCE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_ABSENCE_EP,
        method: 'POST',
        data: absenceData,
      },
    },
  };
};

export const createContract = (contractData: any) => {
  return {
    types: [
      CREATE_CONTRACT,
      CREATE_CONTRACT_SUCCESS,
      CREATE_CONTRACT_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_CONTRACT_EP,
        method: 'POST',
        data: contractData,
      },
    },
  };
};

export const uploadContractDoc = (formData: any) => {  //
  return {
    types: [UPLOAD_CONTRACT_DOC, UPLOAD_CONTRACT_DOC_SUCCESS, UPLOAD_CONTRACT_DOC_FAIL],
    payload: {
      request: {
        url: UPLOAD_CONTRACT_DOC_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const deleteAbsence = (data: string[]) => {
  return {
    types: [
      DELETE_ABSENCE,
      DELETE_ABSENCE_SUCCESS,
      DELETE_ABSENCE_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_ABSENCE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteContract = (data: string[]) => {
  return {
    types: [
      DELETE_CONTRACT,
      DELETE_CONTRACT_SUCCESS,
      DELETE_CONTRACT_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_CONTRACT_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readHumanResourceRolesDropDownData = () => {
  return {
    types: [
      GET_HUMAN_RESOURCE_ROLES_DROPDOWN,
      GET_HUMAN_RESOURCE_ROLES_DROPDOWN_SUCCESS,
      GET_HUMAN_RESOURCE_ROLES_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        url: GET_HUMAN_RESOURCE_ROLES_DROPDOWN_EP,
      },
    },
  };
};

export const getAbsenceLeaveTypeDropdown = () => {
  return {
    types: [
      GET_ABSENCE_LEAVETYPE_DROPDOWN,
      GET_ABSENCE_LEAVETYPE_DROPDOWN_SUCCESS,
      GET_ABSENCE_LEAVETYPE_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        url: GET_ABSENCE_LEAVETYPE_DROPDOWN_EP,
      },
    },
  };
};

export const getContractTypeDropdown = () => {
  return {
    types: [
      GET_CONTRACT_TYPE_DROPDOWN,
      GET_CONTRACT_TYPE_DROPDOWN_SUCCESS,
      GET_CONTRACT_TYPE_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        url: GET_CONTRACT_TYPE_DROPDOWN_EP,
      },
    },
  };
};

export const deleteHumanResource = (id: string) => {
  return {
    types: [
      DELETE_HUMAN_RESOURCE,
      DELETE_HUMAN_RESOURCE_SUCCESS,
      DELETE_HUMAN_RESOURCE_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_HUMAN_RESOURCE_EP + id,
        method: 'DELETE',
      },
    },
  };
}

export const loadAssignedHumanResources = (id: string) => {
  return {
    types: [
      LOAD_ASSIGNED_HUMAN_RESOURCES,
      LOAD_ASSIGNED_HUMAN_RESOURCES_SUCCESS,
      LOAD_ASSIGNED_HUMAN_RESOURCES_FAIL,
    ],
    payload: {
      request: {
        url: LOAD_ASSIGNED_HUMAN_RESOURCES_EP + id,
        method: 'GET',
      },
    },
  };
}

export const readContractListByPersonId = (personId: string) => {
  return {
    types: [
      READ_CONTRACT_LIST_BY_PERSON,
      READ_CONTRACT_LIST_BY_PERSON_SUCCESS,
      READ_CONTRACT_LIST_BY_PERSON_FAIL,
    ],
    payload: {
      request: {
        url: READ_CONTRACT_LIST_BY_PERSON_EP + personId,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_HUMAN_RESOURCE_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_HUMAN_RESOURCE_FORM_DATA };
};

export function saveHRStateAttr(attr: string, value: any) {
  return {
    type: SAVE_ACTIVE_STATE_ATTR,
    [attr]: value,
  };
}


export const saveCuHrAtrr = (attr: string, value: any) => {
  return {
    type: SAVE_CU_HR_STATE_ATTRIBUTE,
    [attr]: value,
  };
};

const formattedPayloadToLeaveType = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        ...item,
        leaveType: item?.leaveType?.key,
        leaveTypeText: item?.leaveType?.text,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formatedPayloadToLabourHistory = (response: any) => {
  if (response) {
    return response.map((item: any) => {
      return {
        ...item,
      };
    });
  } else {
    return [];
  }
};
