import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Field, Form} from 'react-final-form';
import {
    Calendar,
    DayOfWeek,
    DefaultButton,
    defaultCalendarStrings,
    FirstWeekOfYear,
    FontWeights,
    getTheme,
    IBasePickerSuggestionsProps,
    ITag,
    Label,
    mergeStyleSets,
    PrimaryButton,
    TagPicker
} from "@fluentui/react";
import {createForm} from "final-form";
import DefaultCustomModal from '../../customeModal/defaultCustomModal';
import {messageService} from '../../../services/messageService';
import {saveMyDayPlanningStateAttr} from "../../../reducers/myDayPlanningReducer";
import {createPmolCloneForMultipleDays} from "../../../reducers/pmolReducer";
import _ from "lodash";
import {uPrinceTheme} from "../../../../theme";
import {useId} from '@fluentui/react-hooks';
import ValidationLabel from '../../ValidationLabel/component';
import moment from "moment";
import {formatDateTimeToISOString} from "../../util";

const theme = getTheme();

const styles = mergeStyleSets({
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '2px 2px 2px 10px',
            backgroundColor: uPrinceTheme.palette.themePrimary,
            fontSize: 18,
            color: 'white',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '10px 24px',
        overflowY: 'auto',
        minWidth: '23vw',
        minHeight: '10vh',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
        maxHeight: '80vh',
    },
    footer: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 16px 14px 16px',
            textAlign: 'end',
        },
    ]
});

export default function CopyPmolModal() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const today = useConst(new Date());
    const pickerId = useId('inline-picker');

    const pmolCopyState = useSelector((state: any) => state.mydPlan.pmolCopyState);
    const showProgressBar = useSelector((state: any) => state.pmol.showProgressBar);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<any>({});
    const [selectedItems, setSelectedItems] = useState<ITag[]>([]);
    const [restrictedDates, setRestrictedDates] = useState<Date[]>([]);

    useEffect(() => {

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.openTaxonomyModal) {

                }
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (pmolCopyState?.isCopyModalOpen) {
            setIsOpen(true);
            setEvent(pmolCopyState.event);
            // console.log('event >', pmolCopyState.event);
        } else {
            setIsOpen(false);
            setEvent({});
        }
    }, [pmolCopyState]);

    const getInitialFormValues = () => {
        return {
            // projectName: {value: null, label: null},
        }
    }

    const onSubmit = async (values: any) => {
        const formattedDates = restrictedDates.map((date: any) => {
            return formatDateTimeToISOString(date)
        });

        if (values) {
            const data = {
                pmolId: event?.projectMoleculeId,
                contractingUinit: event?.contractingUinit,
                projectSequenceCode: event?.projectSequenceCode,
                typeId: event?.typeId,
                executionDate: formattedDates
            };

            await Promise.all([
                dispatch(createPmolCloneForMultipleDays(data))
            ]).then((res: any) => {
                if (res[0]?.payload?.data?.result) {
                    handleClose();
                }
            });
        }
    };

    const formRef = React.useRef(createForm({onSubmit: onSubmit}));

    const handleClose = () => {
        setRestrictedDates([]);
        setSelectedItems([]);
        dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {event: {}, isCopyModalOpen: false}));
    }

    const onSelectDate = (date: Date): void => {
        restrictedDates.push(date);
        const tagList: ITag[] = restrictedDates.map(item => ({
            key: moment(item).format('DD/MM/YYYY'),
            name: moment(item).format('DD/MM/YYYY'),
            date: item
        }));

        setRestrictedDates(restrictedDates);
        setSelectedItems(tagList);
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested tags',
        noResultsFoundText: 'No color tags found',
    };

    const getTextFromItem = (item: ITag) => item.name;

    const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
        return [];
    };

    const onChangeTags = (items: any) => {
        const dates = items.map((item: any) => item.date);

        setSelectedItems(items);
        setRestrictedDates(dates);
    }

    const onRenderFooter = () => {
        return <div className={'footer-btn'}>
            <DefaultButton onClick={handleClose}>
                {t('cancel')}
            </DefaultButton>
            <PrimaryButton type="submit" form={"copy-pmol-form"}>
                {t('copy')}
            </PrimaryButton>
        </div>
    }

    return <div>
        <DefaultCustomModal
            isModalOpen={isOpen}
            onRenderFooter={onRenderFooter}
            title={t('clonePmol')}
            handleClose={handleClose}
            showProgressBar={showProgressBar}
            styles={styles}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={getInitialFormValues()}
                // mutators={{
                //     setProjectName: (args, state) => {
                //         const field = state.fields.projectName;
                //         field.change(args[0]);
                //     },
                // }}
                validate={values => {
                    const errors: any = {};
                    if (_.isEmpty(restrictedDates)) {
                        errors.selectedDates = t('cloneDatesErrMsg');
                    }
                    return errors;
                }}
                render={({
                             handleSubmit,
                             form,
                             submitting,
                             pristine,
                             errors,
                             values,
                         }) => {
                    formRef.current = form;
                    return <form onSubmit={handleSubmit} id="copy-pmol-form" noValidate style={{width: '100%'}}>
                        <div>
                            <Field
                                name="selectedDates"
                            >{({input, meta}) => (
                                <div>
                                    <Label htmlFor={pickerId}>{t('selectedDates')}</Label>
                                    <TagPicker
                                        removeButtonAriaLabel="Remove"
                                        selectionAriaLabel="Selected colors"
                                        onResolveSuggestions={filterSuggestedTags}
                                        getTextFromItem={getTextFromItem}
                                        pickerSuggestionsProps={pickerSuggestionsProps}
                                        disabled={false}
                                        selectedItems={selectedItems ?? []}
                                        onChange={onChangeTags}
                                        inputProps={{
                                            id: pickerId,
                                            readOnly: true,
                                        }}
                                        styles={{
                                            input: {
                                                display:  'none',
                                            },
                                            itemsWrapper:{
                                                maxWidth: '24vw',
                                            }
                                        }}
                                    />
                                    {((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched &&
                                        <ValidationLabel validationMessage={meta.error}/>
                                    }
                                </div>
                            )}
                            </Field>
                            <Field
                                name="calendar"
                            >{({input, meta}) => (
                                <Calendar
                                    highlightSelectedMonth
                                    showGoToToday={false}
                                    restrictedDates={restrictedDates}
                                    onSelectDate={onSelectDate}
                                    strings={defaultCalendarStrings}
                                    firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                />
                            )}
                            </Field>
                        </div>

                        {/*<div style={{padding: 10}}>{JSON.stringify(values, null, 2)}</div>*/}
                    </form>
                }}
            />
        </DefaultCustomModal>
    </div>
}
