import React from 'react';
import '../style/style.css';
import {AnimationStyles, getTheme, ITheme, mergeStyles, Panel, PanelType, Stack} from '@fluentui/react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import history from '../../history';
import {WithTranslation} from 'react-i18next';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import BookMarkPane from '../../shared/bookMarkPane/bookMarkPane';
import ShortCutPane from './shortCutPane/container';
import {getContractingUnit} from '../../shared/util';
import TitlePane from '../../shared/titlePane/titlePane';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {
  clearFormData,
  filterPSListPaneData,
  readByPSId,
  readPSDropDownData,
  readPSShortCutPaneData,
  resetIsChange,
  setUid,
} from '../../reducers/progressStatementReducer';
import DocumentPane from './documentPane/container';
import ListPane from './listPane/container';
import {ListPaneItem, PSListPaneFilter, PSShortCutPaneItem} from '../../types/progressStatement';
import {messageService} from '../../services/messageService';
import ProgressBar from '../../shared/progressBar/progressBar';
import SplitPane, {Pane} from 'react-split-pane';
import CollapseBookMarkPane from '../../shared/collapseBookMarkPane/bookMarkPane';
import {LanguageGetById} from './../../reducers/languageReducer';
import {Language} from '../../types/language';
import i18n from '../../../i18n';
import {resizerStyle} from "../../shared/customStyle/commonStyles";

export interface State {
  selectedShortCutItem: PSShortCutPaneItem;
  psFilter: PSListPaneFilter;
  projectId: string | null;
  psId: string | null;
  formData: any;
  isClone: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  currentActiveSection: string;
  headerFilter: boolean;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  selectedListPaneItemIds: [] | string[];
  hiddenRemoveDialog: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  lang: Language
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  //readPSShortCutPaneData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: PSShortCutPaneItem[];
  filterPSListPaneData: (filterItem: PSListPaneFilter) => void;
  listPaneItems: ListPaneItem[];
  formData: any;
  setUid: () => void;
  clearUid: () => void;
  clearFormData: () => void;
  resetIsChange: () => void;
  uid: string | null;
  readByPSId: (id: string) => void;
  cloneps: (id: string) => any;
  readPSDropDownData: () => void;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  LanguageGetById: (id: string) => void;
  getKeyWord: () => void;


}

const contentStyle = mergeStyles({
  height: 'calc(100vh - 250px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

class LanguageMainLayout extends React.Component<any, any> {
  _isMounted = false;
  isEdit = false;
  theme: ITheme = getTheme();
  subscription: any;
  //i18n.t(
  private bookmarkList = [
    {
      id: 1,
      link: 'ps-header',
      label: i18n.t('Language'),
    },
    {
      id: 2,
      link: 'ps-customer-information',
      label: i18n.t('language keys'),
    },

    {
      id: 4,
      link: 'history',
      label: i18n.t('history'),
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      selectedShortCutItem: { key: null, id: null, name: 'All', type: 'all', value: null },
      psFilter: new PSListPaneFilter(),
      selectedListPaneItemIds: [],
      projectId: null,
      psId: null,
      hiddenRemoveDialog: true,
      isClone: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      currentActiveSection: '1',
      showDetails: false,
      formData: null,
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: screen.width,
      lang: {
        id: null,
        country: null,
        lang: null,
        code: null,
      },
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this.props.readPSShortCutPaneData();
    this.getListPaneData();
    this.handleUrlChange();
    this.props.readPSDropDownData();
    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    if (location.pathname.split('/').pop() === 'new' && this.state.formData && this.state.formData.id === null) {
      this.createNew(true);
    }
    this.setState({
      formData: this.props.formData,
    }, this.handleShowDocumentPane);
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            reSizer: (this.state.reSizer === 300) ? screen.width / 2 : 300,
            isDrag: (!this.state.isDrag),
            collapseBookmarkPane: (!this.state.collapseBookmarkPane),
            resetLayout: (!this.state.resetLayout),
          });
        }

        if (data && data.data && data.data.hasOwnProperty('isLoadingPSData') && data.data.isLoadingPSData) {
          this.setState({ isDisableNewButton: true });
        } else if (data.data.hasOwnProperty('isLoadingPSData') && !data.data.isLoadingPSData) {
          this.setState({ isDisableNewButton: false });
        }
      }

    });


  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange();
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.psFilter != this.props.location.state.psFilter
    ) {
      if (this.props.location.state.psFilter) {
        this.setState(
          {
            psFilter: this.props.location.state.psFilter,
          },
          this.reloadListPaneItem,
        );
      }
    }


  }

  handleUrlChange() {
    let lastParam = this.props.match.url.split('/').pop();
    let projectId = this.props.match.params.id;
    let langCode = this.props.match.params.lang
      ? this.props.match.params.lang
      : null;
    this.setState(
      {
        isEdit: !!this.props.match.params.id,
        projectId: projectId,
        psId: langCode,
        isDisableNewButton: langCode ? true : false,
        isDrag: false,
        resetLayout: true,
        collapseBookmarkPane: false,
      },
      this.handleShowDocumentPane,
    );
    if (langCode) {
      //this.props.readByPSId(langCode);
      this.props.LanguageGetById(langCode);

    }
  }

  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.showDetails) {
        if (this.state.width > 1100) {
          this.setState({ showPanel: false });
        } else {
          this.setState({ showPanel: true });
        }
      }
    }
  }

  private handleShowDocumentPane = () => {
    let langCode = this.props.match.params.lang
      ? this.props.match.params.lang
      : null;
    if (langCode) {
      if (this.state.width > 1100) {
        this.setState({ showPanel: false, showDetails: true, reSizer: screen.width / 2 });
      } else {
        this.setState({ showPanel: true, showDetails: true, reSizer: screen.width / 2 });
      }
    } else if (location.pathname.split('/').pop() === 'new') {
      if (this.state.width > 1100) {
        this.setState({ showPanel: false, showDetails: true, reSizer: screen.width / 2 });
      } else {
        this.setState({ showPanel: true, showDetails: true, reSizer: screen.width / 2 });
      }
    } else {
      //this.props.resetFormData();
      this.setState({ showPanel: false, showDetails: false, reSizer: screen.width });
    }
  };

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions);
    this.subscription.unsubscribe();
    //this.props.resetFormData();
  }

  getListPaneData = () => {
    this.props.filterPSListPaneData(this.state.psFilter);
  };

  private handleSelectedListPaneItem = (
    selectedListPaneItemIds: [] | string[],
  ) => {
    this.setState({
      selectedListPaneItemIds: selectedListPaneItemIds,
      isClone: false,
    });
  };


  private createNew = (create: boolean): void => {
    this.props.clearFormData();
    this.props.setUid();
    let formData: any = {
      id: this.props.uid ? this.props.uid : null,
      progressStatementId: null,
      title: null,
      headerTitle: null,
      name: null,
      projectTypeId: null,
      projectType: null,
      projectStatusId: null,
      projectStatus: null,
      projectCostId: null,
      projectCompletionDate: null,
      workPeriodFrom: null,
      workPeriodTo: null,
      purchaseOrderNumber: null,
      historyLog: null,
      resources: [],
      isSaved: false,
      date: null,
      isProjectFinished: false,
    };
    this.setState({
      formData: formData,
    });
    //history.push(`/CU/${getContractingUnit()}/project/${getProject()}/language/new`);
    history.push(`/translations-dashboard/new`);

  };

  reloadListPaneItem = () => {
    //
    this.props.resetIsChange();
    setTimeout(() => {
      this.getListPaneData();
    }, 100);
    //this.props.getExcelData();
  };

  private handleActionBarSave = (save: boolean): void => {

  };

  private getDocumentPaneView = () => {
    return (
      <div>
        <Stack
          className="project-list-header right-panel"
          style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
          styles={{ root: { width: '100%', paddingRight: 0 } }}
        >
          <TitlePane
            close={true}
            headerTitle={
              this.state.formData ? this.state.formData.title : ''
            }
            collapse={true}
            isCollapse={this.state.collapseBookmarkPane}
          />
          <CommandBarRight
            createNew={(create: any) => this.createNew(create)}
            saveProductCatalogue={(save: boolean) => {
              this.handleActionBarSave(save);
            }}
            formData={this.state.formData}
            isLoading={this.props.showProgressBar}
            handleRemove={() => this._handleRemove()}
            isDisableNewButton={this.state.isDisableNewButton}
          />
          <ProgressBar show={this.props.showProgressBar}/>
        </Stack>

        <Stack
          className={`project-detail-list ${(this.state.collapseBookmarkPane && screen.width > 1440) ? 'full-inner-container' : 'inner-container'} cpc  card-container`}
          styles={{
            root: {
              width: '100%',
              height: '76vh',
            },
          }}
        >
          {/*doc*/}
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              {/*DocumentPane*/}
              <DocumentPane
                isEdit={this.state.isEdit}
                formData={this.props.formData}
                projectId={this.state.projectId}
                isExpand={this.state.collapseBookmarkPane}

              />

            </Stack>
            {this.state.collapseBookmarkPane && screen.width > 1440 ? <CollapseBookMarkPane
              isEdit={this.props.isEdit}
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
            /> : <BookMarkPane
              isEdit={this.props.isEdit}
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
            />}

          </Stack>
        </Stack>
      </div>
    );
  };


  handelFilter = (filter: PSListPaneFilter) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState(
        {
          psFilter: filter,
        },
        this.reloadListPaneItem,
      );
    } else {
      this.setState({
        psFilter: filter,
      });
    }
  };


  handelShortCutPaneFilter = (selectedItem: PSShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        <SplitPane
          split="vertical"
          minSize={300}
          maxSize={screen.width / 2}
          defaultSize={(this.state.showDetails) ? screen.width / 2 : screen.width}
          resizerStyle={resizerStyle}
          style={{ height: 'calc(100vh - 129px)' }}
          size={this.state.reSizer}
          onDragStarted={() => {
            this.setState({
              isDrag: true,
              collapseBookmarkPane: true,
              resetLayout: false,
            });
          }}
          onResizerDoubleClick={() => {

            this.setState({
              reSizer: (this.state.reSizer === 300) ? screen.width / 2 : 300,
              isDrag: (!this.state.isDrag),
              collapseBookmarkPane: (!this.state.collapseBookmarkPane),
              resetLayout: (!this.state.resetLayout),
            });
          }}
          onDragFinished={(newSize: number) => {
            this.setState({
              isDrag: false,
              resetLayout: false,
              reSizer: newSize,
            });
          }}
          onChange={(newSize: number) => {
            if (newSize === screen.width / 2) {
              this.setState({
                resetLayout: true,
                collapseBookmarkPane: false,
              });
            }
          }}
        >
          <Pane>
            <Stack horizontal>
              <ShortCutPane
                projectId={this.state.projectId}
                shortCutPaneFilters={this.props.shortCutPaneFilters}
                filter={this.state.psFilter}
                handelShortCutPaneFilter={(
                  selectedItem: PSShortCutPaneItem,
                ) => this.handelShortCutPaneFilter(selectedItem)}
                toggleOverlay={this.state.collapseBookmarkPane}
                resetLayout={this.state.resetLayout}
              />

              <div className={this.state.activeClass}>
                <Stack
                  className="project-list-header right-panel"
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    paddingRight: 0,
                  }}
                  styles={{ root: { width: '100%', paddingRight: 0 } }}
                >
                  <TitlePane
                    headerTitle={
                      this.state.selectedShortCutItem
                        ? this.state.selectedShortCutItem.name
                        : ''
                    }
                  />
                  <CommandBarLeft
                    createNew={(create: boolean) => this.createNew(create)}
                    selectedItemIds={this.state.selectedListPaneItemIds}
                    isDisableNewButton={this.state.isDisableNewButton}
                  />
                  <ProgressBar show={this.props.showProgressBar}/>
                </Stack>
                {/*{JSON.stringify(this.props.loadMsg)}*/}
                <Stack style={{ alignSelf: 'center' }}>
                  <ListPane
                    projectId={this.state.projectId}
                    loadMsg={this.props.loadMsg}
                    reloadListPaneItem={() => this.reloadListPaneItem()}
                    filter={this.state.psFilter}
                    listPaneItems={this.props.listPaneItems}
                    isDataLoaded={this.props.isDataLoaded}
                    isChange={this.props.isChange}
                    formData={this.state.formData}
                    handelFilter={(filter: PSListPaneFilter) =>
                      this.handelFilter(filter)
                    }
                    handleSelectedListPaneItem={(
                      selectedListItemIds: [] | string[],
                    ) => {
                      this.handleSelectedListPaneItem(selectedListItemIds);
                    }}
                    reSizer={this.state.reSizer}
                  />
                  {/*LIST PANE*/}
                </Stack>
              </div>
            </Stack>
          </Pane>
          {this.state.showDetails && <Pane>
            <div
              className={`${animation}`}
              style={{
                display: this.state.showDetails ? 'block' : 'none',
                transition: 'all 0.9s linear',
              }}
            >
              {!this.state.showPanel && (
                <div>{this.getDocumentPaneView()}</div>
              )}
            </div>
          </Pane>}

        </SplitPane>
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={i18n.t('Corporate Product Catalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={i18n.t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={this.state.hiddenRemoveDialog}
          title={i18n.t('areyousure')}
          subText={i18n.t('You won\'t be able to revert this!')}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          cancelButtonText={i18n.t('No, I won\'t!')}
          confirmButtonText={i18n.t('Yes, delete it!')}
        />
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/translations-dashboard`);
  };

  private hideDocumentPane = () => {
    this.setState({
      resetLayout: true,
    });
    if (getContractingUnit()) {
      history.push(`/translations-dashboard`);
    } else {
      history.push(`/translations-dashboard`);
    }
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent' });
  };

  private _handleRemove = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    if (this.state.formData.progressStatementId && this.state.formData.id) {
      this.createNew(true);
      history.push(`/translations-dashboard/new`);
    }
  };

  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortCutPaneFilters: state.ps.psShortCutdata,
    listPaneItems: state.ps.listPaneItems,
    isDataLoaded: state.ps.isPSListLoaded,
    loadMsg: state.ps.loadMsg,
    uid: state.ps.uid,
    //formData: state.ps.formData,
    showProgressBar: state.ps.showProgressBar,
    isEdit: state.ps.isEdit,
    isChange: state.ps.isChange,
    formData: state.lang.formData,


  };
};

const mapDispatchToProps = {
  readPSShortCutPaneData,
  filterPSListPaneData,
  resetIsChange,
  readPSDropDownData,
  readByPSId,
  setUid,
  clearFormData,
  LanguageGetById,

};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LanguageMainLayout),
);
