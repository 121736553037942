import React from 'react';
import './../project';
import {AnimationStyles, getTheme, ITheme, mergeStyles, Panel, PanelType, Stack} from '@fluentui/react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import history from '../../history';
import {withTranslation, WithTranslation} from 'react-i18next';
import AddressBookShortCutPane from './shortCutPane';
import AddressBookListPane from './listPane/container';
import AddressBookDocumentPane from './documentPane/container';
import CABListPaneCommandBar from './commandBars/listPaneCommandBar';
import CABDocumentPaneCommandBar from './commandBars/documentPaneCommandBar';
import {messageService} from '../../services/messageService';
import BookMarkPane from '../../shared/bookMarkPane/bookMarkPane';
import TitlePane from '../../shared/titlePane/titlePane';
import {getIsCabExist} from '../../reducers/addressBookReducer';
import {AddressBookItem, Person, PersonCompany} from '../../types/addressBookItem';
import i18n from '../../../i18n';
import {getContractingUnit, getProject} from '../../shared/util';

i18n;

const contentStyle = mergeStyles({
  height: 'calc(100vh - 250px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

export interface State {
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  headerFilter: boolean;
  needRefresh: boolean;
  showDocumentPane: boolean;
  currentActiveSection: string;
  bookmarkDivClass: string;
  isNewCab: boolean;
  selectedAddressItem: AddressBookItem;
  cabId: string | null;
  cabType: string | undefined;
  listTitle: string;
  docTitle: string;
  enableRemoveButton: boolean;
  isCabExist: boolean
}

export interface ProjectDetailsControl {
  showProjectDetails: boolean;
  projectManagementLevel: any;
}

interface Props extends WithTranslation {
  cabType: string | undefined;
  cabId: string | null;
  match: any;
  enableRemoveButton: boolean;
  location: any;
  getIsCabExist: any;
  isCabExist: boolean;
  compDropDowns: any;
}

class AddressBookMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  subscription: any;
  theme: ITheme = getTheme();
  private bookmarkList = [
    {
      id: 1,
      link: 'business-card-header',
      label: this.props.t('businessCard'),
    },
    {
      id: 2,
      link: 'private-contact',
      label: this.props.t('contactDetails'),
    },
    {
      id: 3,
      link: 'competencies',
      label: this.props.t('competencies'),
    },
    {
      id: 4,
      link: 'certifications',
      label: this.props.t('certifications'),
    },
    {
      id: 5,
      link: 'organisation-contact',
      label: this.props.t('organisationDetails'),
    },
    {
      id: 6,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      currentActiveSection: '1',
      selectedAddressItem: new AddressBookItem(
        new Person(),
        null,
        new PersonCompany(),
      ),
      needRefresh: false,
      showDocumentPane: false,
      isNewCab: false,
      cabId: null,
      cabType: '',
      listTitle: 'Person',
      docTitle: '',
      enableRemoveButton: false,
      isCabExist: false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;
    this._isMounted = true;
    this.props.getIsCabExist();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    if (this.props.match.params && this.props.match.params.id) {
      let lastParam = this.props.match.url.split('/').pop();
      let cabId = this.props.match.params.id;
      let showDetails = this.props.match.params.id || lastParam === 'new';
      if (this.state.width <= 1100) {
        this.setState({
          showPanel: true,
          showDocumentPane: false, //(window.innerWidth > 1100)?showDetails:false,
          cabId: cabId,
          isNewCab: lastParam === 'new',
          docTitle: '',
          listTitle:
            this.state.cabType !== 'organisation'
              ? t('person')
              : t('organisation'),
        });
      } else {
        this.setState({
          showPanel: false,
          showDocumentPane: true,
          cabId: cabId,
          isNewCab: lastParam === 'new',
          docTitle: '',
          listTitle:
            this.state.cabType !== 'organisation'
              ? t('person')
              : t('organisation'),
        });
      }
    }
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        // add message to local state if not empty
        if (data.data.cabType) {
          this.setState({ listTitle: t(data.data.cabType) });
        }
        if (data && data.data && data.data.showdocumentPane || data && data.data && data.data.title) {
          if (this.state.width <= 1100) {
            this.setState({
              showPanel: true,
              showDocumentPane: false,
              isNewCab: false,
              docTitle: data.data.title,
              listTitle:
                this.state.cabType !== 'organisation'
                  ? t('person')
                  : t('organisation'),
              //selectedAddressItem:data.data.selectedAddressItem,
              cabId: data.data.cabId,
            });
          } else {
            this.setState({
              showDocumentPane: true,
              showPanel: false,
              isNewCab: false,
              listTitle:
                this.state.cabType !== 'organisation'
                  ? t('person')
                  : t('organisation'),
              docTitle: data.data.title,
              //selectedAddressItem:data.data.selectedAddressItem,
              cabId: data.data.cabId,
            });
          }
        }

        if (data && data.data && data.data.isNewCAB) {
          if (this.state.width <= 1100) {
            this.setState({
              isNewCab: data.data.isNewCAB,
              showDocumentPane: false,
              showPanel: true,
              listTitle:
                this.state.cabType !== 'organisation'
                  ? t('person')
                  : t('organisation'),
              docTitle: '',
            });
          } else {
            this.setState({
              isNewCab: data.data.isNewCAB,
              showDocumentPane: true,
              showPanel: false,
              listTitle:
                this.state.cabType !== 'organisation'
                  ? t('person')
                  : t('organisation'),
              docTitle: '',
            });
          }
        }

        if (data && data.data && data.data.removedPerson) {
          this.setState({ docTitle: '' });
        }

        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data && data.data && data.data.hasOwnProperty('enableRemoveButton')) {
          this.setState({ enableRemoveButton: data.data.enableRemoveButton });
        }
      } else {
        // clear messages when empty message received
      }
    });
  }

  private hideDocumentPane = () => {
    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/address-book`);
    } else {
      history.push(`/address-book/`);
    }
  };

  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.width > 1100) {
        if (this.props.match.params && this.props.match.params.id) {
          this.setState({ showPanel: false, showDocumentPane: true });
        }
      } else if (this.state.showDocumentPane) {
        this.setState({ showPanel: true, showDocumentPane: false });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange();
    }
    if (this.props.location && this.props.location.state && this.state.cabType != this.props.location.state.type) {
      this.setState({ cabType: this.props.location.state.type });
    }

    if (prevProps.isCabExist !== this.props.isCabExist) {
      this.setState({ isCabExist: this.props.isCabExist });
    }
  }

  handleUrlChange() {
    let lastParam = this.props.match.url.split('/').pop();
    let projectId = this.props.match.params.id;
    let pbsId = this.props.match.params.pbsId
      ? this.props.match.params.pbsId
      : null;
    let showDetails = this.props.match.params.id || lastParam === 'new';
    this.setState({
      showDocumentPane: window.innerWidth > 1100 ? showDetails : false,
      cabId: projectId,
    });

  }

  componentWillUnmount() {
    this._isMounted = false;
    this.subscription.unsubscribe();
    window.removeEventListener('resize', this.updateDimensions);
  }

  private getDocumentPaneView = () => {
    return (
      <div>
        <Stack
          className="project-list-header right-panel"
          style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
          styles={{ root: { width: '100%' } }}
        >
          <TitlePane close={true} headerTitle={this.state.docTitle}/>
          <CABDocumentPaneCommandBar/>
        </Stack>

        <Stack
          className="project-detail-list inner-container cab "
          styles={{
            root: {
              width: '100%',
              height: '76vh',
            },
          }}
        >
          {/*doc*/}
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              <AddressBookDocumentPane
                companyId={null}
                countries={[]}
                cabId={this.state.cabId}
                addressBookFromId={
                  new AddressBookItem(new Person(), null, new PersonCompany())
                }
              />
            </Stack>
            <BookMarkPane
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
              isEdit={!this.state.isNewCab}
            />
          </Stack>
        </Stack>
      </div>
    );
  };

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          //console.log(c);
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent' });
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDocumentPane
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        <div className={'ms-Grid'} dir="ltr">
          <div className={'ms-Grid'} style={{ width: '100%', height: '100%' }}>
            <div className="ms-Grid-row">
              <div
                className={
                  this.state.showDocumentPane
                    ? `ms-Grid-col ms-sm6 ms-md8 ms-lg6 padding-0 `
                    : `ms-Grid-col ms-sm12 ms-md12 ms-lg12 padding-0 `
                }
              >
                <Stack horizontal>
                  <AddressBookShortCutPane/>

                  <div className={this.state.activeClass}>
                    <Stack
                      className="project-list-header right-panel"
                      style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        paddingRight: 0,
                      }}
                      styles={{ root: { width: '100%' } }}
                    >
                      <TitlePane
                        headerTitle={this.state.listTitle}
                        isOldItem={true}
                      />
                      <CABListPaneCommandBar
                        enableRemoveButton={this.state.enableRemoveButton}
                        isCabExist={this.state.isCabExist}
                      />
                    </Stack>

                    <Stack style={{ alignSelf: 'center' }}>
                      <AddressBookListPane cabType={this.state.cabType}/>
                    </Stack>
                  </div>
                </Stack>
              </div>

              <div
                className={`ms-Grid-col ms-sm12 ms-md6 padding-left-0 ${animation}`}
                style={{
                  display: this.state.showDocumentPane ? 'block' : 'none',
                  transition: 'all 0.9s linear',
                }}
              >
                {this.state.showDocumentPane && (
                  <div>{this.getDocumentPaneView()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('centralAddressBook')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          <div>{this.getDocumentPaneView()}</div>
        </Panel>
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false });
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isCabExist: state.addressBook.isCabExist,
    compDropDowns: state.comp.compDropDowns,
  };
};

const mapDispatchToProps = {
  getIsCabExist,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(AddressBookMainLayout)),
);
