import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import client from '../../../../api';
import {
  getById,
  saveFormData,
  savePbsStateAttr,
} from '../../../../reducers/projectBreakdownReducer';
import { saveVPStateAttr } from '../../../../reducers/visualPlanReducer';
import { saveFormData as saveBorFormData, getById as readBorBySequenceId, saveBORStateAttr  } from '../../../../reducers/billOfResourcesReducer';
import { isCuProjectPlanning, isMyEnvironment } from '../../../util';
import { saveStateAttr } from '../../../../reducers/uprinceReducer';

const StatusPopOver = (props: { productId: any; visible: boolean, path:any, close:any, node: any, cu:any, project:any }) => {
  // console.log("node", props.node)
  const dispatch = useDispatch();
  const [pbsData, setPbsData]: any = useState(null);
  const [borData, setBorData]: any = useState(null);
  const { t } = useTranslation();

  const options: any = useSelector(
    (state: any) => state.projectBreakdown.productStates
  );

  const options2: IChoiceGroupOption[] = [
    { key: 'A', text: 'Option A' },
    { key: 'B', text: 'Option B' },
    { key: 'C', text: 'Option C', disabled: true },
    { key: 'D', text: 'Option D' },
  ];

  useEffect(() => {
    if (props.visible) {
      dispatch(saveVPStateAttr('expandPath', props.path));
      if (isMyEnvironment()) {
        dispatch(saveStateAttr("contractingUinit",props.cu))
        dispatch(saveStateAttr("projectSequenceCode",props.project))
      }
      if (isCuProjectPlanning()) {
        dispatch(saveStateAttr("projectSequenceCode",props.project))
      }
      if (props.node?.type === 'BOR') {
        dispatch(saveBORStateAttr('isBOROpenFromVP',false));
        const fetchBor = async () => {
          const response: any = await client.get(`Bor/ReadBor/${props.node?.borSequenceId}`);
          if (response?.data?.result) setBorData(response?.data?.result);
        };
        fetchBor();
      } else {
        dispatch(savePbsStateAttr('isPBSOpenFromVP',false));
        const fetchPBS = async () => {
          try {
            const response = await client.get(`Pbs/Read/${props.productId}`, {
              headers: {
                'project': props.project,
              },
            });

            if (response?.data?.result) {
              setPbsData(response.data.result);
            }
          } catch (error) {
            // Handle errors here
            console.error('Error fetching PBS data:', error);
          }
        };
        fetchPBS();
      }
    } else {

    }
  }, [props.productId, props.visible, props.node.borSequenceId]);

  function _onChange(ev: any, option: any): void {
    if (option) { // BOR & PBS StatusId's are identical
      props.close();
      if (props.node?.type === 'BOR') {
        Promise.all([dispatch(saveBorFormData({
          ...borData,
          borStatusId: option?.key,
        }))]).then(() => {
          dispatch(readBorBySequenceId(borData?.itemId));
        });
      } else {
        Promise.all([dispatch(saveFormData({
            ...pbsData,
            pbsProductStatusId: option?.key,
          },
          props?.project,
        ))]).then(() => {
          dispatch(getById(pbsData?.productId, props?.project));
        });
      }
    }
  }

  return (
    <div style={{width:250, height:220}}>
      {(pbsData?.pbsProductStatusId || borData?.borStatusId)  &&
        <ChoiceGroup
          selectedKey={(props.node?.type === 'BOR') ? borData?.borStatusId : pbsData?.pbsProductStatusId}
          options={options}
          onChange={_onChange}
          label={t('productstate')}
        />
      }
    </div>
  );
};

export default StatusPopOver;
