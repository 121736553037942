import {
  ACCEPT_PO_EP,
  APPROVED_PO_CU_EP,
  APPROVED_PO_CU_LEVEL_EP,
  BOR_RESOURCE_GETBY_ID_EP,
  CONVERT_CR_TO_PO_EP,
  CREATE_PO_LOCATION_EP,
  CU_DELIVERY_PLAN_STOCK_UPDATE_EP,
  CU_SEND_PO_EP,
  DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_EP,
  DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_EP,
  DELIVERY_PLAN_STOCK_UPDATE_EP,
  FEEDBACK_PO_CU_EP,
  LOAD_BOR_PO_LIST_PANE_LIST_EP,
  LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_EP,
  LOAD_BOR_RETURN_PO_LIST_PANE_LIST_EP,
  LOAD_CU_DELIVERY_PLAN_EP,
  LOAD_CU_PRODUCT_PO_LIST_PANE_LIST_EP,
  LOAD_DELIVERY_PLAN_EP,
  LOAD_PO_BOR_UPDATE_EP,
  LOAD_PO_CU_BOR_UPDATE_EP,
  LOAD_PO_PROJECT_LIST_EP,
  LOAD_PRODUCT_DELIVERY_PLAN_EP,
  LOAD_PRODUCT_PO_LIST_PANE_LIST_EP,
  LOAD_RFQ_INVITATION_DETAILS_EP,
  PBS_RESOURCE_GETBY_ID_EP,
  PROJECT_SEND_PO_EP,
  PURCHASE_ORDER_SAVE_EP,
  READ_PURCHASE_ORDER_BY_ID_EP,
  READ_PURCHASE_ORDER_DROPDOWN_EP,
  READ_PURCHASE_ORDER_LIST_EP,
  READ_PURCHASE_ORDER_SHORTCUT_PANE_EP,
  SAVE_SIGNATURE_EP,
  SEND_RFQ_EMAIL_EP,
  UPLOAD_RFQ_SIGNATURE_EP,
} from '../shared/endpoints';
import {
  APPROVED_PO,
  APPROVED_PO_CU,
  APPROVED_PO_CU_FAIL,
  APPROVED_PO_CU_LEVEL,
  APPROVED_PO_CU_LEVEL_FAIL,
  APPROVED_PO_CU_LEVEL_SUCCESS,
  APPROVED_PO_CU_SUCCESS,
  APPROVED_PO_FAIL,
  APPROVED_PO_SUCCESS,
  AUTO_SAVE_PURCHASE_ORDER,
  AUTO_SAVE_PURCHASE_ORDER_FAIL,
  AUTO_SAVE_PURCHASE_ORDER_SUCCESS,
  CLEAR_PURCHASE_ORDER_FORM_DATA,
  CLEAR_PURCHASE_ORDERUID,
  CONVERT_CR_TO_PO,
  CONVERT_CR_TO_PO_FAIL,
  CONVERT_CR_TO_PO_SUCCESS,
  CREATE_PO_DELIVERY_LOCATION,
  CREATE_PO_DELIVERY_LOCATION_FAIL,
  CREATE_PO_DELIVERY_LOCATION_SUCCESS,
  CU_SEND_PO,
  CU_SEND_PO_FAIL,
  CU_SEND_PO_SUCCESS,
  FEEDBACK_PO_CU,
  FEEDBACK_PO_CU_FAIL,
  FEEDBACK_PO_CU_SUCCESS,
  LOAD_BOR_PO_LIST_PANE_LIST,
  LOAD_BOR_PO_LIST_PANE_LIST_FAIL,
  LOAD_BOR_PO_LIST_PANE_LIST_SUCCESS,
  LOAD_BOR_RESOURCES_BY_ID,
  LOAD_BOR_RESOURCES_BY_ID_FAIL,
  LOAD_BOR_RESOURCES_BY_ID_SUCCESS,
  LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST,
  LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_FAIL,
  LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_SUCCESS,
  LOAD_BOR_RETURN_PO_LIST_PANE_LIST,
  LOAD_BOR_RETURN_PO_LIST_PANE_LIST_FAIL,
  LOAD_BOR_RETURN_PO_LIST_PANE_LIST_SUCCESS,
  LOAD_CU_DELIVERY_PLAN,
  LOAD_CU_DELIVERY_PLAN_FAIL,
  LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE,
  LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_FAIL,
  LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS,
  LOAD_CU_DELIVERY_PLAN_SUCCESS,
  LOAD_DELIVERY_PLAN,
  LOAD_DELIVERY_PLAN_FAIL,
  LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE,
  LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_FAIL,
  LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_SUCCESS,
  LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE,
  LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_FAIL,
  LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_SUCCESS,
  LOAD_DELIVERY_PLAN_STOCK_UPDATE,
  LOAD_DELIVERY_PLAN_STOCK_UPDATE_FAIL,
  LOAD_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS,
  LOAD_DELIVERY_PLAN_SUCCESS,
  LOAD_PBS_RESOURCES_BY_ID,
  LOAD_PBS_RESOURCES_BY_ID_FAIL,
  LOAD_PBS_RESOURCES_BY_ID_SUCCESS,
  LOAD_PO_BOR_UPDATE,
  LOAD_PO_BOR_UPDATE_FAIL,
  LOAD_PO_BOR_UPDATE_SUCCESS,
  LOAD_PO_CU_BOR_UPDATE,
  LOAD_PO_CU_BOR_UPDATE_FAIL,
  LOAD_PO_CU_BOR_UPDATE_SUCCESS,
  LOAD_PO_PROJECT_LIST,
  LOAD_PO_PROJECT_LIST_FAIL,
  LOAD_PO_PROJECT_LIST_SUCCESS,
  LOAD_PRODUCT_DELIVERY_PLAN,
  LOAD_PRODUCT_DELIVERY_PLAN_FAIL,
  LOAD_PRODUCT_DELIVERY_PLAN_SUCCESS,
  LOAD_PRODUCT_PO_LIST_PANE_LIST,
  LOAD_PRODUCT_PO_LIST_PANE_LIST_FAIL,
  LOAD_PRODUCT_PO_LIST_PANE_LIST_SUCCESS,
  LOAD_PURCHASE_ORDER_BY_ID,
  LOAD_PURCHASE_ORDER_BY_ID_FAIL,
  LOAD_PURCHASE_ORDER_BY_ID_SUCCESS,
  LOAD_PURCHASE_ORDER_DROPDOWN,
  LOAD_PURCHASE_ORDER_DROPDOWN_FAIL,
  LOAD_PURCHASE_ORDER_DROPDOWN_SUCCESS,
  LOAD_PURCHASE_ORDER_LSITPANE,
  LOAD_PURCHASE_ORDER_LSITPANE_FAIL,
  LOAD_PURCHASE_ORDER_LSITPANE_SUCCESS,
  LOAD_PURCHASE_ORDER_SHORTCUTPANE,
  LOAD_PURCHASE_ORDER_SHORTCUTPANE_FAIL,
  LOAD_PURCHASE_ORDER_SHORTCUTPANE_SUCCESS,
  LOAD_RFQ_INVITATION_DETAILS,
  LOAD_RFQ_INVITATION_DETAILS_FAIL,
  LOAD_RFQ_INVITATION_DETAILS_SUCCESS,
  PO_BOR_POPUP_STATE,
  PROJECT_SEND_PO,
  PROJECT_SEND_PO_FAIL,
  PROJECT_SEND_PO_SUCCESS,
  RESET_PURCHASE_ORDER_IS_CHANGE,
  SAVE_PO_STATE_ATTRIBUTE,
  SAVE_PURCHASE_ORDER,
  SAVE_PURCHASE_ORDER_FAIL,
  SAVE_PURCHASE_ORDER_FAIL_NOUPDATE,
  SAVE_PURCHASE_ORDER_NOUPDATE,
  SAVE_PURCHASE_ORDER_SUCCESS,
  SAVE_PURCHASE_ORDER_SUCCESS_NOUPDATE,
  SAVE_SIGNATURE,
  SAVE_SIGNATURE_FAIL,
  SAVE_SIGNATURE_SUCCESS,
  SEND_RFQ_EMAIL,
  SEND_RFQ_EMAIL_FAIL,
  SEND_RFQ_EMAIL_SUCCESS,
  SET_PURCHASE_ORDER_UID,
  UPLOAD_RFQ_SIGNATURE,
  UPLOAD_RFQ_SIGNATURE_FAIL,
  UPLOAD_RFQ_SIGNATURE_SUCCESS,
} from '../actionTypes/purchaseOrderActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { POFilter, PurchaseOrder } from '../types/purchaseOrder';
import { LocationAddress } from '../types/projectDefinition';
import { format24hDateTime, getProject, numberToLocaleString } from '../shared/util';
import _ from 'lodash';


const defaultState: any = {
  purchaseOrderShortCutdata: [],
  poList: [],
  isPOListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  purchaseOrderDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  poShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  borResourcesCount: 0,
  isLoadingPO: false,
  isBorModalVisible: false,
  pmolLocationId: null,
  deliveryPlanListPaneItem: [],
  isDataLoadedDeliveryPlan: false,
  loadMsgDeliveryPlan: '',
  deliveryPlanProductListPaneItem: [],
  isDataLoadedProductDeliveryPlan: false,
  loadMsgProductDeliveryPlan: '',
  purchaseOrderID: null,
  borListPaneItem: [],
  isDataLoadedBor: false,
  loadMsgBor: '',
  borResourceListPaneItem: [],
  productListPaneItem: [],
  poProjectList: [],
  isDataLoadedProduct: false,
  loadMsgProduct: '',
  selectedPoList:[],
  signatureLink:'',
  showRfqAlert:false
};

export default function purchaseOrderReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_PURCHASE_ORDER_SHORTCUTPANE:
        return { ...state };
      case LOAD_PURCHASE_ORDER_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          poShortCutPaneData: action.payload.data.result,
        };
      case LOAD_PURCHASE_ORDER_SHORTCUTPANE_FAIL:
        return { ...state, poShortCutPaneData: [] };
      case LOAD_PURCHASE_ORDER_LSITPANE:
        return { ...state, isPOListLoaded: false };
      case LOAD_PURCHASE_ORDER_LSITPANE_SUCCESS:
        return {
          ...state,
          isPOListLoaded: true,
          isChange: false,
          // poList: dummydata(),
          poList: formattedPoList(action.payload.data.result),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_PURCHASE_ORDER_LSITPANE_FAIL:
        return {
          ...state,
          isPOListLoaded: true,
          poList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_PURCHASE_ORDER_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_PURCHASE_ORDER_DROPDOWN:
        return { ...state, purchaseOrderDropDowns: {} };
      case LOAD_PURCHASE_ORDER_DROPDOWN_SUCCESS:
        return { ...state, purchaseOrderDropDowns: action.payload.data.result };
      case LOAD_PURCHASE_ORDER_DROPDOWN_FAIL:
        return {
          ...state,
          purchaseOrderDropDowns: {},
        };
      case LOAD_BOR_RESOURCES_BY_ID:
        return { ...state, borResources: {}, borResourcesCount: 0 };
      case LOAD_BOR_RESOURCES_BY_ID_SUCCESS:
        return {
          ...state,
          borResources: action.payload.data.result,
          borResourcesCount: getBorResourceCount(action.payload.data.result),
        };
      case LOAD_BOR_RESOURCES_BY_ID_FAIL:
        return {
          ...state,
          borResources: {},
          borResourcesCount: 0,
        };

      case LOAD_PBS_RESOURCES_BY_ID:
        return { ...state, borResources: {}, borResourcesCount: 0 };
      case LOAD_PBS_RESOURCES_BY_ID_SUCCESS:
        return {
          ...state,
          borResources: action.payload.data.result,
          borResourcesCount: getBorResourceCount(action.payload.data.result),
        };
      case LOAD_PBS_RESOURCES_BY_ID_FAIL:
        return {
          ...state,
          borResources: {},
          borResourcesCount: 0,
        };

      case LOAD_PURCHASE_ORDER_BY_ID:
        return {
          ...state,
          formData: {},
          deliveryPlanProductListPaneItem: [],
          showProgressBar: true,
          isLoadingPO: true,
          borResourcesCount: 0,
        };
      case LOAD_PURCHASE_ORDER_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          borResources: {},
          // formData: dummydataGetById(),
          formData: action.payload.data.result,
          deliveryPlanProductListPaneItem: formatDeliveryPlanProductListPaneItem(action.payload.data.result),
          borResourcesCount: getBorResourceCount(action.payload.data.result?.poResources),
          showProgressBar: false,
          isLoadingPO: false,
        };
      case LOAD_PURCHASE_ORDER_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          deliveryPlanProductListPaneItem: [],
          showProgressBar: false,
          isLoadingPO: false,
          borResourcesCount: 0,
        };

      case SAVE_PURCHASE_ORDER:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          purchaseOrderID: null,
          formData: {},
          isLoadingPO: true,
        };
      case SAVE_PURCHASE_ORDER_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          purchaseOrderID: action.payload.data.result,
        };
      case SAVE_PURCHASE_ORDER_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          isLoadingPO: true,
        };

      case AUTO_SAVE_PURCHASE_ORDER:
        return { ...state, showProgressBar: true };
      case AUTO_SAVE_PURCHASE_ORDER_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
        };
      case AUTO_SAVE_PURCHASE_ORDER_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case SAVE_PURCHASE_ORDER_NOUPDATE:
        return { ...state, reloadListPane: false };
      case SAVE_PURCHASE_ORDER_SUCCESS_NOUPDATE:
        return {
          ...state,
          reloadListPane: true,
        };
      case SAVE_PURCHASE_ORDER_FAIL_NOUPDATE:
        return {
          ...state,
          reloadListPane: false,
        };

      case SET_PURCHASE_ORDER_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_PURCHASE_ORDERUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_PURCHASE_ORDER_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isEdit: false,
          isChange: false,
          formData: {},
          borResources: {},
          borResourcesCount: 0,
        };
      case PO_BOR_POPUP_STATE:
        return {
          ...state,
          isBorModalVisible: action.payload,
        };


      case PROJECT_SEND_PO:
        return { ...state, showProgressBar: true };
      case PROJECT_SEND_PO_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case PROJECT_SEND_PO_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case CU_SEND_PO:
        return { ...state, showProgressBar: true };
      case CU_SEND_PO_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case CU_SEND_PO_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case APPROVED_PO:
        return { ...state, showProgressBar: true };
      case APPROVED_PO_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case APPROVED_PO_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case APPROVED_PO_CU:
        return { ...state, showProgressBar: true };
      case APPROVED_PO_CU_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case APPROVED_PO_CU_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case APPROVED_PO_CU_LEVEL:
        return { ...state, showProgressBar: true };
      case APPROVED_PO_CU_LEVEL_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case APPROVED_PO_CU_LEVEL_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case CREATE_PO_DELIVERY_LOCATION:
        return { ...state, pmolLocationId: null };
      case CREATE_PO_DELIVERY_LOCATION_SUCCESS:
        return {
          ...state,
          pmolLocationId: action.payload.data.result,
        };
      case CREATE_PO_DELIVERY_LOCATION_FAIL:
        return {
          ...state,
          pmolLocationId: null,
        };

      case FEEDBACK_PO_CU:
        return { ...state, showProgressBar: true };
      case FEEDBACK_PO_CU_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case FEEDBACK_PO_CU_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case LOAD_DELIVERY_PLAN:
        return {
          ...state, deliveryPlanListPaneItem: [], isDataLoadedDeliveryPlan: false,
          loadMsgDeliveryPlan: '',
        };
      case LOAD_DELIVERY_PLAN_SUCCESS:
        return {
          ...state,
          // deliveryPlanListPaneItem: deliveryPlanListPaneItemDummyData(),
          deliveryPlanListPaneItem: formatDeliveryPlanListPaneItem(action.payload),
          // deliveryPlanListPaneItem: action.payload.data.result
          isDataLoadedDeliveryPlan: true,
          loadMsgDeliveryPlan: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_DELIVERY_PLAN_FAIL:
        return {
          ...state,
          deliveryPlanListPaneItem: [],
          isDataLoadedDeliveryPlan: true,
          loadMsgDeliveryPlan: action.error.response.data.message,
        };

      case LOAD_CU_DELIVERY_PLAN:
        return {
          ...state, deliveryPlanListPaneItem: [], isDataLoadedDeliveryPlan: false,
          loadMsgDeliveryPlan: '',
        };
      case LOAD_CU_DELIVERY_PLAN_SUCCESS:
        return {
          ...state,
          // deliveryPlanListPaneItem: deliveryPlanListPaneItemDummyData(),
          deliveryPlanListPaneItem: formatDeliveryPlanListPaneItem(action.payload),
          // deliveryPlanListPaneItem: action.payload.data.result
          isDataLoadedDeliveryPlan: true,
          loadMsgDeliveryPlan: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_CU_DELIVERY_PLAN_FAIL:
        return {
          ...state,
          deliveryPlanListPaneItem: [],
          isDataLoadedDeliveryPlan: true,
          loadMsgDeliveryPlan: action.error.response.data.message,
        };

      case LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE:
        return { ...state };
      case LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS:
        return {
          ...state,
          isChange: true,
          // deliveryPlanListPaneItem: deliveryPlanListPaneItemDummyData(),
          // deliveryPlanListPaneItem: action.payload.data.result
        };
      case LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_FAIL:
        return { ...state };

      case LOAD_DELIVERY_PLAN_STOCK_UPDATE:
        return { ...state };
      case LOAD_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS:
        return {
          ...state,
          isChange: true,
          // deliveryPlanListPaneItem: deliveryPlanListPaneItemDummyData(),
          // deliveryPlanListPaneItem: action.payload.data.result
        };
      case LOAD_DELIVERY_PLAN_STOCK_UPDATE_FAIL:
        return { ...state };

      case LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE:
        return { ...state };
      case LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_SUCCESS:
        return {
          ...state,
          isChange: true,
        };
      case LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_FAIL:
        return { ...state };

      case LOAD_PRODUCT_DELIVERY_PLAN:
        return {
          ...state, deliveryPlanProductListPaneItem: [], isDataLoadedProductDeliveryPlan: false,
          loadMsgProductDeliveryPlan: '',
        };
      case LOAD_PRODUCT_DELIVERY_PLAN_SUCCESS:
        return {
          ...state,
          deliveryPlanProductListPaneItem: deliveryPlanProductListPaneItemDummyData(),
          // deliveryPlanProductListPaneItem: formatDeliveryPlanListPaneItem(action.payload),
          // deliveryPlanProductListPaneItem: action.payload.data.result
          isDataLoadedProductDeliveryPlan: true,
          loadMsgProductDeliveryPlan: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_PRODUCT_DELIVERY_PLAN_FAIL:
        return {
          ...state,
          deliveryPlanProductListPaneItem: [],
          isDataLoadedProductDeliveryPlan: true,
          loadMsgProductDeliveryPlan: action.error.response.data.message,
        };

      case LOAD_BOR_PO_LIST_PANE_LIST:
        return { ...state, borListPaneItem: [], isDataLoadedBor: false, loadMsgBor: '' };
      case LOAD_BOR_PO_LIST_PANE_LIST_SUCCESS:
        return {
          ...state,
          borListPaneItem: action.payload.data.status ? action.payload.data.result : [],
          isDataLoadedBor: true,
          loadMsgBor: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_BOR_PO_LIST_PANE_LIST_FAIL:
        return {
          ...state,
          borListPaneItem: [],
          isDataLoadedBor: true,
          loadMsgBor: action.error.response.data.message,
        };

      case LOAD_BOR_RETURN_PO_LIST_PANE_LIST:
        return { ...state, borListPaneItem: [], isDataLoadedBor: false, loadMsgBor: '' };
      case LOAD_BOR_RETURN_PO_LIST_PANE_LIST_SUCCESS:
        return {
          ...state,
          borListPaneItem: action.payload.data.status ? action.payload.data.result : [],
          isDataLoadedBor: true,
          loadMsgBor: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_BOR_RETURN_PO_LIST_PANE_LIST_FAIL:
        return {
          ...state,
          borListPaneItem: [],
          isDataLoadedBor: true,
          loadMsgBor: action.error.response.data.message,
        };

      case LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST:
        return { ...state, borResourceListPaneItem: [], isDataLoadedBor: false, loadMsgBor: '' };
      case LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_SUCCESS:
        return {
          ...state,
          borResourceListPaneItem: action.payload.data.status ? formatBorResourceListPaneItem(action.payload.data.result) : [],
          isDataLoadedBor: true,
          loadMsgBor: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_FAIL:
        return {
          ...state,
          borResourceListPaneItem: [],
          isDataLoadedBor: true,
          loadMsgBor: action.error.response.data.message,
        };

      case LOAD_PRODUCT_PO_LIST_PANE_LIST:
        return { ...state, productListPaneItem: [], isDataLoadedProduct: false, loadMsgProduct: '' };
      case LOAD_PRODUCT_PO_LIST_PANE_LIST_SUCCESS:
        return {
          ...state,
          productListPaneItem: action.payload.data.status ? formatProductListPaneItem(action.payload.data.result) : [],
          // productListPaneItem: action.payload.data.status ? action.payload.data.result:[],
          isDataLoadedProduct: true,
          loadMsgProduct: !action.payload.data.status ? action.payload.data.message : '',
        };
      case LOAD_PRODUCT_PO_LIST_PANE_LIST_FAIL:
        return {
          ...state,
          productListPaneItem: [],
          isDataLoadedProduct: true,
          loadMsgProduct: action.error.response.data.message,
        };


      case LOAD_PO_PROJECT_LIST:
        return { ...state, poProjectList: [] };
      case LOAD_PO_PROJECT_LIST_SUCCESS:
        return {
          ...state,
          poProjectList: action.payload?.data?.status ? formatProjectList(action.payload?.data?.result) : [],
        };
      case LOAD_PO_PROJECT_LIST_FAIL:
        return {
          ...state,
          poProjectList: [],
        };

      case LOAD_PO_BOR_UPDATE:
        return { ...state };
      case LOAD_PO_BOR_UPDATE_SUCCESS:
        return {
          ...state,
        };
      case LOAD_PO_BOR_UPDATE_FAIL:
        return {
          ...state,
        };

      case LOAD_PO_CU_BOR_UPDATE:
        return { ...state };
      case LOAD_PO_CU_BOR_UPDATE_SUCCESS:
        return {
          ...state,
        };
      case LOAD_PO_CU_BOR_UPDATE_FAIL:
        return {
          ...state,
        };


      case CONVERT_CR_TO_PO:
        return { ...state };
      case CONVERT_CR_TO_PO_SUCCESS:
        return {
          ...state,
          isChange: true,
        };
      case CONVERT_CR_TO_PO_FAIL:
        return {
          ...state,
        };

      case SEND_RFQ_EMAIL:
        return { ...state,showProgressBar: true };
      case SEND_RFQ_EMAIL_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          isChange: true,
        };
      case SEND_RFQ_EMAIL_FAIL:
        return {
          ...state,
          showProgressBar: false
        };

      case UPLOAD_RFQ_SIGNATURE:
        return { ...state,signatureLink: '' };
      case UPLOAD_RFQ_SIGNATURE_SUCCESS:
        return {
          ...state,
          signatureLink:action.payload?.data?.result,
        };
      case UPLOAD_RFQ_SIGNATURE_FAIL:
        return {
          ...state,
          signatureLink:'',
        };

      case LOAD_RFQ_INVITATION_DETAILS:
        return { ...state,showProgressBar:true };
      case LOAD_RFQ_INVITATION_DETAILS_SUCCESS:
        return {
          ...state,
          rfqFormData:action.payload?.data?.result,
          showProgressBar:false
        };
      case LOAD_RFQ_INVITATION_DETAILS_FAIL:
        return {
          ...state,
          showProgressBar:false
        };

      case SAVE_SIGNATURE:
        return { ...state,showProgressBar:true };
      case SAVE_SIGNATURE_SUCCESS:
        return {
          ...state,
          showProgressBar:false
        };
      case SAVE_SIGNATURE_FAIL:
        return {
          ...state,
          showProgressBar:false
        };

      case SAVE_PO_STATE_ATTRIBUTE:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value
        };

      default:
        return state;
    }
  }
}

export const readPurchaseOrderShortCutPaneData = () => {
  return {
    types: [
      LOAD_PURCHASE_ORDER_SHORTCUTPANE,
      LOAD_PURCHASE_ORDER_SHORTCUTPANE_SUCCESS,
      LOAD_PURCHASE_ORDER_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_PURCHASE_ORDER_SHORTCUT_PANE_EP } },
  };
};

export const readPurchaseOrderDropDownData = () => {
  return {
    types: [
      LOAD_PURCHASE_ORDER_DROPDOWN,
      LOAD_PURCHASE_ORDER_DROPDOWN_SUCCESS,
      LOAD_PURCHASE_ORDER_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_PURCHASE_ORDER_DROPDOWN_EP } },
  };
};


export const filterPurchaseOrderListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_PURCHASE_ORDER_LSITPANE,
      LOAD_PURCHASE_ORDER_LSITPANE_SUCCESS,
      LOAD_PURCHASE_ORDER_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_PURCHASE_ORDER_LIST_EP,
        data: filter,
      },
    },
  };
};

export const readBORResourcesByIds = (ids: string[], project?: string) => {
  return {
    types: [
      LOAD_BOR_RESOURCES_BY_ID,
      LOAD_BOR_RESOURCES_BY_ID_SUCCESS,
      LOAD_BOR_RESOURCES_BY_ID_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: BOR_RESOURCE_GETBY_ID_EP,
        data: ids,
        headers: project && { project: project },
      },
    },
  };
};

export const readPbsResourceByIds = (ids: string[]) => {
  return {
    types: [
      LOAD_PBS_RESOURCES_BY_ID,
      LOAD_PBS_RESOURCES_BY_ID_SUCCESS,
      LOAD_PBS_RESOURCES_BY_ID_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: PBS_RESOURCE_GETBY_ID_EP,
        data: ids,
      },
    },
  };
};


export const convertCrTOPo = (id: string) => {
  return {
    types: [
      CONVERT_CR_TO_PO,
      CONVERT_CR_TO_PO_SUCCESS,
      CONVERT_CR_TO_PO_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: CONVERT_CR_TO_PO_EP,
        data: id,
      },
    },
  };
};


export const readByPurchaseOrderId = (id: string) => {
  return {
    types: [
      LOAD_PURCHASE_ORDER_BY_ID,
      LOAD_PURCHASE_ORDER_BY_ID_SUCCESS,
      LOAD_PURCHASE_ORDER_BY_ID_FAIL],
    payload: { request: { url: READ_PURCHASE_ORDER_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_PURCHASE_ORDER_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_PURCHASE_ORDER,
      SAVE_PURCHASE_ORDER_SUCCESS,
      SAVE_PURCHASE_ORDER_FAIL],
    payload: {
      request: {
        url: PURCHASE_ORDER_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const autoSaveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      AUTO_SAVE_PURCHASE_ORDER,
      AUTO_SAVE_PURCHASE_ORDER_SUCCESS,
      AUTO_SAVE_PURCHASE_ORDER_FAIL],
    payload: {
      request: {
        url: PURCHASE_ORDER_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const saveFormDataWithOutFetching = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_PURCHASE_ORDER_NOUPDATE,
      SAVE_PURCHASE_ORDER_SUCCESS_NOUPDATE,
      SAVE_PURCHASE_ORDER_FAIL_NOUPDATE],
    payload: {
      request: {
        url: PURCHASE_ORDER_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};


export const projectSendPo = (formData: any) => {
  return {
    types: [PROJECT_SEND_PO, PROJECT_SEND_PO_SUCCESS, PROJECT_SEND_PO_FAIL],
    payload: {
      request: {
        url: PROJECT_SEND_PO_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const cuSendPo = (formData: any) => {
  return {
    types: [CU_SEND_PO, CU_SEND_PO_SUCCESS, CU_SEND_PO_FAIL],
    payload: {
      request: {
        url: CU_SEND_PO_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const approvedPo = (formData: any) => {
  return {
    types: [APPROVED_PO, APPROVED_PO_SUCCESS, APPROVED_PO_FAIL],
    payload: {
      request: {
        url: ACCEPT_PO_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};
export const approvedPoCu = (formData: any) => {
  return {
    types: [APPROVED_PO_CU, APPROVED_PO_CU_SUCCESS, APPROVED_PO_CU_FAIL],
    payload: {
      request: {
        url: APPROVED_PO_CU_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const approvedPoCuLevel = (formData: any) => {
  return {
    types: [APPROVED_PO_CU_LEVEL, APPROVED_PO_CU_LEVEL_SUCCESS, APPROVED_PO_CU_LEVEL_FAIL],
    payload: {
      request: {
        url: APPROVED_PO_CU_LEVEL_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};


export const feedbackPoCu = (formData: any) => {
  return {
    types: [FEEDBACK_PO_CU, FEEDBACK_PO_CU_SUCCESS, FEEDBACK_PO_CU_FAIL],
    payload: {
      request: {
        url: FEEDBACK_PO_CU_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const createPODeliveryLocation = (data: LocationAddress) => {
  return {
    types: [CREATE_PO_DELIVERY_LOCATION, CREATE_PO_DELIVERY_LOCATION_SUCCESS, CREATE_PO_DELIVERY_LOCATION_FAIL],
    payload: {
      request: {
        url: CREATE_PO_LOCATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getDeliveryPlanListPaneData = (id: string) => {
  return {
    types: [LOAD_DELIVERY_PLAN, LOAD_DELIVERY_PLAN_SUCCESS, LOAD_DELIVERY_PLAN_FAIL],
    payload: {
      request: {
        url: LOAD_DELIVERY_PLAN_EP,
        method: 'POST',
        data: { poHeaderId: id },
      },
    },
  };
};

export const getCuDeliveryPlanListPaneData = (id: string) => {
  return {
    types: [LOAD_CU_DELIVERY_PLAN, LOAD_CU_DELIVERY_PLAN_SUCCESS, LOAD_CU_DELIVERY_PLAN_FAIL],
    payload: {
      request: {
        url: LOAD_CU_DELIVERY_PLAN_EP,
        method: 'POST',
        data: { poHeaderId: id },
      },
    },
  };
};

export const deliveryPlanStockUpdate = (data: any) => {
  return {
    types: [LOAD_DELIVERY_PLAN_STOCK_UPDATE, LOAD_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS, LOAD_DELIVERY_PLAN_STOCK_UPDATE_FAIL],
    payload: {
      request: {
        url: DELIVERY_PLAN_STOCK_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};
export const cuDeliveryPlanStockUpdate = (data: any) => {
  return {
    types: [LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE, LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS, LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_FAIL],
    payload: {
      request: {
        url: CU_DELIVERY_PLAN_STOCK_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getDeliveryPlanProductListPaneData = (id: string) => {
  return {
    types: [LOAD_PRODUCT_DELIVERY_PLAN, LOAD_PRODUCT_DELIVERY_PLAN_SUCCESS, LOAD_PRODUCT_DELIVERY_PLAN_FAIL],
    payload: {
      request: {
        url: LOAD_PRODUCT_DELIVERY_PLAN_EP,
        method: 'POST',
        data: { poHeaderId: id },
      },
    },
  };
};

export const deliveryPlanProductStockUpdate = (data: any) => {
  return {
    types: [LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE, LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_SUCCESS, LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_FAIL],
    payload: {
      request: {
        url: DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deliveryPlanProductCuStockUpdate = (data: any) => {
  return {
    types: [LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE, LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_SUCCESS, LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_FAIL],
    payload: {
      request: {
        url: DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getBorPoListPaneData = (data: any) => {
  if (data) {
    return {
      types: [LOAD_BOR_PO_LIST_PANE_LIST, LOAD_BOR_PO_LIST_PANE_LIST_SUCCESS, LOAD_BOR_PO_LIST_PANE_LIST_FAIL],
      payload: {
        request: {
          url: LOAD_BOR_PO_LIST_PANE_LIST_EP,
          method: 'POST',
          headers: data.project && { 'project': data.project },
          data: data,
        },
      },
    };
  }
  // return {
  //     types: [ LOAD_BOR_PO_LIST_PANE_LIST, LOAD_BOR_PO_LIST_PANE_LIST_SUCCESS, LOAD_BOR_PO_LIST_PANE_LIST_FAIL ],
  //     payload: {
  //         request: {
  //             url: LOAD_BOR_PO_LIST_PANE_LIST_EP,
  //             method: 'POST',
  //             data: data
  //         }
  //     }
  // };
};

export const getBorReturnPoListPaneData = (data: any) => {
  if (data) {
    return {
      types: [LOAD_BOR_RETURN_PO_LIST_PANE_LIST, LOAD_BOR_RETURN_PO_LIST_PANE_LIST_SUCCESS, LOAD_BOR_RETURN_PO_LIST_PANE_LIST_FAIL],
      payload: {
        request: {
          url: LOAD_BOR_RETURN_PO_LIST_PANE_LIST_EP,
          method: 'POST',
          headers: data.project && { 'project': data.project },
          data: data,
        },
      },
    };
  }
};

export const getBorPoResourcesListPaneData = (data: any) => {
  if (data) {
    return {
      types: [LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST, LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_SUCCESS, LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_FAIL],
      payload: {
        request: {
          url: LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_EP,
          method: 'POST',
          headers: data.project && { 'project': data.project },
          data: data,
        },
      },
    };
  }
};

export const getProductPoListPaneData = (data: any, project: string) => {
  let postData = { ...data, projectSequenceCode: project };
  return {
    types: [LOAD_PRODUCT_PO_LIST_PANE_LIST, LOAD_PRODUCT_PO_LIST_PANE_LIST_SUCCESS, LOAD_PRODUCT_PO_LIST_PANE_LIST_FAIL],
    payload: {
      request: {
        url: getProject() ? LOAD_PRODUCT_PO_LIST_PANE_LIST_EP : LOAD_CU_PRODUCT_PO_LIST_PANE_LIST_EP,
        method: 'POST',
        headers: { 'project': project },
        data: postData,
      },
    },
  };
};


export const getPoProjectList = () => {
  return {
    types: [LOAD_PO_PROJECT_LIST, LOAD_PO_PROJECT_LIST_SUCCESS, LOAD_PO_PROJECT_LIST_FAIL],
    payload: {
      request: {
        url: LOAD_PO_PROJECT_LIST_EP,
        method: 'GET',
      },
    },
  };
};


export const getRfqInvitationDetails = (id:string) => {
  return {
    types: [LOAD_RFQ_INVITATION_DETAILS, LOAD_RFQ_INVITATION_DETAILS_SUCCESS, LOAD_RFQ_INVITATION_DETAILS_FAIL],
    payload: {
      request: {
        url: LOAD_RFQ_INVITATION_DETAILS_EP+id,
        method: 'GET',
      },
    },
  };
};


export const saveSignature = (postData:any) => {
  return {
    types: [SAVE_SIGNATURE, SAVE_SIGNATURE_SUCCESS, SAVE_SIGNATURE_FAIL],
    payload: {
      request: {
        url: SAVE_SIGNATURE_EP,
        method: 'POST',
        data: postData,
      },
    },
  };
};

export const uploadRfqSignature = (formData: any) => {
  return {
    types: [UPLOAD_RFQ_SIGNATURE, UPLOAD_RFQ_SIGNATURE_SUCCESS, UPLOAD_RFQ_SIGNATURE_FAIL],
    payload: {
      request: {
        url: UPLOAD_RFQ_SIGNATURE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};


export const setUid = () => {
  return { type: SET_PURCHASE_ORDER_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_PURCHASE_ORDER_FORM_DATA };
};

export const setBORModalState = (visibility: boolean) => {
  return {
    type: PO_BOR_POPUP_STATE,
    payload: visibility,
  };
};


export const poBorUpdate = (data: any) => {
  return {
    types: [LOAD_PO_BOR_UPDATE, LOAD_PO_BOR_UPDATE_SUCCESS, LOAD_PO_BOR_UPDATE_FAIL],
    payload: {
      request: {
        url: LOAD_PO_BOR_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const poCUBorUpdate = (data: any) => {
  return {
    types: [LOAD_PO_CU_BOR_UPDATE, LOAD_PO_CU_BOR_UPDATE_SUCCESS, LOAD_PO_CU_BOR_UPDATE_FAIL],
    payload: {
      request: {
        url: LOAD_PO_CU_BOR_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};
export const sendRfqEmail = (data: any[]) => {
  return {
    types: [SEND_RFQ_EMAIL, SEND_RFQ_EMAIL_SUCCESS, SEND_RFQ_EMAIL_FAIL],
    payload: {
      request: {
        url: SEND_RFQ_EMAIL_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const savePoAtrr = (attr: string, value: any) => {
  return {
    type: SAVE_PO_STATE_ATTRIBUTE,
    [attr]: value,
  };
};

const formatDeliveryPlanListPaneItem = (response: any) => {
  if (response.data.result && response.data.result.length > 0) {
    const borItems: any = [];
    const data = response.data.result.map((item: any) => {
      const resources = item.resources.map((resourcesItem: any) => {
        return {
          borId: resourcesItem.borId,
          id: resourcesItem.id,
          corporateProductCatalogId: resourcesItem.corporateProductCatalogId,
          resourceTitle: resourcesItem.cTitle,
          mou: resourcesItem.cMou,
          quantity: resourcesItem.cQuantity,
          deliveryDate: resourcesItem.poHeaderDto?.deliveryDate,
          stockAvailability: resourcesItem.stockAvailability,
          resourcesTypeName: resourcesItem.resourcesTypeName,
          resourcesType: resourcesItem.resourcesType,
          expectedDeliveryDate: resourcesItem.expectedDeliveryDate,
          cUnitPrice: resourcesItem.cUnitPrice,
          cTotalPrice: resourcesItem.cTotalPrice,
          cQuantity: resourcesItem.cQuantity,
          wfStatus: resourcesItem.wfStatus,
          isStock: !!resourcesItem.isStock,
          isPo: resourcesItem.isStock === false,
          action: resourcesItem.isStock,
          ccpcId: resourcesItem.ccpcId,
        };
      });
      return {
        borTitle: item.borTitle,
        borId: item.borId,
        resources: resources,
      };
    });
    return data;
  }
  return [];
};


const formatDeliveryPlanProductListPaneItem = (response: any) => {
  if (response) {
    if (response.externalProduct && response.externalProduct.length) {
      const data = response.externalProduct.map((item: any) => {
        return {
          ...item,
          title: item.title,
          cTotalPrice: item.cTotalPrice,
          requestedDeliveryDate: item.requestedDeliveryDate ? (item.requestedDeliveryDate) : response.deliveryDate,
          expectedDeliveryDate: item.expectedDeliveryDate,
          isStock: item.isPo === null ? false : !item.isPo,
          isPo: item.isPo === null ? true : item.isPo,
          action: item.isPo,
        };
      });
      return data;
    }
    return [];
  }
  return [];
};


const formattedPoList = (result: any) => {
  if (result) {
    const data = result.map((item: any) => {
      return {
        ...item,
        lastModifiedDate: format24hDateTime(item.lastModifiedDate),
        totalAmount: item.totalAmount ? numberToLocaleString(item.totalAmount) : item.totalAmount,
        //totalAmount: item.totalAmount ? parseFloat(item.totalAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : item.totalAmount
      };
    });
    return data;
  }
  return [];
};

const formatProductListPaneItem = (result: any) => {
  if (result) {
    const data = result.map((item: any) => {
      return {
        ...item,
        key: item.productId,
      };
    });
    return data;
  }
  return [];
};


const formatBorResourceListPaneItem = (result: any) => {
  if (result) {
    let data = result.map((item: any) => {
      return {
        ...item,
        key: item.id,
        resourceTitle: item.cucpcTitle,
        title: item.cucpcTitle,
        required: item.cQuantity,
        consumed: item.pConsumed,
        purchased: item.cPurchased,
        deliveryRequested: item.cDeliveryRequested,
        invoiced: item.pInvoiced,
        mou: item.cMou,
        inventoryPrice: null,
        pbsTitle: item.pbsTitle,
        resourceFamily: item.resourceFamily,
        date: null,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formatProjectList = (result: any) => {
  if (result) {
    const data = result.map((item: any) => {
      return {
        ...item,
        key: item.sequenceCode,
        value: item.title,
        text: item.title,
      };
    });
    return data;
  }
  return [];
};

const getBorResourceCount = (result: any) => {
  let materials = result && result.materials ? result.materials : [];
  let tools = result && result.tools ? result.tools : [];
  let consumables = result && result.consumable ? result.consumable : [];
  let labours = result && result.labour ? result.labour : [];
  let allResources = _.concat(materials, tools, consumables, labours);
  // return 0
  return allResources.length;
};

const dummydataGetById = () => {
  return {
    'id': '1917278f-4a69-4890-8358-9c39759c4d42',
    'sequenceId': 'PO-0850',
    'title': 'PO-0850 test122',
    'name': 'test122 dummy1',
    'comments': '<p>commenttttts for</p>',
    'customerOrganisationId': null,
    'customerContactId': null,
    'supplierOrganisationId': null,
    'supplierContactId': null,
    'customerOrganisation': null,
    'supplierOrganisation': null,
    'customerReference': null,
    'supplierReference': null,
    'acceptanceLevel': null,
    'type': {
      'key': '94282458-0b40-40a3-b0f9-c2e40344c8f1',
      'text': 'Resources',
    },
    'status': {
      'key': '4010e768-3e06-4702-b337-ee367a82addb',
      'text': 'In Review',
    },
    'history': {
      'createdDate': '2021-05-18T04:45:40.6266667',
      'modifiedDate': '2021-05-18T04:49:05.22',
      'creatBy': 'Achini Uresha',
      'modifiedBy': 'Achini Uresha',
    },
    'customerContact': null,
    'supplierContact': null,
    'poResources': {
      'materials': [
        {
          'id': '0be7b62c-3da1-42b0-9f3e-8cc4700d63f8',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0009 - material_2',
          'resourceNumber': 'PAr01-0009',
          'date': '2021-05-14T08:29:01.4666667',
          'pQuantity': '3',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '59880941-6379-4ea5-af73-4471b61df295',
          'borTitle': 'BOR-0039 - material',
          'borId': 'ff75e218-59f9-401f-b364-901dae2f8c2f',
          'mou': 'km',
          'pDocuments': [
            null,
          ],
          'pComments': '<p>xsxsx dcdfdv</p>',
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '3',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
        {
          'id': '1f288049-057d-41cb-959c-4dc898801919',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0010 - material_3',
          'resourceNumber': 'PAr01-0010',
          'date': '2021-05-14T08:29:11.0566667',
          'pQuantity': '5',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '58ee22b0-f8c3-4ef9-8d90-2ce10fc212bb',
          'borTitle': 'BOR-0039 - material',
          'borId': 'ff75e218-59f9-401f-b364-901dae2f8c2f',
          'mou': 'kg',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '5',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
      ],
      'tools': [
        {
          'id': 'a71b7d24-c8b2-40b8-8984-fb4a6eec5b47',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0012 - tool_2',
          'resourceNumber': 'PAr01-0012',
          'date': '2021-05-14T05:01:03.65',
          'pQuantity': '22',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '39b6228f-1287-4d2e-bb05-094654a4586d',
          'borTitle': 'BOR-0026 - BOR_T',
          'borId': '781be313-8dd8-41c2-b4c7-2f0d0817eccc',
          'mou': 'kg',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '22',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
        {
          'id': 'b886459f-ae4b-4678-b037-3544fa58145f',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0011 - tool_1',
          'resourceNumber': 'PAr01-0011',
          'date': '2021-05-14T05:00:53.8466667',
          'pQuantity': '11',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '4681d7ff-49c5-4e0f-a802-e781a7b8ba81',
          'borTitle': 'BOR-0026 - BOR_T',
          'borId': '781be313-8dd8-41c2-b4c7-2f0d0817eccc',
          'mou': 'm',
          'pDocuments': [
            null,
          ],
          'pComments': '<p>comments for tool_1 0011 cgrrrrrrrcb&nbsp;</p>',
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '11',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
        {
          'id': 'd6d0e284-299c-4985-a520-8000195f8087',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'CAr01-0020 - za12345',
          'resourceNumber': 'CAr01-0020',
          'date': '2021-05-14T05:01:19.8666667',
          'pQuantity': '33',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': 'f5a12b62-7637-47e8-83f5-fd62b6e227e2',
          'borTitle': 'BOR-0026 - BOR_T',
          'borId': '781be313-8dd8-41c2-b4c7-2f0d0817eccc',
          'mou': '(tube)',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '33',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
      ],
      'consumable': [
        {
          'id': '4ae46b1a-e624-47f7-9b83-ba4ead9d6eec',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0003 - consumable_2',
          'resourceNumber': 'PAr01-0003',
          'date': '2021-05-14T05:04:11.73',
          'pQuantity': '6',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': 'f4e5f6be-9ee9-4e4c-a034-1f371a05569a',
          'borTitle': 'BOR-0027 - BOR_C',
          'borId': '0087758f-b898-470d-93d0-616c3cde3db1',
          'mou': '(tube)',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '6',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
        {
          'id': '68300aee-b180-481b-b99e-1156a4df67bc',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'CAr01-0010 - Consumable',
          'resourceNumber': 'CAr01-0010',
          'date': '2021-05-14T05:04:56.8633333',
          'pQuantity': '8',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': 'ed6c2e8a-55cd-46e3-bbe5-6bbded229f62',
          'borTitle': 'BOR-0027 - BOR_C',
          'borId': '0087758f-b898-470d-93d0-616c3cde3db1',
          'mou': 'm',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '8',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
        {
          'id': '8f13688f-070a-49e1-81f2-f0fd740e8b9b',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0002 - consumable_1',
          'resourceNumber': 'PAr01-0002',
          'date': '2021-05-14T05:03:59.6866667',
          'pQuantity': '4',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '42a90944-307a-400f-b077-40e52c1931e0',
          'borTitle': 'BOR-0027 - BOR_C',
          'borId': '0087758f-b898-470d-93d0-616c3cde3db1',
          'mou': 'kg',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '4',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
      ],
      'labour': [
        {
          'id': '45016a82-43ce-44a5-ae25-4aa734adc9f9',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'PAr01-0007 - HR_3',
          'resourceNumber': 'PAr01-0007',
          'date': '2021-05-14T05:36:14.41',
          'pQuantity': '51',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '10adc2d9-4788-4830-bbce-745ef43a2b13',
          'borTitle': 'BOR-0033 - BOR_L',
          'borId': 'c8ba5845-d739-4fbb-9427-a0e19e1d9ecf',
          'mou': 'H',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '51',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
        {
          'id': 'f2e4242b-3e67-402e-89fc-8c678d4de402',
          'purchesOrderId': '3c31ccd7-e4a1-4d9a-889d-36d98ecde146',
          'title': 'CAr01-0008 - Labor',
          'resourceNumber': 'CAr01-0008',
          'date': '2021-05-14T05:36:29.1266667',
          'pQuantity': '52',
          'pPurchased': '0',
          'pDeliveryRequested': '0',
          'warf': '0',
          'pConsumed': '0',
          'pInvoiced': '0',
          'corporateProductCatalogId': '7c31123d-35b5-4ee1-a62f-136c4dc5773a',
          'borTitle': 'BOR-0033 - BOR_L',
          'borId': 'c8ba5845-d739-4fbb-9427-a0e19e1d9ecf',
          'mou': 'H',
          'pDocuments': [
            null,
          ],
          'pComments': null,
          'pUnitPrice': null,
          'pTotalPrice': '0',
          'pCrossReference': null,
          'cComments': null,
          'cUnitPrice': null,
          'cTotalPrice': null,
          'cCrossReference': null,
          'cQuantity': '52',
          'cPurchased': '0',
          'cDeliveryRequested': false,
          'cStartDate': null,
          'cStopDate': null,
          'cNumberOfDate': null,
          'cFullTimeEmployee': null,
          'pStartDate': null,
          'pStopDate': null,
          'pNumberOfDate': null,
          'pFullTimeEmployee': null,
          'pdevices': null,
          'cdevices': null,
          'projectTitle': 'P0010',
          'noOfMaterials': null,
          'noOfTools': null,
          'noOfConsumables': null,
          'noOfLabours': null,
          'pbsTitle': 'PBS-0093 - po1',
        },
      ],
    },
    'projectSequenceCode': 'P0010',
    'files': [],
    'externalProduct': [],
  };
};


const deliveryPlanProductListPaneItemDummyData = () => {
  return [

    {
      'productId': '1235',
      'id': '3',
      'productTitle': 'PAr01-0001 - product 1',
      'quantity': 2,
      'deliveryDate': '05/20/2021 04:41:45',
      'isPo': true,
    },
    {
      'productId': '1235',
      'id': '4',
      'productTitle': 'PAr01-0002 - product 2',
      'quantity': 5,
      'deliveryDate': '05/21/2021 04:41:45',
      'isPo': true,
    },
    {
      'productId': '1235',
      'id': '5',
      'productTitle': 'PAr01-0003 - product 3',
      'quantity': 10,
      'deliveryDate': '05/22/2021 04:41:45',
      'isPo': true,
    },
    {
      'productId': '1235',
      'id': '6',
      'productTitle': 'PAr01-0004 - product 4',
      'quantity': 20,
      'deliveryDate': '05/23/2021 04:41:45',
      'isPo': true,
    },
  ];
};
const deliveryPlanListPaneItemDummyData = () => {
  return [
    {
      'borId': '123',
      'borTitle': 'Bor 1',
      'resources': [
        {
          'borId': '1235',
          'id': '3',
          'corporateProductCatalogId': '3c',
          'resourceTitle': 'PAr01-0001 - material',
          'mou': 'm',
          'quantity': 2,
          'deliveryDate': '05/20/2021 04:41:45',
          'stockAvailability': 100,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '4',
          'corporateProductCatalogId': '4c',
          'resourceTitle': 'PAr01-0002 - tools',
          'mou': 'g',
          'quantity': 5,
          'deliveryDate': '05/21/2021 04:41:45',
          'stockAvailability': null,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '5',
          'corporateProductCatalogId': '5c',
          'resourceTitle': 'PAr01-0003 - consumble',
          'mou': 'l',
          'quantity': 10,
          'deliveryDate': '05/22/2021 04:41:45',
          'stockAvailability': 400,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '6',
          'corporateProductCatalogId': '6c',
          'resourceTitle': 'PAr01-0004 - labor',
          'mou': 'h',
          'quantity': 20,
          'deliveryDate': '05/23/2021 04:41:45',
          'stockAvailability': 200,
          'isStock': false,
          'isPo': false,
        },
      ],
    },
    {
      'borId': '1234',
      'borTitle': 'Bor 2',
      'resources': [
        {
          'borId': '1235',
          'id': '7',
          'corporateProductCatalogId': '7c',
          'resourceTitle': 'PAr01-0005 - material2',
          'mou': 'm',
          'quantity': 10,
          'deliveryDate': '05/24/2021 04:41:45',
          'stockAvailability': 100,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '8',
          'corporateProductCatalogId': '8c',
          'resourceTitle': 'PAr01-0006 - tools2',
          'mou': 'g',
          'quantity': 30,
          'deliveryDate': '05/26/2021 04:41:45',
          'stockAvailability': 500,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '9',
          'corporateProductCatalogId': '9c',
          'resourceTitle': 'PAr01-0007 - consumble2',
          'mou': 'l',
          'quantity': 20,
          'deliveryDate': '05/27/2021 04:41:45',
          'stockAvailability': 200,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '1',
          'corporateProductCatalogId': '1c',
          'resourceTitle': 'PAr01-0008 - labor2',
          'mou': 'h',
          'quantity': 40,
          'deliveryDate': '05/28/2021 04:41:45',
          'stockAvailability': 300,
          'isStock': false,
          'isPo': false,
        },
      ],
    },
    {
      'borId': '1235',
      'borTitle': 'Bor 3',
      'resources': [
        {
          'borId': '1235',
          'id': '10',
          'corporateProductCatalogId': '7c',
          'resourceTitle': 'PAr01-0005 - material2',
          'mou': 'm',
          'quantity': 10,
          'deliveryDate': '05/24/2021 04:41:45',
          'stockAvailability': 100,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '11',
          'corporateProductCatalogId': '8c',
          'resourceTitle': 'PAr01-0006 - tools2',
          'mou': 'g',
          'quantity': 30,
          'deliveryDate': '05/26/2021 04:41:45',
          'stockAvailability': 500,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '12',
          'corporateProductCatalogId': '9c',
          'resourceTitle': 'PAr01-0007 - consumble2',
          'mou': 'l',
          'quantity': 20,
          'deliveryDate': '05/27/2021 04:41:45',
          'stockAvailability': 200,
          'isStock': false,
          'isPo': false,
        },
        {
          'borId': '1235',
          'id': '13',
          'corporateProductCatalogId': '1c',
          'resourceTitle': 'PAr01-0008 - labor2',
          'mou': 'h',
          'quantity': 40,
          'deliveryDate': '05/28/2021 04:41:45',
          'stockAvailability': 300,
          'isStock': false,
          'isPo': false,
        },
      ],
    },
  ];
};


const dummydata = () => {
  return [
    {
      'ammount': 40,
      'id': '7dceedb3-7638-423e-b537-b8cb03c216db',
      'name': null,
      'title': 'PO-0441 poli dummy1',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0441',
      'customerCompanyId': 'e0386eac-c9a0-4f93-8baf-d24948bedda9',
      'suplierCompanyId': '6c549db8-afe2-4813-a47e-056d85c2b64e',
      'customer': 'Goddeeris',
      'supplier': 'Mickiesoft',
      'lastModifiedDate': '05/12/2021 04:35:22',
      'noOfMaterials': 0,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': 'f4ffa350-3a90-4b01-9e1b-1b6380b02738',
      'name': null,
      'title': 'PO-0431 test dummy1',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0431',
      'customerCompanyId': 'e0386eac-c9a0-4f93-8baf-d24948bedda9',
      'suplierCompanyId': '6c549db8-afe2-4813-a47e-056d85c2b64e',
      'customer': 'Goddeeris',
      'supplier': 'Mickiesoft',
      'lastModifiedDate': '05/12/2021 04:15:31',
      'noOfMaterials': 0,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': '8244c518-74f8-431f-b304-250323c67cc6',
      'name': null,
      'title': 'PO-0426 1_pOrder_!!!!',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0426',
      'customerCompanyId': 'e0386eac-c9a0-4f93-8baf-d24948bedda9',
      'suplierCompanyId': '6c549db8-afe2-4813-a47e-056d85c2b64e',
      'customer': 'Goddeeris',
      'supplier': 'Mickiesoft',
      'lastModifiedDate': '05/12/2021 04:05:21',
      'noOfMaterials': 0,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': '89b26a85-c6bb-4bd9-9e32-4350ed6a29ca',
      'name': null,
      'title': 'PO-0414 po edit bor resourrces',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0414',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 11:15:44',
      'noOfMaterials': 0,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': '12e8e571-1d34-4ae9-9258-9648ed806e6c',
      'name': null,
      'title': 'PO-0404 po test wasana 6',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0404',
      'customerCompanyId': '6c549db8-afe2-4813-a47e-056d85c2b64e',
      'suplierCompanyId': 'e0386eac-c9a0-4f93-8baf-d24948bedda9',
      'customer': 'Mickiesoft',
      'supplier': 'Goddeeris',
      'lastModifiedDate': '05/11/2021 11:01:19',
      'noOfMaterials': 0,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': '162ba57b-0cf9-4a17-abae-16b8b1a6a05a',
      'name': null,
      'title': 'PO-0403 dasdads',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0403',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 09:12:23',
      'noOfMaterials': 1,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 0,
    },
    {
      'ammount': 40,
      'id': '2aa3ac54-372b-4845-9eee-80be67b40594',
      'name': null,
      'title': 'PO-0401 ZZZZZ',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0401',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 08:33:57',
      'noOfMaterials': 1,
      'noOfTools': 2,
      'noOfConsumables': 0,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': '12dfc890-f05b-489a-b40a-41190dbccc09',
      'name': null,
      'title': 'PO-0400 test list pane1',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0400',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 08:29:00',
      'noOfMaterials': 1,
      'noOfTools': 0,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': '5f210f04-c08e-40ed-859f-195b65306939',
      'name': null,
      'title': 'PO-0399 test list pane',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0399',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 08:27:41',
      'noOfMaterials': 0,
      'noOfTools': 2,
      'noOfConsumables': 1,
      'noOfLabours': 0,
    },
    {
      'ammount': 40,
      'id': '3fadb877-7add-434c-aea5-dfc8b7f6767a',
      'name': null,
      'title': 'PO-0388 test error',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0388',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 07:38:32',
      'noOfMaterials': 0,
      'noOfTools': 0,
      'noOfConsumables': 1,
      'noOfLabours': 3,
    },
    {
      'ammount': 40,
      'id': 'b0d0e333-9fe7-44aa-ae50-b26bbdc37161',
      'name': null,
      'title': 'PO-0385 poo test wasana 04',
      'executionDate': null,
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0385',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 07:29:35',
      'noOfMaterials': 0,
      'noOfTools': 0,
      'noOfConsumables': 1,
      'noOfLabours': 0,
    },
    {
      'ammount': 40,
      'id': '324310fb-e558-4c97-bf20-fe2670a57c40',
      'name': null,
      'title': 'PO-0382 test bor btn',
      'executionDate': null,
      'type': 'Product',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'PO-0382',
      'customerCompanyId': null,
      'suplierCompanyId': null,
      'customer': null,
      'supplier': null,
      'lastModifiedDate': '05/11/2021 07:07:28',
      'noOfMaterials': 0,
      'noOfTools': 0,
      'noOfConsumables': 0,
      'noOfLabours': 0,
    },
  ];
};
