import React from 'react';
import {
  DetailsListLayoutMode, DirectionalHint,
  FontIcon,
  IColumn,
  IStackStyles,
  IStackTokens, ITooltipHostStyles,
  mergeStyles,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType, TooltipDelay, TooltipHost,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DragBox from '../../../shared/dnd/dragBox';
import { AcceptType, ProjectPlanResourceType } from '../../../types/myDayPlanning';
import { iconColors } from '../../../../theme';


const iconClass = mergeStyles({
  fontSize: 14,
  height: 14,
  width: 14,
  margin: '0 5px',
});

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
  deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
  red: [{ color: 'red' }, iconClass],
  green: [{ color: 'green' }, iconClass],
  orange: [{ color: 'orange' }, iconClass],
});

const wrapStackTokens: IStackTokens = { childrenGap: 2 };

const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block'}};

const stackStyles: IStackStyles = {
  root: {
    padding: 0
  },
};

const WorkerList=()=>{
  const { t } = useTranslation();
  const {
    resourceList,
    isResourceDataLoaded,
  } = useSelector(
    (state: any) => state.projectDPlan
  );



  const columns = [
    {
      key: 'column1',
      name: t('workers'),
      fieldName: 'workers',
      minWidth: 100,
      maxWidth: 1200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('vehicles'),
      fieldName: 'vehicle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const getWorkerIconColor = (precentage: any): string => {
    const getDouble = parseFloat(precentage);
    
    if (0.0 <= getDouble && getDouble <= 80) {
      return classNames.red;
    } else if (80 < getDouble && getDouble <= 110) {
      return classNames.green;
    } else if (110 < getDouble) {
      return classNames.orange;
    }

    return classNames.deepSkyBlue
  };

  const resourceListColumn = (item: any, index?: number, column?: IColumn) => {
    if (column && column.fieldName && (column.fieldName === 'workers')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => { }}  >
                <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
                    {fieldContent.map((worker: any) => {
                      return <div key={Math.random()}>
                        <TooltipHost
                          delay={TooltipDelay.medium}
                          id={worker?.projectList}
                          directionalHint={DirectionalHint.topCenter}
                          styles={hostStyles}
                          content={worker?.projectList?.length ? (
                            worker.projectList?.map((project: any) => <li>{project}</li>)
                          ) : (
                            <span>{t('noAssignedProjects')}</span>
                          )}
                        >
                          <DragBox
                            // disabled={worker.absence ? true : !getProject()}
                            type={ProjectPlanResourceType.TEAM}
                            onClick={(item: any) => {
                            }}
                            item={{ ...worker }}
                            name={worker?.name}
                            icon={!worker?.absence
                              ? <FontIcon aria-label='Worker' iconName='Contact' className={getWorkerIconColor(worker?.precentage)} />
                              : <FontIcon aria-label='Sick' iconName='WarningSolid' className={classNames.red}
                                          style={{ color: iconColors.warningIconColor }} />
                            }
                          />
                        </TooltipHost>
                      </div>
                    })}
                </Stack>
            </span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else if (column && column.fieldName && (column.fieldName === 'vehicle')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => { }}>
                <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
                    {fieldContent.map((vehicle: any) => {
                      return <div key={Math.random()}>
                        <DragBox
                          // disabled={!getProject()}
                          type={AcceptType.VEHICLE}
                          onClick={(item: any) => {}}
                          item={{ ...vehicle}}
                          name={vehicle?.title}
                          icon={<FontIcon aria-label="Compass" iconName="DeliveryTruck" className={classNames.deepSkyBlue} />}
                        />
                      </div>
                    })}
                </Stack>
            </span> : <span style={{ paddingLeft: 9 }}>-</span>;
    }else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => { }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    }

    return <span style={{ paddingLeft: 9 }}>-</span>;
  };

  return <div style={{height:'100%'}}>
    <ShimmeredDetailsList
      items={resourceList ? [resourceList] : []}
      columns={columns}
      setKey="set"
      enableShimmer={!isResourceDataLoaded}
      layoutMode={DetailsListLayoutMode.justified}
      selectionPreservedOnEmptyClick={true}
      checkboxVisibility={0}
      selectionMode={SelectionMode.none}
      ariaLabelForSelectionColumn="Toggle selection"
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      checkButtonAriaLabel="Row checkbox"
      onRenderDetailsHeader={(headerProps, defaultRender) => {
        if (defaultRender !== undefined) {
          return (
            <Sticky
              stickyPosition={StickyPositionType.Header}
              isScrollSynced={true}
              stickyBackgroundColor="transparent"
            >
              <div>{defaultRender(headerProps)}</div>
            </Sticky>
          );
        } else {
          return <span></span>;
        }
      }}
      onRenderItemColumn={(
        item: any,
        index?: number,
        column?: IColumn,
      ) =>
        resourceListColumn(item, index, column)
      }
    />
  </div>
};

export default WorkerList
