import { pdf } from '@react-pdf/renderer';
import PdfTemplate from './pdfTemplate';

export const GeneratePdf = async (signature:any,fullName:any) => {
  try {
    console.log({signature});
    const blobPdf = await pdf(PdfTemplate(signature,fullName)).toBlob();
    return blobPdf;
  } catch (err) {
    console.log(err);
  }
};

