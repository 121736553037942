import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import history from '../../../history';
import { FilterItem, ListPaneItem, PBS_TYPE, ProjectBreakdown } from '../../../types/projectBreakdownStructure';
import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getById, getRiskDropDownsList } from '../../../reducers/projectBreakdownReducer';
import { messageService } from '../../../services/messageService';
import { PMOLDropDown, PMOLListPaneFilter } from '../../../types/projectMolecule';
import { getContractingUnit, getProject } from '../../../shared/util';
import { iconColors } from '../../../../theme';
import { Icon } from '@fluentui/react';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: PMOLListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: ProjectBreakdown;
  selectedListItems: ListPaneItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: ListPaneItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: PMOLListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: FilterItem;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: ProjectBreakdown;
  getById: (id: string) => void;
  projectId: string | null;
  pmolDropDowns: PMOLDropDown;
  reSizer: number;
}

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new PMOLListPaneFilter(),
      formData: {
        id: null,
        title: null,
        headerTitle: null,
        contract: null,
        name: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        productComposition: null,
        productDerivation: null,
        productFormatPresentation: null,
        productId: null,
        projectId: null,
        productPurpose: null,
        scope: null,
        historyLog: null,
        locationTaxonomyParentId: null,
        utilityTaxonomyParentId: null,
        machineTaxonomyParentId: null,
        pbsQualityResponsibilityId: null,
        qualityProducer: null,
        qualityProducerId: null,
        qualityReviewer: null,
        qualityReviewerId: null,
        qualityApprover: null,
        qualityApproverId: null,
        isSaved: false,
        pbsType: PBS_TYPE.REGULAR,
        startDate: null,
        endDate: null,
        parentId: null,
      },
    };

    this._columns = [

        // PMOL JOB NOT DONE WARNING SIGN
      // {
      //   key: 'column0',
      //   name: '',
      //   fieldName: 'isJobDone',
      //   minWidth: 50,
      //   maxWidth: 20,
      //   isResizable: true,
      //   onRender: (item: any) => {
      //     if (item?.isJobNotDone) {
      //       return <span><Icon style={{
      //         fontSize: 16,
      //         color: iconColors.warningIconColor,
      //       }} iconName="WarningSolid"/> </span>;
      //     } else {
      //       return <span></span>;
      //     }
      //   },
      // },

      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('type'),
        fieldName: 'type',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('foreman'),
        fieldName: 'foreman',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('executiondate'),
        fieldName: 'executionDate',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];

    this._columnsReSizer = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
      },
    ];
  }

  static getDerivedStateFromProps(nextProps: any) {
    if (nextProps.isChange) {
      nextProps.reloadListPaneItem();
    }
    return {
      loading: false,
      filter: nextProps.filter,
      formData: nextProps.formData,
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPbs) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }

    // if (prevProps.isChange != this.props.isChange && this.props.isChange) {
    //   this.props.reloadListPaneItem();
    // }
  }

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    //history.push(`/project-molecule/${id}`);
    //console.log("handleListPaneItemClick", Date.now())
    //console.time()
    history.push(`/CU/${getContractingUnit()}/project/${getProject()}/project-molecule/${id}`);
    //messageService.sendMessage({showDocumentPane: true})
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        //history.push(`/project-molecule/${lastId}`);
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/project-molecule/${lastId}`);
      }
    }
  };

  handleFilterChange = (filter: PMOLListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.productId &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(
        this.state.formData.productId,
        true,
        false,
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        {/*{JSON.stringify(this.state.selectedListItems)}*/}
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.props.listPaneItems : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={(this.props.reSizer === 300) ? this._columnsReSizer : this._columns}
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          resourceTypes={this.props.resourceTypes}
          handleFilterChange={(filter: PMOLListPaneFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.pmolDropDowns}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as ListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as ListPaneItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.projectMoleculeId : null;
      });
    }
    return selectedList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as ListPaneItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.pmol.isChange,
    pmolDropDowns: state.pmol.pmolDropDowns,
  };
};

const mapDispatchToProps = {
  getById,
  getRiskDropDownsList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane)),
);
