import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { PickListItem } from '../../../../types/pickListItem';
import { useTranslation } from 'react-i18next';
import TaxonomyField from '../../certificationTaxonomy/taxonomy';
import { CertificationDropDowns, CertificationTaxonomy } from '../../../../types/certification';
// import TaxonomyField from '../../../certification/taxonomy/taxonomy';
import { formatDateTimeToISOString, getProject } from '../../../../shared/util';
import { Field, Form } from 'react-final-form';
import DatePickerRequiredAdapter from '../../../../shared/fluentFinalForm/DatePickerRequiredAdapter';
import { TextFieldFF } from '../../../../shared/fluentFinalForm';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { uploadInstructionDoc } from '../../../../reducers/contractReducer';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '24px 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    flexBasis: '50%',
    height: 50,
    padding: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 0,
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

export type CertificationModalDTO = {
  id: string | null;
  personId: string | undefined;
  startDate: string | null;
  endDate: string | null;
  certificationTaxonomyId: string | null;
};

const CertificationModal = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openCertificationModal: () => void;
  options?: PickListItem[];
  allOptions?: PickListItem[];
  onAddContact?: (certificate: any, saveAndNew: boolean) => void;
  title: string;
  valueLable: string;
  organisationSelectionDetails: CertificationModalDTO[];
  isChecking: boolean;
  isUniq: number;
  isQniqContact: any;
  dropDowns: CertificationDropDowns;
  certificationTaxonomyList: CertificationTaxonomy[];
  certificationTaxonomyId: string | null;
  setCertificationTaxonomyId: (certificationTaxonomyId: string | null) => void;
  createCabCertification: (certificate: any) => void;
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<CertificationModalDTO>({
    id: '',
    personId: '',
    startDate: '',
    endDate: '',
    certificationTaxonomyId: '',
  });
  const inputFile: any = useRef(null);
  const dispatch = useDispatch();

  const getInitialFormValues = (formData: any) => {
    if (formData && formData?.id) {
      return {
        id: formData.id,
        personId: formData.personId,
        startDate: formData.startDate ? new Date(formData.startDate) : null,
        endDate: formData.endDate ? new Date(formData.endDate) : null,
        certificationTaxonomyId: formData.certificationTaxonomyId,
        certificationTitle: formData?.certificationTitle,
        certificationUrl: formData?.certificationUrl,
      };
    }
    return null;
  };

  const onSubmit = async (values: any) => {
    values.projectSequenceCode = getProject();
    props.createCabCertification(values);
    props.openCertificationModal();
    setIsValid(false);
    // if (!values.id) {
    //     values.id = props.uid;
    // }
    //props.saveFormData(values);
  };

  useEffect(() => {
    if (props.isEdit && !props.organisationSelectionDetails[0]) {
      setIsValid(false);
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (props.isOpen && !props.organisationSelectionDetails[0]) {
      setIsValid(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setCertificate(props.organisationSelectionDetails[0]);
    setIsValid(true);
  }, [props.organisationSelectionDetails]);

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openCertificationModal();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {props.isEdit
              ? t('edit') + ' ' + props.title
              : t('add') + ' ' + props.title}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openCertificationModal();
              if (!props.organisationSelectionDetails[0]) {
                setIsValid(false);
              }
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-block">
            <Form
              onSubmit={onSubmit}
              initialValues={getInitialFormValues(certificate)}
              mutators={{
                setCertificationUrl: (args, state, utils) => {
                  const field = state.fields.certificationUrl;
                  field.change(args[0]);
                },
              }}
              validate={values => {
                const errors: any = {};
                if (!values?.startDate) {
                  errors.startDate = t('startDateRequired');
                }
                if (!values?.endDate) {
                  errors.endDate = t('endDateRequired');
                }
                return errors;
              }}
              render={({
                         handleSubmit,
                         form,
                         submitting,
                         pristine,
                         errors,
                         values,
                       }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={{
                        childrenGap: 5,
                        padding: 0,
                      }}
                    >
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="certificationTaxonomyId">
                          {({ input, meta }) => (
                            <TaxonomyField
                              label={t('qualificationType')}
                              treeData={props.certificationTaxonomyList}
                              selectItemId={
                                values && values.certificationTaxonomyId
                                  ? values.certificationTaxonomyId
                                  : null
                              }
                              onSelectItem={(id: string) => {
                                if (id) {
                                  if (props.organisationSelectionDetails[0] && id === props.organisationSelectionDetails[0].certificationTaxonomyId) {
                                    input.onChange(null);
                                    setIsValid(false);
                                    setCertificate({
                                      ...certificate,
                                      certificationTaxonomyId: null,
                                    });
                                  } else {
                                    input.onChange(id);
                                    setIsValid(true);
                                    setCertificate({
                                      ...certificate,
                                      certificationTaxonomyId: id,
                                    });
                                  }
                                } else {
                                  input.onChange(null);
                                  setCertificate({
                                    ...certificate,
                                    certificationTaxonomyId: null,
                                  });
                                }
                              }}
                              treeLevel={
                                props.dropDowns.certificationTaxonomyLevel
                              }
                              onFilterTaxonomyNodeChanged={() => {
                              }}
                              // disabled={ false }
                            />
                          )}
                        </Field>
                        {!isValid &&
                        <Label style={{ marginTop: '-18px', color: '#a4262c', fontSize: '12px' }}>
                          {t('qualificationTypeCannotBeEmpty')}
                        </Label>
                        }
                      </div>
                    </Stack>

                    <div style={{ marginTop: '28px' }}>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.halfWidth}`}>
                            <Field
                              name="certificationTitle"
                              component={TextFieldFF}
                              lable={t('certificationTitle')}
                              disabled={false}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>

                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <div style={{ marginTop: '14px', display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
                              <Field
                                name="certificationUrl"
                                component={TextFieldFF}
                                lable={t('certificationUrl')}
                                disabled={false}
                                placeholder={t('certificationUrl')}
                                required={false}
                                readOnly={true}
                                className={`${classNames.fullWidth}`}
                              />
                              <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                hidden
                                onChange={(event: any) => {
                                  const formData = new FormData();
                                  if (event?.target?.files[0]) {
                                    formData.append('file', event.target.files[0]);
                                    formData.append('id', uuidv4());
                                    Promise.all([
                                      dispatch(uploadInstructionDoc(formData))
                                    ]).then((response: any) => {
                                      if (response[0]?.payload?.data?.result) {
                                        form.mutators.setCertificationUrl(response[0]?.payload?.data?.result);
                                      } else {
                                        form.mutators.setCertificationUrl(null);
                                      }
                                    });
                                  }
                                }}
                              />

                              <PrimaryButton
                                iconProps={uploadIconWhite}
                                text={t('upload')}
                                disabled={false}
                                style={{ marginTop: 10, marginLeft: 10 }}
                                onClick={() => {
                                  inputFile?.current?.click();
                                }}
                              />
                            </div>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    <div style={{ marginTop: '40px' }}>
                      <Label>{t('validityPeriod')}</Label>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="startDate"
                              component={DatePickerRequiredAdapter}
                              lable={t('startDate')}
                              parse={(value) =>
                                value && formatDateTimeToISOString(value)
                              }
                              format={(value) => (value ? new Date(value) : null)}
                              errorMessage={
                                form.getState()?.errors?.startDate
                              }
                            />
                          </div>
                        </Stack.Item>

                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="endDate"
                              component={DatePickerRequiredAdapter}
                              lable={t('endDate')}
                              parse={(value) =>
                                value && formatDateTimeToISOString(value)
                              }
                              format={(value) => (value ? new Date(value) : null)}
                              minDate={new Date(form.getFieldState('startDate')?.value)}
                              errorMessage={
                                form.getState()?.errors?.endDate
                              }
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    <div className={'card-footer'} style={{ marginTop: '24px' }}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              if (isValid) {
                                form.submit();
                              }
                              errors == null ? form.reset() : undefined;
                              setIsValid(false);
                            }}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                    {/* <pre>{JSON.stringify(errors, 0, 2)}</pre> */}
                  </form>
                );
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CertificationModal;
