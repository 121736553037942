import * as React from 'react';
import {
  CommandBarButton,
  ContextualMenuItemType,
  Dropdown,
  FontIcon,
  IButtonStyles,
  IIconProps,
  IStackStyles,
  mergeStyles,
  Stack,
  Text,
} from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';
import AuthService from '../../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { uPrinceTheme } from '../../../theme';
import history from './../../history';
import { hasPermissions } from '../util';
import { requestAccessAllProjects, saveProjectStateAttr } from '../../reducers/projectReducer';
import SuccessDialog from '../brand/dialog';
import { saveStateAttr } from '../../reducers/uprinceReducer';
import ENV from '../../../env.json';

initializeIcons();

export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

const authService = new AuthService();
const addIcon: IIconProps = { iconName: 'Contact' };
const mailIcon: IIconProps = { iconName: 'Mail' };
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    minWidth: 0,
    padding: '0 4px',
    alignSelf: 'stretch',
    height: 40,
    width: 40,
    fontSize: 40,
    background: uPrinceTheme.palette.themePrimary,
  },
  menuIcon: {
    fontSize: 25,
    color: '#ffffff',
  },
};

const iconClass = mergeStyles({
  fontSize: 16,
  height: 20,
  width: 20,
  margin: '5px 2px 2px 5px',
});

const iconEmail = mergeStyles({
  fontSize: 14,
  height: 20,
  width: 20,
  margin: '8px 2px 2px 5px',
});

const iconid = mergeStyles({
  fontSize: 14,
  height: 20,
  width: 20,
  margin: '8px 2px 2px 5px',
});

const getMenuProps = (name: string, email: string, oid: string, t: TFunction, currentUser: any) => {
  const dispatch = useDispatch();

  const menuItems: any = {
    items: [
      {
        key: 'name',
        text: name,
        onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FontIcon iconName="Contact" className={iconClass}/>
                <Text style={{ marginTop: 5 }}>{name}</Text>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FontIcon iconName="Mail" className={iconEmail}/>
                <Text style={{ marginTop: 5 }}>{email}</Text>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FontIcon iconName="World" className={iconEmail}/>
                <Text style={{ marginTop: 2 }}>{oid}</Text>
              </div>

            </div>
          );
        },
        onClick: () => {
        },
      },
      {
        key: 'language',
        text: name,
        onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>

              <Dropdown

                selectedKey={localStorage.getItem('lang') ? localStorage.getItem('lang') : -1}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(event, value: any) => {
                  localStorage.setItem('lang', value?.key);
                  window.location.reload();
                }}
                placeholder={t('selectLanguage')}
                options={[{ key: 'en', text: 'English' }, { key: 'nl', text: 'Dutch' }]}
              />

            </div>
          );
        },
        onClick: () => {
        },
      },
      {
        key: 'divider_1',
        itemType: ContextualMenuItemType.Divider,
      },
      {
        key: 'logout',
        text: t('Logout'),
        iconProps: { iconName: 'Signout' },
        onClick: () => {
          dispatch(saveStateAttr("currentUser",null));
          authService.logout();
        },
      },
    ],
  };

  if (hasPermissions(currentUser?.groups).isProjectManager && ENV.MY_ENV_ENABLED) {
    menuItems.items.splice(2, 0, {
      key: 'me',
      text: t('myEnv'),
      iconProps: { iconName: 'OpenInNewWindow' },
      onClick: () => {
        history.push('/me/project-planning');
      },
    });
    menuItems.items.splice(3, 0, {
      key: 'divider_2',
      itemType: ContextualMenuItemType.Divider,
    });
  }

  // Access only for kurt/ kurt oid
  if(currentUser.user.oId === '3259340e-47c5-4363-b891-8e773f430be6'){
    menuItems.items.splice(4, 0, {
      key: 'request',
      text: t('requestAccess'),
      iconProps: { iconName: 'Signout' },
      onClick: () => {
        dispatch(requestAccessAllProjects())
      },
    },);
  }

  return menuItems;
};

export const ProfileDropdown: React.FunctionComponent<IButtonExampleProps> = (
  props,
) => {
  const { disabled, checked } = props;
  const [modalOpen, setSuucessModal] = React.useState(false);
  const currentUser = useSelector<any, any>(
    (state) => state.uprince.currentUser,
  );
  const requestAllProjectAccessSuccess = useSelector(
    (state: any) => state.project.requestAllProjectAccessSuccess,
  );


  React.useEffect(() => {
    setSuucessModal(requestAllProjectAccessSuccess)
  },[requestAllProjectAccessSuccess])



  let name = '';
  let email = '';
  let oid = '';
  if (currentUser && currentUser.user) {
    name = currentUser.user.firstName + ' ' + currentUser.user.lastName;
    email = currentUser.user.email;
    oid = currentUser.user.oId;
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Here we use a Stack to simulate a command bar.
  // The real CommandBar control also uses CommandBarButtons internally.
  return (
    <Stack horizontal styles={stackStyles}>

      <SuccessDialog show={!modalOpen} onClickConfirmDialog={()=>{
        dispatch(saveProjectStateAttr('requestAllProjectAccessSuccess', false))
      }}/>

      <CommandBarButton
        ariaLabel="More items"
        role="menuitem"
        styles={buttonStyles}
        menuIconProps={addIcon}
        menuProps={getMenuProps(name, email, oid, t, currentUser)}
      />
    </Stack>
  );
};
