export enum ViewMode {
  QuarterDay = 'Quarter Day',
  HalfDay = 'Half Day',
  Day = 'Day',
  /** ISO-8601 week */
  Week = 'Week',
  Month = 'Month',
  QuaterYear = 'QuaterYear'
}

export type TaskType = 'task' | 'milestone' | 'project' | 'parentProduct';

export interface Task {
  id: string;
  type: TaskType;
  name: string;
  start: Date;
  end: Date;

  /**
   * From 0 to 100
   */
  progress: number;
  styles?: {
    backgroundColor?: string;
    backgroundSelectedColor?: string;
    progressColor?: string;
    progressSelectedColor?: string;
  };
  isDisabled?: boolean;
  project?: string;
  dependencies?: string[];
  hideChildren?: boolean;
  parentId?: any;
  title?: string;
  poId?: string;
  teams?: any;
  toolsPool?: any;
  isHidden?: boolean;
  requiredQuantity?: any;
  filedQuantity?: any;
  resourceType?: any;
  borSequenceId?: any;
  pbsSequenceId?: any;
  pmolSequenceId?: any;
  milestoneSequanceId?: any;
  isSelected?:boolean
  deliveryDate?: any;
}

export interface EventOption {
  /**
   * Time step value for date changes.
   */
  timeStep?: number;
  /**
   * Invokes on bar select on unselect.
   */
  onSelect?: (task: Task, isSelected: boolean) => void;
  /**
   * Invokes on bar double click.
   */
  onDoubleClick?: (task: Task) => void;

  onRightClick?: (task: Task) => void;
  /**
   * Invokes on end and start time change. Chart undoes operation if method return false or error.
   */
  onDateChange?: (
    task: Task,
    children: Task[],
  ) => void | boolean | Promise<void> | Promise<boolean>;
  /**
   * Invokes on progress change. Chart undoes operation if method return false or error.
   */
  onProgressChange?: (
    task: Task,
    children: Task[],
  ) => void | boolean | Promise<void> | Promise<boolean>;
  /**
   * Invokes on delete selected task. Chart undoes operation if method return false or error.
   */
  onDelete?: (task: Task) => void | boolean | Promise<void> | Promise<boolean>;
  /**
   * Invokes on expander on task list
   */
  onExpanderClick?: (task: Task) => void;

  onVisibilityChanged?: (data: any) => void;
}

export interface DisplayOption {
  viewMode?: ViewMode;
  /**
   * Specifies the month name language. Able formats: ISO 639-2, Java Locale
   */
  locale?: string;
  rtl?: boolean;
}

export interface StylingOption {
  headerHeight?: number;
  columnWidth?: number;
  listCellWidth?: string;
  rowHeight?: number;
  ganttHeight?: number;
  barCornerRadius?: number;
  handleWidth?: number;
  fontFamily?: string;
  fontSize?: string;
  /**
   * How many of row width can be taken by task.
   * From 0 to 100
   */
  barFill?: number;
  barProgressColor?: string;
  barProgressSelectedColor?: string;
  barBackgroundColor?: string;
  barBackgroundSelectedColor?: string;
  projectProgressColor?: string;
  projectProgressSelectedColor?: string;
  projectBackgroundColor?: string;
  projectBackgroundSelectedColor?: string;
  milestoneBackgroundColor?: string;
  milestoneBackgroundSelectedColor?: string;
  arrowColor?: string;
  arrowIndent?: number;
  todayColor?: string;
  TooltipContent?: React.FC<{
    task: Task;
    fontSize: string;
    fontFamily: string;
  }>;
  TaskListHeader?: React.FC<{
    headerHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
  }>;
  TaskListTable?: React.FC<{
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: Task[];
    selectedTaskId: string;
    /**
     * Sets selected task by id
     */
    setSelectedTask: (taskId: string) => void;
    onExpanderClick: (task: Task) => void;
  }>;
}

export type VisibilityProps = { treeData: any[], node: any, expanded: boolean, path: any[] }

export interface GanttProps extends EventOption, DisplayOption, StylingOption {
  tasks: Task[];
  listTasks: Task[];
  isInitialCollaps: boolean;
  chartType: ChartType;
  searchQuery: string;
  searchMethod: ({ node, searchQuery }: any) => void
  searchFocusOffset: number;
  searchFinishCallback: ((matches: any) => void)
  onlyExpandSearchedNodes: boolean
  onTreeDataChange: ((treeData: any[]) => void);
  canDrag: (dropProps: { node: any, path: number[] | string[], treeIndex: number, lowerSiblingCounts: number[], isSearchMatch: boolean, isSearchFocus: boolean }) => boolean;
  canDrop: (dropProp: { node: any, prevPath: number[] | string[], prevParent: any, prevTreeIndex: number, nextPath: number[] | string[], nextParent: any, nextTreeIndex: number }) => boolean;
  onMoveNode: (treeData:any, node:any, nextParentNode:any, prevPath:any, prevTreeIndex:any, nextPath:any, nextTreeIndex:any, nodeTwo:any) => void
  onVisibilityToggle: (data:VisibilityProps) => void
}

export enum ChartType {
  PBS = 'PBS',
  PBSTAXONOMY = 'PBSTAXONOMY',
  POTAXONOMY = 'POTAXONOMY',
  PMOL = 'PMOL',
  PROJECT = 'PROJECT',
  MS = 'MS',
  RESOURCE = 'RESOURCE',
  PO = 'PO',
  PR = 'PR',
}
