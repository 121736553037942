import React, { useEffect } from 'react';
import {
 connect, useDispatch, useSelector
} from 'react-redux';
import {
 getTheme, Icon, Label, Link
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import { uPrinceTheme } from '../../../../theme';
import HistoryBar from '../../../shared/historyBar/historyBar';
import DataGrid from '../../../shared/dataGrid/component';
import {
  Competence,
  Instruction,
  PBS_TYPE,
  PbsInstructionLink,
  PBSService,
  ProjectBreakdown,
  ProjectBreakdownValidationMessage,
  Quality,
  ReadRisk,
  Resource
} from '../../../types/projectBreakdownStructure';
import ProductHeader from '../product/productHeader';
import QualityGrid from '../quality/component';
import RiskGrid from '../risk/component';
import InstructionDataGrid from '../instructions/dataGrid/component';
import QualityResponsible from '../quality/qualityResponsible';
import CompetencyGrid from '../competency/component';
import ProductDetail from '../product/productDetail';
import { random } from 'lodash-es';
import Service from '../service/componant';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import { RESOURCE_TYPE } from '../../../types/purchaseOrder';
import DynamicAttributes from '../dynamicAttributes/dynamicAttributes';
import CbcGrid from '../cbc/CbcGrid';
import Deliverables from '../deliverables/Deliverables';
import { LocationAddress, Position } from '../../../types/projectDefinition';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

export const DocumentPaneComponent = (props: {
  submitCpc:any;
  onChangeResourceFamily:any;
  selectedResourceFamily:any;
  cpcPressureClass:any
  cpcBasicUnitOfMeasure:any;
  cpcMaterial:any;
  dropDownOptions: any;
  isCollapsed: boolean;
  cpcBrand:any;
  resourceTypes:any;
  onFilterPersonChanged:any;
  savePicturesOfTheInstallation:any
  cpcUnitOfSizeMeasure:any
  languageCodes:any
  resourceFamily:any
  isEdit: boolean;
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (projectBreakdown: ProjectBreakdown, save: boolean) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  handleRiskDelete: () => void;
  qualitySelection: any;
  // qualitySelectionDetails: {};
  qualityActionButtonState: ActionButtonState;
  openRiskModal: () => void;
  riskSelection: any;
  riskActionButtonState: ActionButtonState;
  resetSelection: () => void;
  riskList: ReadRisk[];
  openQualityModal: () => void;
  qualityList: Quality[];
  handleQualityDelete: () => void;
  materials: Resource[];
  openMaterialModal: () => void;
  materialActionButtonState: ActionButtonState;
  materialSelection: any;
  tools: Resource[];
  toolsActionButtonState: ActionButtonState;
  openToolsModal: () => void;
  toolSelection: any;
  handleMaterialDelete: () => void;
  handleToolsDelete: () => void;
  materialLoading: boolean;
  toolsLoading: boolean;
  labours: Resource[];
  labourActionButtonState: ActionButtonState;
  openLabourModal: () => void;
  labourSelection: any;
  labourLoading: boolean;
  handleLabourDelete: () => void;
  openConsumableModal: () => void;
  consumableSelection: any;
  consumableActionButtonState: ActionButtonState;
  consumables: Resource[];
  consumalbeLoading: boolean;
  handleConsumableDelete: () => void;
  openTechInstuctionModal: () => void;
  techInstructionsLoading: boolean;
  techInstructions: Instruction[];
  techInstructionSelection: any;
  techInstructionActionButtonState: ActionButtonState;
  onQualityFilterPersonChanged: any;
  competenceList: Competence[];
  competenceSelection: any;
  competenceActionButtonState: ActionButtonState;
  openCompetenceModal: () => void;
  handleCompetenceDelete: () => void;
  handleDTechInstructionDelete: () => void;
  openSaftyInstuctionModal: () => void;
  saftyInstructionsLoading: boolean;
  saftyInstructions: Instruction[];
  saftyInstructionSelection: any;
  saftyInstructionActionButtonState: ActionButtonState;
  handleSaftyInstructionDelete: () => void;
  //
  openEnvInstuctionModal: () => void;
  envInstructionsLoading: boolean;
  envInstructions: Instruction[];
  envInstructionSelection: any;
  envInstructionActionButtonState: ActionButtonState;
  handleEnvInstructionDelete: () => void;
  //
  openHelthInstuctionModal: () => void;
  helthInstructionsLoading: boolean;
  helthInstructions: Instruction[];
  helthInstructionSelection: any;
  helthInstructionActionButtonState: ActionButtonState;
  handleHelthInstructionDelete: () => void;
  onFilterTaxonomyNodeChanged: any;
  readOnly: boolean;
  pbsType: string;
  onChangeService: (prop: string, value: string) => void;
  service: PBSService,
  feild: string | null,
  hasBorCreatedForThisProduct: boolean
  openMapModal: () => void;
  setLocationAddress: (position: Position, address: LocationAddress) => void;
}) => {
  const classNames = mergeStyleSets({
    fullWidth: {
      width: '100%'
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: {
        '@media(max-width: 1100px)': {
          textAlign: 'start'
        }
      }
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer'
    },
    bigScreenTree: {
      marginBottom: 30,
      height: '30vh'
    },
    smallScreenTree: {
      marginBottom: 30,
      height: '56vh'
    }
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPBSLoaded: string = useSelector(
    (state: any) => state.projectBreakdown.pbsLoaded
  );

  useEffect(() => {
    props.resetSelection();
  }, [ props.projectBreakdown?.id ]);


  useEffect(() => {
    if (isPBSLoaded) {
      dispatch(saveVPStateAttr('selectedPBS', null));
    }
  }, [ isPBSLoaded ]);

  const riskColumns = [
    {
      key: 'column1',
      name: t('ID'),
      fieldName: 'sequenceCode',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column2',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: t('owner'),
      fieldName: 'owner',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column4',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column5',
      name: t('status'),
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const materialsColumns = [
    {
      key: 'column1',
      name: t('resourceNumber'),
      fieldName: 'cpcTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column2',
      name: t('family'),
      fieldName: 'resourceFamilyTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: t('quantity'),
      fieldName: 'quantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column4',
      name: t('consolidatedQuantity'),
      fieldName: 'consolidatedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column5',
      name: t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const InstructionColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true
    },
    {
      key: 'column2',
      name: t('family'),
      fieldName: 'pbsInstructionFamilyName',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true
    },
    {
      key: 'column3',
      name: t('links'),
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Instruction) => {
        const array: PbsInstructionLink[] = item && item?.pbsInstructionLink ? item.pbsInstructionLink : [];
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridGap: '2px',
              gridAutoRows: 'minMax(10px, auto)'
            }}
          >
            {array.map((link) => (

              <div key={(link.id) ? link.id : random()}>
                <Icon
                  style={{ cursor: 'pointer', color: uPrinceTheme.palette.themePrimary }}
                  iconName={link?.type ? link.type : undefined}
                  key={(link.id) ? link.id : random()}
                  onClick={(event) => {
                    event.preventDefault();
                    if(link?.link) {
                      window.open(link.link);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        );
      }
    }
  ];

  const qualtyColumns = [
    {
      key: 'column1',
      name: t('qualityCriteria'),
      fieldName: 'criteria',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        const cleanText = item.criteria ? item?.criteria.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      }
    },
    {
      key: 'column2',
      name: t('qualityTolerance'),
      fieldName: 'tolerance',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        const cleanText = item.tolerance ? item?.tolerance.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      }
    },
    {
      key: 'column3',
      name: t('qualityMethod'),
      fieldName: 'method',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        const cleanText = item.method ? item?.method.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      }
    },
    {
      key: 'column4',
      name: t('qualitySkillRequired'),
      fieldName: 'skills',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        const cleanText = item.skills ? item?.skills.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      }
    }
  ];

  const competencyColumns = [
    // {
    //   key: 'column1',
    //   name: "id",
    //   fieldName: 'id',
    //   minWidth: 100,
    //   maxWidth: 200,
    //   isResizable: true,
    // },
    {
      key: 'column2',
      name: t('skill'),
      fieldName: 'skill',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: t('experience'),
      fieldName: 'experience',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  return (
    <div style={{ width: '100%', height: '100%' }} id={'ProjectBreakdownForm'}>
      <div className="proj-detail-block" id="1">
        <ProductHeader
          dropDownOptions={props.dropDownOptions}
          projectBreakdown={props.projectBreakdown}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(
            projectBreakdown: ProjectBreakdown,
            save: boolean
          ) => props.handleFormChange(projectBreakdown, save)}
          validationMessages={props.validationMessages}
          onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
          isCollapsed={props.isCollapsed}
          hasBorCreatedForThisProduct={props.hasBorCreatedForThisProduct}
          openMapModal={() => props.openMapModal()}
          setLocationAddress={(position: Position, address: LocationAddress) => props.setLocationAddress(position, address)}
        />
      </div>

      <div className="proj-detail-block" id="2">
        <ProductDetail
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          projectBreakdown={props.projectBreakdown}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(
            projectBreakdown: ProjectBreakdown,
            save: boolean
          ) => props.handleFormChange(projectBreakdown, save)}
          validationMessages={props.validationMessages}
          readOnly={props.readOnly}
        />
      </div>

      <div className="proj-detail-block" id="3">
        <DynamicAttributes/>
      </div>
      <div className="proj-detail-block" id="4">
       <CbcGrid readOnly={props.readOnly}
                projectBreakdown={props.projectBreakdown}
                handleFormChange={(
                  projectBreakdown: ProjectBreakdown,
                  save: boolean
                ) => props.handleFormChange(projectBreakdown, save)}
                saveFormData={() => props.saveFormData()}/>
      </div>
      <div className="proj-detail-block" id="5">
        <CompetencyGrid
          dataList={props.competenceList}
          editDataGrid={() => props.openCompetenceModal()}
          openModel={() => {
            props.openCompetenceModal();
          }}
          selection={props.competenceSelection}
          actionButtonState={props.competenceActionButtonState}
          title={t('competencies')}
          deleteDataGrid={() => {
            props.handleCompetenceDelete();
          }}
          readOnly={props.readOnly}
          columns={competencyColumns}
        />
      </div>
      {props.pbsType === PBS_TYPE.REGULAR &&
      <div className="proj-detail-block" id="6">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <Link href="#" id="pbs-resources">
              <Label>6. {t('resources')} </Label>
            </Link>
          </div>
          <div className={'card-body'}>
            <div className="">
              <DataGrid
                dataList={props.materials}
                editDataGrid={() => props.openMaterialModal()}
                openModel={() => {
                  props.openMaterialModal();
                }}
                selection={props.materialSelection}
                actionButtonState={props.materialActionButtonState}
                title={'6.1 ' + t('material')}
                deleteDataGrid={() => {
                  props.handleMaterialDelete();
                }}
                readOnly={props.readOnly}
                columns={materialsColumns}
                isLoaded={props.materialLoading}
                type={RESOURCE_TYPE.MATERIALS}
                isClickable={true}
              />

              <DataGrid
                dataList={props.tools}
                editDataGrid={() => props.openToolsModal()}
                openModel={() => {
                  props.openToolsModal();
                }}
                selection={props.toolSelection}
                actionButtonState={props.toolsActionButtonState}
                title={'6.2 ' + t('tools')}
                deleteDataGrid={() => {
                  props.handleToolsDelete();
                }}
                readOnly={props.readOnly}
                columns={materialsColumns}
                isLoaded={props.toolsLoading}
                type={RESOURCE_TYPE.TOOLS}
                isClickable={true}
              />

              <DataGrid
                dataList={props.consumables}
                editDataGrid={() => props.openConsumableModal()}
                openModel={() => {
                  props.openConsumableModal();
                }}
                selection={props.consumableSelection}
                actionButtonState={props.consumableActionButtonState}
                title={'6.3 ' + t('consumables')}
                deleteDataGrid={() => {
                  props.handleConsumableDelete();
                }}
                readOnly={props.readOnly}
                columns={materialsColumns}
                isLoaded={props.consumalbeLoading}
                type={RESOURCE_TYPE.CONSUMABLE}
                isClickable={true}
              />

              <DataGrid
                dataList={props.labours}
                editDataGrid={() => props.openLabourModal()}
                openModel={() => {
                  props.openLabourModal();
                }}
                selection={props.labourSelection}
                actionButtonState={props.labourActionButtonState}
                title={'6.4 ' + t('labour')}
                deleteDataGrid={() => {
                  props.handleLabourDelete();
                }}
                readOnly={props.readOnly}
                columns={materialsColumns}
                isLoaded={props.labourLoading}
                type={RESOURCE_TYPE.LABOUR}
                isClickable={true}
              />
            </div>
          </div>
        </div>
      </div>
      }

      {props.pbsType === PBS_TYPE.SUB &&
      <Service
        service={props.service}
        projectBreakdown={props.projectBreakdown}
        onChangeService={(prop: string, val: any) => props.onChangeService(prop, val)}
        saveFormData={() => props.saveFormData()}
        cpcBasicUnitOfMeasure={props.cpcBasicUnitOfMeasure}
        validationMessages={props.validationMessages}
      />

      }

      <div className="proj-detail-block" id="7">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <Link href="#" id="pbs-instructions">
              <Label>7. {t('instructions')} </Label>
            </Link>
          </div>
          <div className={'card-body'}>
            <div className="">
              <InstructionDataGrid
                dataList={props.techInstructions}
                editDataGrid={() => props.openTechInstuctionModal()}
                openModel={() => {
                  props.openTechInstuctionModal();
                }}
                selection={props.techInstructionSelection}
                actionButtonState={props.techInstructionActionButtonState}
                title={7.1 + ' ' + t('technicalInstructions')}
                deleteDataGrid={() => {
                  props.handleDTechInstructionDelete();
                }}
                readOnly={props.readOnly}
                columns={InstructionColumns}
                isLoaded={props.techInstructionsLoading}
              />

              <InstructionDataGrid
                dataList={props.saftyInstructions}
                editDataGrid={() => props.openSaftyInstuctionModal()}
                openModel={() => {
                  props.openSaftyInstuctionModal();
                }}
                selection={props.saftyInstructionSelection}
                actionButtonState={props.saftyInstructionActionButtonState}
                title={7.2 + ' ' + t('saftyInstructions')}
                deleteDataGrid={() => {
                  props.handleSaftyInstructionDelete();
                }}
                readOnly={props.readOnly}
                columns={InstructionColumns}
                isLoaded={props.saftyInstructionsLoading}
              />

              <InstructionDataGrid
                dataList={props.envInstructions}
                editDataGrid={() => props.openEnvInstuctionModal()}
                openModel={() => {
                  props.openEnvInstuctionModal();
                }}
                selection={props.envInstructionSelection}
                actionButtonState={props.envInstructionActionButtonState}
                title={7.3 + ' ' + t('environmentalInstructions')}
                deleteDataGrid={() => {
                  props.handleEnvInstructionDelete();
                }}
                readOnly={props.readOnly}
                columns={InstructionColumns}
                isLoaded={props.envInstructionsLoading}
              />

              <InstructionDataGrid
                dataList={props.helthInstructions}
                editDataGrid={() => props.openHelthInstuctionModal()}
                openModel={() => {
                  props.openHelthInstuctionModal();
                }}
                selection={props.helthInstructionSelection}
                actionButtonState={props.helthInstructionActionButtonState}
                title={7.4 + ' ' + t('healthInstructions')}
                deleteDataGrid={() => {
                  props.handleHelthInstructionDelete();
                }}
                readOnly={props.readOnly}
                columns={InstructionColumns}
                isLoaded={props.helthInstructionsLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="proj-detail-block" id="8">
        <RiskGrid
          dataList={props.riskList ? props.riskList : []}
          editDataGrid={() => {
            props.openRiskModal();
          }}
          openModel={() => {
            props.openRiskModal();
          }}
          selection={props.riskSelection}
          actionButtonState={props.riskActionButtonState}
          title={t('risk')}
          deleteDataGrid={() => {
            props.handleRiskDelete();
          }}
          readOnly={props.readOnly}
          columns={riskColumns}
        />
      </div>

      <div className="proj-detail-block" id="9">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <Link href="#" id="pbs-quality">
              <Label>9. {t('quality')} </Label>
            </Link>
          </div>
          <div className={'card-body'}>
            <div className="">
              <QualityGrid
                dataList={props.qualityList}
                editDataGrid={() => props.openQualityModal()}
                openModel={() => {
                  props.openQualityModal();
                }}
                selection={props.qualitySelection}
                actionButtonState={props.qualityActionButtonState}
                title={t('quality')}
                deleteDataGrid={() => {
                  props.handleQualityDelete();
                }}
                readOnly={props.readOnly}
                columns={qualtyColumns}
              />

              <QualityResponsible
                onQualityFilterPersonChanged={
                  props.onQualityFilterPersonChanged
                }
                isEdit={props.isEdit}
                projectBreakdown={props.projectBreakdown}
                saveFormData={() => props.saveFormData()}
                handleFormChange={(
                  projectBreakdown: ProjectBreakdown,
                  save: boolean
                ) => props.handleFormChange(projectBreakdown, save)}
                validationMessages={props.validationMessages}
                readOnly={props.readOnly}
              />
            </div>
          </div>
        </div>
      </div>

      {props.isEdit &&  <Deliverables formData={props.projectBreakdown}/>}

      {props.isEdit &&
      props.projectBreakdown.historyLog &&
      props.projectBreakdown.historyLog.createdByUser && (
        <div className="proj-detail-block" id="10">
          <div className="document-pane-card">
            <div className="marginTop marginBottom">
              <Link href="#" id="history">
                <Label>11. {t('history')} </Label>
              </Link>

              <HistoryBar
                createdByUser={
                  props.projectBreakdown.historyLog.createdByUser
                    ? props.projectBreakdown.historyLog.createdByUser
                    : ''
                }
                updatedByUser={
                  props.projectBreakdown.historyLog.updatedByUser
                    ? props.projectBreakdown.historyLog.updatedByUser
                    : ''
                }
                createdDateTime={
                  props.projectBreakdown.historyLog.createdDateTime
                    ? props.projectBreakdown.historyLog.createdDateTime
                    : ''
                }
                updatedDateTime={
                  props.projectBreakdown.historyLog.updatedDateTime
                    ? props.projectBreakdown.historyLog.updatedDateTime
                    : ''
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentPaneComponent);
