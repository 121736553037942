import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { getContractingUnit, getProject } from '../../shared/util';
import history from './../../history';
import { messageService } from '../../services/messageService';


const CommandBarRight = ({ handelShowDocumentPane, handleActionBarSave, saveBtnDisabled, disableProjectDefinition }: any) => {
  const { t } = useTranslation();

  const projectRedirect = () => {
    messageService.sendMessage({
      isNewProject: true,
    });
    handelShowDocumentPane(true);
  };

  const saveProject = () => {
    handleActionBarSave(true);
  };

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('projectForm'),
  });

  const handleProjectDefinitionClick = () => {
    history.push(`/CU/${getContractingUnit()}/project/${getProject()}/project-molecule`);
  };

  const getItems = () => {
    return [
      {
        key: 'newItem',
        text: t('new'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Add' },
        onClick: projectRedirect,
      },
      {
        key: 'save',
        name: t('save'),
        cacheKey: 'Save',
        iconProps: { iconName: 'Save' },
        onClick: saveProject,
        disabled: saveBtnDisabled,
      },

      {
        key: 'Clone',
        disabled: true,
        name: t('clone'),
        iconProps: { iconName: 'Copy' },
      },
      {
        key: 'Edit',
        disabled: true,
        name: t('edit'),
        iconProps: { iconName: 'Edit' },
      },
      {
        key: 'Print',
        disabled: true,
        name: t('print'),
        iconProps: { iconName: 'Print' },
        onClick: handlePrint,
      },
      {
        key: 'ProjectDefinition',
        disabled: disableProjectDefinition,
        name: t('project'),
        iconProps: { iconName: 'ProjectLogo16' },
        onClick: handleProjectDefinitionClick,
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );

};
export default CommandBarRight;


