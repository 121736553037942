import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentPaneComponent from './component';
import {Selection} from '@fluentui/react/lib/DetailsList';
import {ActionButtonState} from '../../../types/corporateProductCatalogue';
import UprinceLogger from '../../../shared/Logger/logger';
import ContactModal from '../contacts/modal/component';
import Contact from '../../../types/contact';
import {PickListItem} from '../../../types/pickListItem';
import {AddressBookItem, Company, Person, PersonCompany} from '../../../types/addressBookItem';
import {IPersonaProps, ITag, mergeStyles, ValidationState} from '@fluentui/react';
import PersonModal from '../person/component';
import {messageService} from '../../../services/messageService';
import CompanyModal from '../company/component';
import {
  createCabCertification,
  createCabCompetencies,
  createCABEntry,
  createCompany,
  deleteCabCertification,
  deleteCabCompetencies,
  getAddressBookByID,
  getCountryList,
  isQniqContact,
  readCabCertification,
  readCabCompetencies,
  uploadCABImage,
} from './../../../reducers/addressBookReducer';
import client from '../../../api';
import _ from 'lodash';
import history from './../../../history';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import i18n from '../../../../i18n';
import {AddressBookShimmer} from './shimmer';
import {v4 as uuidv4} from 'uuid';
import {getAllProjectDropdowns} from '../../../reducers/projectReducer';
import CompetenceModal, {CompetenceModalDTO} from './modal/competenceModal';
import CertificationModal, {CertificationModalDTO} from './modal/certificationModal';
import Competence, {CompetenceTaxonomy} from '../../../types/competency';
import {readCertificationDropDownData, readCertificationTaxanomyList} from '../../../reducers/certificationReducer';
import {readCompetancyTaxonomyList, readCompetencyDropDownData} from '../../../reducers/competencyReducer';
import {CertificationTaxonomy} from '../../../types/certification';

i18n;

export enum ContactType {
  PROFESSTIONAL_INTERNET,
  PROFESSTIONAL_PHONE,
  PRIVATE_INTERNET,
  PRIVATE_PHONE,
  ORGANISATION_INTERNET,
  ORGANISATION_PHONE,
}

export enum UniqContactType {
  UNKNOWN,
  UNIQ,
  USED,
}

export interface State {
  isOpenProfessionalInternetContactModal: boolean;
  isOpenOrganisatinalInternetContactModal: boolean;
  isOpenOrganisatinalPhoneContactModal: boolean;
  isOpenProfessionalPhoneContactModal: boolean;
  isOpenInternetModel: boolean;
  isOpenPhoneModel: boolean;
  isOpenCompetenceModal: boolean;
  isOpenCertificationModal: boolean;
  editContact: Contact;
  editCompetence: any;
  editCertification: any;
  internetContactSelectionDetails: {} | string;
  phoneContactSelectionDetails: {} | string;
  competenceSelectionDetails: {} | string;
  certificationSelectionDetails: {} | string;
  professionalInternetContactSelectionDetails: {} | string;
  professionalPhoneContactSelectionDetails: {} | string;
  internetContactActionButtonState: ActionButtonState;
  organisationInternetContactActionButtonState: ActionButtonState;
  organisationPhoneContactActionButtonState: ActionButtonState;
  phoneContactActionButtonState: ActionButtonState;
  competenceActionButtonState: ActionButtonState;
  certificationActionButtonState: ActionButtonState;
  professionalInternetContactActionButtonState: ActionButtonState;
  professionalPhoneContactActionButtonState: ActionButtonState;
  addressBookItem: AddressBookItem;
  addressBookItemCopy: AddressBookItem;  //this state used for update first name without popup
  isOpenNameModal: boolean;
  personName: string;
  isOpenCompanyModal: boolean;
  companyName: string;
  countries: { key: string; text: string }[];
  company: Company;
  createCabStatus?: boolean;
  profileImageUrl?: string;
  isInternetContactEdit: boolean;
  isPhoneContactEdit: boolean;
  isCompetenceEdit: boolean;
  isCertificationEdit: boolean;
  isNew: boolean;
  organisationInternetContactSelectionDetails: {} | string;
  organisationPhoneContactSelectionDetails: {} | string;
  isNewCompanyCreate: boolean;
  isDeleteContact: boolean;
  cabValidationMessage: {
    firstNameErrorMsg: string;
    surNameErrorMsg: string;
    fullNameErrorMsg: string;
  };
  isEditCAB: boolean;
  cabId: string | null;
  isDirty: boolean;
  isSaveClick: boolean;
  isCheckingUniqContact: boolean;
  isUniqContact: number;
  isProfessionalContactEdit: boolean;
  isProfessionalPhoneContactEdit: boolean;
  isOrgInternetContactEdit: boolean;
  isOrgPhoneContactEdit: boolean;
  createCompanyStatus: boolean;
  cabType: string;
  uid: string | null;
  competenciesTaxonomyId: string | null;
  certificationTaxonomyId: string | null;
  showProgress: boolean;
}

interface Props {
  countries: { key: string; text: string }[] | [];
  companyId: string | null;
  getCountryList?: any;
  createCompany?: any;
  createCABEntry?: any;
  getAddressBookByID?: any;
  createCabStatus?: boolean;
  uploadImageStatus?: boolean;
  uploadCABImage?: any;
  profileImageUrl?: string;
  personId?: string;
  personCompanyId?: string;
  isCheckingUniqContact?: boolean;
  //selectedAddressBookItem: AddressBookItem;
  cabId: string | null;
  addressBookFromId: AddressBookItem;
  isCabItemLoading?: boolean;
  isQniqContact?: any;
  isUniqContact?: number;
  createCompanyStatus?: boolean;
  isUniqCompanyName?: any;
  getAllProjectDropdowns?: () => void;
  competencyTaxonomyList?: CompetenceTaxonomy[];
  createCabCompetencies?: any;
  createCabCertification?: any;
  readCabCompetencies?: any;
  readCabCertification?: any;
  deleteCabCompetencies?: any;
  deleteCabCertification?: any;
  competence?: any;
  certificate?: any;
  readCertificationTaxanomyList?: any;
  readCompetancyTaxonomyList?: any;
  certificationTaxonomyList?: CertificationTaxonomy[];
  readCertificationDropDownData?: any;
  readCompetencyDropDownData?: any;
  certDropDowns?: any;
  compDropDowns?: any;
}

const acceptedTypes: string[] = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
];

const contentStyle = mergeStyles({
  height: 'calc(100vh - 200px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

class AddressBookDocumentPane extends Component<Props, State> {
  private _competenceSelection: Selection;
  private _certificationSelection: Selection;
  private _internetContactSelection: Selection;
  private _phoneNumberSelection: Selection;
  private _professionalInternetSelection: Selection;
  private _professionalPhoneContactSelection: Selection;
  private _organisationInternetContactSelection: Selection;
  private _organisationPhoneContactSelection: Selection;
  private internetContactTypes: PickListItem[];
  private phoneContactTypes: PickListItem[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenInternetModel: false,
      isDeleteContact: false,
      isOpenPhoneModel: false,
      isOpenCompetenceModal: false,
      isOpenCertificationModal: false,
      isOpenNameModal: false,
      personName: '',
      isOpenCompanyModal: false,
      companyName: '',
      editContact: {
        type: '',
        value: '',
      },
      editCompetence: {
        type: '',
        value: '',
      },
      editCertification: {
        type: '',
        value: '',
      },
      countries: [],
      company: new Company(),
      internetContactSelectionDetails: {},
      organisationInternetContactSelectionDetails: {},
      professionalInternetContactSelectionDetails: {},
      professionalPhoneContactSelectionDetails: {},
      organisationPhoneContactSelectionDetails: {},
      phoneContactSelectionDetails: {},
      competenceSelectionDetails: {},
      certificationSelectionDetails: {},

      internetContactActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      phoneContactActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      competenceActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      certificationActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      professionalInternetContactActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      organisationInternetContactActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      organisationPhoneContactActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      professionalPhoneContactActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      addressBookItem: new AddressBookItem(
        new Person(),
        null,
        new PersonCompany(),
      ),
      addressBookItemCopy: new AddressBookItem(
        new Person(),
        null,
        new PersonCompany(),
      ),
      isOpenProfessionalInternetContactModal: false,
      isOpenProfessionalPhoneContactModal: false,
      isOpenOrganisatinalInternetContactModal: false,
      createCabStatus: false,
      profileImageUrl: '',
      isInternetContactEdit: false,
      isPhoneContactEdit: false,
      isCompetenceEdit: false,
      isCertificationEdit: false,
      isOpenOrganisatinalPhoneContactModal: false,
      isNew: false,
      isNewCompanyCreate: false,
      cabValidationMessage: {
        firstNameErrorMsg: '',
        surNameErrorMsg: '',
        fullNameErrorMsg: '',
      },
      isEditCAB: false,
      cabId: null,
      isDirty: false,
      isSaveClick: false,
      isCheckingUniqContact: false,
      isUniqContact: 0,
      isProfessionalContactEdit: false,
      isProfessionalPhoneContactEdit: false,
      isOrgInternetContactEdit: false,
      isOrgPhoneContactEdit: false,
      createCompanyStatus: false,
      cabType: '',
      uid: null,
      competenciesTaxonomyId: null,
      certificationTaxonomyId: null,
      showProgress: false,
    };

    this._internetContactSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_internetContactSelectiononSelectionChanged',
          this._getInternetContactSelectionDetails(),
        );
        this.setState(
          {
            internetContactSelectionDetails: this._getInternetContactSelectionDetails(),
            internetContactActionButtonState: this._getInternetContactActionButtonState(),
            editContact: this._internetContactEditDetails(),
          },
          () => {
          },
        );
      },
    });

    this._phoneNumberSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_phoneNumberSelection',
          this._getInternetContactSelectionDetails(),
        );
        this.setState(
          {
            phoneContactSelectionDetails: this._getPhoneContactSelectionDetails(),
            phoneContactActionButtonState: this._getPhoneContactActionButtonState(),
            editContact: this._phoneContactEditDetails(),
            //cpcNickNameDeleteItems: this._internetContactDeleteDetails(),
          },
          () => {
          },
        );
      },
    });

    this._competenceSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            competenceSelectionDetails: this._getCompetenceSelectionDetails(),
            competenceActionButtonState: this._getCompetenceActionButtonState(),
            editCompetence: this._competenceEditDetails(),
          },
          () => {
          },
        );
      },
    });

    this._certificationSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            certificationSelectionDetails: this._getCertificationSelectionDetails(),
            certificationActionButtonState: this._getCertificationActionButtonState(),
            editCertification: this._certificationEditDetails(),
          },
          () => {
          },
        );
      },
    });

    this._professionalInternetSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            professionalInternetContactSelectionDetails: this._getProfessionalInternetContactSelectionDetails(),
            professionalInternetContactActionButtonState: this._getProfessionalInternetContactActionButtonState(),
            editContact: this._professionalInternetContactEditDetails(),
            //cpcNickNameDeleteItems: this._internetContactDeleteDetails(),
          },
          () => {
          },
        );
      },
    });

    this._professionalPhoneContactSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_internetContactSelection',
          this._getProfessionalPhoneContactSelectionDetails(),
        );
        this.setState(
          {
            professionalPhoneContactSelectionDetails: this._getProfessionalPhoneContactSelectionDetails(),
            professionalPhoneContactActionButtonState: this._getProfessionalPhoneContactActionButtonState(),
            editContact: this._professionalPhoneContactEditDetails(),
            //cpcNickNameDeleteItems: this._internetContactDeleteDetails(),
          },
          () => {
          },
        );
      },
    });

    this._organisationInternetContactSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_internetContactSelection',
          this._getOrganisationalInternetContactSelectionDetails(),
        );
        this.setState(
          {
            organisationInternetContactSelectionDetails: this._getOrganisationalInternetContactSelectionDetails(),
            organisationInternetContactActionButtonState: this._getOrganisationalInternetContactActionButtonState(),
            editContact: this._organisationInternetContactEditDetails(),
            //cpcNickNameDeleteItems: this._internetContactDeleteDetails(),
          },
          () => {
          },
        );
      },
    });

    this._organisationPhoneContactSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_internetContactSelection',
          this._getOrganisationalPhoneContactSelectionDetails(),
        );
        this.setState(
          {
            organisationPhoneContactSelectionDetails: this._getOrganisationalPhoneContactSelectionDetails(),
            organisationPhoneContactActionButtonState: this._getOrganisationalPhoneContactActionButtonState(),
            editContact: this._organisationPhoneContactEditDetails(),
            //cpcNickNameDeleteItems: this._internetContactDeleteDetails(),
          },
          () => {
          },
        );
      },
    });

    this.internetContactTypes = [
      { key: i18n.t('skype'), text: i18n.t('skype') },
      { key: i18n.t('email'), text: i18n.t('email') },
      { key: i18n.t('whatsApp'), text: i18n.t('whatsApp') },
    ];

    this.phoneContactTypes = [
      { key: i18n.t('phone'), text: i18n.t('phone') },
      { key: i18n.t('mobile'), text: i18n.t('mobile') },
    ];
  }

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: {
      countries: any;
      addressBookItem: { companyId: any };
      createCabStatus: boolean;
      uploadImageStatus: boolean;
      profileImageUrl: string;
      personId: string;
      addressBookFromId: AddressBookItem;
      cabId: string;
      personCompanyId: string;
    },
  ) {
    return {
      countries: nextProps.countries,
      createCabStatus: nextProps.createCabStatus,
      personId: nextProps.personId,
      profileImageUrl: nextProps.profileImageUrl,
      cabId: nextProps.cabId,
      addressBookFromId: nextProps.addressBookFromId,
      personCompanyId: nextProps.personCompanyId,
    };
    // if (nextProps.countries !== prevState.countries) {
    //   return { countries: nextProps.countries };
    // } else if (nextProps.createCabStatus !== prevState.createCabStatus) {
    //   return { createCabStatus: nextProps.createCabStatus };
    // } else if (nextProps.personId !== prevState.personId) {
    //   return { personId: nextProps.personId };
    // } else if (nextProps.profileImageUrl !== prevState.profileImageUrl) {
    //   return { profileImageUrl: nextProps.profileImageUrl };
    // } else if (nextProps.cabId != prevState.cabId) {
    //   return { cabId: nextProps.cabId };
    // } else if (nextProps.addressBookFromId != prevState.addressBookFromId) {
    //   return { addressBooFromId: nextProps.addressBookFromId };
    // } else if (nextProps.personCompanyId != prevState.personCompanyId) {
    //   return { personCompanyId: nextProps.personCompanyId };
    // } else return null;
  }

  private _internetContactEditDetails() {
    const selectionCount = this._internetContactSelection.getSelectedCount();
    let internetContact = this.state.editContact;

    if (selectionCount == 1) {
      this.setState({
        isInternetContactEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._internetContactSelection.getSelection(),
      );
      let selectedContact = this._internetContactSelection.getSelection()[0] as Contact;
      internetContact = {
        type: selectedContact.type,
        value: selectedContact.value,
      };
    } else {
      this.setState({
        isInternetContactEdit: false,
      });
    }
    return internetContact;
  }

  private _professionalInternetContactEditDetails() {
    const selectionCount = this._professionalInternetSelection.getSelectedCount();
    let internetContact = this.state.editContact;

    if (selectionCount == 1) {
      this.setState({
        isProfessionalContactEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._professionalInternetSelection.getSelection(),
      );
      let selectedContact = this._professionalInternetSelection.getSelection()[0] as Contact;
      internetContact = {
        type: selectedContact.type,
        value: selectedContact.value,
      };
    } else {
      this.setState({
        isProfessionalContactEdit: false,
      });
    }
    return internetContact;
  }

  private _professionalPhoneContactEditDetails() {
    const selectionCount = this._professionalPhoneContactSelection.getSelectedCount();
    let internetContact = this.state.editContact;

    if (selectionCount == 1) {
      this.setState({
        isProfessionalPhoneContactEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._professionalPhoneContactSelection.getSelection(),
      );
      let selectedContact = this._professionalPhoneContactSelection.getSelection()[0] as Contact;
      internetContact = {
        type: selectedContact.type,
        value: selectedContact.value,
      };
    } else {
      this.setState({
        isProfessionalPhoneContactEdit: false,
      });
    }
    return internetContact;
  }

  private _organisationInternetContactEditDetails() {
    const selectionCount = this._organisationInternetContactSelection.getSelectedCount();
    let internetContact = this.state.editContact;

    if (selectionCount == 1) {
      this.setState({
        isOrgInternetContactEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._organisationInternetContactSelection.getSelection(),
      );
      let selectedContact = this._organisationInternetContactSelection.getSelection()[0] as Contact;
      internetContact = {
        type: selectedContact.type,
        value: selectedContact.value,
      };
    } else {
      this.setState({
        isOrgInternetContactEdit: false,
      });
    }
    return internetContact;
  }

  private _organisationPhoneContactEditDetails() {
    const selectionCount = this._organisationPhoneContactSelection.getSelectedCount();
    let internetContact = this.state.editContact;

    if (selectionCount == 1) {
      this.setState({
        isOrgPhoneContactEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._organisationPhoneContactSelection.getSelection(),
      );
      let selectedContact = this._organisationPhoneContactSelection.getSelection()[0] as Contact;
      internetContact = {
        type: selectedContact.type,
        value: selectedContact.value,
      };
    } else {
      this.setState({
        isOrgPhoneContactEdit: false,
      });
    }
    return internetContact;
  }

  private _phoneContactEditDetails() {
    const selectionCount = this._phoneNumberSelection.getSelectedCount();
    let phoneContact = this.state.editContact;

    if (selectionCount == 1) {
      this.setState({
        isPhoneContactEdit: true,
      });
      UprinceLogger.log(
        '_phoneNumberSelection',
        this._phoneNumberSelection.getSelection(),
      );
      let selectedContact = this._phoneNumberSelection.getSelection()[0] as Contact;
      phoneContact = {
        type: selectedContact.type,
        value: selectedContact.value,
      };
    } else {
      this.setState({
        isPhoneContactEdit: false,
      });
    }
    return phoneContact;
  }

  private _competenceEditDetails() {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let competence = this.state.editCompetence;

    if (selectionCount == 1) {
      this.setState({
        isCompetenceEdit: true,
      });
      let selectedCompetence = this._competenceSelection.getSelection()[0] as Competence;
      competence = { selectedCompetence };
    } else {
      this.setState({
        isCompetenceEdit: false,
      });
    }
    return competence;
  }

  private _certificationEditDetails() {
    const selectionCount = this._certificationSelection.getSelectedCount();
    let certification = this.state.editCertification;

    if (selectionCount == 1) {
      this.setState({
        isCertificationEdit: true,
      });
      let selectedCompetence = this._certificationSelection.getSelection()[0] as Competence;
      certification = {
        type: selectedCompetence.type,
        value: selectedCompetence.value,
      };
    } else {
      this.setState({
        isCertificationEdit: false,
      });
    }
    return certification;
  }

  private deleteOrganisationSettings = (type: string) => {
    if (type === 'competence') {
      const selectedList = this._competenceSelection
        .getSelection()
        .map((item: any) => {
          return item.id;
        });
      if (selectedList.length > 0) {
        this.props.deleteCabCompetencies(selectedList).then(() => {
          if (this.state.addressBookItem?.person?.id) {
            this.props.readCabCompetencies(this.state.addressBookItem?.person?.id);
          }
        });
      }
    }
    if (type === 'certification') {
      const selectedList = this._certificationSelection
        .getSelection()
        .map((item: any) => {
          return item.id;
        });
      if (selectedList.length > 0) {
        this.props.deleteCabCertification(selectedList).then(() => {
          if (this.state.addressBookItem?.person?.id) {
            this.props.readCabCertification(this.state.addressBookItem?.person?.id);
          }
        });
      }
    }
  };

  private deleteOrganisationContact = (type: string) => {
    if (this.state.addressBookItem.isSaved) {
      this.setState({ isDirty: true, isDeleteContact: true });
    } else {
      this.setState({ isDeleteContact: true });
    }
    let deleteContactList = null;
    if (type === 'internet') {
      deleteContactList = this._organisationInternetContactSelection.getSelection() as Contact[];
      this._organisationInternetContactSelection.setAllSelected(false);
    }
    if (type === 'phone') {
      deleteContactList = this._organisationPhoneContactSelection.getSelection() as Contact[];
      this._organisationPhoneContactSelection.setAllSelected(false);
    }

    //const { t } = this.props;
    if (deleteContactList) {
      deleteContactList.map((item: { type: string; value: string }) => {
        if (item.type === i18n.t('email')) {
          this.setDeleteOrganisationState('email', item.value);
        }
        if (item.type === i18n.t('skype')) {
          this.setDeleteOrganisationState('skype', item.value);
        }
        if (item.type === i18n.t('whatsApp')) {
          this.setDeleteOrganisationState('whatsApp', item.value);
        }
        if (item.type === i18n.t('phone')) {
          this.setDeleteOrganisationState('landPhone', item.value);
        }
        if (item.type === i18n.t('mobile')) {
          this.setDeleteOrganisationState('mobilePhone', item.value);
        }
      });
    }
  };

  setDeleteOrganisationState = (key: string, value: string) => {
    this.setState(
      (prevState) => ({
        //isOpenNameModal: false,
        addressBookItem: {
          ...prevState.addressBookItem,
          company: {
            ...prevState.addressBookItem.company,
            [key]: null,
          },
        },
      }),
      () => {
        //this.saveCab(this.state.addressBookItem);
        this.setState({
          organisationInternetContactActionButtonState: this._getOrganisationalInternetContactActionButtonState(),
          organisationPhoneContactActionButtonState: this._getOrganisationalPhoneContactActionButtonState(),
        });
      },
    );
  };

  private deletePrivateContact = (type: string) => {
    if (this.state.addressBookItem.isSaved) {
      this.setState({ isDirty: true });
    }
    let deleteContactList = null;
    if (type === 'internet') {
      deleteContactList = this._internetContactSelection.getSelection() as Contact[];
      this._internetContactSelection.setAllSelected(false);
    }
    if (type === 'phone') {
      deleteContactList = this._phoneNumberSelection.getSelection() as Contact[];
      this._phoneNumberSelection.setAllSelected(false);
    }

    //const { t } = this.props;
    if (deleteContactList) {
      deleteContactList.map((item: { type: string; value: string }) => {
        if (item.type === i18n.t('email')) {
          this.setDeletePrivateContactState('email', item.value);
        }
        if (item.type === i18n.t('skype')) {
          this.setDeletePrivateContactState('skype', item.value);
        }
        if (item.type === i18n.t('whatsApp')) {
          this.setDeletePrivateContactState('whatsapp', item.value);
        }
        if (item.type === i18n.t('phone')) {
          this.setDeletePrivateContactState('landPhone', item.value);
        }
        if (item.type === i18n.t('mobile')) {
          this.setDeletePrivateContactState('mobilePhone', item.value);
        }
      });
    }
  };

  setDeletePrivateContactState = (key: string, value: string) => {
    this.setState(
      (prevState) => ({
        //isOpenNameModal: false,
        addressBookItem: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            [key]: null,
          },
        },
      }),
      () => {
        this.setState({
          internetContactActionButtonState: this._getInternetContactActionButtonState(),
          phoneContactActionButtonState: this._getPhoneContactActionButtonState(),
        });
        this.saveCab(this.state.addressBookItem);
      },
    );
  };

  private deleteProfessionalContact = (type: string) => {
    let deleteContactList = null;
    if (this.state.addressBookItem.isSaved) {
      this.setState({ isDirty: true });
    }
    if (type === 'internet') {
      deleteContactList = this._professionalInternetSelection.getSelection() as Contact[];
      this._professionalInternetSelection.setAllSelected(false);
    }
    if (type === 'phone') {
      deleteContactList = this._professionalPhoneContactSelection.getSelection() as Contact[];
      this._professionalPhoneContactSelection.setAllSelected(false);
    }

    //const { t } = this.props;
    if (deleteContactList) {
      deleteContactList.map((item: { type: string; value: string }) => {
        if (item.type === i18n.t('email')) {
          this.setDeleteProfessinalState('email', item.value);
        }
        if (item.type === i18n.t('skype')) {
          this.setDeleteProfessinalState('skype', item.value);
        }
        if (item.type === i18n.t('whatsApp')) {
          this.setDeleteProfessinalState('whatsapp', item.value);
        }
        if (item.type === i18n.t('phone')) {
          this.setDeleteProfessinalState('landPhone', item.value);
        }
        if (item.type === i18n.t('mobile')) {
          this.setDeleteProfessinalState('mobilePhone', item.value);
        }
      });
    }
  };

  setDeleteProfessinalState = (key: string, value: string) => {
    this.setState(
      (prevState) => ({
        //isOpenNameModal: false,
        addressBookItem: {
          ...prevState.addressBookItem,
          personCompany: {
            ...prevState.addressBookItem.personCompany,
            [key]: null,
          },
        },
      }),
      () => {
        this.setState({
          professionalInternetContactActionButtonState: this._getProfessionalInternetContactActionButtonState(),
          professionalPhoneContactActionButtonState: this._getProfessionalPhoneContactActionButtonState(),
        });
        this.saveCab(this.state.addressBookItem);
      },
    );
  };

  saveCab = (item: any) => {
    setTimeout(() => {
      if (this.validateCABEntry(false) && !this.state.addressBookItem.isSaved) {
        if (!this.state.addressBookItem.id) {
          this.state.addressBookItem.id = this.state.uid;
        }
        if (!this.state.isSaveClick)
          this.props.createCABEntry(this.state.addressBookItem);
      }
    }, 1000);
  };

  saveCabName = (item: any) => {
    setTimeout(() => {
      if (!this.state.addressBookItemCopy.isSaved) {
        if (!this.state.addressBookItemCopy.id) {
          this.state.addressBookItemCopy.id = this.state.uid;
        }
        if (!this.state.isSaveClick)
          this.props.createCABEntry(this.state.addressBookItemCopy);
      }
    }, 1000);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.countries !== this.props.countries) {
      this.setState({ countries: this.props.countries });
    }
    if (prevProps.countries !== this.props.countries) {
      this.setState({ countries: this.props.countries });
    }
    if (
      prevProps.createCabStatus !== this.props.createCabStatus &&
      this.props.createCabStatus
    ) {
      let title = '';
      if (
        this.state.addressBookItem &&
        this.state.addressBookItem.person &&
        this.state.addressBookItem.person.fullName &&
        !this.state.addressBookItem?.company?.name
      ) {
        title = this.state.addressBookItem.person.fullName;
      } else if (
        this.state.addressBookItem &&
        this.state.addressBookItem.person &&
        this.state.addressBookItem.person.fullName &&
        this.state.addressBookItem?.company?.name
      ) {
        let company = ' - ' + this.state.addressBookItem?.company?.name;
        title = this.state.addressBookItem.person.fullName + company;
      }

      if (!this.state.addressBookItem.isSaved) {
        messageService.sendMessage({ title: title });
      }

      if (this.state.isNew && this.state.addressBookItem.isSaved) {
        this.setState({
          isSaveClick: false,
          createCabStatus: this.props.createCabStatus,
          company: new Company(),
          addressBookItem: new AddressBookItem(
            new Person(),
            null,
            new PersonCompany(),
          ),
        }, () => {
          messageService.sendMessage({ title: '', isNewCAB: true });
        });
      }

      messageService.sendMessage({ isReloadCabList: true });
    }
    if (
      this.props.profileImageUrl &&
      prevProps.profileImageUrl !== this.props.profileImageUrl
    ) {
      this.setState(
        (prevState) => ({
          addressBookItem: {
            ...prevState.addressBookItem,
            person: {
              ...prevState.addressBookItem.person,
              image: this.props.profileImageUrl
                ? this.props.profileImageUrl
                : null,
            },
          },
        }),
        () => {
          if (
            this.state.addressBookItem &&
            this.state.addressBookItem.person &&
            this.state.addressBookItem.person.id
          ) {
            this.saveCab(this.state.addressBookItem);
          }
        },
      );
    }

    if (prevProps.companyId !== this.props.companyId) {
      this.setState(
        (prevState) => ({
          //isOpenCompanyModal: false,
          addressBookItem: {
            ...prevState.addressBookItem,
            companyId: this.props.companyId,
          },
          company: {
            ...prevState.company,
            id: this.props.companyId,
          },
        }),
        () => {
          if (
            this.state.addressBookItem &&
            this.state.addressBookItem.person &&
            this.state.addressBookItem.person.id
          ) {
            this.saveCab(this.state.addressBookItem);
          }
        },
      );
    }

    if (this.props.personId && prevProps.personId !== this.props.personId) {
      if (
        this.state.addressBookItem &&
        this.state.addressBookItem.person &&
        this.state.addressBookItem.person.firstName
      ) {
        this.setState(
          (prevState) => ({
            //isOpenCompanyModal: false,
            isOpenNameModal: false,
            addressBookItem: {
              ...prevState.addressBookItem,
              person: {
                ...prevState.addressBookItem.person,
                id: this.props.personId ? this.props.personId : null,
              },
            },
          }),
          () => {
          },
        );

        if(!this.state.isSaveClick && this.state.isNew){
          this.props.getAddressBookByID(this.props.personId);
          this.props.readCabCompetencies(this.props.personId);
          this.props.readCabCertification(this.props.personId);
        }
      }
    }

    if (
      this.props.personCompanyId &&
      prevProps.personCompanyId !== this.props.personCompanyId
    ) {
      this.setState((prevState) => ({
        //isOpenCompanyModal: false,
        addressBookItem: {
          ...prevState.addressBookItem,
          personCompany: {
            ...prevState.addressBookItem.personCompany,
            id: this.props.personCompanyId ? this.props.personCompanyId : null,
          },
        },
      }));
    }
    if (prevProps.addressBookFromId != this.props.addressBookFromId) {
      this.setState({ addressBookItem: this.props.addressBookFromId }, () => {
        if (this.state.addressBookItem && this.state.addressBookItem.person) {
          let title: string | null = '';
          if (
            this.state.addressBookItem &&
            this.state.addressBookItem.company &&
            this.state.addressBookItem.company.name
          ) {
            title =
              this.state.addressBookItem.person.fullName +
              ' - ' +
              this.state.addressBookItem.company.name;
          } else {
            title = this.state.addressBookItem.person.fullName;
          }
          messageService.sendMessage({ title: title });
          this.setState({ company: this.state.addressBookItem.company });
        }
        this.disableAddButtons();
      });
    }

    if (prevProps.cabId !== this.props.cabId) {
      if (this.props.cabId && this.props.cabId != 'new') {
        this.props.getAddressBookByID(this.props.cabId);
        this.props.readCabCompetencies(this.props.cabId);
        this.props.readCabCertification(this.props.cabId);
      }
    }

    if (prevProps.isCheckingUniqContact !== this.props.isCheckingUniqContact) {
      this.setState({
        isCheckingUniqContact: this.props.isCheckingUniqContact
          ? this.props.isCheckingUniqContact
          : false,
      });
    }

    if (prevProps.isUniqContact !== this.props.isUniqContact) {
      this.setState({
        isUniqContact:
          this.props.isUniqContact === undefined ? 0 : this.props.isUniqContact,
      });
    }

    if (prevProps.createCompanyStatus !== this.props.createCompanyStatus) {
      this.setState({
        createCompanyStatus: this.props.createCompanyStatus
          ? this.props.createCompanyStatus
          : false,
        isOpenCompanyModal: false,
      });
    }
  }

  private disableAddButtons = () => {
    this.disableAddButton(
      'organisationInternetContactActionButtonState',
      ContactType.ORGANISATION_INTERNET,
    );
    this.disableAddButton(
      'internetContactActionButtonState',
      ContactType.PRIVATE_INTERNET,
    );

    this.disableAddButton(
      'professionalInternetContactActionButtonState',
      ContactType.PROFESSTIONAL_INTERNET,
    );

    this.disableAddButton(
      'organisationPhoneContactActionButtonState',
      ContactType.ORGANISATION_PHONE,
    );

    this.disableAddButton(
      'professionalPhoneContactActionButtonState',
      ContactType.PROFESSTIONAL_PHONE,
    );

    this.disableAddButton(
      'phoneContactActionButtonState',
      ContactType.PRIVATE_PHONE,
    );
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.props.readCertificationDropDownData();
    this.props.readCompetencyDropDownData();
    this.props.readCertificationTaxanomyList();
    this.props.readCompetancyTaxonomyList();

    if (this.props.cabId) {
      this.props.readCabCompetencies(this.props.cabId);
      this.props.readCabCertification(this.props.cabId);
    }

    this.props.getCountryList();
    if (this.props.cabId) {
      if (this.props.cabId) {
        this.props.getAddressBookByID(this.props.cabId);
      }
    }
    if (this.props.addressBookFromId && !this.props.addressBookFromId.id) {
      this.setState({
        uid: uuidv4(),
      });
    }
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.isOpenNameModal) {
          // this.setState({
          //   isOpenNameModal: data.data.isOpenNameModal,
          //   personName: data.data.personName,
          // });

          let person:Person=new Person();
          person.firstName=data.data.personName;
          person.surname=data.data.personName;
          person.fullName=data.data.personName;
          this.onAddPersonWithOnlyFirstName(person);

        }
        if (data && data.data && data.data.isOpenCompanyModal) {
          this.setState({
            isNewCompanyCreate: true,
            company: new Company(),
            isOpenCompanyModal: data.data.isOpenCompanyModal,
            companyName: data.data.companyName,
          });
        }
        if (data && data.data && data.data.cabId) {
          this.setState(
            {
              isNew: false,
              isEditCAB: true,
              isSaveClick: false,
              cabValidationMessage: {
                firstNameErrorMsg: '',
                surNameErrorMsg: '',
                fullNameErrorMsg: '',
              },
            },
            () => {
              if (this.state.addressBookItem) {
                this.setState({ company: this.state.addressBookItem.company });
              }
            },
          );
        }
        if (data && data.data && data.data.saveCab) {
          this.setState({ isSaveClick: true, isDirty: false }, () => {
            this.onSaveCABEntry();
          });
        }
        if (data && data.data && data.data.isNewCAB) {
          this.setState({
            uid: uuidv4(),
          });
          this.onNewClick();
        }
        if (data && data.data && data.data.hasOwnProperty('cabType')) {
          this.setState({ cabType: data.data.cabType });
        }
      } else {
        //:TODO clear message
      }
    });
  }

  private onNewClick = () => {
    history.push('/address-book/new');
    this.setState({
      isNew: true,
      isEditCAB: false,
      company: new Company(),
      isSaveClick: false,
      cabValidationMessage: {
        firstNameErrorMsg: '',
        surNameErrorMsg: '',
        fullNameErrorMsg: '',
      },
      addressBookItem: new AddressBookItem(
        new Person(),
        null,
        new PersonCompany(),
      ),
    });
    messageService.sendMessage({ removedPerson: true });
    this.enableAddButton('internetContactActionButtonState');
    this.enableAddButton('phoneContactActionButtonState');
    this.enableAddButton('competenceActionButtonState');
    this.enableAddButton('professionalInternetContactActionButtonState');
    this.enableAddButton('professionalPhoneContactActionButtonState');
    this.enableAddButton('organisationPhoneContactActionButtonState');
    this.enableAddButton('organisationInternetContactActionButtonState');
  };

  private getInternetContactList = () => {
    //const { t } = this.props;
    let internetContacts: Contact[] = [];
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.person &&
      this.state.addressBookItem.person.email
    ) {
      internetContacts = [
        ...internetContacts,
        { type: i18n.t('email'), value: this.state.addressBookItem.person.email },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.person &&
      this.state.addressBookItem.person.skype
    ) {
      internetContacts = [
        ...internetContacts,
        { type: i18n.t('skype'), value: this.state.addressBookItem.person.skype },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.person &&
      this.state.addressBookItem.person.whatsapp
    ) {
      internetContacts = [
        ...internetContacts,
        {
          type: i18n.t('whatsApp'),
          value: this.state.addressBookItem.person.whatsapp,
        },
      ];
    }

    return internetContacts;
  };

  private getPhoneContacts = () => {
    //const { t } = this.props;
    let phoneContact: Contact[] = [];
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.person &&
      this.state.addressBookItem.person.landPhone
    ) {
      phoneContact = [
        ...phoneContact,
        {
          type: i18n.t('phone'),
          value: this.state.addressBookItem.person.landPhone,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.person &&
      this.state.addressBookItem.person.mobilePhone
    ) {
      phoneContact = [
        ...phoneContact,
        {
          type: i18n.t('mobile'),
          value: this.state.addressBookItem.person.mobilePhone,
        },
      ];
    }

    return phoneContact;
  };

  private getProfessinalInternetContactList = () => {
    //const { t } = this.props;
    let professionalInternetContacts: Contact[] = [];
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.personCompany &&
      this.state.addressBookItem.personCompany.email
    ) {
      professionalInternetContacts = [
        ...professionalInternetContacts,
        {
          type: i18n.t('email'),
          value: this.state.addressBookItem.personCompany.email,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.personCompany &&
      this.state.addressBookItem.personCompany.skype
    ) {
      professionalInternetContacts = [
        ...professionalInternetContacts,
        {
          type: i18n.t('skype'),
          value: this.state.addressBookItem.personCompany.skype,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.personCompany &&
      this.state.addressBookItem.personCompany.whatsapp
    ) {
      professionalInternetContacts = [
        ...professionalInternetContacts,
        {
          type: i18n.t('whatsApp'),
          value: this.state.addressBookItem.personCompany.whatsapp,
        },
      ];
    }
    return professionalInternetContacts;
  };

  private getProfessionalPhoneContacts = () => {
    //const { t } = this.props;
    let phoneContact: Contact[] = [];
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.personCompany &&
      this.state.addressBookItem.personCompany.landPhone
    ) {
      phoneContact = [
        ...phoneContact,
        {
          type: i18n.t('phone'),
          value: this.state.addressBookItem.personCompany.landPhone,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.personCompany &&
      this.state.addressBookItem.personCompany.mobilePhone
    ) {
      phoneContact = [
        ...phoneContact,
        {
          type: i18n.t('mobile'),
          value: this.state.addressBookItem.personCompany.mobilePhone,
        },
      ];
    }

    return phoneContact;
  };

  private getOrganisationPhoneContacts = () => {
    //const { t } = this.props;
    let phoneContact: Contact[] = [];
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.company &&
      this.state.addressBookItem.company.landPhone
    ) {
      phoneContact = [
        ...phoneContact,
        {
          type: i18n.t('phone'),
          value: this.state.addressBookItem.company.landPhone,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.company &&
      this.state.addressBookItem.company.mobilePhone
    ) {
      phoneContact = [
        ...phoneContact,
        {
          type: i18n.t('mobile'),
          value: this.state.addressBookItem.company.mobilePhone,
        },
      ];
    }

    return phoneContact;
  };

  private getOrganisationalInternetContactList = () => {
    //const { t } = this.props;
    let organisationInternetContacts: Contact[] = [];
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.company &&
      this.state.addressBookItem.company.email
    ) {
      organisationInternetContacts = [
        ...organisationInternetContacts,
        {
          type: i18n.t('email'),
          value: this.state.addressBookItem.company.email,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.company &&
      this.state.addressBookItem.company.skype
    ) {
      organisationInternetContacts = [
        ...organisationInternetContacts,
        {
          type: i18n.t('skype'),
          value: this.state.addressBookItem.company.skype,
        },
      ];
    }
    if (
      this.state.addressBookItem &&
      this.state.addressBookItem.company &&
      this.state.addressBookItem.company.whatsApp
    ) {
      organisationInternetContacts = [
        ...organisationInternetContacts,
        {
          type: i18n.t('whatsApp'),
          value: this.state.addressBookItem.company.whatsApp,
        },
      ];
    }
    return organisationInternetContacts;
  };

  private isValidFileType = (fileType: string): boolean => {
    return acceptedTypes.includes(fileType);
  };

  onProfileImageChange = (event: any) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      if (!this.isValidFileType(event.target.files[0].type)) {
        //alert('Only images are allowed (png or jpg)');
        return;
      }
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      this.props.uploadCABImage(formData);
      event.target.value = null;
    }
  };

  private handlePersonInputChange = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    this.setState(
      (prevState) => ({
        //isEditCAB:true,
        isDirty: this.state.addressBookItem.isSaved ? true : false,
        addressBookItem: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            [field]: newValue,
          },
        },
      }),
      () => {
        if (field === 'surname') {
          this.setState((prevState) => ({
            cabValidationMessage: {
              ...prevState.cabValidationMessage,
              surNameErrorMsg: '',
            },
          }));
        } else {
          this.setState((prevState) => ({
            cabValidationMessage: {
              ...prevState.cabValidationMessage,
              fullNameErrorMsg: '',
            },
          }));
        }

        this.validateCABEntry(false);
      },
    );
  };

  private handlePersonCompanyInputChange = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    this.setState(
      (prevState) => ({
        ////isEditCAB:true,
        isDirty: this.state.addressBookItem.isSaved ? true : false,
        addressBookItem: {
          ...prevState.addressBookItem,
          personCompany: {
            ...prevState.addressBookItem.personCompany,
            [field]: newValue,
          },
        },
      }),
      () => {
      },
    );
  };

  private handlePersonCountryInputChange = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    item?: any,
  ) => {
    this.setState(
      (prevState) => ({
        isDirty: this.state.addressBookItem.isSaved ? true : false,
        addressBookItem: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            address: {
              ...prevState.addressBookItem.person.address,
              [field]: item.key,
            },
          },
        },
      }),
      () => {
        this.saveCab(this.state.addressBookItem);
      },
    );
  };

  private handlePersonAddressInputChange = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    this.setState(
      (prevState) => ({
        //isEditCAB:true,
        isDirty: this.state.addressBookItem.isSaved ? true : false,
        addressBookItem: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            address: {
              ...prevState.addressBookItem.person.address,
              [field]: newValue,
            },
          },
        },
      }),
      () => {
      },
    );
  };

  private handlePersonNationalityChange = (value: string) => {
    this.setState(
      (prevState) => ({
        //isEditCAB:true,
        isDirty: this.state.addressBookItem.isSaved ? true : false,
        addressBookItem: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            nationalityId: value
          },
        },
      }),
      () => {
      },
    );
  };

  private onFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterCompanyChanged = (
    filterText: string,
    currentPersonas: ITag[],
    limitResults?: number,
  ): ITag[] | Promise<ITag[]> => {
    if (filterText) {
      return this.getCompanyByName(filterText);
    } else {
      return [];
    }
  };

  private onCompanyItemSelected = (item: ITag) => {
    let selectedCompany = item as Company;
    this.setState(
      (prevState) => ({
        company: selectedCompany,
        //isOpenCompanyModal: false,
        isDirty: this.state.addressBookItem.isSaved ? true : false,
        addressBookItem: {
          ...prevState.addressBookItem,
          companyId: selectedCompany.id,
          company: selectedCompany,
        },
      }),
      () => {
        this.saveCab(this.state.addressBookItem);
      },
    );
    return item;
  };

  private onPersonSelect = (item: any) => {
    if (item && item.person && item.person.id) {
      this.props.getAddressBookByID(item.person.id);
    }
  };

  private onBlur = () => {
    this.saveCab(this.state.addressBookItem);
  };

  private getCompanyByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabOrganizationList/${name}`,
    );

    return this.formatCompanyResponse(response, name);
  };

  private formatCompanyResponse = (response: any, name: any) => {
    let data: { key: string; name: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          ...item,
          key: item.id,
          isNewItem: false,
        };
      });
      let obj = data.find(
        (o: any) => o.name.toLowerCase() === name.toLowerCase(),
      );
      if (!obj) {
        data.unshift({ key: '', name: name, isNewItem: true });
      }
    } else {
      return [{ key: '', name: name, isNewItem: true }];
    }
    return data;
  };

  private getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`,
    );
    return this.formatPersonResponse(response, name);
  };

  private formatPersonResponse = (response: any, name: any) => {
    //let data:ITag[]|any = [];
    let data: { key: string; text: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: AddressBookItem) => {
        return {
          ...item,
          text: item.person ? item.person.fullName : '',
          secondaryText: item.personCompany ? item.personCompany.jobRole : '',
          key: item.id,
          isNewItem: false,
        };
      });
      let obj = data.find(
        (o: any) => o.person.fullName.toLowerCase() === name.toLowerCase(),
      );
      if (!obj) {
        data.unshift({ key: '', text: name, isNewItem: true });
      }
    } else {
      return [{ key: '', text: name, isNewItem: true }];
    }
    return data;
  };

  private onCreateNewBusinessCardClick = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return [];
  };

  private validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  private onInputChange = (input: string): string => {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }
    this.setState((prevState) => ({
      cabValidationMessage: {
        ...prevState.cabValidationMessage,
        firstNameErrorMsg: '',
      },
    }));
    return input;
  };

  private getIntenetContactOptions = (type: ContactType) => {
    let email = null;
    let skype = null;
    let wahtsApp = null;
    let options: PickListItem[] = [];

    const cabItem: AddressBookItem = this.state.addressBookItem;
    switch (type) {
      case ContactType.PROFESSTIONAL_INTERNET:
        if (cabItem && cabItem.personCompany) {
          email = cabItem.personCompany.email;
          skype = cabItem.personCompany.skype;
          wahtsApp = cabItem.personCompany.whatsapp;
        }
        break;
      case ContactType.PRIVATE_INTERNET:
        if (cabItem && cabItem.person) {
          email = cabItem.person.email;
          skype = cabItem.person.skype;
          wahtsApp = cabItem.person.whatsapp;
        }
        break;
      case ContactType.ORGANISATION_INTERNET:
        if (cabItem && cabItem.company) {
          email = cabItem.company.email;
          skype = cabItem.company.skype;
          wahtsApp = cabItem.company.whatsApp;
        }
        break;
    }

    if (!email) {
      options.push({ key: i18n.t('email'), text: i18n.t('email') });
    }
    if (!skype) {
      options.push({ key: i18n.t('skype'), text: i18n.t('skype') });
    }
    if (!wahtsApp) {
      options.push({ key: i18n.t('whatsApp'), text: i18n.t('whatsApp') });
    }

    return options;
  };

  private getPhoneContactOptions = (type: ContactType) => {
    let phone = null;
    let mobile = null;
    let options: PickListItem[] = [];

    const cabItem: AddressBookItem = this.state.addressBookItem;
    switch (type) {
      case ContactType.PROFESSTIONAL_PHONE:
        if (cabItem && cabItem.personCompany) {
          phone = cabItem.personCompany.landPhone;
          mobile = cabItem.personCompany.mobilePhone;
        }
        break;
      case ContactType.PRIVATE_PHONE:
        if (cabItem && cabItem.person) {
          phone = cabItem.person.landPhone;
          mobile = cabItem.person.mobilePhone;
        }
        break;
      case ContactType.ORGANISATION_PHONE:
        if (cabItem && cabItem.company) {
          phone = cabItem.company.landPhone;
          mobile = cabItem.company.mobilePhone;
        }
        break;
    }

    if (!phone) {
      options.push({ key: i18n.t('phone'), text: i18n.t('phone') });
    }
    if (!mobile) {
      options.push({ key: i18n.t('mobile'), text: i18n.t('mobile') });
    }

    return options;
  };

  renderShimmer() {
    return (
      <div>
        <AddressBookShimmer/>
      </div>
    );
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { type: this.state.cabType },
    });
  };


  private handleContractorsTaxonomyChange = (value: string) => {
    let contractorTaxonomyId = this.state.addressBookItem?.contractorTaxonomyId ? this.state.addressBookItem?.contractorTaxonomyId?.slice() : [];
    let isExists = contractorTaxonomyId.includes(value);
    if (isExists) {
      contractorTaxonomyId = contractorTaxonomyId.filter(item => item !== value);
    } else {
      contractorTaxonomyId.push(value);
    }

    this.setState(
      (prevState) => ({
        ////isEditCAB:true,
        isDirty: this.state.addressBookItem?.isSaved,
        addressBookItem: {
          ...prevState.addressBookItem,
          contractorTaxonomyId: contractorTaxonomyId,
        },
      }),
      () => {
      },
    );
  };

  render() {
    //const { t } = this.props;
    if (this.props.isCabItemLoading) {
      return <div style={{ width: '100%' }}>{this.renderShimmer()}</div>;
    } else {
      return (
        <div style={{ width: '100%' }}>
          <RouteLeavingGuard
            when={this.state.isDirty}
            navigate={(path) => this.handleDiscardChangesNavigate(path)}
            shouldBlockNavigation={(location) => {
              return true;
            }}
            handleConfirmation={(isConfirm: boolean) => {
              this.setState({
                isDirty: !isConfirm,
              });
            }}
          />

          <DocumentPaneComponent
            openInternetContactModel={() => {
              this.openInternetContactModel();
            }}
            openProfessionalInternetContactModal={() => {
              this.openProfessionalInternetContactModel();
            }}
            openProfessionalPhoneContact={() => {
              this.openProfessionalPhoneContactModel();
            }}
            isOpenInternetModel={this.state.isOpenInternetModel}
            internetContacts={this.getInternetContactList()}
            professionalInternetContact={this.getProfessinalInternetContactList()}
            professionalPhoneNumbers={this.getProfessionalPhoneContacts()}
            editInternetContact={() => this.openInternetContactModel()}
            internetContactSelection={this._internetContactSelection}
            internetContactSelectionDetails={
              this.state.internetContactSelectionDetails
            }
            internetContactActionButtonState={
              this.state.internetContactActionButtonState
            }
            professionalnternetContactSelection={
              this._professionalInternetSelection
            }
            professionalnternetContactSelectionDetails={
              this.state.internetContactSelectionDetails
            }
            professionalInternetContactActionButtonState={
              this.state.professionalInternetContactActionButtonState
            }
            professionalPhoneContactActionButtonState={
              this.state.professionalPhoneContactActionButtonState
            }
            professionalPhoneNumberSelection={
              this._professionalPhoneContactSelection
            }
            professionalPhoneContactSelectionDetails={
              this.state.professionalPhoneContactSelectionDetails
            }
            professionalInternetContactSelectionDetails={
              this.state.professionalInternetContactSelectionDetails
            }
            phoneNumberSelection={this._phoneNumberSelection}
            phoneNumbers={this.getPhoneContacts()}
            editphoneContacts={() => this.openPhoneContactModel()}
            phoneContactSelectionDetails={
              this.state.phoneContactSelectionDetails
            }
            phoneContactActionButtonState={
              this.state.phoneContactActionButtonState
            }
            openPhoneContactModel={() => {
              this.openPhoneContactModel();
            }}
            openCompetenceModal={() => {
              this.openCompetenceModal();
            }}
            openCertificationModal={() => {
              this.openCertificationModal();
            }}
            handlePersonInputChange={this.handlePersonInputChange}
            handlePersonAddressInputChange={this.handlePersonAddressInputChange}
            onFilterPersonChanged={this.onFilterPersonChanged}
            validateInput={this.validateInput}
            onInputChange={this.onInputChange}
            onCreateNewBusinessCardClick={this.onCreateNewBusinessCardClick}
            onFilterCompanyChanged={this.onFilterCompanyChanged}
            countries={this.state.countries}
            handlePersonCountryInputChange={this.handlePersonCountryInputChange}
            company={this.state.company}
            addressBookItem={this.state.addressBookItem}
            addressBookItemCopy={this.state.addressBookItemCopy}
            onCompanyItemSelected={this.onCompanyItemSelected}
            onSaveCABEntry={this.onSaveCABEntry}
            onProfileImageChange={this.onProfileImageChange}
            handlePersonCompanyInputChange={this.handlePersonCompanyInputChange}
            organisationInternetContactSelection={
              this._organisationInternetContactSelection
            }
            organisationalContact={this.getOrganisationalInternetContactList()}
            openOrganisatinalInternetContactModel={() => {
              this.openOrganisatinalInternetContactModel();
            }}
            organisationPhoneContactSelection={
              this._organisationPhoneContactSelection
            }
            organisationalPhoneContact={this.getOrganisationPhoneContacts()}
            openOrganisatinalPhoneContactModel={() => {
              this.openOrganisatinalPhoneContactModel();
            }}
            organisationInternetContactSelectionDetails={
              this.state.organisationInternetContactSelectionDetails
            }
            organisationInternetContactActionButtonState={
              this.state.organisationInternetContactActionButtonState
            }
            organisationPhoneContactSelectionDetails={
              this.state.organisationPhoneContactSelectionDetails
            }
            organisationPhoneContactActionButtonState={
              this.state.organisationPhoneContactActionButtonState
            }
            editProfessionalInternetContactModel={() =>
              this.openProfessionalInternetContactModel()
            }
            editProfessionalPhoneContactModel={() =>
              this.openProfessionalPhoneContactModel()
            }
            isNew={this.state.isNew}
            openCompanyModel={() => this.openCompanyModel()}
            deleteProfessionalInternetContact={() =>
              this.deleteProfessionalContact('internet')
            }
            deleteProfessionalPhoneContact={() =>
              this.deleteProfessionalContact('phone')
            }
            deletePrivateInternetContact={() =>
              this.deletePrivateContact('internet')
            }
            deletePrivatePhoneContact={() => this.deletePrivateContact('phone')}
            editCompanyModel={() => {
              this.editCompanyModel();
            }}
            onRemoveCompanySuggestion={this.onRemoveCompanySuggestion}
            cabValidationMessage={this.state.cabValidationMessage}
            onBlur={() => {
              this.onBlur();
            }}
            onPersonSelect={this.onPersonSelect}
            onRemovePersonSuggetion={this.onRemovePersonSuggetion}
            competenceList={this.props.competence}
            certificationList={this.props.certificate}
            competenceSelection={this._competenceSelection}
            competenceSelectionDetails={
              this.state.competenceSelectionDetails
            }
            competenceActionButtonState={
              this.state.competenceActionButtonState
            }
            certificationSelection={this._certificationSelection}
            certificationSelectionDetails={
              this.state.certificationSelectionDetails
            }
            certificationActionButtonState={
              this.state.certificationActionButtonState
            }
            readOnly={this.state.addressBookItem?.person?.id ? false : true}
            competencyTaxonomyList={this.props.competencyTaxonomyList ? this.props.competencyTaxonomyList : []}
            certificationTaxonomyList={this.props.certificationTaxonomyList ? this.props.certificationTaxonomyList : []}
            deleteOrganisationSettings={(type: string) => this.deleteOrganisationSettings(type)}
            handleContractorsTaxonomyChange={this.handleContractorsTaxonomyChange}
            handlePersonNationalityChange={this.handlePersonNationalityChange}
          />

          <ContactModal
            isOpen={this.state.isOpenInternetModel}
            isEdit={this.state.isInternetContactEdit}
            openContactModel={this.openInternetContactModel}
            options={this.getIntenetContactOptions(
              ContactType.PRIVATE_INTERNET,
            )}
            allOptions={this.internetContactTypes}
            onAddContact={this.onAddInternetContact}
            title={i18n.t('Internet Contact')}
            valueLable={i18n.t('Account')}
            contact={this.state.editContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
          />

          <ContactModal
            isOpen={this.state.isOpenPhoneModel}
            isEdit={this.state.isPhoneContactEdit}
            openContactModel={this.openPhoneContactModel}
            options={this.getPhoneContactOptions(ContactType.PRIVATE_PHONE)}
            allOptions={this.phoneContactTypes}
            onAddContact={this.onAddInternetContact}
            title={i18n.t('phoneNumbers')}
            valueLable={i18n.t('number')}
            contact={this.state.editContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
          />

          <ContactModal
            isOpen={this.state.isOpenProfessionalInternetContactModal}
            isEdit={this.state.isProfessionalContactEdit}
            openContactModel={this.openProfessionalInternetContactModel}
            options={this.getIntenetContactOptions(
              ContactType.PROFESSTIONAL_INTERNET,
            )}
            allOptions={this.internetContactTypes}
            onAddContact={this.onAddProfessionalContact}
            title={i18n.t('Internet Contact')}
            valueLable={i18n.t('Account')}
            contact={this.state.editContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
          />

          <ContactModal
            isOpen={this.state.isOpenProfessionalPhoneContactModal}
            isEdit={this.state.isProfessionalPhoneContactEdit}
            openContactModel={this.openProfessionalPhoneContactModel}
            options={this.getPhoneContactOptions(
              ContactType.PROFESSTIONAL_PHONE,
            )}
            allOptions={this.phoneContactTypes}
            onAddContact={this.onAddProfessionalContact}
            title={i18n.t('phoneNumbers')}
            valueLable={i18n.t('number')}
            contact={this.state.editContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
          />

          <ContactModal
            isOpen={this.state.isOpenOrganisatinalInternetContactModal}
            isEdit={this.state.isOrgInternetContactEdit}
            openContactModel={this.openOrganisatinalInternetContactModel}
            options={this.getIntenetContactOptions(
              ContactType.ORGANISATION_INTERNET,
            )}
            allOptions={this.internetContactTypes}
            onAddContact={this.onAddOrganisationalContact}
            title={i18n.t('Internet Contact')}
            valueLable={i18n.t('Account')}
            contact={this.state.editContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
          />

          <ContactModal
            isOpen={this.state.isOpenOrganisatinalPhoneContactModal}
            isEdit={this.state.isOrgPhoneContactEdit}
            openContactModel={this.openOrganisatinalPhoneContactModel}
            options={this.getPhoneContactOptions(
              ContactType.ORGANISATION_PHONE,
            )}
            allOptions={this.phoneContactTypes}
            onAddContact={this.onAddOrganisationalContact}
            title={i18n.t('phoneNumbers')}
            valueLable={i18n.t('number')}
            contact={this.state.editContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
          />

          <PersonModal
            isOpen={this.state.isOpenNameModal}
            personName={this.state.personName}
            openPersonModel={this.openPersonModel}
            onAddPerson={this.onAddPerson}
            countries={this.state.countries}
          />

          <CompanyModal
            isOpen={this.state.isOpenCompanyModal}
            openComapnyModal={this.openCompanyModel}
            companyName={this.state.companyName}
            onAddCompany={this.onAddComapny}
            countries={this.state.countries}
            organisationInternetContactSelection={
              this._organisationInternetContactSelection
            }
            organisationalContact={this.getOrganisationalInternetContactList()}
            editOrganisationalContact={() => {
              this.openOrganisatinalInternetContactModel();
            }}
            organisationPhoneContactSelectionDetails={
              this.state.organisationPhoneContactSelectionDetails
            }
            organisationInternetContactActionButtonState={
              this.state.organisationInternetContactActionButtonState
            }
            openOrganisatinalInternetContactModel={() => {
              this.openOrganisatinalInternetContactModel();
            }}
            organisationPhoneContactSelection={
              this._organisationPhoneContactSelection
            }
            organisationalPhoneContact={this.getOrganisationPhoneContacts()}
            editOrganisationPhoneContacts={() => {
              this.openOrganisatinalPhoneContactModel();
            }}
            organisationPhoneContactActionButtonState={
              this.state.organisationPhoneContactActionButtonState
            }
            openOrganisatinalPhoneContactModel={() => {
              this.openOrganisatinalPhoneContactModel();
            }}
            company={this.state.company}
            deleteOrganisationContact={() => {
              this.deleteOrganisationContact('internet');
            }}
            deleteOrganisationPhoneContact={() => {
              this.deleteOrganisationContact('phone');
            }}
            isNewCompanyCreate={this.state.isNewCompanyCreate}
            isDeleteContact={this.state.isDeleteContact}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
            handleContractorsTaxonomyChange={this.handleContractorsTaxonomyChange}
            addressBookItem={this.state.addressBookItem}
          />

          <CompetenceModal
            isOpen={this.state.isOpenCompetenceModal}
            isEdit={this.state.isCompetenceEdit}
            openCompetenceModal={this.openCompetenceModal}
            options={this.getIntenetContactOptions(
              ContactType.ORGANISATION_INTERNET,
            )}
            allOptions={this.internetContactTypes}
            onAddCompetency={this.onAddCompetencies}
            title={i18n.t('competencies')}
            valueLable={i18n.t('Account')}
            organisationSelectionDetails={this.state.competenceSelectionDetails as CompetenceModalDTO[]}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
            dropDowns={this.props.compDropDowns}
            competencyTaxonomyList={this.props.competencyTaxonomyList ? this.props.competencyTaxonomyList : []}
            competenciesTaxonomyId={this.state.competenciesTaxonomyId}
            setCompetenciesTaxonomyId={(competenciesTaxonomyId: string | null) => {
              this.setState({ competenciesTaxonomyId: competenciesTaxonomyId });
            }}
            createCabCompetencies={(competence: any) => {
              this.onCreateCabCompetencies(competence);
            }}
          />

          <CertificationModal
            isOpen={this.state.isOpenCertificationModal}
            isEdit={this.state.isCertificationEdit}
            openCertificationModal={this.openCertificationModal}
            options={this.getIntenetContactOptions(
              ContactType.ORGANISATION_INTERNET,
            )}
            allOptions={this.internetContactTypes}
            onAddContact={this.onAddCertification}
            title={i18n.t('certifications')}
            valueLable={i18n.t('Account')}
            organisationSelectionDetails={this.state.certificationSelectionDetails as CertificationModalDTO[]}
            isChecking={this.state.isCheckingUniqContact}
            isUniq={this.state.isUniqContact}
            isQniqContact={this.props.isQniqContact}
            dropDowns={this.props.certDropDowns}
            certificationTaxonomyList={this.props.certificationTaxonomyList ? this.props.certificationTaxonomyList : []}
            certificationTaxonomyId={this.state.certificationTaxonomyId}
            setCertificationTaxonomyId={(certificationTaxonomyId: string | null) => {
              this.setState({ certificationTaxonomyId: certificationTaxonomyId });
            }}
            createCabCertification={(certificate: any) => {
              this.onCreateCabCertification(certificate);
            }}
          />
        </div>
      );
    }
  }

  private onCreateCabCompetencies = (competence: any) => {
    if (!competence.id) {
      competence.id = uuidv4();
    }
    competence.personId = this.state.addressBookItem.person.id;
    this.props.createCabCompetencies(competence).then(() => {
      this.props.readCabCompetencies(competence.personId);
    });
  };

  private onCreateCabCertification = (certificate: any) => {
    if (!certificate.id) {
      certificate.id = uuidv4();
    }
    certificate.personId = this.state.addressBookItem.person.id;
    this.props.createCabCertification(certificate).then(() => {
      this.props.readCabCertification(certificate.personId);
    });
  };

  private onAddComapny = (company: Company) => {
    company.key = company.name;
    company.isSaved = true;
    this.setState(
      (prevState) => ({
        company: company,
        addressBookItem: {
          ...prevState.addressBookItem,
          company: company,
        },
      }),
      () => {
        this.props.createCompany(company);
      },
    );
  };

  private onSaveCABEntry = () => {
    if (this.validateCABEntry(true)) {
      this.setState(
        (prevState) => ({
          isSaveClick: true,
          addressBookItem: {
            ...prevState.addressBookItem,
            isSaved: true,
          },
        }),
        () => {
          this.props.createCABEntry(this.state.addressBookItem);
        },
      );
    }
  };

  fistNameValidate = (showMsg: boolean) => {
    let isValid: boolean;
    if (
      !_.isNil(this.state.addressBookItem.person) &&
      !_.isNil(this.state.addressBookItem.person.firstName) &&
      !_.isEmpty(this.state.addressBookItem.person.firstName)
    ) {
      isValid = true;
      if (showMsg)
        this.setState((prevState) => ({
          cabValidationMessage: {
            ...prevState.cabValidationMessage,
            firstNameErrorMsg: '',
          },
        }));
    } else {
      isValid = false;
      if (showMsg)
        this.setState((prevState) => ({
          cabValidationMessage: {
            ...prevState.cabValidationMessage,
            firstNameErrorMsg: i18n.t('firstNameRequired'),
          },
        }));
    }
    return isValid;
  };

  surnameValidate = (showMsg: boolean) => {
    let isSurNameValid = false;
    if (
      !_.isNil(this.state.addressBookItem.person) &&
      !_.isNil(this.state.addressBookItem.person.surname) &&
      !_.isEmpty(this.state.addressBookItem.person.surname)
    ) {
      isSurNameValid = true;
      if (showMsg)
        this.setState((prevState) => ({
          cabValidationMessage: {
            ...prevState.cabValidationMessage,
            surNameErrorMsg: '',
          },
        }));
    } else {
      isSurNameValid = false;
      if (showMsg)
        this.setState((prevState) => ({
          cabValidationMessage: {
            ...prevState.cabValidationMessage,
            surNameErrorMsg: i18n.t('surnameRequired'),
          },
        }));
    }
    return isSurNameValid;
  };

  fullNameValidate = (showMsg: boolean) => {
    let fullNameValid = false;
    if (
      !_.isNil(this.state.addressBookItem.person) &&
      !_.isNil(this.state.addressBookItem.person.fullName) &&
      !_.isEmpty(this.state.addressBookItem.person.fullName)
    ) {
      fullNameValid = true;
      if (showMsg)
        this.setState((prevState) => ({
          cabValidationMessage: {
            ...prevState.cabValidationMessage,
            fullNameErrorMsg: '',
          },
        }));
    } else {
      fullNameValid = false;
      if (showMsg)
        this.setState((prevState) => ({
          cabValidationMessage: {
            ...prevState.cabValidationMessage,
            fullNameErrorMsg: i18n.t('fullNameRequired'),
          },
        }));
    }

    return fullNameValid;
  };

  validateCABEntry = (showMsg: boolean) => {
    let isFirstNameValidate = this.fistNameValidate(showMsg);
    let isSurNameValidate = this.surnameValidate(showMsg);
    let isFullNameValid = this.fullNameValidate(showMsg);
    return isFirstNameValidate && isSurNameValidate && isFullNameValid;
  };

  private onAddPerson = (person: Person) => {
    this.setState(
      (prevState) => ({
        isOpenNameModal: false,
        addressBookItem: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            firstName: person.firstName,
            surname: person.surname,
            fullName: person.fullName,
          },
        },
      }),
      () => {
        this.fistNameValidate(true);
        this.surnameValidate(true);
        this.saveCab(this.state.addressBookItem);
      },
    );
  };

  private onAddPersonWithOnlyFirstName = (person: Person) => {
    this.setState(
      (prevState) => ({
        isOpenNameModal: false,
        addressBookItemCopy: {
          ...prevState.addressBookItem,
          person: {
            ...prevState.addressBookItem.person,
            firstName: person.firstName,
            surname: person.surname,
            fullName: person.fullName,
          },
        },
      }),
      () => {
        this.saveCabName(this.state.addressBookItemCopy);
      },
    );
  };

  private onAddInternetContact = (contact: Contact, saveAndNew: boolean) => {
    if (contact) {
      this._internetContactSelection.setAllSelected(false);
      this._phoneNumberSelection.setAllSelected(false);
      if (contact.type === i18n.t('email')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenInternetModel: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              person: {
                ...prevState.addressBookItem.person,
                email: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'internetContactActionButtonState',
              ContactType.PRIVATE_INTERNET,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('skype')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenInternetModel: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              person: {
                ...prevState.addressBookItem.person,
                skype: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'internetContactActionButtonState',
              ContactType.PRIVATE_INTERNET,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('whatsApp')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenInternetModel: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              person: {
                ...prevState.addressBookItem.person,
                whatsapp: contact.value,
              },
            },
          }),
          () => {
            this.saveCab(this.state.addressBookItem);
            this.disableAddButton(
              'internetContactActionButtonState',
              ContactType.PRIVATE_INTERNET,
            );
          },
        );
      }
      if (contact.type === i18n.t('phone')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenPhoneModel: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              person: {
                ...prevState.addressBookItem.person,
                landPhone: contact.value,
              },
            },
          }),
          () => {
            this.saveCab(this.state.addressBookItem);
            this.disableAddButton(
              'phoneContactActionButtonState',
              ContactType.PRIVATE_PHONE,
            );
          },
        );
      }
      if (contact.type === i18n.t('mobile')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenPhoneModel: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              person: {
                ...prevState.addressBookItem.person,
                mobilePhone: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'phoneContactActionButtonState',
              ContactType.PRIVATE_PHONE,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
    }
  };

  enableAddButton = (key: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: {
        add: false,
        edit: true,
        remove: true,
      },
    }));
  };

  private disableAddButton = (buttonState: string, type: ContactType) => {
    let length = 0;
    switch (type) {
      case ContactType.PROFESSTIONAL_INTERNET:
        length = this.getIntenetContactOptions(
          ContactType.PROFESSTIONAL_INTERNET,
        ).length;
        break;
      case ContactType.PROFESSTIONAL_PHONE:
        length = this.getPhoneContactOptions(ContactType.PROFESSTIONAL_PHONE)
          .length;
        break;
      case ContactType.PRIVATE_INTERNET:
        length = this.getIntenetContactOptions(ContactType.PRIVATE_INTERNET)
          .length;
        break;
      case ContactType.PRIVATE_PHONE:
        length = this.getPhoneContactOptions(ContactType.PRIVATE_PHONE).length;
        break;
      case ContactType.ORGANISATION_INTERNET:
        length = this.getIntenetContactOptions(
          ContactType.ORGANISATION_INTERNET,
        ).length;
        break;
      case ContactType.ORGANISATION_PHONE:
        length = this.getPhoneContactOptions(ContactType.ORGANISATION_PHONE)
          .length;
        break;
    }
    if (length === 0) {
      this.setState((prevState) => ({
        ...prevState,
        [buttonState]: {
          add: true,
          edit: true,
          remove: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [buttonState]: {
          add: false,
          edit: true,
          remove: true,
        },
      }));
    }
  };

  private onAddProfessionalContact = (
    contact: Contact,
    saveAndNew: boolean,
  ) => {
    if (contact) {
      this._professionalInternetSelection.setAllSelected(false);
      this._professionalPhoneContactSelection.setAllSelected(false);
      if (contact.type === i18n.t('email')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenProfessionalInternetContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              personCompany: {
                ...prevState.addressBookItem.personCompany,
                email: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'professionalInternetContactActionButtonState',
              ContactType.PROFESSTIONAL_INTERNET,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('skype')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenProfessionalInternetContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              personCompany: {
                ...prevState.addressBookItem.personCompany,
                skype: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'professionalInternetContactActionButtonState',
              ContactType.PROFESSTIONAL_INTERNET,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('whatsApp')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenProfessionalInternetContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              personCompany: {
                ...prevState.addressBookItem.personCompany,
                whatsapp: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'professionalInternetContactActionButtonState',
              ContactType.PROFESSTIONAL_INTERNET,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('phone')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenProfessionalPhoneContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              personCompany: {
                ...prevState.addressBookItem.personCompany,
                landPhone: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'professionalPhoneContactActionButtonState',
              ContactType.PROFESSTIONAL_PHONE,
            );
            this.saveCab(this.state.addressBookItem);
            UprinceLogger.log('Phone', this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('mobile')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenProfessionalPhoneContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              personCompany: {
                ...prevState.addressBookItem.personCompany,
                mobilePhone: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'professionalPhoneContactActionButtonState',
              ContactType.PROFESSTIONAL_PHONE,
            );
            this.saveCab(this.state.addressBookItem);
          },
        );
      }
    }
  };

  private onAddOrganisationalContact = (
    contact: Contact,
    saveAndNew: boolean,
  ) => {
    if (contact) {
      this._organisationInternetContactSelection.setAllSelected(false);
      this._organisationPhoneContactSelection.setAllSelected(false);
      if (contact.type === i18n.t('email')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenOrganisatinalInternetContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              company: {
                ...prevState.addressBookItem.company,
                email: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'organisationInternetContactActionButtonState',
              ContactType.ORGANISATION_INTERNET,
            );
            if (this.state.isNewCompanyCreate)
              this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('skype')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenOrganisatinalInternetContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              company: {
                ...prevState.addressBookItem.company,
                skype: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'organisationInternetContactActionButtonState',
              ContactType.ORGANISATION_INTERNET,
            );
            if (this.state.isNewCompanyCreate)
              this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('whatsApp')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenOrganisatinalInternetContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              company: {
                ...prevState.addressBookItem.company,
                whatsApp: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'organisationInternetContactActionButtonState',
              ContactType.ORGANISATION_INTERNET,
            );
            if (this.state.isNewCompanyCreate)
              this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('phone')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenOrganisatinalPhoneContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              company: {
                ...prevState.addressBookItem.company,
                landPhone: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'organisationPhoneContactActionButtonState',
              ContactType.ORGANISATION_PHONE,
            );
            if (this.state.isNewCompanyCreate)
              this.saveCab(this.state.addressBookItem);
          },
        );
      }
      if (contact.type === i18n.t('mobile')) {
        this.setState(
          (prevState) => ({
            isDirty: this.state.addressBookItem.isSaved ? true : false,
            isOpenOrganisatinalPhoneContactModal: saveAndNew ? true : false,
            addressBookItem: {
              ...prevState.addressBookItem,
              company: {
                ...prevState.addressBookItem.company,
                mobilePhone: contact.value,
              },
            },
          }),
          () => {
            this.disableAddButton(
              'organisationPhoneContactActionButtonState',
              ContactType.ORGANISATION_PHONE,
            );
            if (this.state.isNewCompanyCreate)
              this.saveCab(this.state.addressBookItem);
          },
        );
      }
    }
  };

  private onAddCompetencies = (
    competencies: any,
    saveAndNew: boolean,
  ) => {
    if (competencies) {
      this._competenceSelection.setAllSelected(false);
      this.setState(
        (prevState) => ({
          isDirty: this.state.addressBookItem.isSaved ? true : false,
          isOpenCompetenceModal: saveAndNew ? true : false,
          addressBookItem: {
            ...prevState.addressBookItem,
            company: {
              ...prevState.addressBookItem.company,
            },
          },
        }),
        () => {
          if (this.state.isNewCompanyCreate)
            this.saveCab(this.state.addressBookItem);
        },
      );
    }
  };

  private onAddCertification = (
    certification: any,
    saveAndNew: boolean,
  ) => {
    if (certification) {
      this._certificationSelection.setAllSelected(false);
      this.setState(
        (prevState) => ({
          isDirty: this.state.addressBookItem.isSaved ? true : false,
          isOpenOrganisatinalInternetContactModal: saveAndNew ? true : false,
          addressBookItem: {
            ...prevState.addressBookItem,
            company: {
              ...prevState.addressBookItem.company,
            },
          },
        }),
        () => {
          if (this.state.isNewCompanyCreate)
            this.saveCab(this.state.addressBookItem);
        },
      );
    }
  };

  private openInternetContactModel = (): void => {
    this.setState({
      isOpenInternetModel: !this.state.isOpenInternetModel,
      //isInternetContactEdit: false,
      editContact: this._internetContactEditDetails(),
    });
  };

  private openPersonModel = (): void => {
    this.setState({ isOpenNameModal: !this.state.isOpenNameModal });
  };

  private openCompanyModel = (): void => {
    this.setState({
      company: this.state.addressBookItem.company,
      isNewCompanyCreate: true,
      isOpenCompanyModal: !this.state.isOpenCompanyModal,
      isDeleteContact: false,
    });
  };

  private editCompanyModel = (): void => {
    this.setState({
      //company: this.state.addressBookItem.company,
      isEditCAB: true,
      isNewCompanyCreate: false,
      isOpenCompanyModal: !this.state.isOpenCompanyModal,
    });
  };

  private openCompetenceModal = (): void => {
    this.setState({
      isOpenCompetenceModal: !this.state.isOpenCompetenceModal,
      //isPhoneContactEdit: false,
      editContact: this._phoneContactEditDetails(),
    });
  };

  private openCertificationModal = (): void => {
    this.setState({
      isOpenCertificationModal: !this.state.isOpenCertificationModal,
      editCompetence: this._competenceEditDetails(),
    });
  };

  private openPhoneContactModel = (): void => {
    this.setState({
      isOpenPhoneModel: !this.state.isOpenPhoneModel,
      //isPhoneContactEdit: false,
      editContact: this._phoneContactEditDetails(),
    });
  };

  private openProfessionalPhoneContactModel = (): void => {
    this.setState({
      isOpenProfessionalPhoneContactModal: !this.state
        .isOpenProfessionalPhoneContactModal,
      //isPhoneContactEdit: false,
      editContact: this._professionalPhoneContactEditDetails(),
    });
  };

  private openProfessionalInternetContactModel = (): void => {
    this.setState({
      isOpenProfessionalInternetContactModal: !this.state
        .isOpenProfessionalInternetContactModal,
      //isInternetContactEdit: false,
      editContact: this._professionalInternetContactEditDetails(),
    });
  };

  private openOrganisatinalInternetContactModel = (): void => {
    this.setState({
      isOpenOrganisatinalInternetContactModal: !this.state
        .isOpenOrganisatinalInternetContactModal,
      //isInternetContactEdit: false,
      isDeleteContact: false,
      editContact: this._organisationInternetContactEditDetails(),
    });
  };

  private openOrganisatinalPhoneContactModel = (): void => {
    this.setState({
      isOpenOrganisatinalPhoneContactModal: !this.state
        .isOpenOrganisatinalPhoneContactModal,
      //isPhoneContactEdit: false,
      isDeleteContact: false,
      editContact: this._organisationPhoneContactEditDetails(),
    });
  };

  private _getInternetContactSelectionDetails(): string | number | {} | [] {
    return this._internetContactSelection.getSelection();
  }

  private _getPhoneContactSelectionDetails(): string | number | {} | [] {
    return this._phoneNumberSelection.getSelection();
  }

  private _getCompetenceSelectionDetails(): string | number | {} | [] {
    return this._competenceSelection.getSelection();
  }

  private _getCertificationSelectionDetails(): string | number | {} | [] {
    return this._certificationSelection.getSelection();
  }

  private _getProfessionalInternetContactSelectionDetails():
    | string
    | number
    | {}
    | [] {
    return this._professionalInternetSelection.getSelection();
  }

  private _getProfessionalPhoneContactSelectionDetails():
    | string
    | number
    | {}
    | [] {
    return this._professionalPhoneContactSelection.getSelection();
  }

  private _getOrganisationalInternetContactSelectionDetails():
    | string
    | number
    | {}
    | [] {
    return this._organisationInternetContactSelection.getSelection();
  }

  private _getOrganisationalPhoneContactSelectionDetails():
    | string
    | number
    | {}
    | [] {
    return this._organisationPhoneContactSelection.getSelection();
  }

  private _getProfessionalInternetContactActionButtonState(): ActionButtonState {
    const selectionCount = this._professionalInternetSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount === 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (
        this.getIntenetContactOptions(ContactType.PROFESSTIONAL_INTERNET)
          .length > 0
      ) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getProfessionalPhoneContactActionButtonState(): ActionButtonState {
    const selectionCount = this._professionalPhoneContactSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (
        this.getIntenetContactOptions(ContactType.PROFESSTIONAL_PHONE).length >
        0
      ) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getOrganisationalInternetContactActionButtonState(): ActionButtonState {
    const selectionCount = this._organisationInternetContactSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (
        this.getIntenetContactOptions(ContactType.ORGANISATION_INTERNET)
          .length > 0
      ) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getOrganisationalPhoneContactActionButtonState(): ActionButtonState {
    const selectionCount = this._organisationPhoneContactSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount === 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (
        this.getIntenetContactOptions(ContactType.ORGANISATION_PHONE).length > 0
      ) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getInternetContactActionButtonState(): ActionButtonState {
    const selectionCount = this._internetContactSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (
        this.getIntenetContactOptions(ContactType.PRIVATE_INTERNET).length > 0
      ) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getPhoneContactActionButtonState(): ActionButtonState {
    const selectionCount = this._phoneNumberSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (this.getIntenetContactOptions(ContactType.PRIVATE_PHONE).length > 0) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getCompetenceActionButtonState(): ActionButtonState {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (this.getIntenetContactOptions(ContactType.PRIVATE_PHONE).length > 0) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private _getCertificationActionButtonState(): ActionButtonState {
    const selectionCount = this._certificationSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      if (this.getIntenetContactOptions(ContactType.PRIVATE_PHONE).length > 0) {
        disabled = {
          add: false,
          edit: true,
          remove: true,
        };
      } else {
        disabled = {
          add: true,
          edit: true,
          remove: true,
        };
      }
    }

    return disabled;
  }

  private onRemoveCompanySuggestion = (items: any[]): void => {
    if (items && _.isArray(items) && items.length === 0) {
      this.setState(
        (prevState) => ({
          company: new Company(),
          //isOpenCompanyModal: false,
          addressBookItem: {
            ...prevState.addressBookItem,
            companyId: null,
            company: new Company(),
          },
        }),
        () => {
          this.saveCab(this.state.addressBookItem);
        },
      );
    }
  };

  private onRemovePersonSuggetion = (items: any[]): void => {
    this.onNewClick();
  };
}

const mapStateToProps = (state: any, props: Props) => {
  return {
    countries: state.addressBook.countries,
    status: state.addressBook.status,
    createCabStatus: state.addressBook.createCabStatus,
    uploadImageStatus: state.addressBook.uploadImageStatus,
    profileImageUrl: state.addressBook.profileImageUrl,
    message: state.addressBook.message,
    isLoading: state.addressBook.isLoading,
    companyId: state.addressBook.companyId,
    personId: state.addressBook.personId,
    personCompanyId: state.addressBook.personCompanyId,
    addressBookFromId: state.addressBook.addressBookFromId,
    isCabItemLoading: state.addressBook.isCabItemLoading,
    isUniqContact: state.addressBook.isUniqContact,
    createCompanyStatus: state.addressBook.createCompanyStatus,
    isCheckingUniqContact: state.addressBook.isCheckingUniqContact,
    certificationTaxonomyList: state.cert.certificationTaxonomyList,
    competencyTaxonomyList: state.comp.competencyTaxonomyList,
    competence: state.addressBook.competence,
    certificate: state.addressBook.certificate,
    certDropDowns: state.cert.certDropDowns,
    compDropDowns: state.comp.compDropDowns,
  };
};

const mapDispatchToProps = {
  getCountryList,
  createCompany,
  createCABEntry,
  uploadCABImage,
  getAddressBookByID,
  isQniqContact,
  getAllProjectDropdowns,
  createCabCertification,
  readCabCompetencies,
  deleteCabCompetencies,
  createCabCompetencies,
  readCabCertification,
  deleteCabCertification,
  readCertificationTaxanomyList,
  readCompetancyTaxonomyList,
  readCertificationDropDownData,
  readCompetencyDropDownData,
};

export default (
  connect(mapStateToProps, mapDispatchToProps)(AddressBookDocumentPane)
);
