import * as React from 'react';
import CustomDatePicker from '../../shared/customDatePicker/customDatePicker';


const DatePickerAdapter = ({ input: { name, value, onChange, ...restInput }, meta, minDate, maxDate, required, lable, placeholder, data, options, disabled, isClearable = false, onDateClear, ...rest }: any) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  return (
    // <Dropdown
    //     { ...restInput }
    //     value={ -1 }
    //     options={ options }
    //     label={ lable }
    //     onChange={ (e, valuex) => onChange(valuex?.key) }
    //     errorMessage={ showError ? meta.error : '' }
    //     optionLabel="optionLabel"
    //     placeholder={ placeholder }
    //     disabled={ disabled }
    //     selectedKey={ value }
    //     style={ { marginRight: '1rem', width: '100%' } }
    // />

    <CustomDatePicker
      setMinDate={minDate}
      setMaxDate={maxDate}
      label={lable}
      // setValue={ (props.formData.executionDate) ? new Date(props.formData.executionDate) : null }
      setValue={value}
      getValue={(date: Date) => {
        onChange(date);
        // props.formData.executionDate = formatDateTimeToISOString(date);
        // props.handleFormChange(
        //     props.formData,
        //     true
        // );
      }}
      disabled={disabled}
      required={required}
      hasChanged={rest.hasChanged}
      isClearable={isClearable}
      onDateClear={onDateClear}
    />
  );
};

export default DatePickerAdapter;
