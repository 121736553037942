import React from 'react';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { ProjectBreakdown, ProjectBreakdownValidationMessage } from '../../../types/projectBreakdownStructure';
import TextEditor from '../../../shared/textEditor/textEditor';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};


const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? (window.innerWidth <= 1150) ? '60vh' : '50vh' : '30vh',
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const ProductDetail = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (
    projectBreakdown: ProjectBreakdown,
    save: boolean,
  ) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  readOnly: boolean
}) => {

  const { t } = useTranslation();
  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="pbs-product-detail">
          <Label>2. {t('productDetails')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className={`marginTop marginBottom `}>
            {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}> */}
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productPurpose')}</Label>
              <TextEditor
                value={props.projectBreakdown ? props.projectBreakdown.productPurpose : ''}
                onChange={(value: any) => {
                  //if (props.projectBreakdown && props.projectBreakdown.productPurpose && props.projectBreakdown.productPurpose !== value ) {
                  if (value) {
                    props.projectBreakdown.productPurpose = value;
                  } else {
                    props.projectBreakdown.productPurpose = null;
                  }
                  // props.handleFormChange(
                  //   props.projectBreakdown,
                  //   true
                  // );
                }

                }
                readOnly={props.readOnly}
                //}
              />
            </div>
            {/* </Stack.Item>
            </Stack> */}
          </div>

          <div className={`marginTop marginBottom `}>
            {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}> */}
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productComposition')}</Label>
              <TextEditor
                value={
                  props.projectBreakdown.productComposition
                    ? props.projectBreakdown.productComposition
                    : ''
                }
                onChange={(value) => {
                  //if (props.projectBreakdown && props.projectBreakdown.productComposition && props.projectBreakdown.productComposition !== value) {
                  if (value) {
                    props.projectBreakdown.productComposition = value;
                  } else {
                    props.projectBreakdown.productComposition = null;
                  }
                  // props.handleFormChange(
                  //   props.projectBreakdown,
                  //   true
                  // );
                }}
                readOnly={props.readOnly}
              />
            </div>
            {/* </Stack.Item>
            </Stack> */}
          </div>

          <div className={`marginTop marginBottom `}>
            {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}> */}
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productDerivation')}</Label>
              <TextEditor
                value={
                  props.projectBreakdown.productDerivation
                    ? props.projectBreakdown.productDerivation
                    : ''
                }
                onChange={(value) => {
                  //if (props.projectBreakdown && props.projectBreakdown.productDerivation && props.projectBreakdown.productDerivation !== value) {
                  if (value) {
                    props.projectBreakdown.productDerivation = value;
                  } else {
                    props.projectBreakdown.productDerivation = null;
                  }
                  // props.handleFormChange(
                  //   props.projectBreakdown,
                  //   true
                  // );
                }}
                readOnly={props.readOnly}
              />
            </div>
            {/* </Stack.Item>
            </Stack> */}
          </div>

          <div className={`marginTop marginBottom `}>
            {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}> */}
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productFormatPresentation')}</Label>
              <TextEditor
                value={
                  props.projectBreakdown.productFormatPresentation
                    ? props.projectBreakdown.productFormatPresentation
                    : ''
                }
                onChange={(value) => {
                  //if (props.projectBreakdown && props.projectBreakdown.productFormatPresentation && props.projectBreakdown.productFormatPresentation !== value) {
                  if (value) {
                    props.projectBreakdown.productFormatPresentation = value;
                  } else {
                    props.projectBreakdown.productFormatPresentation = null;
                  }
                  // props.handleFormChange(
                  //   props.projectBreakdown,
                  //   true
                  // );
                }

                }
                readOnly={props.readOnly}
              />
            </div>
            {/* </Stack.Item>
            </Stack> */}
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <Separator/>
        <Stack
          styles={stackStyles}
          tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                props.saveFormData();
              }}
              readOnly={props.readOnly}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

    </div>
  );
};


export default ProductDetail;
