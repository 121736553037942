import {useBoolean, useId} from '@uifabric/react-hooks';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SortableTree, {
  getFlatDataFromTree,
  getTreeFromFlatData,
  toggleExpandedForAll
} from '@nosferatu500/react-sortable-tree';
import i18n from '../../../i18n';
import {uPrinceTheme} from '../../../theme';
// import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
// import FileExplorerTheme from 'react-sortable-tree-theme-solverboard';
// react-sortable-tree-list-view.css
import './react-sortable-tree-list-view.css';
import '@nosferatu500/react-sortable-tree/style.css';

const theme = getTheme();
// Styles definition
const stackStyles: IStackStyles = { root: { padding: 0 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const editWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const editIconWhite: IIconProps = {
  iconName: 'Edit',
  styles: editWhiteIconButtonStyles,
};


const SortableTreeListView = (props: {
  treeData: any;
  customNodeRender?: any,
  customNodeLevel?: any,
  treeHeader?: any,
  handleTitleClick?: any,
}) => {
  const [treeData, setTreeData]: any = useState([]);
  const [, setFlatTreeData]: any = useState([]);
  const [searchString, setSearchString]: any = useState('');
  const [searchFocusIndex, setSearchFocusIndex]: any = useState(0);
  const [searchFoundCount, setSearchFoundCount]: any = useState(null);


  useEffect(() => {
    const tData = getTreeFromFlatData({
      flatData: props.treeData,
      getKey: (node: any) => node.id,
      getParentKey: (node: any) => node.parentId,
      // @ts-ignore
      rootKey: null,
    });
    // console.log("treeData row data",props.treeData);
    // console.log("treeData tree data",tData);
    setTreeData(toggleExpandedForAll({
      treeData: tData,
      expanded: true,
    }));
    // setSelectedItem(selectItemId);
  }, [props.treeData]);


  useEffect(() => {
    const fData = getFlatDataFromTree({
      treeData: treeData,
      getNodeKey: (node: any) => node.id,

    });
    setFlatTreeData(fData);
  }, [treeData]);


  const expand = (expanded: any) => {
    setTreeData(
      toggleExpandedForAll({
        treeData: treeData,
        expanded,
      }),
    );
  };

  const expandAll = () => {
    expand(true);
  };

  const collapseAll = () => {
    setSearchString(null);
    expand(false);
  };


  const handleNodeClick = () => {
    // alert(JSON.stringify(rowInfo));
    // const { node,path } = rowInfo;
    // setSelectedPath(path);
    // setSelectedNode(node);
    // setChildLevelName(node.childLevel);
    // if (!node.children) {
    //   props.onSelectItem(node.id);
    // }
  };
  // Case insensitive search of `node.title`
  const customSearchMethod = ({ node, searchQuery }: any) => {
    return searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  };


  const handleTreeOnSearch = (searchString: string) => {
    setSearchString(searchString);
  };

  const selectPrevMatch = () => {
    let searchFoundCountVal = 0;
    let searchFocusIndexValue = 0;
    if (typeof searchFoundCount === 'number') {
      searchFoundCountVal = searchFoundCount;
    }
    if (typeof searchFocusIndex === 'number') {
      searchFocusIndexValue = searchFocusIndex;
    }

    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCountVal + searchFocusIndexValue - 1) %
        searchFoundCountVal
        : searchFoundCountVal - 1,
    );
  };


  const selectNextMatch = () => {
    let searchFoundCountVal = 0;
    if (typeof searchFoundCount === 'number') {
      searchFoundCountVal = searchFoundCount;
    }

    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFocusIndex + 1) % searchFoundCountVal
        : 0,
    );
  };

  // model////////
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(true);
  const [isOpen, setIsOpen] = useState(false);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      // minWidth: screen.width > 1280?"40vw":"50vw",
      // minHeight: screen.width > 1280?"60vh":"75vh",
      // height: screen.width > 1280?"80vh":"75vh",
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };

  const removePadding = () => {
    const rstcustom__nodeContent = document.getElementsByClassName('rstcustom__nodeContent');
    for (let i = 0; i < rstcustom__nodeContent.length; i++) {
      const nodeContentElement = rstcustom__nodeContent[i];
      if (nodeContentElement.querySelectorAll('.lastNode').length > 0) {
        // nodeContentElement.style.paddingLeft="1px";
      }
    }
  };


  const onRenderTitle=(rowInfo:any)=>{
    const { path, node } = rowInfo;
    if(Array.isArray(props.customNodeLevel) && rowInfo.node && props.customNodeLevel.includes(rowInfo.node?.displayOrder) && props.customNodeRender){
      return props.customNodeRender(node);
    }else{
      return (rowInfo.node && rowInfo.node?.displayOrder === props.customNodeLevel && props.customNodeRender) ? props.customNodeRender(node) :
        <Label onClick={() => {
          props?.handleTitleClick ? props.handleTitleClick(node) : () => {
          };
        }}>{(!node?.children) ? <li className={'taxonomyDot'}>{node.title}</li> : node.title}</Label>
    }
  }

  return (
    <div
      style={{ height: screen.width > 1280 ? '65vh' : '60vh', marginBottom: 10 }}
      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
    >
      <div className="row">
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={expandAll}>
              {t('expandAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={collapseAll}>
              {t('collapseAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={3} styles={stackItemStyles}>
            <TextField
              value={searchString}
              placeholder={i18n.t('search')}
              onChange={(event, value) => {
                if (value) {
                  handleTreeOnSearch(value);
                } else {
                  handleTreeOnSearch('');
                }
              }}
            />
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton
              style={{ minWidth: 25 }}
              disabled={!searchFoundCount}
              onClick={selectPrevMatch}
            >
              {' '}
              &lt;
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton
              style={{ minWidth: 25 }}
              disabled={!searchFoundCount}
              onClick={selectNextMatch}
            >
              &gt;
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
                        <span style={{ marginTop: 6 }}>
              &nbsp;
                          {searchFoundCount
                            ? searchFoundCount > 0
                              ? searchFocusIndex + 1
                              : 0
                            : 0}
                          &nbsp;/&nbsp;
                          {searchFoundCount || 0}
                        </span>
          </Stack.Item>
        </Stack>
      </div>
      <div className="row">
        {props.treeHeader && <div style={{ width: '100%' }} className={'treeHeader'}>
          {props.treeHeader}
        </div>}
      </div>

      <label htmlFor="find-box"></label>
      <SortableTree
        canDrag={({}) => false}
        canDrop={() => false}
        searchQuery={searchString}
        searchMethod={customSearchMethod}
        searchFocusOffset={searchFocusIndex}
        searchFinishCallback={(matches) => {
          setSearchFoundCount(matches.length);
          setSearchFocusIndex(
            matches.length > 0 ? searchFocusIndex % matches.length : 0,
          );
        }}
        // isVirtualized={true}
        treeData={treeData}
        onChange={(treeData) => {
          setTreeData(treeData);
        }}
        onlyExpandSearchedNodes={true}
        theme={FileExplorerTheme}
        rowHeight={41}
        generateNodeProps={(rowInfo: any) => {
          const { path, node } = rowInfo;
          //console.log('node', node);
          return {
            buttons: [
              <div>
                { /* {(rowInfo.node &&rowInfo.node?.level==='5')?<div className="lastNode"></div>:false}*/}
                { /* <Label> {(rowInfo.node &&rowInfo.node.parentId!=null && rowInfo.node.children && rowInfo.node.children.length<=0)?"last node":rowInfo.node.parentId}</Label>*/}
              </div>,
            ],
            title: [
              <div style={{ paddingLeft: 5 }}>
                {onRenderTitle(rowInfo)}
                { /* {(rowInfo.node &&rowInfo.node?.level==='5')?<div className="lastNode"></div>:false}*/}
                { /* <Label> {(rowInfo.node &&rowInfo.node.parentId!=null && rowInfo.node.children && rowInfo.node.children.length<=0)?"last node":rowInfo.node.parentId}</Label>*/}
              </div>,
            ],
          };
        }}
      />
    </div>
  );
};

export default SortableTreeListView;
