import React, { useEffect, useState } from 'react';
import { Label, Link } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import DataGridWithModal, { ModalGridContext } from '../../../shared/dataGridModal/component';
import { useSelector } from 'react-redux';


const DynamicAttributes=()=>{
  const { t } = useTranslation();
  const [dynamicAttributes,setDynamicAttributes]: any = useState([]);
  const projectBreakdown = useSelector(
    (state: any) => state.projectBreakdown.formData
  );

  useEffect(()=>{
    if( projectBreakdown?.dynamicAttributes){
      console.log("dynamicAttributes",projectBreakdown?.dynamicAttributes)
      const dynamicAttr = projectBreakdown?.dynamicAttributes;


      let list=[];
      if(dynamicAttr?.key1){
        list.push({key:dynamicAttr?.key1,value:dynamicAttr?.value1,articleNumber:dynamicAttr?.articleNumber,keyId:'key1',valueId:'value1'});
      }
      if(dynamicAttr?.key2){
        list.push({key:dynamicAttr?.key2,value:dynamicAttr?.value2,articleNumber:dynamicAttr?.articleNumber,keyId:'key2',valueId:'value2'});
      }
      if(dynamicAttr?.key3){
        list.push({key:dynamicAttr?.key3,value:dynamicAttr?.value3,articleNumber:dynamicAttr?.articleNumber,keyId:'key3',valueId:'value3'});
      }
      if(dynamicAttr?.key4){
        list.push({key:dynamicAttr?.key4,value:dynamicAttr?.value4,articleNumber:dynamicAttr?.articleNumber,keyId:'key4',valueId:'value4'});
      }
      if(dynamicAttr?.key5){
        list.push({key:dynamicAttr?.key5,value:dynamicAttr?.value5,articleNumber:dynamicAttr?.articleNumber,keyId:'key5',valueId:'value5'});
      }
      setDynamicAttributes(list)
    }else{
      setDynamicAttributes([])
    }
  },[projectBreakdown]);

  let   DynamicAttributesColumns = [
    {
      key: 'column1',
      name: t('key'),
      fieldName: 'key',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('Value'),
      fieldName: 'value',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
  ];

  const renderModalContent=()=>{

  };


  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="dynamic-attributes">
          <Label>3. {t('dynamicAttributes')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <DataGridWithModal
            dataList={ dynamicAttributes?dynamicAttributes:[]}
            modalTitle={t('contractors')}
            deleteDataGrid={async (selection: any) => {
              let contractorList = [];

            }}
            title={`3.1 ${t('dynamicAttributes')}`}
            readOnly={true}
            columns={DynamicAttributesColumns}
            isLoaded={false}
            keyProp={'dynamicAttributes'}
            onDismiss={() => {

            }}
          >
            <ModalGridContext.Consumer>
              {(modalProps: any) => (
               <div>
                 {JSON.stringify(modalProps?.editItem)}
               </div>
              )}
            </ModalGridContext.Consumer>
          </DataGridWithModal>
        </div>
      </div>
   </div>
  )
};

export default DynamicAttributes
