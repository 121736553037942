import {
  AnimationStyles,
  ContextualMenuItemType,
  FontWeights,
  getTheme,
  Icon,
  IIconProps,
  mergeStyles,
  mergeStyleSets,
  Pivot,
  PivotItem,
  PrimaryButton,
} from '@fluentui/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../theme';
import history from '../../history';
import { messageService } from '../../services/messageService';
import { AccessLevel, CU_FEATURES, ORG_FEATURS } from '../../types/permissions';
import MenuCard from '../menuCard/menu/component';
import { getContractingUnit, hasPermissions } from '../util';
import './navBar.css';
import ENV from '../../../env.json';

const theme = getTheme();

const pivotStyle = {
  root: {
    maxWidth: '91vw',
    float: 'left',
    selectors: { '& .is-selected i': { color: iconColors.iconActiveColor } },
  },
  icon: { color: uPrinceTheme.palette.themePrimary },
  linkIsSelected: { selectors: { ':before': { marginBottom: '0px' } } },
};

class CUNavBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      redirect: false,
      redirectTo: '',
      isCalloutVisible: false,
      showNewButton: false,
    };
  }

  subscription: any;
  addWhiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: this.addWhiteIconButtonStyles,
  };
  styles = mergeStyleSets({
    buttonArea: {
      textAlign: 'center',
      margin: '0 0px',
      minWidth: 32,
      height: 32,
    },
    newButtonArea: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    callout: { maxWidth: 300 },
    header: { padding: '18px 24px 12px' },
    title: [
      uPrinceTheme.fonts.xLarge,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    inner: {
      height: '100%',
      padding: '0 24px 20px',
    },
    actions: {
      position: 'relative',
      marginTop: 20,
      width: '100%',
      whiteSpace: 'nowrap',
    },
    subtext: [
      uPrinceTheme.fonts.small,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    link: [
      uPrinceTheme.fonts.medium,
      { color: uPrinceTheme.palette.neutralPrimary },
    ],
  });

  menuProps = () => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'newItem', text: 'New', onClick: () => {
        },
      },
      { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
      {
        key: 'rename', text: 'Rename', onClick: () => {
        },
      },
      {
        key: 'edit', text: 'Edit', onClick: () => {
        },
      },
      {
        key: 'properties', text: 'Properties', onClick: () => {
        },
      },
    ],
  });

  handelRedirect = (item: any) => {
    history.push(item);
  };

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.shortCutPaneExpanded) {
          this.setState({ showNewButton: true });
        } else if (
          data.data.hasOwnProperty('shortCutPaneExpanded') &&
          !data.data.shortCutPaneExpanded
        ) {
          this.setState({ showNewButton: false });
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  onIconClick = () => {
    if (this.props.contractingUnit) {
      history.push(`/CU/${this.props.contractingUnit.title}`);
    }
  };

  toggleIsCalloutVisible = () => {
    this.setState({ isCalloutVisible: !this.state.isCalloutVisible });
  };

  render() {
    const animation = mergeStyles(
      this.state.showNewButton
        ? AnimationStyles.slideRightIn400
        : AnimationStyles.slideLeftIn400,
    );
    let cu = '';
    if (
      window.location.pathname &&
      window.location.pathname.split('/').length > 0
    ) {
      cu = window.location.pathname.split('/')[2];
    }
    const { t } = this.props;
    const hasCUPermission = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).hasContractingUnitPermission;
    const isProjectManager = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).isProjectManager;
    const isPlanningManager = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).isPlanningManager;
    const isHRManager = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).isHRManager;

    const isBackOfficer = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).isBackOfficer;

    const isCUTenderManager = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).isCUTenderManager;

    return (
      <div className="navBar">
        <div className={this.styles.buttonArea}>
          <Icon
            iconName="ViewAll"
            className="navicon"
            onClick={() => {
              if (!ENV.DISABLE_MENU_CARD) {
                this.toggleIsCalloutVisible();
              }
            }}
          />
        </div>
        {this.state.showNewButton && ENV.ENABLE_NAV_BAR_BUTTON && (
          <div>
            <PrimaryButton
              iconProps={this.addIconWhite}
              text={t('new')}
              menuProps={this.menuProps()}
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginRight: 35,
                height: 30,
              }}
              onClick={() => {
              }}
            />
          </div>
        )}
        {!ENV.ENABLE_NAV_BAR_BUTTON && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              marginRight: 140,
              height: 30,
            }}
          ></div>
        )}
        <div>
          <Pivot
            selectedKey={this.props.selectedKey}
            defaultSelectedKey={
              !hasPermissions(this.props.currentUser?.groups).isProjectManager
                ? 0
                : this.props.selectedKey
            }
            styles={pivotStyle}
            onLinkClick={(item) => {
              item ? this.handelRedirect(item.props.itemKey) : null;
            }}
          >
            {ENV.CU_FEATURES.includes(CU_FEATURES.PROJECT) && (
              <PivotItem
                itemKey={`/CU/${cu}`}
                headerText={t('project')}
                itemIcon="ProjectLogoInverse"
              ></PivotItem>
            )}
            {hasCUPermission && isHRManager &&
            ENV.CU_FEATURES.includes(CU_FEATURES.TIME_CLOCK) && (
              <PivotItem
                itemKey={`/CU/${cu}/time_clock`}
                headerText={t('timeClock')}
                itemIcon="Clock"
              ></PivotItem>
            )}
            {hasCUPermission &&
            ENV.CU_FEATURES.includes(CU_FEATURES.QR_CODE) && (
              <PivotItem
                itemKey={`/CU/${cu}/qr-code`}
                headerText={t('qRCode')}
                itemIcon="QrCode"
              ></PivotItem>
            )}
            {hasCUPermission && ENV.CU_FEATURES.includes(CU_FEATURES.CPC) && (
              <PivotItem
                itemKey={`/CU/${cu}/product-catalogue`}
                headerText={t('corporateProductCatalogue')}
                itemIcon="ProductCatalog"
              ></PivotItem>
            )}
            {hasCUPermission && isPlanningManager && ENV.CU_FEATURES.includes(CU_FEATURES.VP) && (
              <PivotItem
                itemKey={`/CU/${cu}/cu-project-planning`}
                headerText={t('longTermPlanning')}
                itemIcon="StackedBarChart"
              ></PivotItem>
            )}
            {hasCUPermission && isBackOfficer && ENV.CU_FEATURES.includes(CU_FEATURES.VP) && (
              <PivotItem
                itemKey={`/CU/${cu}/visual-plan`}
                headerText={t('visualPlanning')}
                itemIcon="BarChartHorizontal"
              ></PivotItem>
            )}
            {/* { hasCUPermission &&
                            <PivotItem
                                itemKey={ `/CU/${cu}/organisation-cbc` }
                                headerText={ t('cbc') }
                                itemIcon="BarChartHorizontal"
                            ></PivotItem>
                        } */}
            {hasCUPermission && isPlanningManager && ENV.CU_FEATURES.includes(CU_FEATURES.DP) && (
              <PivotItem
                itemKey={`/CU/${cu}/cu-my-calendar`}
                headerText={t('myCalendar')}
                itemIcon="CalendarSettings"
              ></PivotItem>
            )}
            {hasCUPermission && isBackOfficer && ENV.CU_FEATURES.includes(CU_FEATURES.PO) && (
              <PivotItem
                itemKey={`/CU/${cu}/purchase-order`}
                headerText={t('purchaseOrder')}
                itemIcon="ActivateOrders"
              ></PivotItem>
            )}
            {hasCUPermission && isBackOfficer && ENV.CU_FEATURES.includes(CU_FEATURES.PS) && (
              <PivotItem
                itemKey={`/CU/${cu}/progress-statement`}
                headerText={t('progressStatement')}
                itemIcon="TableGroup"
              ></PivotItem>
            )}
            {hasCUPermission &&
            ENV.CU_FEATURES.includes(CU_FEATURES.INVOICE) && (
              <PivotItem
                itemKey={`/CU/${cu}/invoice`}
                headerText={t('invoice')}
                itemIcon="M365InvoicingLogo"
              ></PivotItem>
            )}
            {hasCUPermission && isCUTenderManager && ENV.CU_FEATURES.includes(CU_FEATURES.SM) && (
              <PivotItem
                itemKey={`/CU/${cu}/standard-mail`}
                headerText={t('standardmail')}
                itemIcon="EditMail"
              ></PivotItem>
            )}
            {/* { isProjectManager &&
                            <PivotItem
                                itemKey={ `/CU/${cu}/purchase-order` }
                                headerText={ t('purchaseOrder') }
                                itemIcon="M365InvoicingLogo"
                            ></PivotItem>
                        } */}
            {/* { isProjectManager &&
                            <PivotItem
                                itemKey={ `/CU/${cu}/warehouse` }
                                headerText={ t('warehouse') }
                                itemIcon="M365InvoicingLogo"
                            ></PivotItem>
                        } */}
            {/* <PivotItem
                            itemKey={ `/CU/${cu}/template` }
                            headerText={ t('template') }
                            itemIcon="PageList"
                        ></PivotItem> */}
            {hasCUPermission && ENV.CU_FEATURES.includes(CU_FEATURES.STOCK) && (
              <PivotItem
                itemKey={`/CU/${cu}/stock`}
                headerText={t('stock')}
                itemIcon="Archive"
              ></PivotItem>
            )}

            {hasCUPermission && ENV.CU_FEATURES.includes(CU_FEATURES.STOCK) && (
              <PivotItem
                itemKey={`/CU/${cu}/wbs-create`}
                headerText={t('wbsCreate')}
                itemIcon="ExternalTFVC"
              ></PivotItem>
            )}

            {hasCUPermission && ENV.CU_FEATURES.includes(CU_FEATURES.WBS) && (
              <PivotItem
                itemKey={`/CU/${cu}/wbs-cu-task`}
                headerText={t('task')}
                itemIcon="Stopwatch"
              ></PivotItem>
            )}

            {hasCUPermission && ENV.CU_FEATURES.includes(CU_FEATURES.INSTRUCTION) && (
              <PivotItem
                itemKey={`/CU/${cu}/cu-instruction-register`}
                headerText={t('instruction')}
                itemIcon="AccountActivity"
              ></PivotItem>
            )}

            {hasCUPermission &&
              ENV.CU_FEATURES.includes(CU_FEATURES?.CBC) && (
                <PivotItem
                  itemKey={`/CU/${cu}/organisation-cbc`}
                  headerText={t('cbc')}
                  itemIcon="ProductCatalog"
                ></PivotItem>
              )}
          </Pivot>
        </div>
        <MenuCard
          isMenuCardVisible={this.state.isCalloutVisible}
          className={this.styles.callout}
          target={this.styles.buttonArea}
          onDissmissed={() => this.toggleIsCalloutVisible()}
          accessLevel={AccessLevel.CONTRACTING_UNIT}
          currentUser={this.props.currentUser}
          organization={this.props.organization}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { organisation: state.uprince.organisation };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CUNavBar));
