import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    filterCiawListPaneData,
    readCabCertificationCIAW,
    readCIAWById,
    saveCIAWStateAttr
} from '../../../reducers/ciawReducer'
import {useTranslation} from 'react-i18next';
import {
    DetailsListLayoutMode,
    DetailsRow,
    DirectionalHint,
    Dropdown,
    FontIcon,
    IColumn,
    Icon,
    IDetailsFooterProps,
    IDetailsListProps,
    IDetailsRowStyles,
    ITooltipHostStyles,
    registerIcons,
    ScrollablePane,
    ScrollbarVisibility,
    Selection,
    SelectionMode,
    ShimmeredDetailsList,
    Sticky,
    StickyPositionType,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost,
} from '@fluentui/react';
import {SvgIcons} from '../../../shared/svgIcons/svgIcons';
import {classNames} from "../../contract/listPane/component";
import CustomDatePicker from "../../../shared/customDatePicker/customDatePicker";
import {CiawFilterData, CiawListItem} from '../../../types/ciaw';
import moment from "moment";
import _ from 'lodash';
import {getTomorrow} from "../../../shared/util";
import {useId} from "@uifabric/react-hooks";

registerIcons(SvgIcons);
const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block'}};

const ListPaneComponent = () => {
    const dispatch = useDispatch();
    // const store = useStore();
    const {t} = useTranslation();
    const tooltipId = useId('tooltip');

    let columns = [
        {
            key: 'column0',
            name: t("date"),
            fieldName: 'date',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: false,
            onColumnClick: _onColumnClick,
        },
        {
            key: 'column1',
            name: t("employee"),
            fieldName: 'cabPersonName',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: false,
            onColumnClick: _onColumnClick,
        },
        {
            key: 'column2',
            name: t("title"),
            fieldName: 'title',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: _onColumnClick,
        },
        {
            key: 'column3',
            name: t('eligibility'),
            fieldName: 'isCiawEligible',
            minWidth: 60,
            maxWidth: 80,
            isResizable: true,
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                switch (item?.isCiawEligible) {
                    case "1":
                        return <span className="ciaw-eligibility-list-column"><Icon
                          style={{fontSize: 8, color: "#07AA6B", display: 'flex', justifyContent: 'center'}}
                          iconName="CircleFill"/> </span>;
                    case "2":
                        return <span className="ciaw-eligibility-list-column"><Icon
                          style={{fontSize: 8, color: "#EB5757", display: 'flex', justifyContent: 'center'}}
                          iconName="CircleFill"/> </span>;
                    case "3":
                        return <span className="ciaw-eligibility-list-column"><Icon
                          style={{fontSize: 8, color: "#eb9557", display: 'flex', justifyContent: 'center'}}
                          iconName="CircleFill"/> </span>;
                    default:
                        return <div>
                            <Dropdown
                              // placeholder={t('all')}
                              options={eligibilityOptions}
                              // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
                              onChange={(event, value) => {
                                  setFilter((prevState: any) => ({
                                      ...prevState,
                                      isCiawEligible: value?.key ? value.key : null,
                                  }));
                              }}
                              onRenderOption={onRenderOption}
                              onRenderTitle={onRenderTitle}
                            />
                        </div>;
                }
            },
        },
        {
            key: 'column4',
            name: t('warning'),
            fieldName: 'remark',
            minWidth: 60,
            maxWidth: 80,
            isResizable: true,
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                if (item?.remark && item?.remark.length > 0) {
                    return <div
                      style={{
                          margin: 5,
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center'
                      }}>
                        <TooltipHost
                          delay={TooltipDelay.medium}
                          id={tooltipId}
                          directionalHint={DirectionalHint.bottomCenter}
                          styles={hostStyles}
                          content={item?.remark?.map((item: any) => {
                              return <li>{item}</li>
                          })}
                        >
                            <FontIcon
                              aria-label="Warning"
                              iconName="Warning"
                            />
                        </TooltipHost>
                    </div>
                } else {
                    return <span></span>
                }
            }
        },
        {
            key: 'column5',
            name: t("ciawStatus"),
            fieldName: 'ciawStatus',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: _onColumnClick,
        },
    ];

    const eligibilityOptions = [
        {key: '0', text: t('all'), data: {color: '#000000'}},
        {key: '2', text: t('2'), data: {color: '#EB5757'}},
        {key: '3', text: t('3'), data: {color: '#eb9557'}},
        {key: '1', text: t('1'), data: {color: '#07AA6B'}},
    ];

    const onRenderOption = (eligibilityOptions: any): JSX.Element => {
        return (
          <div>
              {eligibilityOptions.key && (
                <Icon
                  style={{paddingLeft: 23, color: eligibilityOptions.data.color}}
                  iconName={eligibilityOptions.key == '0' ? 'CircleRing' : 'CircleFill'}
                  aria-hidden='true'/>
              )}
          </div>
        );
    };

    const onRenderTitle = (eligibilityOptions: any): JSX.Element => {
        const eligibilityOption = eligibilityOptions[0];

        return (
          <div>
              {eligibilityOption.key && (
                <Icon style={{paddingLeft: 23, color: eligibilityOption.data.color}}
                      iconName={eligibilityOption.key == '0' ? 'CircleRing' : 'CircleFill'}
                      aria-hidden='true'/>
              )}
          </div>
        );
    };

    function _onColumnClick(
      ev: React.MouseEvent<HTMLElement>,
      column: IColumn,
    ): void {
        sortedColumns.map((item: any) => {
            if (item.key === column.key) {
                item.isSorted = true;
                item.isSortedDescending = !item.isSortedDescending;

                let sortBy = item.isSortedDescending ? 'desc' : 'asc';
                setFilter((prevState: any) => ({
                    ...prevState,
                    sorter: {
                        attribute: item.fieldName ? item.fieldName : null,
                        order: sortBy ? sortBy : null
                    }
                }));

            } else {
                item.isSorted = false;
                item.isSortedDescending = true;
            }
        });
        setSortedColumns(sortedColumns);
    }

    // const {
    //     userInfo
    // } = useSelector(
    //   (state: any) => state.uprince

    const [filter, setFilter] = useState<any>(new CiawFilterData);
    const [selectionDetails, setSelectionDetails]: any = React.useState<any>(null);
    const [sortedColumns, setSortedColumns] = useState<any>(columns);
    // );

    const {
        ciawListData,
        ciawDropdownData,
        timeClockSelectedDate,
        ciawFilterData,
        ciawSendResult,
        ciawCancelResult,
    } = useSelector(
      (state: any) => state.ciaw
    );

    const _gridSelection = new Selection({
        onSelectionChanged: () => {
            setSelectionDetails(_gridSelection.getSelection());
            dispatch(saveCIAWStateAttr('selectedCIAWListItems', _gridSelection.getSelection()));
        }
    });

    useEffect(() => {
        // setFilter(new CiawFilterData()); NOTE: this isn't necessary, but don't remove this line
        dispatch(saveCIAWStateAttr('timeClockSelectedDate', moment(getTomorrow()).format('YYYY-MM-DD')));

        return () => {
            dispatch(saveCIAWStateAttr('ciawListData', []));
        }
    }, []);

    useEffect(() => {
        if (selectionDetails) {
            dispatch(saveCIAWStateAttr('selectedCiaw', selectionDetails));
        }
    }, [selectionDetails])

    useEffect(() => {
        if (ciawSendResult || ciawCancelResult) {
            Promise.all([dispatch(filterCiawListPaneData(filter))]).then(() => {
                dispatch(saveCIAWStateAttr('ciawSendResult', null));
                dispatch(saveCIAWStateAttr('ciawCancelResult', null));
            });
        }
    }, [ciawSendResult, ciawCancelResult]);

    useEffect(() => {
        dispatch(filterCiawListPaneData(filter));   //get ListPane filters
        // console.log('ciaw filter> ', filter);
    }, [filter]);


    useEffect(() => {
        // console.log(ciawFilterData)
        setFilter((prevState: any) => ({
            ...prevState,
            date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY-MM-DD') : undefined,
            ciawStatus: ciawFilterData.ciawStatus,
        }));
    }, [timeClockSelectedDate, ciawFilterData]);

    const _renderFilterItemColumn = (
      item?: CiawListItem,
      index?: number,
      column?: IColumn,
    ) => {
        if (column?.key === 'column0') {
            {
                return (
                  <div>
                      <CustomDatePicker
                        setValue={timeClockSelectedDate ? new Date(timeClockSelectedDate) : null}
                        getValue={(date: Date) => {
                            if (date) {
                                setFilter((prevState: any) => ({
                                    ...prevState,
                                    date: date ? moment(date).format('YYYY-MM-DD') : null
                                }));
                                dispatch(saveCIAWStateAttr('timeClockSelectedDate', date));
                            }
                        }}
                        isClearable={true}
                        onDateClear={
                            () => {
                                setFilter((prevState: any) => ({
                                    ...prevState,
                                    date: null
                                }));
                                dispatch(saveCIAWStateAttr('timeClockSelectedDate', null));
                            }
                        }
                      />
                  </div>
                );

            }
        } else if (column?.key === 'column1') {
            {
                return (
                  <div>
                      <TextField
                        value={filter?.cabPerson ? filter?.cabPerson : null}
                        onChange={(event, value) => {
                            setFilter((prevState: any) => ({
                                ...prevState,
                                cabPerson: value ?? null
                            }));
                        }}
                      />
                  </div>
                );

            }
        } else if (column?.key === 'column2') {
            {
                return (
                  <div>
                      <TextField
                        value={filter?.project ? filter?.project : null}
                        onChange={(event, value) => {
                            setFilter((prevState: any) => ({
                                ...prevState,
                                project: value ?? null
                            }));
                        }}
                      />
                  </div>
                );

            }
        } else if (column?.key === 'column3') {
            {
                return (
                  <div>

                  </div>
                );

            }
        } else if (column?.key === 'column4') {
            {
                return <span></span>;
            }
        } else if (column?.key === 'column5') {
            {
                return (
                  <div>
                      <Dropdown
                        placeholder={t('all')}
                        options={formatToDropDownList(ciawDropdownData?.status)}
                        selectedKey={filter?.ciawStatus ? filter?.ciawStatus : null}
                        onChange={(event, value) => {
                            setFilter((prevState: any) => ({
                                ...prevState,
                                ciawStatus: value?.key ?? null
                            }));
                        }}
                      />
                  </div>
                );

            }
        } else {
            {

            }
        }
    };

    const formatToDropDownList = (dropdown: any) => {
        const allCol = [{key: 0, text: 'All'}];
        if (dropdown) {
            return allCol.concat(dropdown);
        }
        return [];
    };

    const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (row) {
            return <span
              style={{textDecoration: 'none'}}
              onClick={() => {
                  dispatch(readCIAWById(row?.item?.id));
                  dispatch(readCabCertificationCIAW({
                      personId: row?.item?.cabPersonId,
                      date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
                  }));
              }}>
                <DetailsRow {...row} styles={customStyles}/>
            </span>;
        }
        return null;
    };

    const _onRenderDetailsFooter = (
      detailsFooterProps: IDetailsFooterProps | undefined,
    ) => {
        return (
          <DetailsRow
            className="footer-as-filter"
            {...detailsFooterProps}
            columns={detailsFooterProps?.columns}
            item={{}}
            itemIndex={-1}
            groupNestingDepth={detailsFooterProps?.groupNestingDepth}
            selection={detailsFooterProps?.selection}
            onRenderItemColumn={_renderFilterItemColumn}
            // onRenderCheck={_onRenderCheckForFooterRow}
          />
        );
    };

    const _renderItemColumn = (item: any, index?: number, column?: IColumn) => {
        // if (column && column.fieldName && column.fieldName === 'title') {
        //     return <Link to={'#'} onClick={(e) => {
        //         e.preventDefault();
        //         dispatch(readCIAWById(item?.id));
        //         dispatch(readCabCertificationCIAW({
        //             personId: item?.cabPersonId,
        //             date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
        //         }));
        //         setSelectionDetails([item]);
        //         // dispatch(saveCIAWStateAttr('selectedCiaw', [item]));
        //     }}>{item[column.fieldName]}</Link>;
        // } else
        if (column && column.fieldName) {
            const fieldContent = item[column.fieldName];
            return <span style={{paddingLeft: 9}} onClick={(e) => {
                e.preventDefault();
                setSelectionDetails([item]);
            }}>{fieldContent}</span>;
        } else {
            return <span style={{paddingLeft: 9}}>-</span>;
        }
    }

    const renderListPane = () => {
        return <div className={`wrapper-holder ${classNames.wrapper}`}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <ShimmeredDetailsList
                  items={ciawListData}
                  compact={true}
                  columns={sortedColumns}
                  setKey="set"
                  enableShimmer={false}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={1}
                  onRenderRow={_onRenderRow}
                  onRenderDetailsFooter={_onRenderDetailsFooter}
                  selection={_gridSelection}
                  selectionMode={SelectionMode.multiple}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  onRenderItemColumn={(
                    item: any,
                    index?: number,
                    column?: IColumn,
                  ) =>
                    _renderItemColumn(
                      item,
                      index,
                      column,
                    )
                  }
                  onRenderDetailsHeader={(headerProps, defaultRender) => {
                      if (defaultRender !== undefined) {
                          return (
                            <Sticky
                              stickyPosition={StickyPositionType.Header}
                              isScrollSynced={true}
                              stickyBackgroundColor="transparent"
                            >
                                <div>{defaultRender(headerProps)}</div>
                            </Sticky>
                          );
                      } else {
                          return <span></span>;
                      }
                  }}
                />
                {ciawListData && _.isEmpty(ciawListData) &&
                    <div style={{paddingTop: 28, paddingLeft: 20}}><Text>{`${t("noCiawDataAvailable")}`}</Text></div>
                }
            </ScrollablePane>
        </div>
    }

    return <div>
        <div className={'pdp'} style={{width: '100%', height: '100%'}}>
            <div className={'project-day_planing'}
                 style={{width: '100%', height: screen.height, overflow: 'overlay'}}> {renderListPane()}</div>
        </div>
    </div>
};

export default ListPaneComponent;