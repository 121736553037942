import * as React from 'react';
import { DetailsRow, IColumn, IDetailsListProps, IDetailsRowStyles, Selection } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import {
  ScrollablePane,
  ScrollbarVisibility,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { _renderFilterItemColumn } from './filterHeader';
import { connect } from 'react-redux';
import TableColumn from '../../shared/tableColumn/tableColumn';
import { getContractingUnit } from '../../shared/util';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 264px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

export interface TimeClockListItem {
  value: string;
  startDate: string;
  endDate: string;
  userName: string;
  status: string;
}

export interface State {
  items: TimeClockListItem[];
  redirect: boolean;
  selectionDetails: any;
  selectedTimeClockId: any;
  loadMsg: any;
  announcedMessage?: string;
  filterEmployee: string | null;
  filterTimeClockState: string | number | null;
  filterStartDateTime: string | Date | null;
  filterEndDateTime: string | Date | null;
  isDataLoaded?: boolean;
}

interface Props extends WithTranslation {
  allTimeClocks: [];
  handleTimeClockListUpdate: (value: string | number | null) => void;
  handleResetFilter: (value: string | boolean | null) => void;
  resetFilter: boolean;
  handleTimeClockListFiltering: (value: any) => void;
  handleShiftListSorting: (fieldName?: string | null, sortBy?: string | null) => void;
  updateTimeClock: (value: string | number | null) => void;
  loadMsg: string;
  timeClockState: [];
  isDataLoaded?: boolean;
}

class TimeClockList extends React.Component<Props, State> {

  private _selection: Selection;
  private _allItems: TimeClockListItem[];
  private _columns: IColumn[];

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails('all'),
          selectedTimeClockId: this._getSelectionDetails('value'),
        });
      },
    });

    this._allItems = [];

    this._columns = [
      {
        key: 'column1',
        name: t('startDateTime'),
        fieldName: 'startDateTime',
        minWidth: 100,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
      },
      {
        key: 'column2',
        name: t('endDateTime'),
        fieldName: 'endDateTime',
        minWidth: 100,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        isPadded: true,
      },
      {
        key: 'column3',
        name: t('employee'),
        fieldName: 'userName',
        minWidth: 100,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
    ];

    this.state = {
      items: this._allItems,
      redirect: false,
      selectionDetails: {},
      selectedTimeClockId: null,
      loadMsg: '',
      filterEmployee: null,
      filterStartDateTime: null,
      filterEndDateTime: null,
      filterTimeClockState: null,
      isDataLoaded: false,
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.updateTimeClock) {
      nextProps.handleTimeClockListUpdate();
    }
    if (nextProps.resetFilter) {
      nextProps.handleResetFilter(false);
      return {
        filterEmployee: null,
        filterStartDateTime: null,
        filterEndDateTime: null,
        filterTimeClockState: null,
        loadMsg: nextProps.loadMsg,
        loading: false,
        isDataLoaded: nextProps.isDataLoaded,
      };
    } else {
      return {
        loadMsg: nextProps.loadMsg,
        loading: false,
        isDataLoaded: nextProps.isDataLoaded,
      };
    }

  }

  onChangeEmployee = (value: string | null) => {
    this.setState({
      filterEmployee: value,
    }, this.filterTimeClock);
  };

  onChangeState = (value: string | number | null) => {
    this.setState({
      filterTimeClockState: value,
    }, this.filterTimeClock);
  };

  onChangeStartDateTime = (value: string | Date | null) => {
    this.setState({
      filterStartDateTime: value,
    }, this.filterTimeClock);
  };

  onChangeEndDateTime = (value: string | Date | null) => {
    this.setState({
      filterEndDateTime: value,
    }, this.filterTimeClock);
  };

  private filterTimeClock = () => {
    if (this.state.filterEmployee != null && this.state.filterEmployee.length >= 3) {
      let data = {
        filterStartDateTime: this.state.filterStartDateTime,
        filterEndDateTime: this.state.filterEndDateTime,
        filterEmployee: this.state.filterEmployee,
        filterTimeClockState: (this.state.filterTimeClockState == '0') ? null : this.state.filterTimeClockState,
      };
      this.props.handleTimeClockListFiltering(data);
    } else if (this.state.filterEmployee == null || this.state.filterEmployee == '') {
      let data = {
        filterStartDateTime: this.state.filterStartDateTime,
        filterEndDateTime: this.state.filterEndDateTime,
        filterEmployee: this.state.filterEmployee,
        filterTimeClockState: (this.state.filterTimeClockState == '0') ? null : this.state.filterTimeClockState,
      };
      this.props.handleTimeClockListFiltering(data);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Fabric>
        <div className={`wrapper-holder ${classNames.wrapper}`}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <MarqueeSelection selection={this._selection}>
              <ShimmeredDetailsList
                items={this.props.allTimeClocks}
                columns={this._columns}
                setKey="set"
                compact={false}
                layoutMode={1}
                enableShimmer={!this.state.isDataLoaded}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={2}
                onRenderItemColumn={(
                  item: TimeClockListItem,
                  index?: number,
                  column?: IColumn,
                ) =>
                  TableColumn(
                    item,
                    index,
                    column,
                  )
                }
                onRenderRow={this._onRenderRow}
                onRenderDetailsFooter={(
                  detailsFooterProps,
                  defaultRender,
                ) => {
                  if (defaultRender !== undefined) {
                    return (
                      <DetailsRow
                        className="footer-as-filter"
                        {...detailsFooterProps}
                        columns={detailsFooterProps?.columns}
                        item={{}}
                        itemIndex={-1}
                        groupNestingDepth={
                          detailsFooterProps?.groupNestingDepth
                        }
                        selection={detailsFooterProps?.selection}
                        onRenderItemColumn={(
                          item?: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          _renderFilterItemColumn(
                            this.state.filterTimeClockState,
                            this.props.timeClockState,
                            this.onChangeState,
                            this.state.filterEmployee,
                            this.onChangeEmployee,
                            this.state.filterStartDateTime,
                            this.onChangeStartDateTime,
                            this.state.filterEndDateTime,
                            this.onChangeEndDateTime,
                            item,
                            index,
                            column,
                          )
                        }
                      />
                    );
                  } else {
                    return <span> </span>;
                  }
                }}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                      >
                        <div>{defaultRender(headerProps)}</div>
                      </Sticky>
                    );
                  } else {
                    return <span></span>;
                  }
                }}
              />
              {this.state.isDataLoaded &&
              <div style={{ paddingTop: 36, paddingLeft: 20 }}><Text>{`${t(this.props.loadMsg)}`}</Text></div>}
            </MarqueeSelection>
          </ScrollablePane>
        </div>

      </Fabric>
    );
  }

  private _getSelectionDetails(item: string): any {
    const selectionCount = this._selection.getSelectedCount();

    switch (item) {
      case 'value':
        return (this._selection.getSelection()[0] as TimeClockListItem)
          ? (this._selection.getSelection()[0] as TimeClockListItem).value
          : '';
      case 'startDate':
        return (this._selection.getSelection()[0] as TimeClockListItem).value;
      case 'all':
        return this._selection.getSelection()[0] as TimeClockListItem;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};

    if (props) {
      if (getContractingUnit()) {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            to={`/CU/${getContractingUnit()}/time_clock/${props.item.value}`}
          >
            <DetailsRow {...props} styles={customStyles}/>
          </Link>
        );
      } else {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            to={'/time_clock/' + props.item.value}
          >
            <DetailsRow {...props} styles={customStyles}/>
          </Link>
        );
      }
    }
    return null;
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { items } = this.state;
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
        let fieldName = currColumn.fieldName;
        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        this.props.handleShiftListSorting(fieldName, sortby);
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    timeClockState: state.timeClock.filterTimeClockState,
  };
};

export default withTranslation()(connect(mapStateToProps)(TimeClockList));
