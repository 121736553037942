import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react';
import React, {
 useEffect, useRef, useState
} from 'react';
import {
 useBoolean, useId
} from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { InstructionLink } from '../../../../types/projectBreakdownStructure';
import ProgressBar from '../../../../shared/progressBar/progressBar';

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit'
  },
  pane: {
    maxWidth: 400
  },
  textContent: {
    padding: '15px 10px'
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer'
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start'
      }
    }
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer'
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5
  },
  fullWidth: {
    width: '100%'
  }
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0
  }
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0
  }
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white'
    }
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px'
  }
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10
};

class LinkValidationMessages {
  typeValidationMsg: string = '';
  valueValidationMsg: string = '';
  titleValidationMsg: string = '';
}

const LinkModal = (props: {
  isOpen: boolean;
  openLinkModal: () => void;
  isEdit: boolean;
  instructionLink: InstructionLink;
  onLinkFileChange: any;
  link: string;
  onAddInstructionLink: (InstructionLink: InstructionLink) => void
}) => {
  const inputFile: any = useRef(null);
  const { t } = useTranslation();
  const [ isDraggable ] = useBoolean(true);
  const titleId = useId('title');
  const ref: any = useRef();
  const [ instructionLink, setLink ] = useState(new InstructionLink());
  const [ isProgress, setProgress ] = useState(false);
  const [ diableUpload, setDisableUpload ] = useState(false);
  const [ linkValidationMessages, setLinkValidationMsg ] = useState<LinkValidationMessages>(new LinkValidationMessages());
  const [ hasEdit, setHasEdit ] = useState(false);
  const [ disableSave, setDisableSave ] = useState(true);

  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (field === 'value') {
      setLink((prevState:any) => ({
        ...prevState,
        value: newValue,
        link: newValue
      }));
    } else {
      setLink((prevState) => ({
        ...prevState,
        [field]: newValue
      }));
    }
    setHasEdit(true);
    // eslint-disable-next-line no-use-before-define
    setLinkValidationState(field + 'ValidationMsg', '');
    if (field === 'value' && newValue && newValue?.length > 0) {
      setDisableUpload(true);
    } else {
      setDisableUpload(false);
    }
    // handleSaveBtnDisable()
    if (field === 'title' && newValue && newValue !== '' && instructionLink.link) {
      setDisableSave(false);
    } else if (field === 'value' && newValue && newValue !== '' && instructionLink.title) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  };


  useEffect(() => {
    if (props.isEdit) {
      // setLink(props.instructionLink);
      setLink({
        type: props.instructionLink.type,
        value: props.instructionLink.value ? props.instructionLink.value : props.link,
        title: props.instructionLink.title,
        id: props.instructionLink.id,
        pbsInstructionId: props.instructionLink.pbsInstructionId,
        link: props.instructionLink.link
      });
      setDisableUpload(true);
      setDisableSave(false);
    } else {
      setLink(new InstructionLink());
      setLinkValidationMsg(new LinkValidationMessages());
      setDisableUpload(false);
      setDisableSave(true);
    }
  }, [ props.instructionLink ]);

  useEffect(() => {
    if (props.isEdit) {
      setLink((prevState) => ({
        ...prevState,
        value: props.link,
        link: props.link
      }));
      setProgress(false);
      setLinkValidationState('value' + 'ValidationMsg', '');
      handleSaveBtnDisable();
      // setLink(props.instructionLink);
      // setLink({
      //   type:instructionLink.type,
      //   value:instructionLink.value? instructionLink.value:props.link,
      //   title:instructionLink.title,
      //   id:instructionLink.id,
      //   pbsInstructionId:instructionLink.pbsInstructionId
      // });
    } else {
      if (props.link) {
        setLink((prevState) => ({
          ...prevState,
          value: props.link,
          link: props.link
        }));
        setProgress(false);
        setLinkValidationState('value' + 'ValidationMsg', '');
        setDisableUpload(true);
        if (instructionLink.title && props.link) {
          setDisableSave(false);
        }
      } else {
        setDisableUpload(false);
        setLink(new InstructionLink());
        handleSaveBtnDisable(true);
        setLinkValidationMsg(new LinkValidationMessages());
      }
    }
  }, [ props.link ]);

  // useEffect(() => {
  //   if (instructionLink && ref.current && ref.current.criteria) {
  //     //validaErrorOnvalueChange('criteria');
  //   }
  //   ref.current = instructionLink;
  // }, [instructionLink]);

  const validateLink = (link: InstructionLink) => {
    // let isValidType = validateLinkType(link);
    const isValidLink = validateLinkValue(link);
    const isValidTitle = validateLinkTitle(link);
    return isValidLink && isValidTitle;
  };

  const validateLinkTitle = (link: InstructionLink) => {
    let isValidName = false;
    if (link && link.title) {
      isValidName = true;
      setLinkValidationState('titleValidationMsg', '');
    } else {
      isValidName = false;
      setLinkValidationState('titleValidationMsg', t('titleRequired'));
    }
    return isValidName;
  };

  const handleSaveBtnDisable = (isDisable = false) => {
    if (instructionLink.title && instructionLink.link) {
      setDisableSave(false);
    } else if (!instructionLink.title || !instructionLink.link) {
      setDisableSave(true);
    }
    if (isDisable) {
      setDisableSave(true);
    }
  };

  const validateLinkType = (link: InstructionLink) => {
    let isValidName = false;
    if (link && link.type) {
      isValidName = true;
      setLinkValidationState('typeValidationMsg', '');
    } else {
      isValidName = false;
      setLinkValidationState('typeValidationMsg', t('linkTypeRequired'));
    }
    return isValidName;
  };

  const validateLinkValue = (link: InstructionLink) => {
    let isValidLink = false;
    if (link && link.value) {
      isValidLink = true;
      setLinkValidationState('valueValidationMsg', '');
    } else {
      isValidLink = false;
      setLinkValidationState('valueValidationMsg', t('urlRequired'));
    }
    return isValidLink;
  };

  const setLinkValidationState = (key: string, msg: string) => {
    setLinkValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg
    }));
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openLinkModal();
          setTimeout(() => {
            setLinkValidationMsg(new LinkValidationMessages());
          }, 1000);
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.isEdit ? t('editInstructionLink') : t('addInstructionLink')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openLinkModal();
              setTimeout(() => {
                setLinkValidationMsg(new LinkValidationMessages());
              }, 1000);
            }}
          />
        </div>
        <ProgressBar show={isProgress} />
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>1. {t('link')} </Label>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('title')}
                      placeholder={t('title')}
                      value={
                        instructionLink && instructionLink.title
                          ? instructionLink.title
                          : ''
                      }

                      errorMessage={linkValidationMessages.titleValidationMsg}
                      onChange={onChangeInput('title')}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('type')}
                      label={t('type')}
                      options={getOptions()}
                      selectedKey={
                        instructionLink && instructionLink.type
                          ? instructionLink.type
                          : -1
                      }
                      errorMessage={linkValidationMessages.typeValidationMsg}
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item: any
                      ) => {
                        setLinkValidationState('typeValidationMsg', '');
                        setLink((prevState) => ({
                          ...prevState,
                          type: item.key,
                        }));
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack> */}

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('URL')}
                      placeholder={t('copyURLhere')}
                      value={
                        instructionLink && instructionLink.link
                          ? instructionLink.link
                          : ''
                      }

                      errorMessage={linkValidationMessages.valueValidationMsg}
                      onChange={onChangeInput('value')}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item
                  grow={6}
                  styles={{
                    root: {
                      display: 'flex',
                      height: 30,
                      paddingBottom: 0,
                      flexGrow: 1,
                      width: 0
                    }
                  }}
                >
                  {!(instructionLink && instructionLink.value) &&
                  <div className={`${classNames.fullWidth}`}>
                    <TextField readOnly={true} borderless={true} value={t('OR')}
                               disabled={instructionLink && instructionLink.value ? true : false} />
                  </div>
                  }
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={props.onLinkFileChange}
                />

                <PrimaryButton
                  iconProps={addIconWhite}
                  text={t('upload')}
                  // disabled={diableUpload}
                  style={{ marginTop: 0, marginBottom: 30 }}
                  onClick={() => {
                    inputFile?.current?.click();
                  }}
                />
              </Stack>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            disabled={disableSave}
            onClick={() => {
              if (validateLink(instructionLink)) {
                // instructionLink.link = instructionLink.value
                props.onAddInstructionLink(instructionLink);
                props.openLinkModal();
              }
            }}
          />
          {!props.isEdit && <PrimaryButton
            iconProps={addIconWhite}
            text={t('saveandNew')}
            style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
            onClick={() => {
              if (validateLink(instructionLink)) {
                // instructionLink.link = instructionLink.value
                props.onAddInstructionLink(instructionLink);
                setLink(new InstructionLink());
                setDisableSave(true);
              }
            }}
            disabled={disableSave}
          />}
        </div>
        {/* <pre>{JSON.stringify(instructionLink, 0, 2)}</pre>  */}
      </Modal>
    </div>
  );
};

export default LinkModal;
