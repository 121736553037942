import React from 'react';
import {DatePicker, DayOfWeek} from '@fluentui/react';
import {withTranslation} from 'react-i18next';
import {iconColors} from '../../../theme';
import {Icon} from '@fluentui/react/lib/Icon';


class CustomDatePicker extends React.Component<any, any> {


  public render(): JSX.Element {
    const { t } = this.props;
    const language = window.navigator.language;
    // let today=new Date();
    return (
      <DatePicker
        firstDayOfWeek={this.firstDayOfWeek('nl')}
        minDate={this.props.setMinDate}
        maxDate={this.props.setMaxDate}
        label={this.props.label}
        placeholder={t('selectadate')}
        ariaLabel={this.props.label}
        value={(this.props.setValue) ? this.props.setValue : null}
        onSelectDate={this.onSelectDate}
        disabled={this.props.disabled}
        textField= {this.props.isClearable ? {
          required: false,
          inputClassName: { paddingLeft: "34px", paddingRight: "8px" } as any,
          onRenderSuffix:  () =>
            <Icon
              onClick={()=>{
                this.props.onDateClear()
                this.onClearDate()}}
              iconName="ChromeClose"
              style={
                { paddingRight: 4 }
              }
            />,
          styles: { suffix: { padding: "0 4px", backgroundColor: 'white', cursor: 'pointer' } }
        } : undefined}
        styles={

          this.props.hasChanged ? {
            icon: { color: iconColors.iconActiveColor },
            root: { label: { color: iconColors.iconActiveColor } },
            textField: { color: iconColors.iconActiveColor },
          } : this.props.isClearable ? {
            icon: { left: "5px", right: "unset", }
          } : {}

        }
        formatDate={this.onFormatDate}
      />
    );
  }
    private onFormatDate = (date?: Date): string => {
        return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
    };

  private onSelectDate = (date: Date | null | undefined): void => {
    if (date) {
      let month = (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1);
      let day = (date.getDate() < 10 ? '0' : '') + (date.getDate());
      let formatDate = (date.getFullYear()) + '-' + month + '-' + day + 'T00:00:00.000Z';

      this.props.getValue(date);
    }
  };

  private onClearDate() {
    this.props.getValue(null);
  };

  private firstDayOfWeek(language: string): DayOfWeek | undefined {
    switch (language) {
      case 'en':
        return 0;
      case 'nl':
        return 1;
      default:
        return 0;
    }
  };
}

export default withTranslation()(CustomDatePicker);
