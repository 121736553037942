import React, {useEffect, useState} from 'react';
import {CommandBarButton} from "office-ui-fabric-react";
import DefaultCustomModal from "../../shared/customeModal/defaultCustomModal";
import {useTranslation} from "react-i18next";
import BurnDownChart from "./chart";
import {readBurnDownChartData, saveBDCStateAttr} from "../../reducers/bdcReducer";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';

//
interface Props {
    styles: any
    menuIconProps: string
}

export default function BurnDownChartComponent(props: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {chartData, showProgressBar} = useSelector((state: any) => state.bdc);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {

        return () => {
            dispatch(saveBDCStateAttr("chartData", []));
        }
    },[]);

    useEffect(() => {
        const currentDate = moment();
        if (isOpen) {
            dispatch(readBurnDownChartData({
                startDate: currentDate.clone().startOf('isoWeek').weekday(1).format('YYYY-MM-DD'),
                endDate: currentDate.clone().startOf('isoWeek').weekday(5).format('YYYY-MM-DD')
            }));
        }
    }, [isOpen]);

    return (
      <>
          <CommandBarButton
            ariaLabel="More items"
            role="menuitem"
            styles={props.styles}
            menuIconProps={{iconName: props.menuIconProps}}
            onClick={() => setIsOpen(state => !state)}
          />

          <DefaultCustomModal
            onRenderFooter={() => {
            }}
            isModalOpen={isOpen}
            showProgressBar={showProgressBar}
            title={t('burnDownChart')}
            handleClose={() => setIsOpen(false)}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div>
                      <BurnDownChart data={chartData.find((i: any) => i.isPrimary == true)}/>
                  </div>
                  <div style={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gridTemplateRows: 'repeat(auto-fill, 1fr)',
                      gridRowGap: '.5em',
                      gridColumnGap: '1em'
                  }}
                  >
                      {chartData.map((item: any) => {
                          if (!item.isPrimary) {
                              return <BurnDownChart key={Math.random() + item.title} data={item}/>
                          }
                      })}
                  </div>

              </div>
          </DefaultCustomModal>
      </>
    );
}