import { Text } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFlatDataFromTree, getNodeAtPath, getTreeFromFlatData, walk } from '@nosferatu500/react-sortable-tree';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { ExpandProvider } from '../../../hooks/expandContext';
import { savePMOLStateAttr } from '../../../reducers/pmolReducer';
import {
  saveVPStateAttr,
  readVPPBSTaxonomyData,
  updateProductTaxonomyParent,
  formatVProductTaxonomyBarDataWithoutRefresh,
  updateProductTaxonomyTreeIndex,
  updateProjectItemsDate,
} from '../../../reducers/visualPlanReducer';
import { Gantt, Task, ViewMode } from '../../../shared/ganttv2';
import { ChartType } from '../../../shared/ganttv2/types/public-types';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { initTasks } from './helper';
import { ViewSwitcher } from './view-switcher';
import { getById } from '../../../reducers/projectBreakdownReducer';

export const PBS_STATE = {
  PENDDING_DEVELOPMENT: 'd60aad0b-2e84-482b-ad25-618d80d49477',
  IN_DEVELOPMENT: '94282458-0b40-40a3-b0f9-c2e40344c8f1',
  IN_REVIEW: '7143ff01-d173-4a20-8c17-cacdfecdb84c',
  APPROVED: '7bcb4e8d-8e8c-487d-8170-6b91c89fc3da',
  HAND_OVER: '4010e768-3e06-4702-b337-ee367a82addb',
};

export const ViewModeContext = React.createContext({
  onViewModeChange: (viewMode: ViewMode) => {},
  onViewListChange: (isCheck: boolean) => {},
  isCheck: true,
});
// Init
const ProductTreeListPaneComponent = () => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [tree, setTree] = React.useState<any>([]);
  const isTreeIndexUpdate = useRef(false);
  const viewSwitcherFilterData = useRef({});

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDirty = useRef(false);
  const treeSnapShot = useRef<any[]>([]);

  let listTasks: any[] = useSelector(
    (state: any) => state.vp.vpProducTaxonomyList
  );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpProducTaxonomyInteractBarList
  );

  const isDataLoaded: boolean = useSelector(
    (state: any) => state.vp.prodTaxanomyDataLoaded
  );

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  let columnWidth = 60;

  if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Month) {
    columnWidth = 320;
  } else if (view === ViewMode.QuaterYear) {
    columnWidth = 350;
  }

  useEffect(() => {
    dispatch(savePMOLStateAttr('isPMOLOpenFromVP', true));
    dispatch(
      readVPPBSTaxonomyData({
        fromDate: null,
        toDate: null,
      })
    );
  }, []);

  useEffect(() => {
    setDataLoaded(isDataLoaded);
  }, [isDataLoaded]);

  const getElementDepth : any = (arr: any[], targetNode: any, depth = 1) =>
    arr.reduce((result, item) => {
      if (result !== -1) {
        return result;
      }
      if (item === targetNode) {
        return depth;
      } else if (item.children && item.children.length > 0) {
        return getElementDepth(item.children, targetNode, depth + 1);
      }
      return -1;
    }, -1);

  const getIndexesAtDepth: any = (arr: any[], targetDepth: number, currentDepth = 1, currentIndex = 0, treeIndexObjArr: any[] = []) => {
    arr.forEach((item) => {
      if (currentDepth === targetDepth) {
        treeIndexObjArr.push({ pbsProductId: item.id, treeIndex: item.treeIndex });
      } else if (item.children && item.children.length > 0) {
        getIndexesAtDepth(item.children, targetDepth, currentDepth + 1, currentIndex, treeIndexObjArr);
      }
      currentIndex++;
    });
    return treeIndexObjArr;
  };

  const debounced = useDebouncedCallback(
    (value: any) => {
      dispatch(updateProductTaxonomyParent(value));
      dispatch(getById(value.childPbsSequenceId));
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    const tData = getTreeFromFlatData({
      flatData: listTasks,
      getKey: (node: any) => node.id,
      getParentKey: (node: any) => node.parentId,
      // @ts-ignore
      rootKey: null,
    });

    treeSnapShot.current = tData;
  }, [listTasks]);

  const debouncedTreeIndex = useDebouncedCallback(
    (treeData: any) => {
      if (isTreeIndexUpdate.current) {
        updateTreeIndexList(treeData);
        isTreeIndexUpdate.current = false;
      }
    },
    1000,
  );

  const handleTaskChange = (task: any) => {
    console.log('update node', task);
    isDirty.current = true;
    setTimeout(() => {
      isDirty.current = false;
    }, 2000);

    const ffData: any[] = getFlatDataFromTree({
      treeData: tree,
      getNodeKey: (node: any) => node.pbsSequenceId,
      ignoreCollapsed: false,
    });

    const finalresult = ffData.map((item: any) => {
      return item?.node;
    });

    let modifiedIndex = finalresult.findIndex(
      (t) => t.pbsSequenceId === task.pbsSequenceId
    );
    if (modifiedIndex >= 0) {
      finalresult[modifiedIndex] = {
        ...finalresult[modifiedIndex],
        start: task.start,
        startDate: task.start.toISOString().replace('Z', ''),
        end: task.end,
        endDate: task.end.toISOString().replace('Z', ''),
      };
      dispatch(saveVPStateAttr('vpProducTaxonomyList', [...finalresult]));
    }

    const startDate =
      moment(task.start).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate =
      moment(task.end).subtract(1, 'days').format('YYYY-MM-DD') +
      'T00:00:00.000Z';
    //interactBarTasks.splice(task.index, 1, task);
    const payloadUpdate = {
      type: 'PBS',
      sequenceId: task.pbsSequenceId,
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(updateProjectItemsDate(payloadUpdate));
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      // setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    if (!isDirty.current) {
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
      dispatch(saveVPStateAttr('slectedPBSForBor', null));
      dispatch(saveVPStateAttr('selectedPBS', task.pbsSequenceId));
    }
  };

  const handleSelect = (_task: Task, _isSelected: boolean) => {};

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const onVisibilityChanged = (data: any) => {};

  const updateTreeIndexList = (treeData: any[] ) => {
    dispatch(updateProductTaxonomyTreeIndex({ pbsTreeIndex: getTreeIndexList(treeData) }));
  };

  const getTreeIndexList = (treeData: any[]) => {
    let modifiedArray: any[] = [];
    const callback = (props: any) => {
      if (props.node.id.length > 4) {
        modifiedArray.push({
          pbsProductId: props.node.id,
          treeIndex: treeData[0].isFilter ? props.node.treeIndex : props.treeIndex,
        });
      }
    };

    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: false,
    });
    //dispatch(updateProductTaxonomyTreeIndex({ pbsTreeIndex: modifiedArray }));
    console.log('');
    return modifiedArray;
  };

  const onvisibilityToggled = (data: any) => {};

  const onFilterChanged = (data: any) => {
    console.log('onFilterChanged');
  };

  const onViewSwitcherFilterValueChange = (data: any) => {
    viewSwitcherFilterData.current = data;
  };

  return (
    <div>
      <ViewModeContext.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
          //onFilterChange: (isChanged: boolean) => onFilterChanged(isChanged),
        }}
      >
        <ExpandProvider>
          <ViewSwitcher onViewSwitcherFilterValueChange={onViewSwitcherFilterValueChange} />

          {!dataLoaded && <ProgressBar show={true} />}
          {dataLoaded && _.isEmpty(interactBarTasks) && (
            <Text style={{ marginLeft: 20, marginTop: 5 }}>{t('noData')}</Text>
          )}
          {dataLoaded && !_.isEmpty(interactBarTasks) && (
            <Gantt
              tasks={
                listTasks &&
                _.isArray(listTasks) &&
                !_.isEmpty(listTasks) &&
                dataLoaded &&
                !_.isEmpty(interactBarTasks) &&
                typeof interactBarTasks[0].start !== 'string'
                  ? interactBarTasks
                  : tasks
              }
              listTasks={
                listTasks &&
                _.isArray(listTasks) &&
                !_.isEmpty(listTasks) &&
                dataLoaded
                  ? listTasks
                  : tasks
              }
              searchQuery={''}
              searchMethod={({ node, searchQuery }: any) => {
                return (
                  searchQuery &&
                  node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                    -1
                );
              }}
              searchFocusOffset={0}
              searchFinishCallback={(matches) => {}}
              onlyExpandSearchedNodes={true}
              viewMode={view}
              onDateChange={handleTaskChange}
              onDelete={handleTaskDelete}
              onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={isChecked ? '155px' : ''}
              columnWidth={columnWidth}
              onVisibilityChanged={onVisibilityChanged}
              onVisibilityToggle={onvisibilityToggled}
              ganttHeight={560}
              isInitialCollaps={true}
              onMoveNode={(
                treeData: any,
                nodeOne: any,
                nextParentNode: any,
                prevPath: any,
                prevTreeIndex: any,
                nextPath: any,
                nextTreeIndex: any,
                nodeTwo:any
              ) => {


                if (nextParentNode) {
                  nodeOne.parentId = nextParentNode.id;
                } else {
                  nodeOne.parentId = null;
                }

                dispatch(saveVPStateAttr('pbsTaxonomyTreeHasChanged', true));
                dispatch(saveVPStateAttr('expandPath', nextPath));

                const ffData: any[] = getFlatDataFromTree({
                  treeData: treeData,
                  getNodeKey: (node: any) => node.id,
                  ignoreCollapsed: true,
                });

                // Original tree index list from the backend
                const OriginalTreeIndexList = listTasks
                  .filter((item) => {
                    return item.treeIndex >= 0;
                  })
                  .map((item) => {
                    return item.treeIndex;
                  });

                // Mapping the new order changes to the original tree index list
                if (treeData[0].isFilter) {
                  // if (nodeOne?.children && nodeOne?.children.length > 0) {
                  if (true) {
                    const depthOfNodeOne = getElementDepth(treeData, nodeOne);
                    const allNodesAtGivenDepthObj = getIndexesAtDepth(treeData.filter((item: any) => !item.isHidden), depthOfNodeOne);
                    const indexesAtDepthArray = allNodesAtGivenDepthObj.map((item: any) => { return item.treeIndex })
                    const customSort = (a: any, b: any): any=> OriginalTreeIndexList.indexOf(a) - OriginalTreeIndexList.indexOf(b);
                    indexesAtDepthArray.sort(customSort);

                    let count = 0;
                    ffData.forEach((item: any) => {
                      allNodesAtGivenDepthObj.forEach((t1: any) => {
                        if (item.node.id === t1.pbsProductId) {
                          item.node.treeIndex = indexesAtDepthArray[count];
                          count++;
                        }
                      });
                    });

                  } else {
                    // CURRENTLY NOT USING THIS
                    // Mapping the new node order changes to the original tree index list
                    ffData.map((item: any) => {
                      const indexOfTheMappingItem = ffData.findIndex((t1: any) => {
                        return t1?.node?.id === item.node.id;
                      });
                      item.node.treeIndex = OriginalTreeIndexList[indexOfTheMappingItem];
                    });
                  }
                }


                // if (prevTreeIndex < nextTreeIndex) {
                //   console.log('top to bottom move');
                //
                //   ffData.map((item: any) => {
                //     const indexOfTheMappingItem = ffData.findIndex((t1: any) => {
                //       return t1?.node?.id === item.node.id;
                //     });
                //
                //       item.node.treeIndex = OriginalTreeIndexList[indexOfTheMappingItem];
                //   });
                //
                // } else {
                //   console.log('bottom to top move');
                //
                //   ffData.map((item: any) => {
                //     const indexOfTheMappingItem = ffData.findIndex((t1: any) => {
                //       return t1?.node?.id === item.node.id;
                //     });
                //
                //     if (indexOfTheMappingItem >= nextTreeIndex && indexOfTheMappingItem <= prevTreeIndex) {
                //       item.node.treeIndex = ffData[indexOfTheMappingItem + 1].node.treeIndex;
                //     }
                //   });
                //   ffData[prevTreeIndex].node.treeIndex = OriginalTreeIndexList[prevTreeIndex];
                // }

                if (treeData[0]?.id !== '0000') {
                  if (isTreeIndexUpdate.current) {
                    debouncedTreeIndex(treeData);
                  }
                  setTree(treeData);
                }
              }}
              canDrag={({ node, path, treeIndex }: any) => {
                if (node?.isHidden) {
                  return false;
                } else {
                  return true;
                }
              }}
              canDrop={({
                node,
                prevPath,
                prevParent,
                prevTreeIndex,
                nextPath,
                nextParent,
                nextTreeIndex,
                treeData,
              }: any) => {
                const comNode: any = getNodeAtPath({
                  treeData: tree,
                  path: nextPath,
                  getNodeKey: getNodeKey,
                });

                debounced({
                  parentPbsSequenceId: nextParent
                    ? nextParent.pbsSequenceId
                    : null,
                  childPbsSequenceId: node.pbsSequenceId,
                });
                isTreeIndexUpdate.current = true;
                if (nextParent) {
                  if (_.inRange(nextParent.id, 1000, 1100)) {
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  if (!_.inRange(comNode?.node?.id, 1000, 1100)) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }}
              chartType={ChartType.PBSTAXONOMY}
              onTreeDataChange={(treeData: any[]) => {
                if (treeData[0]?.id !== '0000') {
                  //   if (isTreeIndexUpdate.current) {
                  //     console.log('asdsdasda');
                  //     debouncedTreeIndex(treeData);
                  //     isTreeIndexUpdate.current = false;
                  //   }
                  setTree(treeData);
                }
                dispatch(
                  saveVPStateAttr('vpProducTaxonomyBarList', [
                    ...formatVProductTaxonomyBarDataWithoutRefresh(treeData)[0],
                  ])
                );
                dispatch(
                  saveVPStateAttr('vpProducTaxonomyInteractBarList', [
                    ...formatVProductTaxonomyBarDataWithoutRefresh(treeData)[1],
                  ])
                );
              }}
            />
          )}
        </ExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
};

export default ProductTreeListPaneComponent;
