import React, {useEffect, useState} from 'react';
import SplitPane, {Pane} from 'react-split-pane';
import {DocumentPaneType} from '../../types/dayPlanning';
import {Panel, PanelType, Stack} from '@fluentui/react';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {useTranslation} from 'react-i18next';
import TitlePane from '../../shared/titlePane/titlePane';
import CommandBarLeft from './commandBar/commandBarLeft';
import ProgressBar from '../../shared/progressBar/progressBar';
import {useDispatch, useSelector, useStore} from 'react-redux';
import TopListPane from './listPane/topListPane/topListPane';
import {saveProjectDayPlanningStateAttr} from '../../reducers/projectDayPlanningReducer';
import DocumentPane from './documentPane/documentPane';
import {messageService} from '../../services/messageService';
import BottomShortcutPane from './shortCutPane/bottomShortcutPane';
import BottomListPane from './listPane/bottomListPane';
import {DpViewMode} from '../../types/myDayPlanning';
import {readPMOLDropDownData} from "../../reducers/pmolReducer";
import {getContractingUnits} from '../../reducers/cuProjectReducer';
import {saveMyDayPlanningStateAttr} from "../../reducers/myDayPlanningReducer";
import PlanBoardShortcutPane from "./shortCutPane/planboardShortcutPane";


const ProjectDayPlanningMainLayout = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const store = useStore();

    const [showPanel, setShowPanel]: any = useState(false);
    const [showDetails, setShowDetails]: any = useState(false);
    const [topReSizer, setTopReSizer]: any = useState(screen.width);
    const [bottomReSizer, setBottomReSizer]: any = useState(screen.width);
    const [documentPaneType, setDocumentPaneType]: any = useState("");
    const [isExpand, setIsExpand] = useState(false);
    const [isMinimize, setIsMinimize] = useState(true);


    const {
        showProgressBar,
        horizontalReSizer,
        selectedPmol,
        collapseBookmarkPane,
        isShortCutPaneCollapse
    } = useSelector(
        (state: any) => state.projectDPlan
    );

    useEffect(() => {
        dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', true));
        dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', screen.height - 330));
        dispatch(readPMOLDropDownData());
        // dispatch(saveProjectDayPlanningStateAttr('selectedCu',store.getState()?.uprince?.userCu ));
        dispatch(getContractingUnits());
        dispatch(saveMyDayPlanningStateAttr('selectedDate', new Date()));

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.hideDocumentPane) {

                    if (data.data.documentPaneType === 1) {
                        _hideDocPane(true)
                    } else {
                        _hideDocPane(false)
                    }
                }

                if (data.data.collapseDocumentPane) {
                    setIsExpand((prevCheck) => !prevCheck);
                }

                if (data.data.minimizeBottomPane) {
                    dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', data.data.isMinimize ? screen.height - 330 : screen.height / 2 - 140));
                    setIsMinimize(data.data.isMinimize);
                }
            }
        });

        return () => {
            subscription.unsubscribe();
            dispatch(saveProjectDayPlanningStateAttr('selectedPmol', null));
            dispatch(saveProjectDayPlanningStateAttr('projectDpViewMode', DpViewMode.TwoHour));
            dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', true));
            setTopReSizer(screen.width);
        }
    }, []);


    useEffect(() => {
        if (selectedPmol) {
            setShowDetails(true);
            setDocumentPaneType(DocumentPaneType.PMOL);
            setTopReSizer(screen.width / 2)
        }
    }, [selectedPmol]);

    useEffect(() => {
        if (isExpand) {
            if (showDetails) {
                dispatch(saveProjectDayPlanningStateAttr('collapseBookmarkPane', true));
                setTopReSizer(screen.width / 2);
            }
        } else {
            if (showDetails) {
                dispatch(saveProjectDayPlanningStateAttr('collapseBookmarkPane', false));
                setTopReSizer(300);
            }
        }
    }, [isExpand]);


    const _hidePanel = () => {
        setShowPanel(false)
    };
    const _hideDocPane = (isTop?: boolean) => {
        if (isTop) {
            setTopReSizer(screen.width)
        } else {
            setBottomReSizer(screen.width)
        }
        setShowDetails(false);
        setDocumentPaneType(null);
        setTopReSizer(screen.width);
        dispatch(saveProjectDayPlanningStateAttr('selectedPmol', null))
    };

    const getDocumentPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return <div>
                <DocumentPane/>
            </div>
        } else {
            return <div> &nbsp; </div>
        }

    };

    const renderShortCutPane = (isFirst: boolean, isTopPane?: boolean) => {
        if (isTopPane) {
            return <div>
                <PlanBoardShortcutPane type={"project"}/>
            </div>
        } else {
            return <div><BottomShortcutPane/></div>
        }

    };

    const getListPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return <div style={{width: '100%'}}>
                <TitlePane
                    headerTitle={"All"}
                    type={1}
                    isFullScreen={true}
                />
                <div style={{
                    width: '100%',
                    height: isTopPane ? '100%' : screen.height - (horizontalReSizer - 10),
                    overflow: 'overlay'
                }}>
                    <Stack horizontal>
                        {renderShortCutPane(true, true)}

                        {/*<div style={{width:'calc(100% - 32px)'}}>*/}
                        <div style={{width: isShortCutPaneCollapse ? 'calc(100% - 32px)' : 'calc(100% - 255px)'}}>
                            <Stack
                                className="project-list-header right-panel ppb"
                                style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingRight: 0
                                }}
                                styles={{root: {width: '100%', paddingRight: 0}}}
                            >
                                <CommandBarLeft disable={false} isTopPane={true}/>

                            </Stack>
                            <ProgressBar show={showProgressBar}/>

                            <Stack style={{alignSelf: 'center', width: '100%'}}>
                                <TopListPane/>
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </div>
        } else {
            return <div style={{width: '100%'}}>
                <TitlePane
                    headerTitle={"All"}
                    type={1}
                    minimize={true}
                    isMinimize={isMinimize}
                />
                <div style={{width: '100%', height: '100%', overflow: 'overlay'}}>
                    <Stack horizontal>
                        {renderShortCutPane(false)}

                        <div style={{width: '100%'}}>
                            <Stack
                                className="project-list-header right-panel"
                                style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingRight: 0
                                }}
                                styles={{root: {width: '100%', paddingRight: 0}}}
                            >
                                <ProgressBar show={showProgressBar}/>
                                <CommandBarLeft disable={false} isTopPane={false}/>

                            </Stack>
                            <ProgressBar show={showProgressBar}/>

                            <Stack style={{alignSelf: 'center', width: '100%'}}>
                                <BottomListPane/>
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </div>
        }
    };
    const getListPaneHeight = () => {
        if (screen.width <= 1920) {
            return screen.height - (horizontalReSizer + 285)
        } else if (screen.width > 1920 && screen.width <= 2048) {
            return screen.height - (horizontalReSizer + 150)
        } else if (screen.width > 2048) {
            return screen.height - (horizontalReSizer + 200)
        } else {
            return screen.height - (horizontalReSizer + 285)
        }
    };

    return <div>

        {/* <DndProvider key={useId()} backend={HTML5Backend}> */}
        <SplitPane
            defaultSize={screen.height - 310}
            onChange={(size: any) => {
                dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', size));
            }}
            maxSize={screen.height - 310}
            minSize={40}
            style={{position: 'inherit'}}
            split="horizontal"
            size={horizontalReSizer}
        >
            <div style={{width: '100%', height: '100%'}} className={'ms-Grid'}>
                <SplitPane className={'SplitPane'}
                           minSize={(screen.width / 3)}
                           maxSize={(screen.width / 3) * 2}
                           defaultSize={
                               showDetails && documentPaneType === DocumentPaneType.PMOL ? screen.width / 2 : screen.width
                           }
                           split="vertical"
                           onChange={(size: any) => {
                               setTopReSizer(size)
                           }}
                           size={topReSizer}
                >
                    <Pane className={''} style={{width: '100%'}}>{getListPaneView(true)} </Pane>
                    <Pane className={''} style={{
                        width: '100%',
                        height: '100%',
                        zIndex: 1
                    }}> {documentPaneType === DocumentPaneType.PMOL && getDocumentPaneView(true)}</Pane>
                </SplitPane>
                <div></div>
            </div>
            <div style={{width: '100%'}} className={'ms-Grid'}>
                <div id={"bottomPane"} style={{height: getListPaneHeight()}}>
                    <SplitPane
                        className={'SplitPane'}
                        minSize={(screen.width / 3)}
                        maxSize={(screen.width / 3) * 2}
                        defaultSize={
                            showDetails && (documentPaneType === DocumentPaneType.CU_HR || documentPaneType === DocumentPaneType.CPC) ? screen.width / 2 : screen.width
                        }
                        split="vertical"
                        onChange={(size: any) => {
                            setBottomReSizer(size)
                        }}
                        size={bottomReSizer}
                    >
                        <Pane className={''} style={{width: '100%', height: '100%'}}>{getListPaneView()}</Pane>
                        <Pane className={''} style={{width: '100%', height: '100%'}}>{getDocumentPaneView()}</Pane>
                    </SplitPane>
                </div>
            </div>

        </SplitPane>
        {/* </DndProvider> */}
        <Panel
            isOpen={showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={_hidePanel}
            isLightDismiss={true}
            headerText={t('corporateProductCatalogue')}
            className="custom-detail-panel"
            closeButtonAriaLabel={t('close')}
        >
            {getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
            hidden={true}
            title={t('areyousure')}
            subText={t('youwonTbeabletorevertthis')}
            onClickConfirmDialog={() => {
            }}
            onClickCancelDialog={() => {
            }}
            cancelButtonText={t('noIwonT')}
            confirmButtonText={t('yesDeleteit')}
        />

    </div>
};


export default ProjectDayPlanningMainLayout
