import React, { useState } from 'react';
import { uPrinceTheme } from '../../../theme';
import AsyncSelect from 'react-select/async';
import { mergeStyles, Text } from '@fluentui/react';

const customStyles = {
  control: (_: any, { selectProps: { width } }: any) => ({
    ..._,
    width: width,
    height: 34,
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    minHeight: 34,
    zIndex: 0,
  }),
  menuPortal: (base: any) => ({ ...base }),
};

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const errorMessage = mergeStyles({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgb(164, 38, 44)',
  animationDuration: '0.367s',
  animationFillMode: 'both',
});

const DropdownIndicator = (props: any) => {
  const { className, cx, getStyles, innerProps } = props;
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
      style={{ fontSize: 12, paddingRight: 10, paddingTop: 4 }}
    >
      <i
        className={`filter-icon ms-Icon ms-Icon--ChevronDown filter-icon`}
        aria-hidden="true"
      ></i>
    </div>
  );
};

const SearchableDropdown = (props: {
  selectedOption: any;
  onChange: (item: any) => void;
  promiseOptions: any;
  defaultInputValue?: string | null;
  required?: boolean;
  validationMessage: string | null;
  isOnModal?: boolean
  disabled?: boolean
}) => {
  const [height, setHeight] = useState(50);
  const handleChange = (selectedOption: any, actionMeta: any) => {
    if (actionMeta.action === 'clear') {
      props.onChange(null);
    } else {
      props.onChange(selectedOption);
    }
    onMenuClose();
  };

  const onMenuOpen = () => {
    if (props.isOnModal) {
      setHeight(350);
    }

  };
  const onMenuClose = () => {
    if (props.isOnModal) {
      setHeight(50);
    }
  };


  return (
    <div style={{ height: height, transition: 'all 0.4s linear' }}>
      {/*<Select*/}
      {/*value={props.selectedOption}*/}
      {/*components={{ DropdownIndicator }}*/}
      {/*styles={customStyles}*/}
      {/*className="reactSelectContainer"*/}
      {/*classNamePrefix="reactSelect"*/}
      {/*onChange={handleChange}*/}
      {/*options={props.options}*/}
      {/*theme={theme => ({*/}
      {/*...theme,*/}
      {/*borderRadius: 0,*/}
      {/*height: 34,*/}
      {/*minHeight: 34,*/}
      {/*colors: {*/}
      {/*...theme.colors,*/}
      {/*primary25: uPrinceTheme.palette.themeLight,*/}
      {/*primary: uPrinceTheme.palette.themePrimary,*/}
      {/*neutral20: uPrinceTheme.palette.neutralSecondary,*/}
      {/*neutral30: uPrinceTheme.palette.neutralSecondary,*/}
      {/*},*/}
      {/*})}*/}
      {/*/>*/}
      <AsyncSelect
        value={(props.selectedOption) ? props.selectedOption : { value: '', label: '' }}
        // defaultInputValue={
        //   props.defaultInputValue ? props.defaultInputValue : ''
        // }
        components={{ DropdownIndicator }}
        className="reactSelectContainer"
        classNamePrefix="reactSelect"
        styles={customStyles}
        onChange={handleChange}
        // menuPortalTarget={document.body}
        // menuPosition={'fixed'}
        //menuPosition={isFixed ? 'fixed' : 'absolute'}
        required={props.required}
        isClearable={!!(props.selectedOption)}
        menuPlacement={'auto'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          height: 34,
          minHeight: 34,
          colors: {
            ...theme.colors,
            primary25: uPrinceTheme.palette.themeLight,
            primary: uPrinceTheme.palette.themePrimary,
            neutral20: props.validationMessage
              ? 'rgb(164, 38, 44)'
              : uPrinceTheme.palette.neutralSecondary,
            neutral30: uPrinceTheme.palette.neutralSecondary,
          },
        })}
        cacheOptions
        defaultOptions
        loadOptions={props.promiseOptions}
        onMenuOpen={() => onMenuOpen()}
        onMenuClose={() => onMenuClose()}
        onBlur={() => onMenuClose()}
        isDisabled={props.disabled}
      />
      {props.validationMessage && (
        <Text className={errorMessage}>{props.validationMessage}</Text>
      )}
    </div>
  );
};

export default SearchableDropdown;
