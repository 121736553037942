export const LOAD_COUNTRIES = 'uprince/LOAD_COUNTRIES';
export const LOAD_COUNTRIES_SUCCESS = 'uprince/LOAD_COUNTRIES_SUCCESS';
export const LOAD_COUNTRIES_FAIL = 'uprince/LOAD_COUNTRIES_FAIL';

export const CREATE_COMPANY = 'uprince/CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'uprince/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'uprince/CREATE_COMPANY_FAIL';

export const CREATE_CAB = 'uprince/CREATE_CAB';
export const CREATE_CAB_SUCCESS = 'uprince/CREATE_CAB_SUCCESS';
export const CREATE_CAB_FAIL = 'uprince/CREATE_CAB_FAIL';

export const LOAD_CAB = 'uprince/LOAD_CAB';
export const LOAD_CAB_SUCCESS = 'uprince/LOAD_CAB_SUCCESS';
export const LOAD_CAB_FAIL = 'uprince/LOAD_CAB_FAIL';

export const LOAD_PERSONS = 'uprince/LOAD_PERSONS';
export const LOAD_PERSONS_SUCCESS = 'uprince/LOAD_PERSONS_SUCCESS';
export const LOAD_PERSONS_FAIL = 'uprince/LOAD_PERSONS_FAIL';

export const UPLOAD_CAB_IMAGE = 'uprince/UPLOAD_CAB_IMAGE';
export const UPLOAD_CAB_IMAGE_SUCCESS = 'uprince/UPLOAD_CAB_IMAGE_SUCCESS';
export const UPLOAD_CAB_IMAGE_FAIL = 'uprince/UPLOAD_CAB_IMAGE_FAIL';

export const DELETE_CAB = 'uprince/DELETE_CAB';
export const DELETE_CAB_SUCCESS = 'uprince/DELETE_CAB_SUCCESS';
export const DELETE_CAB_FAIL = 'uprince/DELETE_CAB_FAIL';

export const GET_CAB_BY_ID = 'uprince/GET_CAB_BY_ID';
export const GET_CAB_BY_ID_SUCCESS = 'uprince/GET_CAB_BY_ID_SUCCESS';
export const GET_CAB_BY_ID_FAIL = 'uprince/GET_CAB_BY_ID_FAIL';

export const LOAD_UNIQ_CONTACT = 'uprince/LOAD_UNIQ_CONTACT';
export const LOAD_UNIQ_CONTACT_SUCCESS = 'uprince/LOAD_UNIQ_CONTACT_SUCCESS';
export const LOAD_UNIQ_CONTACT_FAIL = 'uprince/LOAD_UNIQ_CONTACT_FAIL';

export const LOAD_ISEXIST_CONTACT = 'uprince/LOAD_ISEXIST_CONTACT';
export const LOAD_ISEXIST_CONTACT_SUCCESS = 'uprince/LOAD_ISEXIST_CONTACT_SUCCESS';
export const LOAD_ISEXIST_CONTACT_FAIL = 'uprince/LOAD_ISEXIST_CONTACT_FAIL';

export const LOAD_NOCAB_COMPANIES = 'uprince/LOAD_NOCAB_COMPANIES';
export const LOAD_NOCAB_COMPANIES_SUCCESS = 'uprince/LOAD_NOCAB_COMPANIES_SUCCESS';
export const LOAD_NOCAB_COMPANIES_FAIL = 'uprince/LOAD_NOCAB_COMPANIES_FAIL';

export const LOAD_EXPORT_CABS = 'uprince/LOAD_EXPORT_CABS_COMPANIES';
export const LOAD_EXPORT_CABS_SUCCESS = 'uprince/LOAD_EXPORT_CABS_SUCCESS';
export const LOAD_EXPORT_CABS_FAIL = 'uprince/LOAD_EXPORT_CABS_FAIL';

// CAB-Competencies
export const CREATE_CAB_COMPETENCIES = 'uprince/CREATE_CAB_COMPETENCIES';
export const CREATE_CAB_COMPETENCIES_SUCCESS = 'uprince/CREATE_CAB_COMPETENCIES_SUCCESS';
export const CREATE_CAB_COMPETENCIES_FAIL = 'uprince/CREATE_CAB_COMPETENCIES_FAIL';

export const READ_CAB_COMPETENCIES = 'uprince/READ_CAB_COMPETENCIES';
export const READ_CAB_COMPETENCIES_SUCCESS = 'uprince/READ_CAB_COMPETENCIES_SUCCESS';
export const READ_CAB_COMPETENCIES_FAIL = 'uprince/READ_CAB_COMPETENCIES_FAIL';

export const DELETE_CAB_COMPETENCIES = 'uprince/DELETE_CAB_COMPETENCIES';
export const DELETE_CAB_COMPETENCIES_SUCCESS = 'uprince/DELETE_CAB_COMPETENCIES_SUCCESS';
export const DELETE_CAB_COMPETENCIES_FAIL = 'uprince/DELETE_CAB_COMPETENCIES_FAIL';

// CAB-Certification
export const CREATE_CAB_CERTIFICATION = 'uprince/CREATE_CAB_CERTIFICATION';
export const CREATE_CAB_CERTIFICATION_SUCCESS = 'uprince/CREATE_CAB_CERTIFICATION_SUCCESS';
export const CREATE_CAB_CERTIFICATION_FAIL = 'uprince/CREATE_CAB_CERTIFICATION_FAIL';

export const READ_CAB_CERTIFICATION = 'uprince/READ_CAB_CERTIFICATION';
export const READ_CAB_CERTIFICATION_SUCCESS = 'uprince/READ_CAB_CERTIFICATION_SUCCESS';
export const READ_CAB_CERTIFICATION_FAIL = 'uprince/READ_CAB_CERTIFICATION_FAIL';

export const DELETE_CAB_CERTIFICATION = 'uprince/DELETE_CAB_CERTIFICATION';
export const DELETE_CAB_CERTIFICATION_SUCCESS = 'uprince/DELETE_CAB_CERTIFICATION_SUCCESS';
export const DELETE_CAB_CERTIFICATION_FAIL = 'uprince/DELETE_CAB_CERTIFICATION_FAIL';


{/*BmeRequirement*/
}
export const GET_CONTRACTORS_DATA = 'uprince/GET_CONTRACTORS_DATA';
export const GET_CONTRACTORS_DATA_SUCCESS = 'uprince/GET_CONTRACTORS_DATA_SUCCESS';
export const GET_CONTRACTORS_DATA_FAIL = 'uprince/GET_CONTRACTORS_DATA_FAIL';
{/*BmeRequirement*/
}
