import {
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
} from '@fluentui/react';
import { createForm } from 'final-form';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import CustomFileUploader from '../../../../shared/fileUploader/customFileUploader';
import { CKEditorWrapper, TextFieldFF } from '../../../../shared/fluentFinalForm';
import CurrencyNumberFormatField from '../../../../shared/fluentFinalForm/CurrencyNumberFormatField ';
import { getImages, getProject } from '../../../../shared/util';
import {
  IN_DEVELOPMENT_STATUS_ID,
  IN_REVIEW_FEEDBACK_STATUS_ID,
  IN_REVIEW_STATUS_ID,
} from '../../../../types/projectMolecule';
import { POAccessLevel, PODetails } from '../../../../types/purchaseOrder';
import moment from 'moment';
import { useSelector } from 'react-redux';

const theme = getTheme();
1;
const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};
const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      minWidth: '75vw',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '75vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#C4C4C4',
    height: 40,
    marginLeft: 'auto',
  },
  subHeaderBtn: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    borderBottom: '1px solid #C4C4C4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const classNames = mergeStyleSets({
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.white,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  wrapper: {
    height: 'calc(100vh - 264px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};


const POProductModal = (props: {
  isOpen: boolean,
  setOpenPOProductModal: () => void,
  setProductChangeProject: (resource: any, level: any) => void,
  formData: PODetails,
  product: any,
  projectSequance: any
}) => {
  const [product, setProduct] = useState<any>(null);
  const [status, setStatus] = useState<any>(IN_DEVELOPMENT_STATUS_ID);
  const [projectDisabled, setProjectDisabled] = useState<boolean>(false);
  const [cuDisabled, setCuDisabled] = useState<boolean>(false);
  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    // window.alert(JSON.stringify(values, 0, 2));
  };
  const currentUser = useSelector<any, any>(
    (state) => state.uprince.currentUser,
  );

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));
  const formRefCu = React.useRef(createForm({ onSubmit: onSubmit }));

  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  useEffect(() => {
    if (props?.formData?.status?.key) {
      setStatus(props?.formData?.status?.key);
    } else {
      setStatus(IN_DEVELOPMENT_STATUS_ID);
    }
  }, [props.formData]);

  useEffect(() => {
    if (getProject()) {
      setCuDisabled(true);
      if (status === IN_DEVELOPMENT_STATUS_ID) {
        setProjectDisabled(false);
      } else {
        setProjectDisabled(true);
      }
    } else {
      setProjectDisabled(true);
      if (status === IN_DEVELOPMENT_STATUS_ID || status === IN_REVIEW_STATUS_ID || status === IN_REVIEW_FEEDBACK_STATUS_ID) {
        setCuDisabled(false);
      } else {
        setCuDisabled(true);
      }
    }
  }, [status]);

  const { t } = useTranslation();

  const handleHideCu = () => {
    let handleHide = false;
    if (getProject() && (status === IN_DEVELOPMENT_STATUS_ID || status === IN_REVIEW_STATUS_ID)) {
      handleHide = true;
    } else {
      handleHide = false;
    }
    return handleHide;
  };

  const handleHideProject = () => {
    let handleHide = false;
    if (!getProject() && (status === IN_DEVELOPMENT_STATUS_ID)) {
      handleHide = true;
    } else {
      handleHide = false;
    }
    return handleHide;
  };

  const autoGneratedComment = (formValues: any) => {
    let Username = '';
    let time = moment().format('DD/MM HH:mm (ddd)');
    if (currentUser && currentUser.user) {
      Username = currentUser.user.firstName + ' ' + currentUser.user.lastName;
    }
    let comment = product.cComment ? product.cComment : '';

    if (formValues.cTotalPrice != product.cTotalPrice) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('totalPrice')}: ${product.cTotalPrice} : ${formValues.cTotalPrice}</p>`;
    }
    if (formValues.title != product.title) {
      comment = comment + ' ' + `<p> ${time} - ${Username} <br/> ${t('productTitle')}: ${product.title} : ${formValues.title}</p>`;
    }
    return comment;
  };

  return (
    <div className={'splitPopup'}>
      <Modal
        isOpen={props.isOpen}
        onDismiss={() => {
          // this.props.openModal();

        }}
        scrollableContentClassName="body"
        isBlocking={false}
        className={'grid-righter column-filter-activated'}
      >
        <div className={contentStyles.header}>
          {!getProject() && (status === IN_DEVELOPMENT_STATUS_ID) &&
          <span>{(props?.formData && product?.projectTitle ? product?.projectTitle : props.projectSequance) + ' ◦ ' + product?.title}</span>
          }
          {(getProject()) || (!getProject() && (status !== IN_DEVELOPMENT_STATUS_ID)) &&
          <span>{t('cuApproval')} {' - ' + (props?.formData && product?.projectTitle ? product?.projectTitle : props.projectSequance) + ' ◦ ' + product?.title}</span>
          }
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.setOpenPOProductModal();
            }}
          />
        </div>

        <div className="ms-Grid" dir="ltr" style={{ height: '100%' }}>
          <div className="ms-Grid-row">
            {!handleHideProject() &&
            <div
              className={handleHideCu() ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12' : 'ms-Grid-col ms-sm6 ms-md6 ms-lg6'}
              style={{ borderRight: '1px solid black', height: '100%', padding: 0, margin: 0 }}
            >
              <div className={contentStyles.subHeader}>
                <Label className={contentStyles.actionButtonLabel}>
                  {t('project')} {' - ' + (props?.formData && product?.projectTitle ? product?.projectTitle : props.projectSequance) + ' ◦ ' + product?.title}
                </Label>
              </div>

              <Form
                // form={formRefProject.current}
                onSubmit={onSubmit}
                mutators={{
                  setResourceDocs: (args, state, utils) => {
                    const field = state.fields.pDocuments;
                    field.change(args[0]);
                  },
                }}
                initialValues={product}
                // validate={ validate }
                render={({
                           handleSubmit,
                           form,
                           submitting,
                           pristine,
                           values,
                         }) => {
                  // props.submit(handleSubmit);
                  formRef.current = form;
                  const valuesx: any = values;
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="" style={{ padding: 16 }}>
                        <div className={'card-header'}>
                          <Label>1. {t('referenceDetails')}</Label>

                        </div>


                        <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="projectTitle"
                                component={TextFieldFF}
                                lable={t('projectTitle')}
                                disabled={true}
                              />
                            </div>
                          </Stack.Item>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="title"
                                component={TextFieldFF}
                                lable={t('productTitle')}
                                disabled={true}
                              />
                            </div>
                          </Stack.Item>
                        </Stack>

                        <Label>3. {t('financeDetails')}</Label>
                        <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="pTotalPrice"
                                component={CurrencyNumberFormatField}
                                lable={t('totalPrice')}
                                disabled={projectDisabled}

                              />
                            </div>
                          </Stack.Item>
                        </Stack>


                        <Field
                          name="pDocuments"
                          component={'input'}
                          type={'hidden'}
                          disabled={false}
                          hidden
                        />
                        <CustomFileUploader
                          uploadedImages={valuesx?.pDocuments ? getImages(valuesx?.pDocuments) : []}
                          label={'4. ' + t('documents')}
                          uploadUrl={'PO/UploadPoDocuments'}
                          onSaveSuccess={(response: any) => {
                            if (valuesx?.pDocuments && _.isArray(valuesx?.pDocuments)) {
                              valuesx?.pDocuments.push(response);
                            } else {
                              valuesx.pDocuments = [];
                              valuesx?.pDocuments.push(response);
                            }

                            form.mutators.setResourceDocs(valuesx?.pDocuments);
                          }}
                          disable={projectDisabled}
                        />

                        <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                          <div className={`${classNames.fullWidth}`}>
                            <Label>{'5.' + t('comments')}</Label>
                            <Field
                              name="pComment"
                              component={CKEditorWrapper}
                              disabled={projectDisabled}
                            />
                          </div>
                        </Stack>

                        { /* <pre>{ JSON.stringify(values, 0, 2) } </pre>  */}
                      </div>

                      <div className={'card-footer'} style={{ marginBottom: 20, marginRight: 20 }}>
                        <Separator/>
                        <Stack
                          styles={stackStyles}
                          tokens={itemAlignmentsStackTokens}
                        >
                          <Stack.Item
                            align="end"
                            styles={stackFooterBarItemStyles}
                          >
                            <PrimaryButton
                              className={classNames.actionButton}
                              // iconProps={ saveIcon }
                              allowDisabledFocus
                              onClick={() => {
                                const formValues = formRef.current.getState().values;
                                props.setProductChangeProject(formValues, POAccessLevel.PROJECT);
                                props.setOpenPOProductModal();
                              }}
                              disabled={projectDisabled}
                            >
                              <Label className={classNames.actionButton}>
                                {t('save')}
                              </Label>
                            </PrimaryButton>
                          </Stack.Item>
                        </Stack>
                      </div>

                    </form>
                  );
                }}
              />
            </div>
            }

            {!handleHideCu() ? <div
              className={handleHideProject() ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12' : 'ms-Grid-col ms-sm6 ms-md6 ms-lg6'}
              style={{ padding: 0, margin: 0 }}>
              <div className={contentStyles.subHeader}>
                <Label className={contentStyles.actionButtonLabel}>
                  {t('CU')} {' - ' + (props?.formData && product?.projectTitle ? product?.projectTitle : props.projectSequance) + ' ◦ ' + product?.title}
                </Label>
              </div>
              <Form
                onSubmit={onSubmit}
                initialValues={product}
                // validate={ validate }
                mutators={{
                  setResourceDocs: (args, state, utils) => {
                    const field = state.fields.cDocuments;
                    field.change(args[0]);
                  },
                  setCuComment: (args, state, utils) => {
                    const field = state.fields.cComment;
                    field.change(args[0]);
                  },
                }}
                render={({
                           handleSubmit,
                           form,
                           submitting,
                           pristine,
                           values,
                         }) => {
                  const valuesx: any = values;
                  formRefCu.current = form;
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="" style={{ padding: 16 }}>
                        <div className={'card-header'}>
                          <Label>1. {t('referenceDetails')}</Label>

                        </div>

                        <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="projectTitle"
                                component={TextFieldFF}
                                lable={t('projectTitle')}
                                disabled={true}
                              />
                            </div>
                          </Stack.Item>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="title"
                                component={TextFieldFF}
                                lable={t('productTitle')}
                                disabled={cuDisabled}

                              />
                            </div>
                          </Stack.Item>
                        </Stack>


                        <Label>3. {t('financeDetails')}</Label>
                        <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>

                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="cTotalPrice"
                                component={CurrencyNumberFormatField}
                                lable={t('totalPrice')}
                                disabled={cuDisabled}
                                // prefix="€"
                                hasChanged={(valuesx.pTotalPrice !== valuesx.cTotalPrice)}
                                step={1}
                                parse={value => !value ? 0.00 : value}
                              />
                            </div>
                          </Stack.Item>
                        </Stack>


                        <CustomFileUploader
                          uploadedImages={valuesx?.cDocuments ? getImages(valuesx?.cDocuments) : []}
                          label={'4. ' + t('documents')}
                          uploadUrl={'PO/UploadPoDocuments'}
                          onSaveSuccess={(response: any) => {
                            if (valuesx?.cDocuments && _.isArray(valuesx?.cDocuments)) {
                              valuesx?.cDocuments.push(response);
                            } else {
                              valuesx.cDocuments = [];
                              valuesx?.cDocuments.push(response);
                            }

                            form.mutators.setResourceDocs(valuesx?.cDocuments);
                          }}
                          disable={cuDisabled}
                        />

                        <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                          <div className={`${classNames.fullWidth}`}>
                            <Label>{'5.' + t('çomments')}</Label>
                            <Field
                              name="cComment"
                              component={CKEditorWrapper}
                              disabled={cuDisabled}
                            />
                          </div>
                        </Stack>


                      </div>
                      <div className={'card-footer'} style={{ marginBottom: 20, marginRight: 20 }}>
                        <Separator/>
                        <Stack
                          styles={stackStyles}
                          tokens={itemAlignmentsStackTokens}
                        >
                          <Stack.Item
                            align="end"
                            styles={stackFooterBarItemStyles}
                          >
                            <PrimaryButton
                              className={classNames.actionButton}
                              // iconProps={ saveIcon }
                              allowDisabledFocus
                              onClick={() => {
                                let comment = autoGneratedComment(formRefCu.current.getState().values);
                                if (comment) {
                                  form.mutators.setCuComment(comment);
                                }
                                const formValues = formRefCu.current.getState().values;
                                props.setProductChangeProject(formValues, POAccessLevel.CU);
                                props.setOpenPOProductModal();
                              }}
                              disabled={cuDisabled}
                            >
                              <Label className={classNames.actionButton}>
                                {t('save')}
                              </Label>
                            </PrimaryButton>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </form>
                  );
                }}
              />
            </div> : false}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default POProductModal;
