import {
    GET_CU_EP, GET_ORG_EP, GET_THEME_EP, LOAD_USER_INFORMATION_EP
} from '../shared/endpoints';

export const SAVE_STATE_ATTR = 'SAVE_STATE_ATTR';

export const GET_ORG = 'GET_ORG';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const GET_ORG_FAIL = 'GET_ORG_FAIL';

export const GET_CU = 'GET_CU';
export const GET_CU_SUCCESS = 'GET_CU_SUCCESS';
export const GET_CU_FAIL = 'GET_CU_FAIL';

export const GET_THEME = 'GET_THEME';
export const GET_THEME_SUCCESS = 'GET_THEME_SUCCESS';
export const GET_THEME_FAIL = 'GET_THEME_FAIL';

export const LOAD_USER_INFORMATION = "LOAD_USER_INFORMATION";
export const LOAD_USER_INFORMATION_SUCCESS = "LOAD_USER_INFORMATION_SUCCESS";
export const LOAD_USER_INFORMATION_FAIL = "LOAD_USER_INFORMATION_FAIL";

const defaultState = {
    customerId: null,
    currentUser: null,
    organization: null,
    selectedCu: null,
    themeColor: null,
    userInfo: null,
    accessToken: null,
    contractingUinit: null,
    projectSequenceCode: null,
    userCu: null
};

export default function uprinceReducer(state:any = defaultState, action: any) {
    // console.log('save 22'+action.type);
    if (action) {
        switch (action?.type) {
        case SAVE_STATE_ATTR:
            const attr = Object.keys(action)[1];
            const value = Object.values(action)[1];
            return {
                ...state,
                [attr]: value
            };

        case GET_ORG:
            return { ...state };
        case GET_ORG_SUCCESS:
            return {
                ...state,
                organization: action.payload.data.length > 0 ? action.payload.data[0] : null
            };
        case GET_ORG_FAIL:
            return { ...state };

        case GET_CU:
            return { 
                ...state,
                selectedCu: null
             };
        case GET_CU_SUCCESS:
            return {
                ...state,
                selectedCu: action.payload.data.result ? action.payload.data.result : null
            };
        case GET_CU_FAIL:
            return { ...state };

        case GET_THEME:
            return { ...state };
        case GET_THEME_SUCCESS:
            localStorage.setItem('themeColor', action.payload.data.result ? action.payload.data.result.colorCode : '');
            localStorage.setItem('logoUrl', action.payload.data.result ? action.payload.data.result.logoUrl : '');
            return {
                ...state,
                // themeColor:action.payload.data.result?action.payload.data.result:null,
                themeColor: action.payload.data.result ? '#' + Math.floor(Math.random() * 16777215).toString(16) : null,
                organization: action.payload.data.result ? action.payload.data.result.name : ''

            };
        case GET_THEME_FAIL:
            return { ...state };

        case LOAD_USER_INFORMATION:
            return {
                ...state,
                userInfo: [],
            };
        case LOAD_USER_INFORMATION_SUCCESS:
            return {
                ...state,
                userInfo: action?.payload?.data?.result,
                userCu: formattedPayloadForCu(action?.payload?.data?.result?.contractingUnit)
            };
        case LOAD_USER_INFORMATION_FAIL:
            return {
                ...state,
            };

        default:
            return state;
        }
    }
}

export function saveStateAttr(attr: string, value: any) {
    // console.log('save 11');
    // console.log(value);
    return {
        type: SAVE_STATE_ATTR,
        [attr]: value
    };
}

export const getOrganizationDetails = () => {
    return {
        types: [
            GET_ORG,
            GET_ORG_SUCCESS,
            GET_ORG_FAIL
        ],
        payload: {
            request: {
                url: GET_ORG_EP,
                method: 'GET'
            }
        }
    };
};

export const getUserInformation = () => {
    return {
        types: [LOAD_USER_INFORMATION, LOAD_USER_INFORMATION_SUCCESS, LOAD_USER_INFORMATION_FAIL],
        payload: {
            request: {
                url: LOAD_USER_INFORMATION_EP,
                method: "GET",
            },
        },
    };
};

export const getCuDetails = (id:any) => {
    return {
        types: [
            GET_CU,
            GET_CU_SUCCESS,
            GET_CU_FAIL
        ],
        payload: {
            request: {
                url: GET_CU_EP + 'id=' + id,
                method: 'GET'
            }
        }
    };
};

export const getThemeColor = () => {
    return {
        types: [
            GET_THEME,
            GET_THEME_SUCCESS,
            GET_THEME_FAIL
        ],
        payload: {
            request: {
                url: GET_THEME_EP,
                method: 'GET'
            }
        }
    };
};

const formattedPayloadForCu = (contractingUnit: any) => {
    return {key: contractingUnit?.id, sequenceCode: contractingUnit?.sequnceCode};
}