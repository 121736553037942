import React from 'react';
import {Gantt} from '../../../shared/ganttv2/components/gantt/gantt';


import {walk} from '@nosferatu500/react-sortable-tree';
import {Task, ViewMode} from '../../../shared/ganttv2';
import {getStartEndDateForProject, initTasks} from './helper';
import {ViewSwitcher} from './view-switcher';
import _ from 'lodash';
import {Label, Text} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {saveVPStateAttr} from '../../../reducers/visualPlanReducer';
import {RESOURCE_TYPE_IDS} from '../../../shared/util';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {useTranslation} from 'react-i18next';
import {ChartType} from '../../../shared/ganttv2/types/public-types';
import {ViewModeContext} from '../../projectVisualPlan/productTaxonomyListPane/component';
import {ExpandProvider} from '../../../hooks/expandContext';

// Init
const ListPaneComponent = (props: {
  vpOrgList: any[],
  isDataLoaded: boolean,
  isToolsDataLoaded: boolean,
  vpWhToolsList: any[]
}) => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reduxOrgBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpOrgBarList,
  );

  const listTasks: any[] = useSelector(
    (state: any) => state.vp.vpOrgTaxonomyList,
  );

  const interactOrgBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpOrgInteractBarList,
  );

  const selectedResourceTypeOrg: string = useSelector(
    (state: any) => state.vp.selectedResourceTypeOrg,
  );

  // const reduxToolsBarTasks: any[] = useSelector(
  //     (state: any) => state.vp.whTaxonomyList
  // );

  const toolsListTasks: any[] = useSelector(
    (state: any) => state.vp.whBarList,
  );

  const interacttoolsBarTasks: any[] = useSelector(
    (state: any) => state.vp.whInteractBarList,
  );

  const reduxToolsBarTasks: any[] = useSelector(
    (state: any) => state.vp.whBarList,
  );

  // const whListLoaded: boolean = useSelector(
  //     (state: any) => state.vp.whListLoaded
  // );

  const { toolsTaxonomyList, newToolsListLoaded, toolsInteractBarList, toolsBarList } = useSelector(
    (state: any) => state.vp
  );

  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 60;
  if (view === ViewMode.Day) {
    columnWidth = 60;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.QuaterYear) {
    columnWidth = 350;
  }

  const handleTaskToolsChange = (task: Task) => {
    console.log('On date change Id:' + task.id);
    let newTasks = interactOrgBarTasks.map(t => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t =>
          t.id === task.project ? changedProject : t,
        );
      }
    }
    dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    // setTasks(newTasks);
  };

  const handleToolsTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      setTasks(tasks.filter(t => t.id !== task.id));
    }
    return conf;
  };


  const handleTaskChange = (task: Task) => {
    console.log('On date change Id:' + task.id);
    let newTasks = interactOrgBarTasks.map(t => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t =>
          t.id === task.project ? changedProject : t,
        );
      }
    }
    dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    // setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      setTasks(tasks.filter(t => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };

  const handleToolsProgressChange = async (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: any) => {
    //alert('On Double Click event Id:' + task.id);
    dispatch(saveVPStateAttr('showToolsOrLabourDocumentPane', true));
    dispatch(saveVPStateAttr('selectedLabour', task.personId));
  };

  const handleToolsDblClick = (task: Task) => {

    dispatch(saveVPStateAttr('showToolsOrLabourDocumentPane', true));
    dispatch(saveVPStateAttr('selectedTool', task?.name.split(' ')[0]));
    //dispatch(saveVPStateAttr('selectedTool', true));
    //alert('On Double Click event Id:' + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    //props.handleBarTaskItemClick(task.poId);
    //props.handleBarTaskItemClick(task.poId);
    dispatch(saveVPStateAttr('selectedLabour', null));
  };

  const handleToolsSelect = (task: Task, isSelected: boolean) => {
    dispatch(saveVPStateAttr('selectedTool', null));
    //props.handleBarTaskItemClick(task.poId);
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };

  const handleToolsExpanderClick = (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };

  const stringToArr = (string1: any) => {
    if (string1) {
      const x = string1.trim().split(',');
      x.shift();
      return x;
    }
    return [];
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
      indexss += ',' + node.id;
    }
      ;

    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: true,
    });


    return indexss;
  };

  const onVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interactOrgBarTasks.length) {
      // const newTasks = reduxOrgBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
      const newTasks = interactOrgBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('vpOrgInteractBarList', newTasks));
    } else {
      const newTasks = reduxOrgBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('vpOrgInteractBarList', newTasks));
    }
  };

  const onToolsVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interacttoolsBarTasks.length) {
      // const newTasks = reduxOrgBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
      const newTasks = interacttoolsBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('whInteractBarList', newTasks));
    } else {
      const newTasks = reduxToolsBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('whInteractBarList', newTasks));
    }
  };

  const onNewToolsVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= toolsInteractBarList.length) {
      // const newTasks = reduxOrgBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
      const newTasks = toolsInteractBarList.filter((item: any) => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('toolsInteractBarList', newTasks));
    } else {
      const newTasks = toolsBarList.filter((item: any) => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('toolsInteractBarList', newTasks));
    }
  };

  const getTasks = () => {
    if (selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS) {
      if ((props.vpOrgList && _.isArray(props.vpOrgList) && !_.isEmpty(props.vpOrgList) && props.isDataLoaded) && !_.isEmpty(interactOrgBarTasks)) {
        return [interactOrgBarTasks, listTasks];
      } else {
        return [tasks, tasks];
      }
    } else if (selectedResourceTypeOrg === RESOURCE_TYPE_IDS.TOOLS) {
      if ((props.vpWhToolsList && _.isArray(props.vpWhToolsList) && !_.isEmpty(props.vpWhToolsList) && props.isToolsDataLoaded) && !_.isEmpty(interacttoolsBarTasks)) {
        return [interacttoolsBarTasks, toolsListTasks];

      } else {
        return [tasks, tasks];
      }
    } else {
      if ((toolsTaxonomyList && _.isArray(toolsTaxonomyList) && !_.isEmpty(toolsTaxonomyList) && newToolsListLoaded) && !_.isEmpty(toolsInteractBarList)) {
        return [toolsInteractBarList, toolsBarList];
      } else {
        return [tasks, tasks];
      }
    }
  };

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  const onvisibilityToggled = (data: any) => {

  }

  return (
    <div>
      <ViewModeContext.Provider value={{
        onViewModeChange: (viewMode: ViewMode) => { onViewModeChange(viewMode); },
        onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
        isCheck: isChecked
      }}>
        <ExpandProvider>
          <ViewSwitcher
            onViewModeChange={(viewMode: React.SetStateAction<ViewMode>) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            renderFilterComponent={() => <Label>Test Comp</Label>}
          />
          {!props.isDataLoaded &&
            <ProgressBar show={true} />
          }
          {props.isDataLoaded && _.isEmpty(interactOrgBarTasks) &&
            <Text style={{ marginLeft: 20 }}>{t('noData')}</Text>
          }
          {props.isDataLoaded && !_.isEmpty(interactOrgBarTasks) &&
            <Gantt
              tasks={getTasks()[0]}
              listTasks={getTasks()[1]}
              viewMode={view}
              onDateChange={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleTaskChange : handleTaskToolsChange}
              onDelete={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleTaskDelete : handleToolsTaskDelete}
              onProgressChange={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleProgressChange : handleToolsProgressChange}
              onDoubleClick={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleDblClick : handleToolsDblClick}
              onSelect={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleSelect : handleToolsSelect}
              onExpanderClick={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleExpanderClick : handleToolsExpanderClick}
              listCellWidth={isChecked ? '155px' : ''}
              columnWidth={columnWidth}
              onVisibilityChanged={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? onVisibilityChanged : selectedResourceTypeOrg === RESOURCE_TYPE_IDS.TOOLS ? onToolsVisibilityChanged : onNewToolsVisibilityChanged}
              ganttHeight={560}
              searchQuery={''}
              searchMethod={() => { }}
              searchFocusOffset={0}
              searchFinishCallback={(matches) => { }}
              onlyExpandSearchedNodes={true}
              canDrag={(dropProps: any) => {
                if (selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS) {
                  return (dropProps.node?.personId && dropProps.node?.organizationTaxonomyLevelId) ? true : false;
                } else if (selectedResourceTypeOrg === RESOURCE_TYPE_IDS.TOOLS) {
                  return dropProps.node?.wareHouseId && dropProps.node?.cpcId ? true : false;
                } else if (selectedResourceTypeOrg === 'c46c3a26-39a5-42cc-newToolkey') {
                  return dropProps.node?.cpcId ? true : false;
                } else {
                  return false;
                }
              }}
              canDrop={(dropProps: any) => {
                return false;
              }}
              isInitialCollaps={true}
              onMoveNode={(path, node) => {
                // getNodeAtPath({
                //   treeData,
                //   path,
                //   getNodeKey: ({ treeIndex }) => treeIndex,
                //   ignoreCollapsed: false,
                // }).path;
                // console.log('Dragged node path:', draggedNodePath);
              }}
              chartType={ChartType.RESOURCE}
              onVisibilityToggle={onvisibilityToggled}
              onTreeDataChange={(treeData) => { }}
            />
          }
        </ExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
};

export default ListPaneComponent;
