import { useTranslation } from 'react-i18next';
import {
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { getHtmlPrintData, saveProjectDayPlanningStateAttr } from '../../../../reducers/projectDayPlanningReducer';
import { Form } from 'react-final-form';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { formatDateTimeToISOString, isMyEnvironment } from '../../../../shared/util';

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIconWhite: IIconProps = {
  iconName: 'Print',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '20vw',
    minHeight: '10vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const tableStyle = mergeStyleSets({
  table: {
    marginTop: 10,
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 11,
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
    verticalAlign: 'top',
  },
  tdProjectName: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
    verticalAlign: 'top',
    width: 120,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    textAlignLast: 'center',
    padding: 8,
  },
  thHeading: {
    // border: '1px solid #dddddd',
    textAlign: 'center',
    padding: 8,
    fontWeight: 'bold',
  },
  even: {
    backgroundColor: '#dddddd',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const PrintModal = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleId = useId('title');
  const { isPrintModelOpen } = useSelector((state: any) => state.projectDPlan);
  const { selectedDate } = useSelector((state: any) => state.mydPlan);
  const { htmlPrintData } = useSelector((state: any) => state.projectDPlan);
  const { showProgressBar } = useSelector((state: any) => state.projectDPlan);
  const { selectedBu } = useSelector((state: any) => state.projectDPlan);
  const { selectedCu } = useSelector((state: any) => state.projectDPlan);
  const { selectedProject } = useSelector((state: any) => state.project);

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <PrimaryButton
        iconProps={addIconWhite}
        text={t('print')}
        disabled={false}
        style={{ marginTop: 20, marginBottom: 10 }}
      />
    );
  }, []);

  useEffect(() => {
    if (isPrintModelOpen) {
      dispatch(getHtmlPrintData({
        date: formatDateTimeToISOString(selectedDate),
        buId: isMyEnvironment() ? selectedBu : selectedProject?.projectClassification?.projectClassificationBuisnessUnit,
        cu: selectedCu?.sequenceCode,
      }));
    }
  }, [isPrintModelOpen]);


  const onSubmit = async (values: any) => {
    // const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    // await sleep(300);
    //
    // Promise.all([
    //   // dispatch(updatePlanBoardsPmolTitle(values))
    // ]).then(() => {
    //   onModalClose();
    // });
  };


  const onModalClose = () => {
    dispatch(saveProjectDayPlanningStateAttr('isPrintModelOpen', false));
  };

  // const getInitialFormValues = (formData: any, formValue: any) => {
  //   if (formData) {
  //     return {
  //       id: formData?.id,
  //       name: formData?.name,
  //       projectMoleculeId: formData?.projectMoleculeId,
  //       projectSequenceCode: formData?.projectSequenceCode,
  //       contractingUinit: formData?.contractingUinit,
  //     };
  //   }
  //   return formValue;
  // };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={() => {
        }}
        render={({
                   handleSubmit,
                   form,
                   submitting,
                   pristine,
                   values,
                 }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={isPrintModelOpen}
                  onDismiss={() => {
                    onModalClose();
                  }}
                  scrollableContentClassName='body'
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <span id={titleId}>{t('print')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel='Close popup modal'
                      onClick={() => {
                        onModalClose();
                      }}
                    />
                  </div>
                  <ProgressBar show={showProgressBar} />

                  <div className={contentStyles.body}>

                    <div className='proj-detail-content inner'>
                      <div className='ms-Grid-row'>

                        <div id='print-area-2' className='print-area' style={{overflow: 'scroll'}} ref={componentRef}>
                          <table className={tableStyle.table}>
                            <thead>
                            <tr>
                              <td colSpan={8}
                                  className={tableStyle.thHeading}>{moment(selectedDate).format('dddd MMMM D')}</td>
                            </tr>
                            <tr>
                              <th className={tableStyle.th}>{t('project')}</th>
                              <th className={tableStyle.th}>{t('hour')}</th>
                              <th className={tableStyle.th}>{t('projectMolecule')}</th>
                              <th className={tableStyle.th}>{t('siteManager')}</th>
                              <th className={tableStyle.th}>{t('names')}</th>
                              <th className={tableStyle.th}>{t('material')}</th>
                              <th className={tableStyle.th}>{t('vehicles')}</th>
                              <th className={tableStyle.th}>{t('address')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {htmlPrintData?.map((row: any) => (
                              // <tr className={ index % 2 === 0 ? tableStyle.even : '' }>
                              <tr>
                                <td className={tableStyle.tdProjectName}>{row.project}</td>
                                <td className={tableStyle.td}>{row?.hour}</td>
                                <td className={tableStyle.td}>{row?.projectMolecule}</td>
                                <td className={tableStyle.td}>{row?.siteManager}</td>
                                <td className={tableStyle.td}>
                                  {row.names?.map((name: any, i: any) => (
                                    <div key={i}>{name}</div>
                                  ))}
                                </td>
                                <td className={tableStyle.td}>
                                  {row.material?.map((material: any, i: any) => (
                                    <div key={i}>{material}</div>
                                  ))}
                                </td>
                                <td className={tableStyle.td}>
                                  {row.vehicle?.map((vehicle: any, i: any) => (
                                    <div key={i}>{vehicle}</div>
                                  ))}
                                </td>
                                <td className={tableStyle.td}>{row?.address}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className={contentStyles.footer}>

                    <ReactToPrint
                      trigger={reactToPrintTrigger}
                      content={reactToPrintContent}
                      documentTitle={t('projectPlanboard') + ' ' + moment(selectedDate).format('DD-MM-YYYY')}
                      copyStyles={true}
                    />

                  </div>
                </Modal>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default PrintModal;