import React, { useEffect, useRef, useState } from 'react';
import { BarTask } from '../../types/bar-task';
import { GanttContentMoveAction } from '../../types/gantt-task-actions';
import { Bar } from './bar/bar';
import { BarSmall } from './bar/bar-small';
import { Milestone } from './milestone/milestone';
import { Project } from './project/project';
import style from './task-list.module.css';
import { messageService } from '../../../../services/messageService';
import { Product } from './product/product';
import { ChartType } from '../../types/public-types';

export type TaskItemProps = {
  task: BarTask;
  arrowIndent: number;
  taskHeight: number;
  isProgressChangeable: boolean;
  isDateChangeable: boolean;
  isDelete: boolean;
  isSelected: boolean;
  rtl: boolean;
  chartType: ChartType;
  onEventStart: (
    action: GanttContentMoveAction,
    selectedTask: BarTask,
    event?: React.MouseEvent | React.KeyboardEvent,
  ) => any;
};

export const TaskItem: React.FC<TaskItemProps> = props => {
  const {
    task,
    arrowIndent,
    isDelete,
    taskHeight,
    isSelected,
    rtl,
    onEventStart,
    chartType,
  } = {
    ...props,
  };
  const textRef = useRef<SVGTextElement>(null);
  const [taskItem, setTaskItem] = useState<JSX.Element>(<div/>);
  const [isTextInside, setIsTextInside] = useState(true);

  useEffect(() => {
    switch (task.typeInternal) {
      case 'milestone':
        setTaskItem(<Milestone {...props} />);
        break;
      case 'project':
        setTaskItem(<Project {...props} />);
        break;
      case 'smalltask':
        setTaskItem(<BarSmall {...props} />);
        break;
      case 'parentProduct':
        setTaskItem(<Product {...props} />);
        break;
      default:
        setTaskItem(<Bar {...props} />);
        break;
    }
  }, [task, isSelected]);

  useEffect(() => {
    if (textRef.current) {
      setIsTextInside(textRef.current.getBBox().width < task.x2 - task.x1);
    }
  }, [textRef, task]);

  const getX = () => {
    const width = task.x2 - task.x1;
    const hasChild = task.barChildren.length > 0;
    if (isTextInside) {
      return task.x1 + width * 0.5;
    }
    if (rtl && textRef.current) {
      return (
        task.x1 -
        textRef.current.getBBox().width -
        arrowIndent * +hasChild -
        arrowIndent * 0.2
      );
    } else {
      return task.x1 + width + arrowIndent * +hasChild + arrowIndent * 0.2;
    }
  };

  const getY = (task: BarTask) => {
    if (task.type === 'parentProduct') {
      return task.y - taskHeight + 25;
    } else {
      return task.y + taskHeight * 0.5;
    }
  };

  const getClassName = (task: BarTask) => {
    if (task.type === 'parentProduct') {
      return style.productLabel;
    } else {
      return isTextInside
        ? style.barLabel
        : style.barLabel && style.barLabelOutside;
    }
  };

  return (

    <g
      onKeyDown={e => {
        switch (e.key) {
          case 'Delete': {
            if (isDelete) onEventStart('delete', task, e);
            break;
          }
        }
        e.stopPropagation();
      }}
      onMouseEnter={e => {
        onEventStart('mouseenter', task, e);
      }}
      onMouseLeave={e => {
        onEventStart('mouseleave', task, e);
      }}
      onDoubleClick={e => {
        onEventStart('dblclick', task, e);
      }}
      onFocus={() => {
        onEventStart('select', task);
      }}
      onClick={(e) => {
        onEventStart('dblclick', task, e);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        messageService.sendMessage({
          rightClickTask: task,
        });
        //onEventStart("rightClick", task);
        //alert('rightClick')
      }}
    >
      {taskItem}
      {task.requiredQuantity ?
        <text
          x={getX()}
          y={task.y + taskHeight * 0.5}
          className={
            isTextInside
              ? style.barLabel
              : style.barLabel && style.barLabelOutside
          }
          ref={textRef}
        >
          {(task.resourceType === 'Tools' || task.resourceType === 'Labour') ? task.name + '      ' + '[' + task.filedQuantity + '/' + (task.requiredQuantity ? task.requiredQuantity : 0) + ']' : task.name}
          {/* {task.name +'      '+'['+task.filedQuantity+'/'+(task.requiredQuantity ? task.requiredQuantity: 0)+']'} */}

        </text>

        :
        <text
          x={getX()}
          // y={task.y + taskHeight * 0.5}
          y={getY(task)}
          className={getClassName(task)}
          ref={textRef}
        >

          {/* This projectTitle and removeTitleFromChart are only used in midtermPlanning organization taxonomy chart !!! */}
          {task?.projectTitle ? task?.projectTitle : task?.removeTitleFromChart ? null : task.name}


        </text>
      }
    </g>

  );
};
