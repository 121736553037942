import React from 'react';
import {Document, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {store} from '../../../../index';
import {formatwithoutTime} from '../../../shared/util';


const isMobile = screen.width < 768;
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    width: '50%',
    textAlign:'right',
    justifyContent:'flex-end',
    alignContent:'flex-end',
    alignSelf:'flex-end',
    alignItems:'flex-end',
    marginBottom:20
  },
  signatureImage: {
    textAlign:'left',justifyContent:'flex-start',
    alignContent:'flex-start',
    alignSelf:'flex-start',
    alignItems:'flex-start',
    width: '60%',
    marginTop:5
  },
  signatureChiefImage: {
    textAlign:'left',justifyContent:'flex-start',
    alignContent:'flex-start',
    alignSelf:'flex-start',
    alignItems:'flex-start',
    width: '50%',
    marginTop:5
  },
  signature: {
    width: 100,
    height: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#020202',
  },
  tableBody: {
    display: 'flex', // error TS2322: Type '"table"' is not assignable to type '"none" | "flex" | undefined'.
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderBottomWidth: 0,
  },
  tableCol: {
    width: '60%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 10,
  },
  tableCol2: {
    width: '40%',
    // textAlign: 'right',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 8,
    paddingLeft: 8,
    fontSize: 10,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
});

const itemTableWidth = {
  col: '30%',
  col2: '25%',
  col3: '20%',
  col4: '25%',
  col5: '20%',
};

const backgroundColor = {
  color1: '#ffffff',
  color2: '#ffffff',
};

// const formatDateTimeToLocal = (date: moment.MomentInput) => {
//   const formatted = moment.utc(date).local().format('DD/MM/YYYY');
//   return formatted;
// };

const PdfTemplate=(signature:any,fullName:any)=>{
  const {rfqFormData}=store.getState()?.po;
  console.log('rfqFormData > ',rfqFormData);
  const supplierContact=rfqFormData?.supplierContact;
  const customerContact=rfqFormData?.customerContact;
  const externalProduct=rfqFormData.externalProduct && rfqFormData.externalProduct[0]?rfqFormData.externalProduct[0]:{};

  return <Document>
    <Page wrap style={styles.body}>
      <Text style={styles.header} fixed>
        {/*~ Created with react-pdf ~*/}
      </Text>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text>Purchase Order: Annex to Framework Contract for (Sub)contracting Dated   {rfqFormData?.resourceLeadTime} </Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text>Between:  </Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text> <Text>Defraeye Bob bv</Text>  with registered offices at 8600 Diksmuide, Kleine Dries 8 and company number 0430.896.566 represented by Chief Executive Officer Hendrik Defraeye.  </Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text> Hereinafter referred to as "Defraeye Bob bv".</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{ width: '50%',fontSize: 10,textAlign:'left' }}>
          <Text><Text style={{fontSize:11}}>And:</Text>  - {rfqFormData?.supplierOrganisation?rfqFormData?.supplierOrganisation:''}  – {supplierContact?supplierContact?.vat:''}   </Text>
          <Text>with registered offices
            at {rfqFormData?.supplierCity} </Text>
          <Text>represented by</Text>
          <Text>NAME: {supplierContact?supplierContact?.customerContact:''}</Text>
          <Text>POSITION: {rfqFormData?.customerJobRole} </Text>
        </View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text> Hereinafter referred to as "the Subcontractor".</Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text> THE FOLLOWING IS HEREBY AGREED:</Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text> Article 1</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'90%',fontSize:10,fontWeight:600}}><Text> This Purchase Order is an Annex to the Framework Contract entered into between the parties ("the Contract") and is an integral part of it. </Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text> Article 2</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text> The Subcontractor agrees to perform the following Assignment: Preparing and chaping floors. </Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text> The Subcontractor acknowledges that they have received the charge sheet for this Assignment </Text></View>
      </View>

      {/*<View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>*/}
      {/*  <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text>Place of performance of the Assignment: {rfqFormData?.mapLocation?rfqFormData?.mapLocation?.address?.freeformAddress:''} </Text></View>*/}
      {/*</View>*/}
      <View style={{ width: '100%', flexDirection: 'row',marginTop:5 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text>Start date and duration of the Assignment: {rfqFormData?.deliveryDate?formatwithoutTime(rfqFormData?.deliveryDate):''}</Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text> Article 3</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text> The Subcontractor shall appoint the following person to act as the central communications person for the performance of the Assignment described in Article 2 of this Annex in accordance with Article 3.2, paragraph 3 of the Contract: {supplierContact && supplierContact?.customerContact}  </Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text> Article 4</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text>The Assignment described in this Annex will be reimbursed at a price of   € {rfqFormData?.externalProduct[0]?.cUnitPrice}/m²  (excl. VAT). </Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text> Article 5</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text>The client expressly notifies the Subcontractor that information concerning the wages due is included on the following website of the FPS WASO: https://www.minimumlonen.be and on FPS WASO’s own website: https://employment.belgium.be/en/. The Subcontractor confirms that it is paying and will continue to pay the wages due to its employees.  </Text></View>
      </View>



      <View style={{ width: '100%', flexDirection: 'row',marginTop:25 }}>
        <View style={{width:'100%',fontSize:12,fontWeight:600}}><Text>Article 6: Final Provisions</Text></View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text>The Contract is drawn up in Dutch, and translated into Polish, whereby the parties agree that only the Dutch version is considered official. </Text></View>
      </View>
      <View style={{ width: '100%', flexDirection: 'row',marginTop:20 }}>
        <View style={{width:'100%',fontSize:10,fontWeight:600}}><Text>Drawn up in Diksmuide on {rfqFormData?.requestedDate?formatwithoutTime(rfqFormData?.requestedDate):''}  in as many copies as there are parties, of which each party declares to have received a copy. </Text></View>
      </View>


      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ width: '50%' }}>
          <View style={{ width: '100%', flexDirection: 'column',marginTop:50 }}>
            <View style={{width:'100%',fontSize:10}}><Text> Defraeye Bob bv,</Text></View>
            <View style={{width:'100%'}}><Image
              style={styles.signatureChiefImage}
              src={require(`../../../../images/careyeChiefSignature.png`).default}
            /></View>
            <View style={{width:'45%',fontSize:10}}><Text>Chief Executive Officer</Text></View>
          </View>
        </View>
        <View style={{ width: '50%',fontSize: 12 }}>
          <View style={{ width: '100%', flexDirection: 'column',marginTop:50 }}>
            <View style={{width:'100%',fontSize:10}}><Text> The Subcontractor,</Text></View>
            <View style={{width:'100%'}}><Image
              style={styles.signatureImage}
              src={signature}
            /></View>
            <View style={{width:'45%',fontSize:10}}><Text>{fullName}</Text></View>
          </View>
        </View>
      </View>



      <View style={{ width: '100%', flexDirection: 'row',marginTop:5 }}>
        {/*<View style={{width:'100%',fontSize:10}}><Text>{getFooterText()}</Text></View>*/}
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed/>
    </Page>
  </Document>
}

const getFooterText=()=>{
  const language =  window.navigator.language;
  if(language){
    switch (language) {
      case "en-US":
        return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
      case "en":
        return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
      case "nl":
        return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
      case "nl-BE":
        return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
      case "de":
        return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
      default:
        return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
    }

  }else{
    return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
  }
};

const Table = (props: any) => {
  return (
    <View style={styles.table}>
      {props.children}
    </View>
  );
};
const TableRow = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableRow.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      styles.tableRow.borderColor = props.style.borderColor;
      styles.tableRow.borderBottomWidth = 2;
    } else {
      styles.tableRow.borderBottomWidth = 0;
    }
  }
  return (
    <View wrap={false} style={styles.tableRow}>
      {props.children}
    </View>
  );
};
const TableCol = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableCol.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableCol.borderBottomWidth = 0;
        styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableCol.borderBottomWidth = 1;
        styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }
    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableCol.borderBottomWidth = 0;
      styles.tableCol.borderRightWidth = 0;
    }

    if (props.style.width) {
      styles.tableCol.width = props.style.width;
    } else {
      styles.tableCol.width = '40%';
    }
  }
  return (
    <View style={styles.tableCol}>
      {props.children}
    </View>
  );
};
const TableCol2 = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableCol2.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableCol2.borderBottomWidth = 0;
        styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableCol2.borderBottomWidth = 1;
        styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }

    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableCol2.borderBottomWidth = 0;
      styles.tableCol2.borderRightWidth = 0;
    }
    if (props.style.width) {
      styles.tableCol2.width = props.style.width;
    } else {
      styles.tableCol2.width = '60%';
    }
  }
  return (
    <View style={styles.tableCol2}>
      {props.children}
    </View>
  );
};


export default PdfTemplate