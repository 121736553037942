import {
  Checkbox,
  ContextualMenu,
  DetailsRow,
  Fabric,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  IDragOptions,
  IIconProps,
  Link,
  MarqueeSelection,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { _renderDetailsFooterItemColumn } from './filterHeader';
import { IDetailsListProps, IDetailsRowStyles, Selection } from '@fluentui/react/lib/DetailsList';
import { withRouter } from 'react-router';
import { readCostListData } from './../../../../reducers/progressStatementReducer';
import { Cost, CostFilter, ProjectCost } from '../../../../types/progressStatement';
import {
  formatDateTimeToISOString,
  formatEndDateTimeToISOString,
  getProject,
  getSelectedCostList,
} from '../../../../shared/util';
import { messageService } from '../../../../services/messageService';
import { readDropdownData as readPBSDropdownData } from './../../../../reducers/projectBreakdownReducer';

const theme = getTheme();

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '95vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 264px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

interface State {
  product: string,
  costFilter: CostFilter,
  selectedCostList: ProjectCost[],
  disableConfirmBtn: boolean,
  data: ProjectCost[]
}

interface Props extends WithTranslation {
  isOpen: boolean;
  openModal: () => void;
  readCostListData: any;
  readPBSDropdownData: any;
  costList: ProjectCost[];
  onConfirmSelection: (list: Cost[]) => void;
  psId: string | null;
  pbsTypes: [],
}

class CostModal extends Component<Props, State> {
  private _selection: Selection;
  subscription: any;
  private _columns: IColumn[];

  constructor(props: Props) {
    super(props);
    this._selection = new Selection(
      {
        onSelectionChanged: () => {
          if (this._selection.getSelection().length > 0) {
            this.setState({
              disableConfirmBtn: true,
              selectedCostList: this._selection.getSelection() as ProjectCost[],
            });
          } else {
            this.setState({ disableConfirmBtn: false, selectedCostList: [] });
          }

        },
      },
    );
    this.state = {
      product: '',
      costFilter: new CostFilter(),
      selectedCostList: [],
      disableConfirmBtn: true,
      data: [{
        id: '0c254d94-26e7-4241-a308-39a0171df4b6',
        productTitle: 'PBS-0098',
        borTitle: 'BOR-0151',
        date: null,
        resourceType: 'Materials',
        pmolTitle: 'PMOL-0059 - XDSS',
        resourceNumber: 1,
        resourceTitle: 'xyz Materials',
        mou: null,
        consumedQuantity: 4,
        costMou: 20,
        totalCost: 80,
        resourceTypeId: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
      },
        {
          id: '0c254d94-26e7-4241-a308-39a0171df44234',
          productTitle: 'PBS-0098',
          borTitle: 'BOR-0151',
          date: null,
          resourceType: 'Materials',
          pmolTitle: 'PMOL-0059 - XDSS',
          resourceNumber: 1,
          resourceTitle: 'Abc Materials',
          mou: null,
          consumedQuantity: 6,
          costMou: 50,
          totalCost: 300,
          resourceTypeId: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
        },
        {
          id: '0c254d94-26e7-4241-a308-39a0171df4b6',
          productTitle: 'PBS-0098',
          borTitle: 'BOR-0151',
          date: null,
          resourceType: 'Tools',
          pmolTitle: 'PMOL-0059 - XDSS',
          resourceNumber: 2,
          resourceTitle: 'pqr Materials',
          mou: null,
          consumedQuantity: 3,
          costMou: 40,
          totalCost: 120,
          //resourceTypeId: 'c46c3a26-39a5-42cc-n9wn-89655304eh6'
          resourceTypeId: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
        },
        {
          id: '0c254d94-26e7-4241-a308-39a0171df5b6',
          productTitle: 'PBS-0098',
          borTitle: 'BOR-0151',
          date: null,
          resourceType: 'Tools',
          pmolTitle: 'PMOL-0059 - XDSS',
          resourceNumber: 2,
          resourceTitle: 'mno MAt',
          mou: null,
          consumedQuantity: 3,
          costMou: 40,
          totalCost: 120,
          resourceTypeId: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
          //resourceTypeId: 'c46c3a26-39a5-42cc-n9wn-89655304eh6'
        },
        {
          id: '0c254d94-26e7-4241-a308-39a0171df4b6',
          productTitle: 'PBS-0046',
          borTitle: 'BOR-0134',
          date: null,
          resourceType: 'Tools',
          pmolTitle: 'PMOL-0059 - XDSS',
          resourceNumber: 2,
          resourceTitle: 'opq Tools',
          mou: null,
          consumedQuantity: 2,
          costMou: 30,
          totalCost: 60,
          resourceTypeId: 'c46c3a26-39a5-42cc-n9wn-89655304eh6',
        },
        {
          id: '0c254d94-26e7-3441-a308-39a0171df4b6',
          productTitle: 'PBS-0050',
          borTitle: 'BOR-0134',
          date: null,
          resourceType: 'Consumables',
          pmolTitle: 'PMOL-0059 - XDSS',
          resourceNumber: 2,
          resourceTitle: 'suv Consumable',
          mou: null,
          consumedQuantity: 1,
          costMou: 34,
          totalCost: 34,
          resourceTypeId: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
        },
        {
          id: '0c254d94-26e7-8941-a308-39a0171df4b6',
          productTitle: 'PBS-0050',
          borTitle: 'BOR-0134',
          date: null,
          resourceType: 'Labour',
          pmolTitle: 'PMOL-0059 - XDSS',
          resourceNumber: 2,
          resourceTitle: 'abc Labour',
          mou: null,
          consumedQuantity: 2,
          costMou: 200,
          totalCost: 400,
          resourceTypeId: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
        }],
    };
    this._columns = [
      {
        key: 'column1',
        name: this.props.t('productTitle'),
        fieldName: 'productTitle',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isPadded: true,
        isSorted: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('productType'),
        fieldName: 'productType',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('pmolTitle'),
        fieldName: 'pmolTitle',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('resourceType'),
        fieldName: 'resourceType',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column6',
        name: this.props.t('resourceTitle'),
        fieldName: 'resourceTitle',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column7',
        name: this.props.t('mou'),
        fieldName: 'mou',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column8',
        name: this.props.t('consumedquantity'),
        fieldName: 'consumedQuantity',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column9',
        name: this.props.t('costMOU'),
        fieldName: 'costMou',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column10',
        name: this.props.t('totalCost'),
        fieldName: 'totalCost',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column11',
        name: this.props.t('date'),
        fieldName: 'date',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
        // onRender: (item: BorResourcesItem) => {
        //   if (item.date) {
        //     return <span>{format24hDateTime(item.date)}</span>;
        //   } else {
        //     return <span></span>;
        //   }
        // },
      },
      {
        key: 'column12',
        name: this.props.t('projectCostType'),
        fieldName: 'isPlannedResource',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
    ];
  }

  onChangeProduct = (value: string | null) => {

    this.setState((prevState) => ({
        //isOpenNameModal: false,
        costFilter: {
          ...prevState.costFilter,
          productTitle: value != '' ? value : null,
        },
      }), () => {
        if ((this.state.costFilter.productTitle && this.state.costFilter.productTitle.length >= 3) || value === '') {
          this.props.readCostListData(this.state.costFilter);
        }
      },
    );
  };

  onChangeBORTitle = (value: string | null) => {
    //this.setState({ product: value!! });

    this.setState((prevState) => ({
        //isOpenNameModal: false,
        costFilter: {
          ...prevState.costFilter,
          borTitle: value != '' ? value : null,
        },
      }), () => {
        if ((this.state.costFilter.borTitle && this.state.costFilter.borTitle.length >= 3) || value === '') {
          this.props.readCostListData(this.state.costFilter);
        }
      },
    );


  };

  onChangeResourceTitle = (value: string | null) => {
    //this.setState({ product: value!! });

    this.setState((prevState) => ({
        //isOpenNameModal: false,
        costFilter: {
          ...prevState.costFilter,
          resourceTitle: value != '' ? value : null,
        },
      }), () => {
        if ((this.state.costFilter.resourceTitle && this.state.costFilter.resourceTitle.length >= 3) || value === '') {
          this.props.readCostListData(this.state.costFilter);
        }
      },
    );


  };

  onChangeProductType = (value: string | null) => {
    //this.setState({ product: value!! });

    this.setState((prevState) => ({
        //isOpenNameModal: false,
        costFilter: {
          ...prevState.costFilter,
          productType: value != '0' ? value : null,
        },
      }), () => {
        // if ((this.state.costFilter.resourceTypeId && this.state.costFilter.resourceTypeId.length >= 3) || value === '') {
        this.props.readCostListData(this.state.costFilter);
        //}
      },
    );
  };

  onChangeResourceType = (value: string | null) => {
    //this.setState({ product: value!! });

    this.setState((prevState) => ({
        //isOpenNameModal: false,
        costFilter: {
          ...prevState.costFilter,
          resourceTypeId: value != '-1' ? value : null,
        },
      }), () => {
        // if ((this.state.costFilter.resourceTypeId && this.state.costFilter.resourceTypeId.length >= 3) || value === '') {
        this.props.readCostListData(this.state.costFilter);
        //}
      },
    );
  };

  onDateSelected = (dateRange: any) => {
    this.setState((prevState) => ({
        //isOpenNameModal: false,
        costFilter: {
          ...prevState.costFilter,
          strDate: formatDateTimeToISOString(dateRange.from),
          endDate: formatEndDateTimeToISOString(dateRange.to),
        },
      }), () => {
        if (this.state.costFilter.strDate || dateRange.strDate === '') {
          this.props.readCostListData(this.state.costFilter);
        }
      },
    );
  };

  _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={'/time_clock/' + props.item.value}
          onClick={() => {
            //onQRItemClick(props.item);
          }}
        >
          <DetailsRow {...props} styles={customStyles}/>
        </Link>
      );
    }
    return null;
  };

  onRenderCheckbox = (props: any) => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} checkmarkIconProps={{ iconName: 'Add' }}/>
      </div>
    );
  };

  componentDidMount() {
    if (getProject()) {
      this.props.readCostListData(this.state.costFilter);
    }
    if (this.props.isOpen) {
      this.props.readPBSDropdownData();
    }

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.resetCostList) {
          this.resetFilter();
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.psId != this.props.psId) {
      this._selection.setAllSelected(false);
    }
    if (prevProps.isOpen != this.props.isOpen) {
      if (this.props.isOpen) {
        this.props.readPBSDropdownData();
      }
    }
    if (prevProps.costList != this.props.costList) {
      this._selection.setAllSelected(false);
    }
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.costFilter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.projectCostSortingModel.attribute = fieldName ? fieldName : null;
        filter.projectCostSortingModel.order = sortby ? sortby : null;
        this.setState(
          {
            costFilter: filter,
          }, () => {
            this.props.readCostListData(this.state.costFilter);
          },
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };


  resetFilter = () => {
    this.setState({
      costFilter: new CostFilter(),
    }, this.readCostListData);
    this._selection.setAllSelected(false);
  };

  readCostListData = () => {
    this.props.readCostListData(this.state.costFilter);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          onDismiss={() => {
            this.props.openModal();
          }}
          scrollableContentClassName="body"
          isBlocking={false}
          className={'grid-righter column-filter-activated'}
        >
          <div className={contentStyles.header}>
            <span>{this.props.t('projectCostList')}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                this.props.openModal();
              }}
            />
          </div>

          <div className={contentStyles.body}>
            <div className="proj-detail-content inner">
              <Fabric>
                <div className={`wrapper-holder ${classNames.wrapper}`}>
                  <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <MarqueeSelection selection={this._selection}>
                      <ShimmeredDetailsList
                        items={this.props.costList}
                        // items={this.state.data}
                        columns={this._columns}
                        setKey="set"
                        compact={false}
                        layoutMode={1}
                        enableShimmer={false}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        // onRenderItemColumn={(
                        //   item: QRCodeLite,
                        //   index?: number,
                        //   column?: IColumn
                        // ) => TableColumn(item, index, column)}
                        onRenderDetailsFooter={(
                          detailsFooterProps,
                          defaultRender,
                        ) => {
                          if (defaultRender !== undefined) {
                            return (
                              <DetailsRow
                                className="footer-as-filter"
                                {...detailsFooterProps}
                                columns={detailsFooterProps?.columns}
                                item={{}}

                                itemIndex={-1}
                                groupNestingDepth={
                                  detailsFooterProps?.groupNestingDepth
                                }
                                selectionMode={SelectionMode.single}
                                selection={detailsFooterProps?.selection}
                                onRenderItemColumn={(
                                  item?: any,
                                  index?: number,
                                  column?: IColumn,
                                ) =>
                                  _renderDetailsFooterItemColumn(
                                    this.state.costFilter.productTitle!!,
                                    this.onChangeProduct,
                                    this.props.pbsTypes,
                                    this.state.costFilter.productType!!,
                                    this.state.costFilter.borTitle!!,
                                    this.state.costFilter.resourceTitle!!,
                                    this.onChangeBORTitle,
                                    this.onChangeResourceTitle,
                                    this.state.costFilter.resourceTypeId!!,
                                    this.onChangeProductType,
                                    this.onChangeResourceType,
                                    this.onDateSelected,
                                    this.state.costFilter,
                                    t,
                                    item,
                                    index,
                                    column,
                                  )
                                }
                              />
                            );
                          } else {
                            return <span></span>;
                          }
                        }}
                        onRenderDetailsHeader={(headerProps, defaultRender) => {
                          if (defaultRender !== undefined) {
                            return (
                              <Sticky
                                stickyPosition={StickyPositionType.Header}
                                isScrollSynced={true}
                                stickyBackgroundColor="transparent"
                              >
                                <div>
                                  {defaultRender(headerProps)}
                                </div>
                              </Sticky>
                            );
                          } else {
                            return <span></span>;
                          }
                        }}
                        onItemInvoked={() => {
                        }}
                        onRenderRow={this._onRenderRow}
                        onRenderCheckbox={this.onRenderCheckbox}
                        // onRenderCheckbox={(props)=>{
                        //   return (
                        //       <div >
                        //           <Checkbox
                        //           checkmarkIconProps={{iconName:'Add'}}
                        //           {...props}

                        //           />
                        //       </div>
                        //   );
                        // }
                        // }
                      />
                    </MarqueeSelection>
                  </ScrollablePane>
                  {!status && (
                    <div style={{ padding: 20, paddingTop: 150 }}>

                    </div>
                  )}
                </div>
              </Fabric>

            </div>
          </div>
          <div className={contentStyles.footer}>
            <PrimaryButton

              text={t('reset')}
              style={{ marginTop: 20, marginBottom: 30, marginRight: 10 }}
              onClick={() => this.resetFilter()}
            />
            <PrimaryButton

              text={t('confirm')}
              disabled={this.state.selectedCostList.length === 0 ? true : false}
              style={{ marginTop: 20, marginBottom: 30 }}
              onClick={() => this.props.onConfirmSelection(getSelectedCostList(this.state.selectedCostList))}
            />
          </div>


        </Modal>

      </div>
    );
  }
}


const mapStateToProps = (state: any, ownProps: any) => {
  return {
    costList: state.ps.costList,
    isLoadingCostLIst: state.ps.isLoadingCostLIst,
    pbsTypes: state.projectBreakdown.itemTypesFilter,

  };
};

const mapDispatchToProps = {
  readCostListData,
  readPBSDropdownData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CostModal)),
);
