import React from 'react';
import '../../style/style.css';
import {AnimationStyles, getTheme, ITheme, mergeStyles, Stack} from '@fluentui/react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import history from '../../../history';
import {withTranslation, WithTranslation} from 'react-i18next';
import BookMarkPaneComponent from '../../projectMolecule/bookMarkPane/component';
import DocumentPane from '../../projectMolecule/documentPane/container';
import {CorporateProductCatalogue, CorporateProductCatalogueListItem,} from '../../../types/corporateProductCatalogue';
import {excelExport, getContractingUnit, getProject} from '../../../shared/util';
import {
  BorFormData,
  ListPaneItem,
  PMOL_TYPE,
  pmolFormData,
  PMOLListPaneFilter,
  PMOLShortCutPaneItem,
  ProjectMolecule,
} from '../../../types/projectMolecule';
import {messageService} from '../../../services/messageService';
import {getRiskDropDownsList, resetIsChange, setUid} from '../../../reducers/projectBreakdownReducer';
import {addUserProject} from '../../../reducers/cuProjectReducer';
import {clearFormData, getProjectDefinition} from '../../../reducers/projectReducer';
import CollapseBookMarkPane from '../../../shared/collapseBookMarkPane/bookMarkPane';
import {
  clonePmol,
  filterPMOLListPaneData,
  readByPMOLId,
  readPMOLByID,
  readPmolData,
  readPMOLDropDownData,
  readPMOLShortCutPaneData,
} from '../../../reducers/pmolReducer';

export interface State {
  selectedShortCutItem: PMOLShortCutPaneItem;
  pmolFilter: PMOLListPaneFilter;
  projectId: string | null;
  pbsId: string | null;
  formData: ProjectMolecule;

  isClone: boolean;
  submitCpc: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  headerFilter: boolean;
  showDetails: boolean;
  currentActiveSection: string;
  isOpenNickNameModel: boolean;
  allProductCatalogue: [];
  uploadedImages: string | null;
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  cpcListItem: CorporateProductCatalogueListItem[];
  cpcPressureClass: [];
  selectedListPaneItemIds: [] | string[];
  //headerTitle:string|null,
  hiddenRemoveDialog: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  pmolType: PMOL_TYPE;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  readPMOLShortCutPaneData: () => void;
  showProgressBar: boolean;
  //readDropdownData: () => void;
  shortCutPaneFilters: PMOLShortCutPaneItem[];
  filterPMOLListPaneData: (filterItem: PMOLListPaneFilter) => void;
  listPaneItems: ListPaneItem[];
  formData: ProjectMolecule;
  setUid: () => void;
  clearUid: () => void;
  clearFormData: () => void;
  resetIsChange: () => void;
  uid: string | null;
  readByPMOLId: (id: string) => void;
  clonePmol: (id: string) => any;
  getExcelData: () => void;
  newProductCatalogue: () => void;
  getOrganizationByName: (name: string) => void;
  getCpcResourceFamily: () => void;
  deleteProduct: (idList: [] | string[]) => void;
  deleteProductCatalogueNickName: (idList: [] | string[]) => void;
  deleteProductCatalogueVendor: (idList: [] | string[]) => void;
  deleteProductCatalogueImage: (idList: [] | string[]) => void;
  deleteProductCatalogue: (idList: [] | string[]) => void;
  saveProductCatalogue: (productCatalogue: CorporateProductCatalogue) => void;
  getProductCatalogueById: (id: string) => void;
  readPMOLDropDownData: () => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  getRiskDropDownsList: () => void;
  cpcListItem: CorporateProductCatalogueListItem[];
  uploadedImages: string | null;
  resourceTypes: [];
  resourceTypeFilter: [];
  resourceFamily: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  languageCodes: [];
  organizations: [];
  cpcUnitOfSizeMeasure: [];
  excelData: [];
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  addUserProject: any;
  getProjectDefinition: any,
  selectedProject: any,
  readPmolData: any,
  readPMOLByID: any,
  horizontalReSizer: number
}

const contentStyle = mergeStyles({
  height: 'calc(100vh - 250px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

class VPPMOLDocumentPane extends React.Component<Props, State> {
  _isMounted = false;
  isEdit = false;
  theme: ITheme = getTheme();
  subscription: any;
  //this.props.t
  private bookmarkList = [
    {
      id: 1,
      link: 'pbs-product-header',
      label: this.props.t('productHeader'),
    },
    {
      id: 2,
      link: 'pbs-product-detail',
      label: this.props.t('productDetails'),
    },
    { id: 3, link: 'pbs-competency', label: this.props.t('competencies') },
    { id: 4, link: 'pbs-resources', label: this.props.t('resources') },
    { id: 5, link: 'pbs-instructions', label: this.props.t('instructions') },
    { id: 6, link: 'pbs-risks', label: this.props.t('risks') },
    { id: 7, link: 'pbs-quality', label: this.props.t('quality') },
    {
      id: 8,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedShortCutItem: { key: null, id: null, name: this.props.t('all'), type: 'all', value: null },
      pmolFilter: new PMOLListPaneFilter(),
      projectId: null,
      pbsId: null,

      hiddenRemoveDialog: true,
      isClone: false,
      submitCpc: false,
      selectedListPaneItemIds: [],
      cpcListItem: [],
      selectedCpcId: null,
      isOpenNickNameModel: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel-vp parent pmol-bookmark-panel',
      currentActiveSection: '1',
      showDetails: false,
      allProductCatalogue: [],
      uploadedImages: null,
      formData: pmolFormData,
      cpcPressureClass: [],
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: window.innerWidth,
      pmolType: PMOL_TYPE.REGULAR,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this.props.readPmolData();
    this.props.readPMOLShortCutPaneData();
    //this.props.readDropdownData();
    this.props.getRiskDropDownsList();
    this.getListPaneData();
    this.handleUrlChange();
    //
    this.props.readPMOLDropDownData();
    // this.props.getCpcResourceFamily();
    // this.props.getExcelData();
    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    if (location.pathname.split('/').pop() === 'new' && this.state.formData && this.state.formData.id === null) {
      this.createNew(true);
    }
    this.setState({
      formData: this.props.formData,
    }, this.handleShowDocumentPane);
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          //this.hideDocumentPane();
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            reSizer: (this.state.reSizer === 300) ? window.innerWidth / 2 : 300,
            isDrag: (!this.state.isDrag),
            collapseBookmarkPane: (!this.state.collapseBookmarkPane),
            resetLayout: (!this.state.resetLayout),
          });
        }
        if (data.data.shortCutPaneExpanded) {
          if (this.state.showDetails) {
            this.setState({
              reSizer: window.innerWidth / 2,
              isDrag: false,
              collapseBookmarkPane: false,
              resetLayout: true,
            });
          }
        }

        if (data.data.pmolTypeFromRead) {
          this.setState({ pmolType: data.data.pmolTypeFromRead });
        }
        if (data.data.pmolType) {
          this.setState({ pmolType: data.data.pmolType });
        }

        if (data && data.data && data.data.hasOwnProperty('isLoadingPMOLData') && data.data.isLoadingPMOLData) {
          this.setState({ isDisableNewButton: true });
        } else if (data.data.hasOwnProperty('isLoadingPMOLData') && !data.data.isLoadingPMOLData) {
          this.setState({ isDisableNewButton: false });
        }
      }
    });

    // this.props.getProjectDefinition(getProject()).then((response:any) => {
    //   if (response && response.payload && response.payload.data && response.payload.data.result && response.payload.data.result) {
    //     this.props.addUserProject(response.payload.data.result.id).then((res:any) => {
    //       messageService.sendMessage({waypointRefresh:true})
    //     })
    //   }
    // });
    if (this.props.selectedProject?.id && this.props.selectedProject?.id !== '0') {
      this.props.addUserProject(this.props.selectedProject.id).then((res: any) => {
        messageService.sendMessage({ waypointRefresh: true });
      });
    }

  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange(prevState.collapseBookmarkPane);
    }
    if (this.props.match?.params?.id !== prevProps.match?.params?.id) {
      this.getListPaneData();
    }

    if (this.props.selectedProject !== prevProps.selectedProject) {
      if (this.props.selectedProject && this.props.selectedProject.id !== prevProps.selectedProject.id && prevProps.selectedProject.id !== '0') {
        this.props.addUserProject(this.props.selectedProject.id).then((res: any) => {
          messageService.sendMessage({ waypointRefresh: true });
        });
      }
    }

    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
        //pmolType:this.props.formData.pmolType?this.props.formData.pmolType:this.state.pmolType,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.pmolFilter != this.props.location.state.pmolFilter
    ) {
      if (this.props.location.state.pmolFilter) {
        this.setState(
          {
            pmolFilter: this.props.location.state.pmolFilter,
          },
          this.reloadListPaneItem,
        );
      }
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let lastParam = this.props.match.url.split('/').pop();
    let projectId = this.props.match.params.pmolId;
    let pmolId = this.props.match.params.pmolId
      ? this.props.match.params.pmolId
      : null;
    this.setState(
      {
        isEdit: !!this.props.match.params.id,
        projectId: projectId,
        pbsId: pmolId,
        isDisableNewButton: pmolId ? true : false,
        resetLayout: collapseBookmarkPane ? false : true,
        collapseBookmarkPane: collapseBookmarkPane ? true : false,
      }, () => {
        this.handleShowDocumentPane(collapseBookmarkPane);
      });
    if (pmolId) {
      //this.props.readByPMOLId(pmolId);
      this.props.readPMOLByID(pmolId);
    }
  }

  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.showDetails) {
        if (this.state.width > 1100) {
          this.setState({ showPanel: false });
        } else {
          this.setState({ showPanel: true });
        }
      }
    }
  }

  private handleShowDocumentPane = (collapseBookmarkPane?: boolean) => {
    let pmolId = this.props.match.params.pmolId
      ? this.props.match.params.pmolId
      : null;
    if (pmolId) {
      if (this.state.width > 1100) {
        this.setState({
          showPanel: false,
          showDetails: true,
          reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
        });
      } else {
        this.setState({
          showPanel: true,
          showDetails: true,
          reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
        });
      }
    } else if (location.pathname.split('/').pop() === 'new') {
      if (this.state.width > 1100) {
        this.setState({ showPanel: false, showDetails: true, reSizer: window.innerWidth / 2 });
      } else {
        this.setState({ showPanel: true, showDetails: true, reSizer: window.innerWidth / 2 });
      }
    } else {
      //this.props.resetFormData();
      this.setState({ showPanel: false, showDetails: false, reSizer: window.innerWidth });
    }
  };

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions);
    this.subscription.unsubscribe();
  }

  getListPaneData = () => {
    this.props.filterPMOLListPaneData(this.state.pmolFilter);
  };

  private handleSelectedListPaneItem = (
    selectedListPaneItemIds: [] | string[],
  ) => {
    this.setState({
      selectedListPaneItemIds: selectedListPaneItemIds,
      isClone: false,
      submitCpc: false,
    });
  };

  private handleCloneItem = () => {
    let formData = this.state.formData;

    this.setState({
      isClone: true,
    });
    if (this.state.selectedListPaneItemIds.length >= 1 && this.state.formData.projectMoleculeId) {
      this.props.clonePmol(this.state.formData.projectMoleculeId);
    }
  };

  savePicturesOfTheInstallation = (files: FileList | null) => {
    if (files) {
      this.props.savePicturesOfTheInstallation(files);
    }
  };

  private createNew = (create: boolean, type?: PMOL_TYPE): void => {
    this.props.clearFormData();
    this.props.setUid();
    let formData: ProjectMolecule = {
      id: this.props.uid ? this.props.uid : null,
      projectMoleculeId: null,
      title: null,
      headerTitle: null,
      productId: null,
      productTitle: null,
      projectId: null,
      name: null,
      typeId: null,
      statusId: null,
      foremanMobileNumber: null,
      executionDate: null,
      executionStartTime: null,
      executionEndTime: null,
      foreman: null,
      foremanId: null,
      comment: null,
      bor: BorFormData,
      historyLog: null,
      isSaved: false,
      typeNo: 0,
      locationId: null,
      isFinished: false,
      pmolType: type ? type : PMOL_TYPE.REGULAR,
      projectDefinition: null,
    };
    this.setState({
      formData: formData,
    });
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/project-molecule/new`,
      state: { type: type },
    });
  };

  reloadListPaneItem = () => {
    //
    this.props.resetIsChange();
    setTimeout(() => {
      this.getListPaneData();
    }, 100);
    //this.props.getExcelData();
  };

  // private handleActionBarSave = (save: boolean): void => {
  //   this.setState({
  //     submitCpc: save,
  //   });
  // };

  private getDocumentPaneView = () => {
    return (
      <div>


        <Stack
          //className={`project-detail-list ${(this.state.collapseBookmarkPane && window.innerWidth>1440)?'full-inner-container':'inner-container'} cpc  card-container`}
          styles={{
            root: {
              width: '100%',
              height: '76vh',
            },
          }}
        >
          {/*doc*/}
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              <DocumentPane
                projectId={this.state.projectId}
                submitCpc={this.state.submitCpc}
                isEdit={this.state.isEdit}
                selectedCpcId={this.state.selectedCpcId}
                formData={this.state.formData}
                savePicturesOfTheInstallation={(files: FileList | null) =>
                  this.savePicturesOfTheInstallation(files)
                }
                saveProductCatalogue={(
                  productCatalogue: CorporateProductCatalogue,
                ) => {
                  this.setState({
                    submitCpc: false,
                  });
                  this.props.saveProductCatalogue(productCatalogue);
                }}
                deleteProductCatalogueNickName={(deleteList: [] | string[]) => {
                  this.props.deleteProductCatalogueNickName(deleteList);
                }}
                deleteProductCatalogueVendor={(deleteList: [] | string[]) => {
                  this.props.deleteProductCatalogueVendor(deleteList);
                }}
                deleteProductCatalogueImage={(deleteList: [] | string[]) => {
                  this.props.deleteProductCatalogueImage(deleteList);
                }}
                autoSave={this.props.autoSave}
                uploadedImages={this.props.uploadedImages}
                resourceTypes={this.props.resourceTypes}
                resourceFamily={this.props.resourceFamily}
                languageCodes={this.props.languageCodes}
                cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
                cpcPressureClass={this.props.cpcPressureClass}
                cpcMaterial={this.props.cpcMaterial}
                cpcBrand={this.props.cpcBrand}
                organizations={this.props.organizations}
                cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
                handelGetOrganizationByName={(name: string) =>
                  this.handelGetOrganizationByName(name)
                }
              />
            </Stack>
            <div className={'bookmarkwrapper'} style={{ maxHeight: this.props.horizontalReSizer }}>
              {this.state.collapseBookmarkPane && window.innerWidth > 1440 ? <CollapseBookMarkPane
                isEdit={this.props.isEdit}
                divClass={this.state.bookmarkDivClass}
                currentActiveSection={this.state.currentActiveSection}
                list={this.bookmarkList}
                toggleBookmark={this._toggleBookmark}
                toggleBookmarkOut={this._toggleBookmarkOut}
                pmolType={this.state.pmolType}
                isVp={true}
              /> : <BookMarkPaneComponent
                isEdit={this.props.isEdit}
                divClass={this.state.bookmarkDivClass}
                currentActiveSection={this.state.currentActiveSection}
                list={this.bookmarkList}
                toggleBookmark={this._toggleBookmark}
                toggleBookmarkOut={this._toggleBookmarkOut}
                pmolType={this.state.pmolType}
                horizontalReSizer={this.props.horizontalReSizer}
              />}</div>


          </Stack>
        </Stack>
      </div>
    );
  };

  handelFilter = (filter: PMOLListPaneFilter) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState(
        {
          pmolFilter: filter,
        },
        this.reloadListPaneItem,
      );
    } else {
      this.setState({
        pmolFilter: filter,
      });
    }
  };

  handelGetOrganizationByName = (name: string) => {
    this.props.getOrganizationByName(name);
  };

  handelShortCutPaneFilter = (selectedItem: PMOLShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        {this.getDocumentPaneView()}
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/projects/project/${this.state.projectId}/product/pbs`);
  };

  private hideDocumentPane = () => {
    const paths = window.location.pathname.split('/');
    if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/project-molecule`);
    }
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent' });
  };

  private openNickNameModel = (): void => {
    this.setState({ isOpenNickNameModel: !this.state.isOpenNickNameModel });
  };

  private _handleRemove = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    if (this.state.formData.productId && this.state.formData.id) {
      this.props.deleteProduct([this.state.formData.id]);
      this.createNew(true);
      history.push(`/projects/project/${this.state.projectId}/product/pbs/new`);
    }
  };

  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

  private exportExcel = (): void => {
    if (this.props.excelData) {
      excelExport(
        this.props.excelData,
        this.props.t('corporateProductCatalogue'),
        this.props.t('corporateProductCatalogue'),
      );
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortCutPaneFilters: state.pmol.pmolShortCutdata,
    listPaneItems: state.pmol.listPaneItems,
    isDataLoaded: state.pmol.isPMOLListLoaded,
    loadMsg: state.pmol.loadMsg,
    uid: state.pmol.uid,
    formData: state.pmol.formData,
    showProgressBar: state.pmol.showProgressBar,
    isEdit: state.pmol.isEdit,

    isChange: state.projectBreakdown.isChange,
    cpcListItem: state.productCatalogue.cpcListItem,
    uploadedImages: state.productCatalogue.uploadedImages,
    resourceTypes: state.productCatalogue.resourceTypes,
    resourceTypeFilter: state.productCatalogue.resourceTypeFilter,
    resourceFamily: state.productCatalogue.resourceFamily,
    cpcBasicUnitOfMeasure: state.productCatalogue.cpcBasicUnitOfMeasure,
    cpcPressureClass: state.productCatalogue.cpcPressureClass,
    cpcMaterial: state.productCatalogue.cpcMaterial,
    cpcBrand: state.productCatalogue.cpcBrand,
    languageCodes: state.productCatalogue.languageCodes,
    autoSave: state.productCatalogue.autoSave,
    organizations: state.productCatalogue.organizations,
    cpcUnitOfSizeMeasure: state.productCatalogue.cpcUnitOfSizeMeasure,
    excelData: state.productCatalogue.excelData,
    selectedProject: state.project.selectedProject,
  };
};

const mapDispatchToProps = {
  readPMOLShortCutPaneData,
  filterPMOLListPaneData,
  resetIsChange,
  readPMOLDropDownData,
  readByPMOLId,
  setUid,
  clearFormData,
  getRiskDropDownsList,
  clonePmol,
  addUserProject,
  getProjectDefinition,
  readPmolData,
  readPMOLByID,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(VPPMOLDocumentPane)),
);
